export const FastSpringPriceFiltered: any = {
  'pdf-one-year-multi-ocr': {
    action: 'product.price.getall',
    result: 'success',
    product: 'pdf-one-year-multi-ocr',
    pricing: {
      PR: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PS: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PT: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '57,00 €',
            unitPrice: 90,
            unitPriceDisplay: '90,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PW: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PY: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      QA: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AD: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AE: {
        currency: 'USD',
        price: 138.6,
        display: '$138.60',
        quantityDiscount: {
          '1': {
            discountValue: 56.7,
            discountValueDisplay: '$56.70',
            unitPrice: 81.9,
            unitPriceDisplay: '$81.90',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AF: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AG: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AI: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AL: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AM: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AN: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AO: {
        currency: 'USD',
        price: 132,
        display: '132,00 US$',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '54,00 US$',
            unitPrice: 78,
            unitPriceDisplay: '78,00 US$',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AQ: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AR: {
        currency: 'USD',
        price: 132,
        display: 'US$ 132,00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$ 54,00',
            unitPrice: 78,
            unitPriceDisplay: 'US$ 78,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AS: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AT: {
        currency: 'EUR',
        price: 147,
        display: '€ 147,00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€ 57,00',
            unitPrice: 90,
            unitPriceDisplay: '€ 90,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      RE: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AU: {
        currency: 'AUD',
        price: 159,
        display: '$159.00',
        quantityDiscount: {
          '1': {
            discountValue: 69,
            discountValueDisplay: '$69.00',
            unitPrice: 90,
            unitPriceDisplay: '$90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AW: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AX: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AZ: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      RO: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BA: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BB: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      RS: {
        currency: 'EUR',
        price: 147,
        display: '147,00 EUR',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '57,00 EUR',
            unitPrice: 90,
            unitPriceDisplay: '90,00 EUR',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BD: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BE: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '57,00 €',
            unitPrice: 90,
            unitPriceDisplay: '90,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      RU: {
        currency: 'USD',
        price: 158.4,
        display: '158,40 $',
        quantityDiscount: {
          '1': {
            discountValue: 64.8,
            discountValueDisplay: '64,80 $',
            unitPrice: 93.6,
            unitPriceDisplay: '93,60 $',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BF: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BG: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      RW: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BH: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BI: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BJ: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BL: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BM: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BN: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BO: {
        currency: 'USD',
        price: 132,
        display: 'USD132,00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'USD54,00',
            unitPrice: 78,
            unitPriceDisplay: 'USD78,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SA: {
        currency: 'USD',
        price: 151.8,
        display: '$151.80',
        quantityDiscount: {
          '1': {
            discountValue: 62.1,
            discountValueDisplay: '$62.10',
            unitPrice: 89.7,
            unitPriceDisplay: '$89.70',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BQ: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SB: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BR: {
        currency: 'USD',
        price: 132,
        display: 'US$ 132,00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$ 54,00',
            unitPrice: 78,
            unitPriceDisplay: 'US$ 78,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SC: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BS: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SD: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BT: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SE: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '57,00 €',
            unitPrice: 90,
            unitPriceDisplay: '90,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BV: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SG: {
        currency: 'USD',
        price: 143.88,
        display: 'US$143.88',
        quantityDiscount: {
          '1': {
            discountValue: 58.86,
            discountValueDisplay: 'US$58.86',
            unitPrice: 85.02,
            unitPriceDisplay: 'US$85.02',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BW: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SH: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SI: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '57,00 €',
            unitPrice: 90,
            unitPriceDisplay: '90,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BY: {
        currency: 'USD',
        price: 158.4,
        display: '$158.40',
        quantityDiscount: {
          '1': {
            discountValue: 64.8,
            discountValueDisplay: '$64.80',
            unitPrice: 93.6,
            unitPriceDisplay: '$93.60',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SJ: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BZ: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SK: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '57,00 €',
            unitPrice: 90,
            unitPriceDisplay: '90,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SL: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SM: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SN: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SO: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CA: {
        currency: 'CAD',
        price: 159,
        display: '$159.00',
        quantityDiscount: {
          '1': {
            discountValue: 69,
            discountValueDisplay: '$69.00',
            unitPrice: 90,
            unitPriceDisplay: '$90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SR: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CC: {
        currency: 'AUD',
        price: 159,
        display: '$159.00',
        quantityDiscount: {
          '1': {
            discountValue: 69,
            discountValueDisplay: '$69.00',
            unitPrice: 90,
            unitPriceDisplay: '$90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SS: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CD: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ST: {
        currency: 'USD',
        price: 132,
        display: '132,00 US$',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '54,00 US$',
            unitPrice: 78,
            unitPriceDisplay: '78,00 US$',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CF: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SV: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CG: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CH: {
        currency: 'EUR',
        price: 147,
        display: '€ 147,00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€ 57,00',
            unitPrice: 90,
            unitPriceDisplay: '€ 90,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SX: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CI: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SY: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SZ: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CK: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CL: {
        currency: 'USD',
        price: 157.08,
        display: 'US$157,08',
        quantityDiscount: {
          '1': {
            discountValue: 64.26,
            discountValueDisplay: 'US$64,26',
            unitPrice: 92.82,
            unitPriceDisplay: 'US$92,82',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CM: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CN: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CO: {
        currency: 'USD',
        price: 157.08,
        display: 'US$ 157,08',
        quantityDiscount: {
          '1': {
            discountValue: 64.26,
            discountValueDisplay: 'US$ 64,26',
            unitPrice: 92.82,
            unitPriceDisplay: 'US$ 92,82',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CR: {
        currency: 'USD',
        price: 132,
        display: 'USD132,00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'USD54,00',
            unitPrice: 78,
            unitPriceDisplay: 'USD78,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TC: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TD: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CU: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TF: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CV: {
        currency: 'USD',
        price: 132,
        display: '132,00 US$',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '54,00 US$',
            unitPrice: 78,
            unitPriceDisplay: '78,00 US$',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TG: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CW: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TH: {
        currency: 'USD',
        price: 141.24,
        display: '$141.24',
        quantityDiscount: {
          '1': {
            discountValue: 57.78,
            discountValueDisplay: '$57.78',
            unitPrice: 83.46,
            unitPriceDisplay: '$83.46',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CX: {
        currency: 'AUD',
        price: 159,
        display: '$159.00',
        quantityDiscount: {
          '1': {
            discountValue: 69,
            discountValueDisplay: '$69.00',
            unitPrice: 90,
            unitPriceDisplay: '$90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CY: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TJ: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CZ: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '57,00 €',
            unitPrice: 90,
            unitPriceDisplay: '90,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TK: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TL: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TM: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TN: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TO: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TR: {
        currency: 'USD',
        price: 158.4,
        display: '$158,40',
        quantityDiscount: {
          '1': {
            discountValue: 64.8,
            discountValueDisplay: '$64,80',
            unitPrice: 93.6,
            unitPriceDisplay: '$93,60',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TT: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DE: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '57,00 €',
            unitPrice: 90,
            unitPriceDisplay: '90,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TV: {
        currency: 'AUD',
        price: 159,
        display: '$159.00',
        quantityDiscount: {
          '1': {
            discountValue: 69,
            discountValueDisplay: '$69.00',
            unitPrice: 90,
            unitPriceDisplay: '$90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TW: {
        currency: 'USD',
        price: 138.6,
        display: 'US$138.60',
        quantityDiscount: {
          '1': {
            discountValue: 56.7,
            discountValueDisplay: 'US$56.70',
            unitPrice: 81.9,
            unitPriceDisplay: 'US$81.90',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DJ: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TZ: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DK: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '57,00 €',
            unitPrice: 90,
            unitPriceDisplay: '90,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DM: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DO: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      UA: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      UG: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DZ: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      UM: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      EC: {
        currency: 'USD',
        price: 132,
        display: '$132,00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54,00',
            unitPrice: 78,
            unitPriceDisplay: '$78,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      US: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      EE: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      EG: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      EH: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      UY: {
        currency: 'USD',
        price: 132,
        display: 'US$ 132,00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$ 54,00',
            unitPrice: 78,
            unitPriceDisplay: 'US$ 78,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      UZ: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VA: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ER: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VC: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ES: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '57,00 €',
            unitPrice: 90,
            unitPriceDisplay: '90,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ET: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VE: {
        currency: 'USD',
        price: 132,
        display: 'USD132,00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'USD54,00',
            unitPrice: 78,
            unitPriceDisplay: 'USD78,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VG: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VI: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VN: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VU: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FI: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '57,00 €',
            unitPrice: 90,
            unitPriceDisplay: '90,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FJ: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FK: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FM: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FO: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FR: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '57,00 €',
            unitPrice: 90,
            unitPriceDisplay: '90,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      WF: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GA: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GB: {
        currency: 'GBP',
        price: 132,
        display: '£132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '£54.00',
            unitPrice: 78,
            unitPriceDisplay: '£78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      WS: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GD: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GE: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GF: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GG: {
        currency: 'GBP',
        price: 132,
        display: '£132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '£54.00',
            unitPrice: 78,
            unitPriceDisplay: '£78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GH: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GI: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GL: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GM: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GN: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GP: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GQ: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GR: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '57,00 €',
            unitPrice: 90,
            unitPriceDisplay: '90,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GS: {
        currency: 'GBP',
        price: 132,
        display: '£132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '£54.00',
            unitPrice: 78,
            unitPriceDisplay: '£78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GT: {
        currency: 'USD',
        price: 132,
        display: 'USD132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'USD54.00',
            unitPrice: 78,
            unitPriceDisplay: 'USD78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GU: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GW: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GY: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HK: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HM: {
        currency: 'AUD',
        price: 159,
        display: 'A$159.00',
        quantityDiscount: {
          '1': {
            discountValue: 69,
            discountValueDisplay: 'A$69.00',
            unitPrice: 90,
            unitPriceDisplay: 'A$90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HN: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HR: {
        currency: 'EUR',
        price: 147,
        display: '147,00 EUR',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '57,00 EUR',
            unitPrice: 90,
            unitPriceDisplay: '90,00 EUR',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HT: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      YE: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HU: {
        currency: 'EUR',
        price: 147,
        display: '147,00 EUR',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '57,00 EUR',
            unitPrice: 90,
            unitPriceDisplay: '90,00 EUR',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IC: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '57,00 €',
            unitPrice: 90,
            unitPriceDisplay: '90,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ID: {
        currency: 'USD',
        price: 146.52,
        display: '$146.52',
        quantityDiscount: {
          '1': {
            discountValue: 59.94,
            discountValueDisplay: '$59.94',
            unitPrice: 86.58,
            unitPriceDisplay: '$86.58',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      YT: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IE: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IL: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IM: {
        currency: 'GBP',
        price: 132,
        display: '£132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '£54.00',
            unitPrice: 78,
            unitPriceDisplay: '£78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IN: {
        currency: 'USD',
        price: 155.76,
        display: 'US$ 155.76',
        quantityDiscount: {
          '1': {
            discountValue: 63.72,
            discountValueDisplay: 'US$ 63.72',
            unitPrice: 92.04,
            unitPriceDisplay: 'US$ 92.04',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IO: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ZA: {
        currency: 'USD',
        price: 151.8,
        display: 'US$151,80',
        quantityDiscount: {
          '1': {
            discountValue: 62.1,
            discountValueDisplay: 'US$62,10',
            unitPrice: 89.7,
            unitPriceDisplay: 'US$89,70',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IQ: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IR: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IS: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IT: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '57,00 €',
            unitPrice: 90,
            unitPriceDisplay: '90,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ZM: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      JE: {
        currency: 'GBP',
        price: 132,
        display: '£132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '£54.00',
            unitPrice: 78,
            unitPriceDisplay: '£78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ZW: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      JM: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      JO: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      JP: {
        currency: 'USD',
        price: 145.2,
        display: '$145.20',
        quantityDiscount: {
          '1': {
            discountValue: 59.4,
            discountValueDisplay: '$59.40',
            unitPrice: 85.8,
            unitPriceDisplay: '$85.80',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KE: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KG: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KH: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KI: {
        currency: 'AUD',
        price: 159,
        display: '$159.00',
        quantityDiscount: {
          '1': {
            discountValue: 69,
            discountValueDisplay: '$69.00',
            unitPrice: 90,
            unitPriceDisplay: '$90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KM: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KN: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KP: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KR: {
        currency: 'USD',
        price: 145.2,
        display: 'US$145.20',
        quantityDiscount: {
          '1': {
            discountValue: 59.4,
            discountValueDisplay: 'US$59.40',
            unitPrice: 85.8,
            unitPriceDisplay: 'US$85.80',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KW: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KY: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KZ: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LA: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LB: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LC: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LI: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LK: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LR: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LS: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LT: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '57,00 €',
            unitPrice: 90,
            unitPriceDisplay: '90,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LU: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LV: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '57,00 €',
            unitPrice: 90,
            unitPriceDisplay: '90,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LY: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MA: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MC: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MD: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ME: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MF: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MG: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MH: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MK: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ML: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MM: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MN: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MO: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MP: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MQ: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MR: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MS: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MT: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MU: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MV: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MW: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MX: {
        currency: 'USD',
        price: 132,
        display: 'USD132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'USD54.00',
            unitPrice: 78,
            unitPriceDisplay: 'USD78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MY: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MZ: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NA: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NC: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NE: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NF: {
        currency: 'AUD',
        price: 159,
        display: '$159.00',
        quantityDiscount: {
          '1': {
            discountValue: 69,
            discountValueDisplay: '$69.00',
            unitPrice: 90,
            unitPriceDisplay: '$90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NG: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NI: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NL: {
        currency: 'EUR',
        price: 147,
        display: '€ 147,00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€ 57,00',
            unitPrice: 90,
            unitPriceDisplay: '€ 90,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NO: {
        currency: 'EUR',
        price: 147,
        display: '€ 147,00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€ 57,00',
            unitPrice: 90,
            unitPriceDisplay: '€ 90,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NP: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NR: {
        currency: 'AUD',
        price: 159,
        display: '$159.00',
        quantityDiscount: {
          '1': {
            discountValue: 69,
            discountValueDisplay: '$69.00',
            unitPrice: 90,
            unitPriceDisplay: '$90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NU: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NZ: {
        currency: 'USD',
        price: 151.8,
        display: 'US$151.80',
        quantityDiscount: {
          '1': {
            discountValue: 62.1,
            discountValueDisplay: 'US$62.10',
            unitPrice: 89.7,
            unitPriceDisplay: 'US$89.70',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      OM: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PA: {
        currency: 'USD',
        price: 132,
        display: 'USD132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'USD54.00',
            unitPrice: 78,
            unitPriceDisplay: 'USD78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PE: {
        currency: 'USD',
        price: 132,
        display: 'USD132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'USD54.00',
            unitPrice: 78,
            unitPriceDisplay: 'USD78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PF: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PG: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PH: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PK: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PL: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '57,00 €',
            unitPrice: 90,
            unitPriceDisplay: '90,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PM: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PN: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
    },
  },
  'ocr-m': {
    action: 'product.price.getall',
    result: 'success',
    product: 'ocr-m',
    pricing: {
      PR: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PS: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PT: {
        currency: 'EUR',
        price: 5,
        display: '5,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PW: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PY: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      QA: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AD: {
        currency: 'EUR',
        price: 4,
        display: '€4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AE: {
        currency: 'USD',
        price: 4.2,
        display: '$4.20',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AF: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AG: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AI: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AL: {
        currency: 'EUR',
        price: 4,
        display: '€4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AM: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AN: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AO: {
        currency: 'USD',
        price: 4,
        display: '4,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AQ: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AR: {
        currency: 'USD',
        price: 4,
        display: 'US$ 4,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AS: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AT: {
        currency: 'EUR',
        price: 5,
        display: '€ 5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RE: {
        currency: 'EUR',
        price: 4,
        display: '€4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AU: {
        currency: 'AUD',
        price: 7,
        display: '$7.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AW: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AX: {
        currency: 'EUR',
        price: 4,
        display: '€4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AZ: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RO: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BA: {
        currency: 'EUR',
        price: 4,
        display: '€4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BB: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RS: {
        currency: 'EUR',
        price: 5,
        display: '5,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BD: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BE: {
        currency: 'EUR',
        price: 5,
        display: '5,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RU: {
        currency: 'USD',
        price: 4.8,
        display: '4,80 $',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BF: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BG: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RW: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BH: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BI: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BJ: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BL: {
        currency: 'EUR',
        price: 4,
        display: '€4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BM: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BN: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BO: {
        currency: 'USD',
        price: 4,
        display: 'USD4,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SA: {
        currency: 'USD',
        price: 4.6,
        display: '$4.60',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BQ: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SB: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BR: {
        currency: 'USD',
        price: 4,
        display: 'US$ 4,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SC: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BS: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SD: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BT: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SE: {
        currency: 'EUR',
        price: 5,
        display: '5,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BV: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SG: {
        currency: 'USD',
        price: 4.36,
        display: 'US$4.36',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BW: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SH: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SI: {
        currency: 'EUR',
        price: 5,
        display: '5,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BY: {
        currency: 'USD',
        price: 4.8,
        display: '$4.80',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SJ: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BZ: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SK: {
        currency: 'EUR',
        price: 5,
        display: '5,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SL: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SM: {
        currency: 'EUR',
        price: 4,
        display: '€4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SN: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SO: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CA: {
        currency: 'CAD',
        price: 6,
        display: '$6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SR: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CC: {
        currency: 'AUD',
        price: 7,
        display: '$7.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SS: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CD: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ST: {
        currency: 'USD',
        price: 4,
        display: '4,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CF: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SV: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CG: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CH: {
        currency: 'EUR',
        price: 5,
        display: '€ 5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SX: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CI: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SY: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SZ: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CK: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CL: {
        currency: 'USD',
        price: 4.76,
        display: 'US$4,76',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CM: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CN: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CO: {
        currency: 'USD',
        price: 4,
        display: 'US$ 4,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CR: {
        currency: 'USD',
        price: 4,
        display: 'USD4,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TC: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TD: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CU: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TF: {
        currency: 'EUR',
        price: 4,
        display: '€4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CV: {
        currency: 'USD',
        price: 4,
        display: '4,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TG: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CW: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TH: {
        currency: 'USD',
        price: 4.28,
        display: '$4.28',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CX: {
        currency: 'AUD',
        price: 7,
        display: '$7.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CY: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TJ: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CZ: {
        currency: 'EUR',
        price: 5,
        display: '5,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TK: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TL: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TM: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TN: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TO: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TR: {
        currency: 'USD',
        price: 4.8,
        display: '$4,80',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TT: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DE: {
        currency: 'EUR',
        price: 5,
        display: '5,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TV: {
        currency: 'AUD',
        price: 7,
        display: '$7.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TW: {
        currency: 'USD',
        price: 4.2,
        display: 'US$4.20',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DJ: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TZ: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DK: {
        currency: 'EUR',
        price: 5,
        display: '5,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DM: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DO: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UA: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UG: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DZ: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UM: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EC: {
        currency: 'USD',
        price: 4,
        display: '$4,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      US: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EE: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EG: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EH: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UY: {
        currency: 'USD',
        price: 4,
        display: 'US$ 4,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UZ: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VA: {
        currency: 'EUR',
        price: 4,
        display: '€4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ER: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VC: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ES: {
        currency: 'EUR',
        price: 5,
        display: '5,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ET: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VE: {
        currency: 'USD',
        price: 4,
        display: 'USD4,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VG: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VI: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VN: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VU: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FI: {
        currency: 'EUR',
        price: 5,
        display: '5,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FJ: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FK: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FM: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FO: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FR: {
        currency: 'EUR',
        price: 5,
        display: '5,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WF: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GA: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GB: {
        currency: 'GBP',
        price: 4,
        display: '£4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WS: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GD: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GE: {
        currency: 'EUR',
        price: 4,
        display: '€4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GF: {
        currency: 'EUR',
        price: 4,
        display: '€4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GG: {
        currency: 'GBP',
        price: 4,
        display: '£4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GH: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GI: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GL: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GM: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GN: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GP: {
        currency: 'EUR',
        price: 4,
        display: '€4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GQ: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GR: {
        currency: 'EUR',
        price: 5,
        display: '5,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GS: {
        currency: 'GBP',
        price: 4,
        display: '£4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GT: {
        currency: 'USD',
        price: 4,
        display: 'USD4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GU: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GW: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GY: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HK: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HM: {
        currency: 'AUD',
        price: 7,
        display: 'A$7.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HN: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HR: {
        currency: 'EUR',
        price: 5,
        display: '5,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HT: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YE: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HU: {
        currency: 'EUR',
        price: 5,
        display: '5,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IC: {
        currency: 'EUR',
        price: 4,
        display: '4,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ID: {
        currency: 'USD',
        price: 4.44,
        display: '$4.44',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YT: {
        currency: 'EUR',
        price: 4,
        display: '€4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IE: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IL: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IM: {
        currency: 'GBP',
        price: 4,
        display: '£4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IN: {
        currency: 'USD',
        price: 4.72,
        display: 'US$ 4.72',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IO: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZA: {
        currency: 'USD',
        price: 4.6,
        display: 'US$4,60',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IQ: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IR: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IS: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IT: {
        currency: 'EUR',
        price: 5,
        display: '5,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZM: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JE: {
        currency: 'GBP',
        price: 4,
        display: '£4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZW: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JM: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JO: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JP: {
        currency: 'USD',
        price: 4.4,
        display: '$4.40',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KE: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KG: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KH: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KI: {
        currency: 'AUD',
        price: 7,
        display: '$7.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KM: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KN: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KP: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KR: {
        currency: 'USD',
        price: 4.4,
        display: 'US$4.40',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KW: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KY: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KZ: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LA: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LB: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LC: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LI: {
        currency: 'EUR',
        price: 4,
        display: '€4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LK: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LR: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LS: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LT: {
        currency: 'EUR',
        price: 5,
        display: '5,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LU: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LV: {
        currency: 'EUR',
        price: 5,
        display: '5,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LY: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MA: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MC: {
        currency: 'EUR',
        price: 4,
        display: '€4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MD: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ME: {
        currency: 'EUR',
        price: 4,
        display: '€4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MF: {
        currency: 'EUR',
        price: 4,
        display: '€4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MG: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MH: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MK: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ML: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MM: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MN: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MO: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MP: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MQ: {
        currency: 'EUR',
        price: 4,
        display: '€4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MR: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MS: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MT: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MU: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MV: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MW: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MX: {
        currency: 'USD',
        price: 4,
        display: 'USD4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MY: {
        currency: 'USD',
        price: 4.32,
        display: 'US$4.32',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MZ: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NA: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NC: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NE: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NF: {
        currency: 'AUD',
        price: 7,
        display: '$7.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NG: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NI: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NL: {
        currency: 'EUR',
        price: 5,
        display: '€ 5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NO: {
        currency: 'EUR',
        price: 5,
        display: '€ 5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NP: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NR: {
        currency: 'AUD',
        price: 7,
        display: '$7.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NU: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NZ: {
        currency: 'USD',
        price: 4.6,
        display: 'US$4.60',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      OM: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PA: {
        currency: 'USD',
        price: 4,
        display: 'USD4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PE: {
        currency: 'USD',
        price: 4,
        display: 'USD4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PF: {
        currency: 'USD',
        price: 4,
        display: '$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PG: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PH: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PK: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PL: {
        currency: 'EUR',
        price: 5,
        display: '5,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PM: {
        currency: 'EUR',
        price: 4,
        display: '€4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PN: {
        currency: 'USD',
        price: 4,
        display: 'US$4.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
    },
  },
  'pdf-monthly-multi-ocr-uk': {
    action: 'product.price.getall',
    result: 'success',
    product: 'pdf-monthly-multi-ocr-uk',
    pricing: {
      PR: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PS: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PT: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PY: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      QA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AD: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AE: {
        currency: 'USD',
        price: 5.25,
        display: '$5.25',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AI: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AL: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AO: {
        currency: 'USD',
        price: 5,
        display: '5,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AQ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AR: {
        currency: 'USD',
        price: 5,
        display: 'US$ 5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AS: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AT: {
        currency: 'EUR',
        price: 6,
        display: '€ 6,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RE: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AU: {
        currency: 'AUD',
        price: 10,
        display: '$10.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AW: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AX: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RO: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BA: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BB: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RS: {
        currency: 'EUR',
        price: 6,
        display: '6,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BD: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BE: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RU: {
        currency: 'USD',
        price: 6,
        display: '6,00 $',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BG: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BH: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BI: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BJ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BL: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BO: {
        currency: 'USD',
        price: 5,
        display: 'USD5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SA: {
        currency: 'USD',
        price: 5.75,
        display: '$5.75',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BQ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SB: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BR: {
        currency: 'USD',
        price: 5,
        display: 'US$ 5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SC: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SD: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BT: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SE: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BV: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SG: {
        currency: 'USD',
        price: 5.45,
        display: 'US$5.45',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SH: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SI: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BY: {
        currency: 'USD',
        price: 6,
        display: '$6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SJ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BZ: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SK: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SL: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SM: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SO: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CA: {
        currency: 'CAD',
        price: 7,
        display: '$7.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SR: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CC: {
        currency: 'AUD',
        price: 10,
        display: '$10.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CD: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ST: {
        currency: 'USD',
        price: 5,
        display: '5,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SV: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CG: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CH: {
        currency: 'EUR',
        price: 6,
        display: '€ 6,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SX: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CI: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SY: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SZ: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CL: {
        currency: 'USD',
        price: 5.95,
        display: 'US$5,95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CN: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CO: {
        currency: 'USD',
        price: 5.95,
        display: 'US$ 5,95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CR: {
        currency: 'USD',
        price: 5,
        display: 'USD5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TC: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TD: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CU: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TF: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CV: {
        currency: 'USD',
        price: 5,
        display: '5,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TG: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CW: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TH: {
        currency: 'USD',
        price: 5.35,
        display: '$5.35',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CX: {
        currency: 'AUD',
        price: 10,
        display: '$10.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CY: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TJ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CZ: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TL: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TO: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TR: {
        currency: 'USD',
        price: 6,
        display: '$6,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TT: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DE: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TV: {
        currency: 'AUD',
        price: 10,
        display: '$10.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TW: {
        currency: 'USD',
        price: 5.25,
        display: 'US$5.25',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DJ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TZ: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DK: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DO: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EC: {
        currency: 'USD',
        price: 5,
        display: '$5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      US: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EE: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EG: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EH: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UY: {
        currency: 'USD',
        price: 5,
        display: 'US$ 5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VA: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ER: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VC: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ES: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ET: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VE: {
        currency: 'USD',
        price: 5,
        display: 'USD5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VI: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VU: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FI: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FJ: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FO: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FR: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GB: {
        currency: 'GBP',
        price: 7,
        display: '£7.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GD: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GE: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GF: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GG: {
        currency: 'GBP',
        price: 7,
        display: '£7.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GH: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GI: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GL: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GP: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GQ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GR: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GS: {
        currency: 'GBP',
        price: 7,
        display: '£7.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GT: {
        currency: 'USD',
        price: 5,
        display: 'USD5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GU: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GW: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GY: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HM: {
        currency: 'AUD',
        price: 10,
        display: 'A$10.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HR: {
        currency: 'EUR',
        price: 6,
        display: '6,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HT: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YE: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HU: {
        currency: 'EUR',
        price: 7,
        display: '7,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IC: {
        currency: 'EUR',
        price: 5,
        display: '5,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ID: {
        currency: 'USD',
        price: 5.55,
        display: '$5.55',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YT: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IE: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IL: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IM: {
        currency: 'GBP',
        price: 7,
        display: '£7.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IN: {
        currency: 'USD',
        price: 5.9,
        display: 'US$ 5.90',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IO: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZA: {
        currency: 'USD',
        price: 5.75,
        display: 'US$5,75',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IQ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IR: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IS: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IT: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JE: {
        currency: 'GBP',
        price: 7,
        display: '£7.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JO: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JP: {
        currency: 'USD',
        price: 5.5,
        display: '$5.50',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KE: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KG: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KH: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KI: {
        currency: 'AUD',
        price: 10,
        display: '$10.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KN: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KP: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KR: {
        currency: 'USD',
        price: 5.5,
        display: 'US$5.50',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KW: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KY: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LB: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LC: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LI: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LK: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LR: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LT: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LU: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LV: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LY: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MC: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MD: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ME: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MF: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MH: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MK: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ML: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MO: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MP: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MQ: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MR: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MT: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MU: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MV: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MX: {
        currency: 'USD',
        price: 5,
        display: 'USD5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MY: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NA: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NC: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NE: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NF: {
        currency: 'AUD',
        price: 10,
        display: '$10.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NI: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NL: {
        currency: 'EUR',
        price: 6,
        display: '€ 6,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NO: {
        currency: 'EUR',
        price: 6,
        display: '€ 6,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NP: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NR: {
        currency: 'AUD',
        price: 10,
        display: '$10.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NU: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NZ: {
        currency: 'USD',
        price: 5.75,
        display: 'US$5.75',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      OM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PA: {
        currency: 'USD',
        price: 5,
        display: 'USD5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PE: {
        currency: 'USD',
        price: 5,
        display: 'USD5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PH: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PL: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PM: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PN: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
    },
  },
  'example-pro': {
    action: 'product.price.getall',
    result: 'success',
    product: 'example-pro',
    pricing: {
      PR: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      PS: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      PT: {
        currency: 'EUR',
        price: 71,
        display: '71,00 €',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      PW: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      PY: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      QA: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AD: {
        currency: 'EUR',
        price: 58,
        display: '€58.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AE: {
        currency: 'USD',
        price: 63,
        display: '$63.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AF: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AG: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AI: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AL: {
        currency: 'EUR',
        price: 58,
        display: '€58.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AM: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AN: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AO: {
        currency: 'USD',
        price: 60,
        display: '60,00 US$',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AQ: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AR: {
        currency: 'USD',
        price: 60,
        display: 'US$ 60,00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AS: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AT: {
        currency: 'EUR',
        price: 69,
        display: '€ 69,00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      RE: {
        currency: 'EUR',
        price: 58,
        display: '€58.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AU: {
        currency: 'AUD',
        price: 104,
        display: '$104.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AW: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AX: {
        currency: 'EUR',
        price: 58,
        display: '€58.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AZ: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      RO: {
        currency: 'EUR',
        price: 69,
        display: '€69.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BA: {
        currency: 'EUR',
        price: 58,
        display: '€58.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BB: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      RS: {
        currency: 'EUR',
        price: 69,
        display: '69,00 EUR',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BD: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BE: {
        currency: 'EUR',
        price: 70,
        display: '70,00 €',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      RU: {
        currency: 'USD',
        price: 72,
        display: '72,00 $',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BF: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BG: {
        currency: 'EUR',
        price: 69,
        display: '€69.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      RW: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BH: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BI: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BJ: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BL: {
        currency: 'EUR',
        price: 58,
        display: '€58.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BM: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BN: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BO: {
        currency: 'USD',
        price: 60,
        display: 'USD60,00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SA: {
        currency: 'USD',
        price: 69,
        display: '$69.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BQ: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SB: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BR: {
        currency: 'USD',
        price: 60,
        display: 'US$ 60,00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SC: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BS: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SD: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BT: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SE: {
        currency: 'EUR',
        price: 72,
        display: '72,00 €',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BV: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SG: {
        currency: 'USD',
        price: 65.4,
        display: 'US$65.40',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BW: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SH: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SI: {
        currency: 'EUR',
        price: 70,
        display: '70,00 €',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BY: {
        currency: 'USD',
        price: 72,
        display: '$72.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SJ: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BZ: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SK: {
        currency: 'EUR',
        price: 69,
        display: '69,00 €',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SL: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SM: {
        currency: 'EUR',
        price: 58,
        display: '€58.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SN: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SO: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CA: {
        currency: 'CAD',
        price: 84,
        display: '$84.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SR: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CC: {
        currency: 'AUD',
        price: 95,
        display: '$95.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SS: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CD: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      ST: {
        currency: 'USD',
        price: 60,
        display: '60,00 US$',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CF: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SV: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CG: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CH: {
        currency: 'EUR',
        price: 62,
        display: '€ 62,00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SX: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CI: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SY: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SZ: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CK: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CL: {
        currency: 'USD',
        price: 71.4,
        display: 'US$71,40',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CM: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CN: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CO: {
        currency: 'USD',
        price: 71.4,
        display: 'US$ 71,40',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CR: {
        currency: 'USD',
        price: 60,
        display: 'USD60,00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TC: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TD: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CU: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TF: {
        currency: 'EUR',
        price: 58,
        display: '€58.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CV: {
        currency: 'USD',
        price: 60,
        display: '60,00 US$',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TG: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CW: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TH: {
        currency: 'USD',
        price: 64.2,
        display: '$64.20',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CX: {
        currency: 'AUD',
        price: 95,
        display: '$95.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CY: {
        currency: 'EUR',
        price: 69,
        display: '€69.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TJ: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CZ: {
        currency: 'EUR',
        price: 70,
        display: '70,00 €',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TK: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TL: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TM: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TN: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TO: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TR: {
        currency: 'USD',
        price: 72,
        display: '$72,00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TT: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      DE: {
        currency: 'EUR',
        price: 69,
        display: '69,00 €',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TV: {
        currency: 'AUD',
        price: 95,
        display: '$95.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TW: {
        currency: 'USD',
        price: 63,
        display: 'US$63.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      DJ: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TZ: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      DK: {
        currency: 'EUR',
        price: 72,
        display: '72,00 €',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      DM: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      DO: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      UA: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      UG: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      DZ: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      UM: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      EC: {
        currency: 'USD',
        price: 60,
        display: '$60,00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      US: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      EE: {
        currency: 'EUR',
        price: 70,
        display: '€70.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      EG: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      EH: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      UY: {
        currency: 'USD',
        price: 60,
        display: 'US$ 60,00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      UZ: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      VA: {
        currency: 'EUR',
        price: 58,
        display: '€58.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      ER: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      VC: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      ES: {
        currency: 'EUR',
        price: 70,
        display: '70,00 €',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      ET: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      VE: {
        currency: 'USD',
        price: 60,
        display: 'USD60,00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      VG: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      VI: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      VN: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      VU: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      FI: {
        currency: 'EUR',
        price: 71,
        display: '71,00 €',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      FJ: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      FK: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      FM: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      FO: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      FR: {
        currency: 'EUR',
        price: 69,
        display: '69,00 €',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      WF: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GA: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GB: {
        currency: 'GBP',
        price: 59,
        display: '£59.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      WS: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GD: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GE: {
        currency: 'EUR',
        price: 58,
        display: '€58.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GF: {
        currency: 'EUR',
        price: 58,
        display: '€58.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GG: {
        currency: 'GBP',
        price: 50,
        display: '£50.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GH: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GI: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GL: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GM: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GN: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GP: {
        currency: 'EUR',
        price: 58,
        display: '€58.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GQ: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GR: {
        currency: 'EUR',
        price: 71,
        display: '71,00 €',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GS: {
        currency: 'GBP',
        price: 50,
        display: '£50.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GT: {
        currency: 'USD',
        price: 60,
        display: 'USD60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GU: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GW: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GY: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      HK: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      HM: {
        currency: 'AUD',
        price: 95,
        display: 'A$95.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      HN: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      HR: {
        currency: 'EUR',
        price: 72,
        display: '72,00 EUR',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      HT: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      YE: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      HU: {
        currency: 'EUR',
        price: 73,
        display: '73,00 EUR',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      IC: {
        currency: 'EUR',
        price: 58,
        display: '58,00 €',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      ID: {
        currency: 'USD',
        price: 66.6,
        display: '$66.60',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      YT: {
        currency: 'EUR',
        price: 58,
        display: '€58.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      IE: {
        currency: 'EUR',
        price: 71,
        display: '€71.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      IL: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      IM: {
        currency: 'GBP',
        price: 50,
        display: '£50.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      IN: {
        currency: 'USD',
        price: 70.8,
        display: 'US$ 70.80',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      IO: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      ZA: {
        currency: 'USD',
        price: 69,
        display: 'US$69,00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      IQ: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      IR: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      IS: {
        currency: 'EUR',
        price: 71,
        display: '€71.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      IT: {
        currency: 'EUR',
        price: 70,
        display: '70,00 €',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      ZM: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      JE: {
        currency: 'GBP',
        price: 50,
        display: '£50.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      ZW: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      JM: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      JO: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      JP: {
        currency: 'USD',
        price: 66,
        display: '$66.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      KE: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      KG: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      KH: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      KI: {
        currency: 'AUD',
        price: 95,
        display: '$95.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      KM: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      KN: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      KP: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      KR: {
        currency: 'USD',
        price: 66,
        display: 'US$66.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      KW: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      KY: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      KZ: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      LA: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      LB: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      LC: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      LI: {
        currency: 'EUR',
        price: 58,
        display: '€58.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      LK: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      LR: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      LS: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      LT: {
        currency: 'EUR',
        price: 70,
        display: '70,00 €',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      LU: {
        currency: 'EUR',
        price: 67,
        display: '€67.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      LV: {
        currency: 'EUR',
        price: 70,
        display: '70,00 €',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      LY: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MA: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MC: {
        currency: 'EUR',
        price: 58,
        display: '€58.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MD: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      ME: {
        currency: 'EUR',
        price: 58,
        display: '€58.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MF: {
        currency: 'EUR',
        price: 58,
        display: '€58.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MG: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MH: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MK: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      ML: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MM: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MN: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MO: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MP: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MQ: {
        currency: 'EUR',
        price: 58,
        display: '€58.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MR: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MS: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MT: {
        currency: 'EUR',
        price: 68,
        display: '€68.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MU: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MV: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MW: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MX: {
        currency: 'USD',
        price: 60,
        display: 'USD60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MY: {
        currency: 'USD',
        price: 64.8,
        display: 'US$64.80',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MZ: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      NA: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      NC: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      NE: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      NF: {
        currency: 'AUD',
        price: 95,
        display: '$95.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      NG: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      NI: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      NL: {
        currency: 'EUR',
        price: 70,
        display: '€ 70,00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      NO: {
        currency: 'EUR',
        price: 72,
        display: '€ 72,00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      NP: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      NR: {
        currency: 'AUD',
        price: 95,
        display: '$95.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      NU: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      NZ: {
        currency: 'USD',
        price: 69,
        display: 'US$69.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      OM: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      PA: {
        currency: 'USD',
        price: 60,
        display: 'USD60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      PE: {
        currency: 'USD',
        price: 60,
        display: 'USD60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      PF: {
        currency: 'USD',
        price: 60,
        display: '$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      PG: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      PH: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      PK: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      PL: {
        currency: 'EUR',
        price: 71,
        display: '71,00 €',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      PM: {
        currency: 'EUR',
        price: 58,
        display: '€58.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      PN: {
        currency: 'USD',
        price: 60,
        display: 'US$60.00',
        quantityDiscount: {},
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
    },
  },
  'pdf-monthly-multi-australia': {
    action: 'product.price.getall',
    result: 'success',
    product: 'pdf-monthly-multi-australia',
    pricing: {
      PR: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PS: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PT: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PY: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      QA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AD: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AE: {
        currency: 'USD',
        price: 5.25,
        display: '$5.25',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AI: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AL: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AO: {
        currency: 'USD',
        price: 5,
        display: '5,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AQ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AR: {
        currency: 'USD',
        price: 5,
        display: 'US$ 5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AS: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AT: {
        currency: 'EUR',
        price: 6,
        display: '€ 6,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RE: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AU: {
        currency: 'AUD',
        price: 8,
        display: '$8.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AW: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AX: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RO: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BA: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BB: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RS: {
        currency: 'EUR',
        price: 6,
        display: '6,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BD: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BE: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RU: {
        currency: 'USD',
        price: 6,
        display: '6,00 $',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BG: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BH: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BI: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BJ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BL: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BO: {
        currency: 'USD',
        price: 5,
        display: 'USD5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SA: {
        currency: 'USD',
        price: 5.75,
        display: '$5.75',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BQ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SB: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BR: {
        currency: 'USD',
        price: 5,
        display: 'US$ 5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SC: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SD: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BT: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SE: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BV: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SG: {
        currency: 'USD',
        price: 5.45,
        display: 'US$5.45',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SH: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SI: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BY: {
        currency: 'USD',
        price: 6,
        display: '$6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SJ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BZ: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SK: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SL: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SM: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SO: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CA: {
        currency: 'CAD',
        price: 7,
        display: '$7.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SR: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CC: {
        currency: 'AUD',
        price: 8,
        display: '$8.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CD: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ST: {
        currency: 'USD',
        price: 5,
        display: '5,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SV: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CG: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CH: {
        currency: 'EUR',
        price: 6,
        display: '€ 6,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SX: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CI: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SY: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SZ: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CL: {
        currency: 'USD',
        price: 5.95,
        display: 'US$5,95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CN: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CO: {
        currency: 'USD',
        price: 5.95,
        display: 'US$ 5,95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CR: {
        currency: 'USD',
        price: 5,
        display: 'USD5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TC: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TD: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CU: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TF: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CV: {
        currency: 'USD',
        price: 5,
        display: '5,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TG: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CW: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TH: {
        currency: 'USD',
        price: 5.35,
        display: '$5.35',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CX: {
        currency: 'AUD',
        price: 8,
        display: '$8.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CY: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TJ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CZ: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TL: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TO: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TR: {
        currency: 'USD',
        price: 6,
        display: '$6,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TT: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DE: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TV: {
        currency: 'AUD',
        price: 8,
        display: '$8.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TW: {
        currency: 'USD',
        price: 5.25,
        display: 'US$5.25',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DJ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TZ: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DK: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DO: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EC: {
        currency: 'USD',
        price: 5,
        display: '$5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      US: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EE: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EG: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EH: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UY: {
        currency: 'USD',
        price: 5,
        display: 'US$ 5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VA: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ER: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VC: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ES: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ET: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VE: {
        currency: 'USD',
        price: 5,
        display: 'USD5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VI: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VU: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FI: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FJ: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FO: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FR: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GB: {
        currency: 'GBP',
        price: 5,
        display: '£5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GD: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GE: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GF: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GG: {
        currency: 'GBP',
        price: 5,
        display: '£5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GH: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GI: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GL: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GP: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GQ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GR: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GS: {
        currency: 'GBP',
        price: 5,
        display: '£5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GT: {
        currency: 'USD',
        price: 5,
        display: 'USD5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GU: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GW: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GY: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HM: {
        currency: 'AUD',
        price: 8,
        display: 'A$8.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HR: {
        currency: 'EUR',
        price: 6,
        display: '6,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HT: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YE: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HU: {
        currency: 'EUR',
        price: 7,
        display: '7,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IC: {
        currency: 'EUR',
        price: 5,
        display: '5,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ID: {
        currency: 'USD',
        price: 5.55,
        display: '$5.55',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YT: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IE: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IL: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IM: {
        currency: 'GBP',
        price: 5,
        display: '£5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IN: {
        currency: 'USD',
        price: 5.9,
        display: 'US$ 5.90',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IO: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZA: {
        currency: 'USD',
        price: 5.75,
        display: 'US$5,75',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IQ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IR: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IS: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IT: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JE: {
        currency: 'GBP',
        price: 5,
        display: '£5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JO: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JP: {
        currency: 'USD',
        price: 5.5,
        display: '$5.50',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KE: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KG: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KH: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KI: {
        currency: 'AUD',
        price: 8,
        display: '$8.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KN: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KP: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KR: {
        currency: 'USD',
        price: 5.5,
        display: 'US$5.50',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KW: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KY: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LB: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LC: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LI: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LK: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LR: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LT: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LU: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LV: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LY: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MC: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MD: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ME: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MF: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MH: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MK: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ML: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MO: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MP: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MQ: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MR: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MT: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MU: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MV: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MX: {
        currency: 'USD',
        price: 5,
        display: 'USD5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MY: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NA: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NC: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NE: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NF: {
        currency: 'AUD',
        price: 8,
        display: '$8.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NI: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NL: {
        currency: 'EUR',
        price: 6,
        display: '€ 6,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NO: {
        currency: 'EUR',
        price: 6,
        display: '€ 6,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NP: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NR: {
        currency: 'AUD',
        price: 8,
        display: '$8.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NU: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NZ: {
        currency: 'USD',
        price: 5.75,
        display: 'US$5.75',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      OM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PA: {
        currency: 'USD',
        price: 5,
        display: 'USD5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PE: {
        currency: 'USD',
        price: 5,
        display: 'USD5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PH: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PL: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PM: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PN: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
    },
  },
  'pdf-monthly-multi-ocr-australia': {
    action: 'product.price.getall',
    result: 'success',
    product: 'pdf-monthly-multi-ocr-australia',
    pricing: {
      PR: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PS: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PT: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PY: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      QA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AD: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AE: {
        currency: 'USD',
        price: 5.25,
        display: '$5.25',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AI: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AL: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AO: {
        currency: 'USD',
        price: 5,
        display: '5,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AQ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AR: {
        currency: 'USD',
        price: 5,
        display: 'US$ 5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AS: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AT: {
        currency: 'EUR',
        price: 6,
        display: '€ 6,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RE: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AU: {
        currency: 'AUD',
        price: 10,
        display: '$10.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AW: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AX: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RO: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BA: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BB: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RS: {
        currency: 'EUR',
        price: 6,
        display: '6,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BD: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BE: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RU: {
        currency: 'USD',
        price: 6,
        display: '6,00 $',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BG: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BH: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BI: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BJ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BL: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BO: {
        currency: 'USD',
        price: 5,
        display: 'USD5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SA: {
        currency: 'USD',
        price: 5.75,
        display: '$5.75',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BQ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SB: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BR: {
        currency: 'USD',
        price: 5,
        display: 'US$ 5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SC: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SD: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BT: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SE: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BV: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SG: {
        currency: 'USD',
        price: 5.45,
        display: 'US$5.45',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SH: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SI: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BY: {
        currency: 'USD',
        price: 6,
        display: '$6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SJ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BZ: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SK: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SL: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SM: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SO: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CA: {
        currency: 'CAD',
        price: 7,
        display: '$7.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SR: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CC: {
        currency: 'AUD',
        price: 10,
        display: '$10.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CD: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ST: {
        currency: 'USD',
        price: 5,
        display: '5,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SV: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CG: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CH: {
        currency: 'EUR',
        price: 6,
        display: '€ 6,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SX: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CI: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SY: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SZ: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CL: {
        currency: 'USD',
        price: 5.95,
        display: 'US$5,95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CN: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CO: {
        currency: 'USD',
        price: 5.95,
        display: 'US$ 5,95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CR: {
        currency: 'USD',
        price: 5,
        display: 'USD5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TC: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TD: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CU: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TF: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CV: {
        currency: 'USD',
        price: 5,
        display: '5,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TG: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CW: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TH: {
        currency: 'USD',
        price: 5.35,
        display: '$5.35',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CX: {
        currency: 'AUD',
        price: 10,
        display: '$10.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CY: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TJ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CZ: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TL: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TO: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TR: {
        currency: 'USD',
        price: 6,
        display: '$6,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TT: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DE: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TV: {
        currency: 'AUD',
        price: 10,
        display: '$10.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TW: {
        currency: 'USD',
        price: 5.25,
        display: 'US$5.25',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DJ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TZ: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DK: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DO: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EC: {
        currency: 'USD',
        price: 5,
        display: '$5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      US: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EE: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EG: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EH: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UY: {
        currency: 'USD',
        price: 5,
        display: 'US$ 5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VA: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ER: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VC: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ES: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ET: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VE: {
        currency: 'USD',
        price: 5,
        display: 'USD5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VI: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VU: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FI: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FJ: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FO: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FR: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GB: {
        currency: 'GBP',
        price: 7,
        display: '£7.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GD: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GE: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GF: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GG: {
        currency: 'GBP',
        price: 7,
        display: '£7.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GH: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GI: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GL: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GP: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GQ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GR: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GS: {
        currency: 'GBP',
        price: 7,
        display: '£7.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GT: {
        currency: 'USD',
        price: 5,
        display: 'USD5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GU: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GW: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GY: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HM: {
        currency: 'AUD',
        price: 10,
        display: 'A$10.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HR: {
        currency: 'EUR',
        price: 6,
        display: '6,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HT: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YE: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HU: {
        currency: 'EUR',
        price: 7,
        display: '7,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IC: {
        currency: 'EUR',
        price: 5,
        display: '5,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ID: {
        currency: 'USD',
        price: 5.55,
        display: '$5.55',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YT: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IE: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IL: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IM: {
        currency: 'GBP',
        price: 7,
        display: '£7.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IN: {
        currency: 'USD',
        price: 5.9,
        display: 'US$ 5.90',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IO: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZA: {
        currency: 'USD',
        price: 5.75,
        display: 'US$5,75',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IQ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IR: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IS: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IT: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JE: {
        currency: 'GBP',
        price: 7,
        display: '£7.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JO: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JP: {
        currency: 'USD',
        price: 5.5,
        display: '$5.50',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KE: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KG: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KH: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KI: {
        currency: 'AUD',
        price: 10,
        display: '$10.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KN: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KP: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KR: {
        currency: 'USD',
        price: 5.5,
        display: 'US$5.50',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KW: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KY: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LB: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LC: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LI: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LK: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LR: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LT: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LU: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LV: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LY: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MC: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MD: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ME: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MF: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MH: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MK: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ML: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MO: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MP: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MQ: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MR: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MT: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MU: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MV: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MX: {
        currency: 'USD',
        price: 5,
        display: 'USD5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MY: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NA: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NC: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NE: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NF: {
        currency: 'AUD',
        price: 10,
        display: '$10.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NI: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NL: {
        currency: 'EUR',
        price: 6,
        display: '€ 6,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NO: {
        currency: 'EUR',
        price: 6,
        display: '€ 6,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NP: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NR: {
        currency: 'AUD',
        price: 10,
        display: '$10.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NU: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NZ: {
        currency: 'USD',
        price: 5.75,
        display: 'US$5.75',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      OM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PA: {
        currency: 'USD',
        price: 5,
        display: 'USD5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PE: {
        currency: 'USD',
        price: 5,
        display: 'USD5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PH: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PL: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PM: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PN: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
    },
  },
  'pdf-perpetual': {
    action: 'product.price.getall',
    result: 'success',
    product: 'pdf-perpetual',
    pricing: {
      PR: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PS: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PT: {
        currency: 'EUR',
        price: 199,
        display: '199,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PW: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PY: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      QA: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AD: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AE: {
        currency: 'USD',
        price: 201.6,
        display: '$201.60',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AF: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AG: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AI: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AL: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AM: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AN: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AO: {
        currency: 'USD',
        price: 192,
        display: '192,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AQ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AR: {
        currency: 'USD',
        price: 192,
        display: 'US$ 192,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AS: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AT: {
        currency: 'EUR',
        price: 199,
        display: '€ 199,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RE: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AU: {
        currency: 'AUD',
        price: 249,
        display: '$249.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AW: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AX: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AZ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RO: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BA: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BB: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RS: {
        currency: 'EUR',
        price: 199,
        display: '199,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BD: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BE: {
        currency: 'EUR',
        price: 199,
        display: '199,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RU: {
        currency: 'USD',
        price: 230.4,
        display: '230,40 $',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BF: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BG: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RW: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BH: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BI: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BJ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BL: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BM: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BN: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BO: {
        currency: 'USD',
        price: 192,
        display: 'USD192,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SA: {
        currency: 'USD',
        price: 220.8,
        display: '$220.80',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BQ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SB: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BR: {
        currency: 'USD',
        price: 192,
        display: 'US$ 192,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SC: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BS: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SD: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BT: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SE: {
        currency: 'EUR',
        price: 199,
        display: '199,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BV: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SG: {
        currency: 'USD',
        price: 209.28,
        display: 'US$209.28',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BW: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SH: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SI: {
        currency: 'EUR',
        price: 199,
        display: '199,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BY: {
        currency: 'USD',
        price: 230.4,
        display: '$230.40',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SJ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BZ: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SK: {
        currency: 'EUR',
        price: 199,
        display: '199,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SL: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SM: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SN: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SO: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CA: {
        currency: 'CAD',
        price: 249,
        display: '$249.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SR: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CC: {
        currency: 'AUD',
        price: 249,
        display: '$249.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SS: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CD: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ST: {
        currency: 'USD',
        price: 192,
        display: '192,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CF: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SV: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CG: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CH: {
        currency: 'EUR',
        price: 199,
        display: '€ 199,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SX: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CI: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SY: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SZ: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CK: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CL: {
        currency: 'USD',
        price: 228.48,
        display: 'US$228,48',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CM: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CN: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CO: {
        currency: 'USD',
        price: 228.48,
        display: 'US$ 228,48',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CR: {
        currency: 'USD',
        price: 192,
        display: 'USD192,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TC: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TD: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CU: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TF: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CV: {
        currency: 'USD',
        price: 192,
        display: '192,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TG: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CW: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TH: {
        currency: 'USD',
        price: 205.44,
        display: '$205.44',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CX: {
        currency: 'AUD',
        price: 249,
        display: '$249.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CY: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TJ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CZ: {
        currency: 'EUR',
        price: 199,
        display: '199,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TK: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TL: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TM: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TN: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TO: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TR: {
        currency: 'USD',
        price: 230.4,
        display: '$230,40',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TT: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DE: {
        currency: 'EUR',
        price: 199,
        display: '199,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TV: {
        currency: 'AUD',
        price: 249,
        display: '$249.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TW: {
        currency: 'USD',
        price: 201.6,
        display: 'US$201.60',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DJ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TZ: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DK: {
        currency: 'EUR',
        price: 199,
        display: '199,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DM: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DO: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UA: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UG: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DZ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UM: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EC: {
        currency: 'USD',
        price: 192,
        display: '$192,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      US: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EE: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EG: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EH: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UY: {
        currency: 'USD',
        price: 192,
        display: 'US$ 192,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UZ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VA: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ER: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VC: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ES: {
        currency: 'EUR',
        price: 199,
        display: '199,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ET: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VE: {
        currency: 'USD',
        price: 192,
        display: 'USD192,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VG: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VI: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VN: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VU: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FI: {
        currency: 'EUR',
        price: 199,
        display: '199,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FJ: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FK: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FM: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FO: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FR: {
        currency: 'EUR',
        price: 199,
        display: '199,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WF: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GA: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GB: {
        currency: 'GBP',
        price: 189,
        display: '£189.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WS: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GD: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GE: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GF: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GG: {
        currency: 'GBP',
        price: 189,
        display: '£189.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GH: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GI: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GL: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GM: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GN: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GP: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GQ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GR: {
        currency: 'EUR',
        price: 199,
        display: '199,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GS: {
        currency: 'GBP',
        price: 189,
        display: '£189.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GT: {
        currency: 'USD',
        price: 192,
        display: 'USD192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GU: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GW: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GY: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HK: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HM: {
        currency: 'AUD',
        price: 249,
        display: 'A$249.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HN: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HR: {
        currency: 'EUR',
        price: 199,
        display: '199,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HT: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YE: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HU: {
        currency: 'EUR',
        price: 199,
        display: '199,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IC: {
        currency: 'EUR',
        price: 199,
        display: '199,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ID: {
        currency: 'USD',
        price: 213.12,
        display: '$213.12',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YT: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IE: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IL: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IM: {
        currency: 'GBP',
        price: 189,
        display: '£189.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IN: {
        currency: 'USD',
        price: 226.56,
        display: 'US$ 226.56',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IO: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZA: {
        currency: 'USD',
        price: 220.8,
        display: 'US$220,80',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IQ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IR: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IS: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IT: {
        currency: 'EUR',
        price: 199,
        display: '199,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZM: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JE: {
        currency: 'GBP',
        price: 189,
        display: '£189.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZW: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JM: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JO: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JP: {
        currency: 'USD',
        price: 211.2,
        display: '$211.20',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KE: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KG: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KH: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KI: {
        currency: 'AUD',
        price: 249,
        display: '$249.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KM: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KN: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KP: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KR: {
        currency: 'USD',
        price: 211.2,
        display: 'US$211.20',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KW: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KY: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KZ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LA: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LB: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LC: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LI: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LK: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LR: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LS: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LT: {
        currency: 'EUR',
        price: 199,
        display: '199,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LU: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LV: {
        currency: 'EUR',
        price: 199,
        display: '199,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LY: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MA: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MC: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MD: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ME: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MF: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MG: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MH: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MK: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ML: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MM: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MN: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MO: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MP: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MQ: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MR: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MS: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MT: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MU: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MV: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MW: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MX: {
        currency: 'USD',
        price: 192,
        display: 'USD192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MY: {
        currency: 'USD',
        price: 207.36,
        display: 'US$207.36',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MZ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NA: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NC: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NE: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NF: {
        currency: 'AUD',
        price: 249,
        display: '$249.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NG: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NI: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NL: {
        currency: 'EUR',
        price: 199,
        display: '€ 199,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NO: {
        currency: 'EUR',
        price: 199,
        display: '€ 199,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NP: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NR: {
        currency: 'AUD',
        price: 249,
        display: '$249.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NU: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NZ: {
        currency: 'USD',
        price: 220.8,
        display: 'US$220.80',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      OM: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PA: {
        currency: 'USD',
        price: 192,
        display: 'USD192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PE: {
        currency: 'USD',
        price: 192,
        display: 'USD192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PF: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PG: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PH: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PK: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PL: {
        currency: 'EUR',
        price: 199,
        display: '199,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PM: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PN: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
    },
  },
  'pdf-monthly-ocr-australia': {
    action: 'product.price.getall',
    result: 'success',
    product: 'pdf-monthly-ocr-australia',
    pricing: {
      PR: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PS: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PT: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PY: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      QA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AD: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AE: {
        currency: 'USD',
        price: 5.25,
        display: '$5.25',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AI: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AL: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AO: {
        currency: 'USD',
        price: 5,
        display: '5,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AQ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AR: {
        currency: 'USD',
        price: 5,
        display: 'US$ 5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AS: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AT: {
        currency: 'EUR',
        price: 6,
        display: '€ 6,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RE: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AU: {
        currency: 'AUD',
        price: 10,
        display: '$10.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AW: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AX: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RO: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BA: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BB: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RS: {
        currency: 'EUR',
        price: 6,
        display: '6,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BD: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BE: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RU: {
        currency: 'USD',
        price: 6,
        display: '6,00 $',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BG: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BH: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BI: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BJ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BL: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BO: {
        currency: 'USD',
        price: 5,
        display: 'USD5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SA: {
        currency: 'USD',
        price: 5.75,
        display: '$5.75',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BQ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SB: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BR: {
        currency: 'USD',
        price: 5,
        display: 'US$ 5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SC: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SD: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BT: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SE: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BV: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SG: {
        currency: 'USD',
        price: 5.45,
        display: 'US$5.45',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SH: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SI: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BY: {
        currency: 'USD',
        price: 6,
        display: '$6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SJ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BZ: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SK: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SL: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SM: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SO: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CA: {
        currency: 'CAD',
        price: 7,
        display: '$7.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SR: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CC: {
        currency: 'AUD',
        price: 10,
        display: '$10.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CD: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ST: {
        currency: 'USD',
        price: 5,
        display: '5,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SV: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CG: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CH: {
        currency: 'EUR',
        price: 6,
        display: '€ 6,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SX: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CI: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SY: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SZ: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CL: {
        currency: 'USD',
        price: 5.95,
        display: 'US$5,95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CN: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CO: {
        currency: 'USD',
        price: 5.95,
        display: 'US$ 5,95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CR: {
        currency: 'USD',
        price: 5,
        display: 'USD5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TC: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TD: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CU: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TF: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CV: {
        currency: 'USD',
        price: 5,
        display: '5,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TG: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CW: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TH: {
        currency: 'USD',
        price: 5.35,
        display: '$5.35',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CX: {
        currency: 'AUD',
        price: 10,
        display: '$10.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CY: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TJ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CZ: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TL: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TO: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TR: {
        currency: 'USD',
        price: 6,
        display: '$6,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TT: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DE: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TV: {
        currency: 'AUD',
        price: 10,
        display: '$10.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TW: {
        currency: 'USD',
        price: 5.25,
        display: 'US$5.25',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DJ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TZ: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DK: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DO: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EC: {
        currency: 'USD',
        price: 5,
        display: '$5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      US: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EE: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EG: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EH: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UY: {
        currency: 'USD',
        price: 5,
        display: 'US$ 5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VA: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ER: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VC: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ES: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ET: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VE: {
        currency: 'USD',
        price: 5,
        display: 'USD5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VI: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VU: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FI: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FJ: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FO: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FR: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GB: {
        currency: 'GBP',
        price: 7,
        display: '£7.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GD: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GE: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GF: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GG: {
        currency: 'GBP',
        price: 7,
        display: '£7.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GH: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GI: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GL: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GP: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GQ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GR: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GS: {
        currency: 'GBP',
        price: 7,
        display: '£7.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GT: {
        currency: 'USD',
        price: 5,
        display: 'USD5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GU: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GW: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GY: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HM: {
        currency: 'AUD',
        price: 10,
        display: 'A$10.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HR: {
        currency: 'EUR',
        price: 6,
        display: '6,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HT: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YE: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HU: {
        currency: 'EUR',
        price: 7,
        display: '7,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IC: {
        currency: 'EUR',
        price: 5,
        display: '5,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ID: {
        currency: 'USD',
        price: 5.55,
        display: '$5.55',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YT: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IE: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IL: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IM: {
        currency: 'GBP',
        price: 7,
        display: '£7.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IN: {
        currency: 'USD',
        price: 5.9,
        display: 'US$ 5.90',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IO: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZA: {
        currency: 'USD',
        price: 5.75,
        display: 'US$5,75',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IQ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IR: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IS: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IT: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JE: {
        currency: 'GBP',
        price: 7,
        display: '£7.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JO: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JP: {
        currency: 'USD',
        price: 5.5,
        display: '$5.50',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KE: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KG: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KH: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KI: {
        currency: 'AUD',
        price: 10,
        display: '$10.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KN: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KP: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KR: {
        currency: 'USD',
        price: 5.5,
        display: 'US$5.50',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KW: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KY: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LB: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LC: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LI: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LK: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LR: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LT: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LU: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LV: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LY: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MC: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MD: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ME: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MF: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MH: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MK: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ML: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MO: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MP: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MQ: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MR: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MT: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MU: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MV: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MX: {
        currency: 'USD',
        price: 5,
        display: 'USD5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MY: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NA: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NC: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NE: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NF: {
        currency: 'AUD',
        price: 10,
        display: '$10.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NI: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NL: {
        currency: 'EUR',
        price: 6,
        display: '€ 6,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NO: {
        currency: 'EUR',
        price: 6,
        display: '€ 6,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NP: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NR: {
        currency: 'AUD',
        price: 10,
        display: '$10.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NU: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NZ: {
        currency: 'USD',
        price: 5.75,
        display: 'US$5.75',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      OM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PA: {
        currency: 'USD',
        price: 5,
        display: 'USD5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PE: {
        currency: 'USD',
        price: 5,
        display: 'USD5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PH: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PL: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PM: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PN: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
    },
  },
  'pdf-monthly': {
    action: 'product.price.getall',
    result: 'success',
    product: 'pdf-monthly',
    pricing: {
      PR: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PS: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PT: {
        currency: 'EUR',
        price: 15,
        display: '15,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PW: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PY: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      QA: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AD: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AE: {
        currency: 'USD',
        price: 15.75,
        display: '$15.75',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AF: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AG: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AI: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AL: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AM: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AN: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AO: {
        currency: 'USD',
        price: 15,
        display: '15,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AQ: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AR: {
        currency: 'USD',
        price: 15,
        display: 'US$ 15,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AS: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AT: {
        currency: 'EUR',
        price: 15,
        display: '€ 15,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RE: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AU: {
        currency: 'AUD',
        price: 17,
        display: '$17.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AW: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AX: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AZ: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RO: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BA: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BB: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RS: {
        currency: 'EUR',
        price: 15,
        display: '15,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BD: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BE: {
        currency: 'EUR',
        price: 15,
        display: '15,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RU: {
        currency: 'USD',
        price: 18,
        display: '18,00 $',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BF: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BG: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RW: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BH: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BI: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BJ: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BL: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BM: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BN: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BO: {
        currency: 'USD',
        price: 15,
        display: 'USD15,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SA: {
        currency: 'USD',
        price: 17.25,
        display: '$17.25',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BQ: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SB: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BR: {
        currency: 'USD',
        price: 15,
        display: 'US$ 15,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SC: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BS: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SD: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BT: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SE: {
        currency: 'EUR',
        price: 15,
        display: '15,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BV: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SG: {
        currency: 'USD',
        price: 16.35,
        display: 'US$16.35',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BW: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SH: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SI: {
        currency: 'EUR',
        price: 15,
        display: '15,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BY: {
        currency: 'USD',
        price: 18,
        display: '$18.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SJ: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BZ: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SK: {
        currency: 'EUR',
        price: 15,
        display: '15,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SL: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SM: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SN: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SO: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CA: {
        currency: 'CAD',
        price: 17,
        display: '$17.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SR: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CC: {
        currency: 'AUD',
        price: 17,
        display: '$17.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SS: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CD: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ST: {
        currency: 'USD',
        price: 15,
        display: '15,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CF: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SV: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CG: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CH: {
        currency: 'EUR',
        price: 15,
        display: '€ 15,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SX: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CI: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SY: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SZ: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CK: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CL: {
        currency: 'USD',
        price: 17.85,
        display: 'US$17,85',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CM: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CN: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CO: {
        currency: 'USD',
        price: 17.85,
        display: 'US$ 17,85',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CR: {
        currency: 'USD',
        price: 15,
        display: 'USD15,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TC: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TD: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CU: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TF: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CV: {
        currency: 'USD',
        price: 15,
        display: '15,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TG: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CW: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TH: {
        currency: 'USD',
        price: 16.05,
        display: '$16.05',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CX: {
        currency: 'AUD',
        price: 17,
        display: '$17.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CY: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TJ: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CZ: {
        currency: 'EUR',
        price: 15,
        display: '15,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TK: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TL: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TM: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TN: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TO: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TR: {
        currency: 'USD',
        price: 18,
        display: '$18,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TT: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DE: {
        currency: 'EUR',
        price: 15,
        display: '15,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TV: {
        currency: 'AUD',
        price: 17,
        display: '$17.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TW: {
        currency: 'USD',
        price: 15.75,
        display: 'US$15.75',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DJ: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TZ: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DK: {
        currency: 'EUR',
        price: 15,
        display: '15,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DM: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DO: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UA: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UG: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DZ: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UM: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EC: {
        currency: 'USD',
        price: 15,
        display: '$15,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      US: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EE: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EG: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EH: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UY: {
        currency: 'USD',
        price: 15,
        display: 'US$ 15,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UZ: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VA: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ER: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VC: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ES: {
        currency: 'EUR',
        price: 15,
        display: '15,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ET: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VE: {
        currency: 'USD',
        price: 15,
        display: 'USD15,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VG: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VI: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VN: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VU: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FI: {
        currency: 'EUR',
        price: 15,
        display: '15,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FJ: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FK: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FM: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FO: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FR: {
        currency: 'EUR',
        price: 15,
        display: '15,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WF: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GA: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GB: {
        currency: 'GBP',
        price: 15,
        display: '£15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WS: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GD: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GE: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GF: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GG: {
        currency: 'GBP',
        price: 15,
        display: '£15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GH: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GI: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GL: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GM: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GN: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GP: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GQ: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GR: {
        currency: 'EUR',
        price: 15,
        display: '15,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GS: {
        currency: 'GBP',
        price: 15,
        display: '£15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GT: {
        currency: 'USD',
        price: 15,
        display: 'USD15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GU: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GW: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GY: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HK: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HM: {
        currency: 'AUD',
        price: 17,
        display: 'A$17.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HN: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HR: {
        currency: 'EUR',
        price: 15,
        display: '15,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HT: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YE: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HU: {
        currency: 'EUR',
        price: 15,
        display: '15,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IC: {
        currency: 'EUR',
        price: 15,
        display: '15,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ID: {
        currency: 'USD',
        price: 16.65,
        display: '$16.65',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YT: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IE: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IL: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IM: {
        currency: 'GBP',
        price: 15,
        display: '£15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IN: {
        currency: 'USD',
        price: 17.7,
        display: 'US$ 17.70',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IO: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZA: {
        currency: 'USD',
        price: 17.25,
        display: 'US$17,25',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IQ: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IR: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IS: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IT: {
        currency: 'EUR',
        price: 15,
        display: '15,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZM: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JE: {
        currency: 'GBP',
        price: 15,
        display: '£15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZW: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JM: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JO: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JP: {
        currency: 'USD',
        price: 16.5,
        display: '$16.50',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KE: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KG: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KH: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KI: {
        currency: 'AUD',
        price: 17,
        display: '$17.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KM: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KN: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KP: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KR: {
        currency: 'USD',
        price: 16.5,
        display: 'US$16.50',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KW: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KY: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KZ: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LA: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LB: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LC: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LI: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LK: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LR: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LS: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LT: {
        currency: 'EUR',
        price: 15,
        display: '15,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LU: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LV: {
        currency: 'EUR',
        price: 15,
        display: '15,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LY: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MA: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MC: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MD: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ME: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MF: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MG: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MH: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MK: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ML: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MM: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MN: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MO: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MP: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MQ: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MR: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MS: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MT: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MU: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MV: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MW: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MX: {
        currency: 'USD',
        price: 15,
        display: 'USD15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MY: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MZ: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NA: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NC: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NE: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NF: {
        currency: 'AUD',
        price: 17,
        display: '$17.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NG: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NI: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NL: {
        currency: 'EUR',
        price: 15,
        display: '€ 15,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NO: {
        currency: 'EUR',
        price: 15,
        display: '€ 15,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NP: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NR: {
        currency: 'AUD',
        price: 17,
        display: '$17.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NU: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NZ: {
        currency: 'USD',
        price: 17.25,
        display: 'US$17.25',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      OM: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PA: {
        currency: 'USD',
        price: 15,
        display: 'USD15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PE: {
        currency: 'USD',
        price: 15,
        display: 'USD15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PF: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PG: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PH: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PK: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PL: {
        currency: 'EUR',
        price: 15,
        display: '15,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PM: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PN: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
    },
  },
  'pdf-two-year-ocr': {
    action: 'product.price.getall',
    result: 'success',
    product: 'pdf-two-year-ocr',
    pricing: {
      PR: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      PS: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      PT: {
        currency: 'EUR',
        price: 310,
        display: '310,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 154.88,
            discountValueDisplay: '154,88 €',
            unitPrice: 155.12,
            unitPriceDisplay: '155,12 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      PW: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      PY: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      QA: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AD: {
        currency: 'EUR',
        price: 252,
        display: '€252.00',
        quantityDiscount: {
          '1': {
            discountValue: 125.92,
            discountValueDisplay: '€125.92',
            unitPrice: 126.08,
            unitPriceDisplay: '€126.08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AE: {
        currency: 'USD',
        price: 277.2,
        display: '$277.20',
        quantityDiscount: {
          '1': {
            discountValue: 138.6,
            discountValueDisplay: '$138.60',
            unitPrice: 138.6,
            unitPriceDisplay: '$138.60',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AF: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AG: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AI: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AL: {
        currency: 'EUR',
        price: 252,
        display: '€252.00',
        quantityDiscount: {
          '1': {
            discountValue: 125.92,
            discountValueDisplay: '€125.92',
            unitPrice: 126.08,
            unitPriceDisplay: '€126.08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AM: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AN: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AO: {
        currency: 'USD',
        price: 264,
        display: '264,00 US$',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '132,00 US$',
            unitPrice: 132,
            unitPriceDisplay: '132,00 US$',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AQ: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AR: {
        currency: 'USD',
        price: 264,
        display: 'US$ 264,00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$ 132,00',
            unitPrice: 132,
            unitPriceDisplay: 'US$ 132,00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AS: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AT: {
        currency: 'EUR',
        price: 303,
        display: '€ 303,00',
        quantityDiscount: {
          '1': {
            discountValue: 151.1,
            discountValueDisplay: '€ 151,10',
            unitPrice: 151.9,
            unitPriceDisplay: '€ 151,90',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      RE: {
        currency: 'EUR',
        price: 252,
        display: '€252.00',
        quantityDiscount: {
          '1': {
            discountValue: 125.92,
            discountValueDisplay: '€125.92',
            unitPrice: 126.08,
            unitPriceDisplay: '€126.08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AU: {
        currency: 'AUD',
        price: 456,
        display: '$456.00',
        quantityDiscount: {
          '1': {
            discountValue: 227.89,
            discountValueDisplay: '$227.89',
            unitPrice: 228.11,
            unitPriceDisplay: '$228.11',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AW: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AX: {
        currency: 'EUR',
        price: 252,
        display: '€252.00',
        quantityDiscount: {
          '1': {
            discountValue: 125.92,
            discountValueDisplay: '€125.92',
            unitPrice: 126.08,
            unitPriceDisplay: '€126.08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AZ: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      RO: {
        currency: 'EUR',
        price: 300,
        display: '€300.00',
        quantityDiscount: {
          '1': {
            discountValue: 149.84,
            discountValueDisplay: '€149.84',
            unitPrice: 150.16,
            unitPriceDisplay: '€150.16',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BA: {
        currency: 'EUR',
        price: 252,
        display: '€252.00',
        quantityDiscount: {
          '1': {
            discountValue: 125.92,
            discountValueDisplay: '€125.92',
            unitPrice: 126.08,
            unitPriceDisplay: '€126.08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BB: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      RS: {
        currency: 'EUR',
        price: 303,
        display: '303,00 EUR',
        quantityDiscount: {
          '1': {
            discountValue: 151.1,
            discountValueDisplay: '151,10 EUR',
            unitPrice: 151.9,
            unitPriceDisplay: '151,90 EUR',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BD: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BE: {
        currency: 'EUR',
        price: 305,
        display: '305,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 152.36,
            discountValueDisplay: '152,36 €',
            unitPrice: 152.64,
            unitPriceDisplay: '152,64 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      RU: {
        currency: 'USD',
        price: 316.8,
        display: '316,80 $',
        quantityDiscount: {
          '1': {
            discountValue: 158.4,
            discountValueDisplay: '158,40 $',
            unitPrice: 158.4,
            unitPriceDisplay: '158,40 $',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BF: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BG: {
        currency: 'EUR',
        price: 303,
        display: '€303.00',
        quantityDiscount: {
          '1': {
            discountValue: 151.1,
            discountValueDisplay: '€151.10',
            unitPrice: 151.9,
            unitPriceDisplay: '€151.90',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      RW: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BH: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BI: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BJ: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BL: {
        currency: 'EUR',
        price: 252,
        display: '€252.00',
        quantityDiscount: {
          '1': {
            discountValue: 125.92,
            discountValueDisplay: '€125.92',
            unitPrice: 126.08,
            unitPriceDisplay: '€126.08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BM: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BN: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BO: {
        currency: 'USD',
        price: 264,
        display: 'USD264,00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'USD132,00',
            unitPrice: 132,
            unitPriceDisplay: 'USD132,00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SA: {
        currency: 'USD',
        price: 303.6,
        display: '$303.60',
        quantityDiscount: {
          '1': {
            discountValue: 151.8,
            discountValueDisplay: '$151.80',
            unitPrice: 151.8,
            unitPriceDisplay: '$151.80',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BQ: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SB: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BR: {
        currency: 'USD',
        price: 264,
        display: 'US$ 264,00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$ 132,00',
            unitPrice: 132,
            unitPriceDisplay: 'US$ 132,00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SC: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BS: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SD: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BT: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SE: {
        currency: 'EUR',
        price: 315,
        display: '315,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 157.4,
            discountValueDisplay: '157,40 €',
            unitPrice: 157.6,
            unitPriceDisplay: '157,60 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BV: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SG: {
        currency: 'USD',
        price: 287.76,
        display: 'US$287.76',
        quantityDiscount: {
          '1': {
            discountValue: 143.88,
            discountValueDisplay: 'US$143.88',
            unitPrice: 143.88,
            unitPriceDisplay: 'US$143.88',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BW: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SH: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SI: {
        currency: 'EUR',
        price: 308,
        display: '308,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 153.62,
            discountValueDisplay: '153,62 €',
            unitPrice: 154.38,
            unitPriceDisplay: '154,38 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BY: {
        currency: 'USD',
        price: 316.8,
        display: '$316.80',
        quantityDiscount: {
          '1': {
            discountValue: 158.4,
            discountValueDisplay: '$158.40',
            unitPrice: 158.4,
            unitPriceDisplay: '$158.40',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SJ: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BZ: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SK: {
        currency: 'EUR',
        price: 303,
        display: '303,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 151.1,
            discountValueDisplay: '151,10 €',
            unitPrice: 151.9,
            unitPriceDisplay: '151,90 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SL: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SM: {
        currency: 'EUR',
        price: 252,
        display: '€252.00',
        quantityDiscount: {
          '1': {
            discountValue: 125.92,
            discountValueDisplay: '€125.92',
            unitPrice: 126.08,
            unitPriceDisplay: '€126.08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SN: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SO: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CA: {
        currency: 'CAD',
        price: 370,
        display: '$370.00',
        quantityDiscount: {
          '1': {
            discountValue: 184.65,
            discountValueDisplay: '$184.65',
            unitPrice: 185.35,
            unitPriceDisplay: '$185.35',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SR: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CC: {
        currency: 'AUD',
        price: 415,
        display: '$415.00',
        quantityDiscount: {
          '1': {
            discountValue: 207.17,
            discountValueDisplay: '$207.17',
            unitPrice: 207.83,
            unitPriceDisplay: '$207.83',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SS: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CD: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      ST: {
        currency: 'USD',
        price: 264,
        display: '264,00 US$',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '132,00 US$',
            unitPrice: 132,
            unitPriceDisplay: '132,00 US$',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CF: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SV: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CG: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CH: {
        currency: 'EUR',
        price: 273,
        display: '€ 273,00',
        quantityDiscount: {
          '1': {
            discountValue: 136.12,
            discountValueDisplay: '€ 136,12',
            unitPrice: 136.88,
            unitPriceDisplay: '€ 136,88',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SX: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CI: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SY: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SZ: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CK: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CL: {
        currency: 'USD',
        price: 314.16,
        display: 'US$314,16',
        quantityDiscount: {
          '1': {
            discountValue: 157.08,
            discountValueDisplay: 'US$157,08',
            unitPrice: 157.08,
            unitPriceDisplay: 'US$157,08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CM: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CN: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CO: {
        currency: 'USD',
        price: 314.16,
        display: 'US$ 314,16',
        quantityDiscount: {
          '1': {
            discountValue: 157.08,
            discountValueDisplay: 'US$ 157,08',
            unitPrice: 157.08,
            unitPriceDisplay: 'US$ 157,08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CR: {
        currency: 'USD',
        price: 264,
        display: 'USD264,00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'USD132,00',
            unitPrice: 132,
            unitPriceDisplay: 'USD132,00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TC: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TD: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CU: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TF: {
        currency: 'EUR',
        price: 252,
        display: '€252.00',
        quantityDiscount: {
          '1': {
            discountValue: 125.92,
            discountValueDisplay: '€125.92',
            unitPrice: 126.08,
            unitPriceDisplay: '€126.08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CV: {
        currency: 'USD',
        price: 264,
        display: '264,00 US$',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '132,00 US$',
            unitPrice: 132,
            unitPriceDisplay: '132,00 US$',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TG: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CW: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TH: {
        currency: 'USD',
        price: 282.48,
        display: '$282.48',
        quantityDiscount: {
          '1': {
            discountValue: 141.24,
            discountValueDisplay: '$141.24',
            unitPrice: 141.24,
            unitPriceDisplay: '$141.24',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CX: {
        currency: 'AUD',
        price: 415,
        display: '$415.00',
        quantityDiscount: {
          '1': {
            discountValue: 207.17,
            discountValueDisplay: '$207.17',
            unitPrice: 207.83,
            unitPriceDisplay: '$207.83',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CY: {
        currency: 'EUR',
        price: 300,
        display: '€300.00',
        quantityDiscount: {
          '1': {
            discountValue: 149.84,
            discountValueDisplay: '€149.84',
            unitPrice: 150.16,
            unitPriceDisplay: '€150.16',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TJ: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CZ: {
        currency: 'EUR',
        price: 305,
        display: '305,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 152.36,
            discountValueDisplay: '152,36 €',
            unitPrice: 152.64,
            unitPriceDisplay: '152,64 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TK: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TL: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TM: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TN: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TO: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TR: {
        currency: 'USD',
        price: 316.8,
        display: '$316,80',
        quantityDiscount: {
          '1': {
            discountValue: 158.4,
            discountValueDisplay: '$158,40',
            unitPrice: 158.4,
            unitPriceDisplay: '$158,40',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TT: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      DE: {
        currency: 'EUR',
        price: 300,
        display: '300,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 149.84,
            discountValueDisplay: '149,84 €',
            unitPrice: 150.16,
            unitPriceDisplay: '150,16 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TV: {
        currency: 'AUD',
        price: 415,
        display: '$415.00',
        quantityDiscount: {
          '1': {
            discountValue: 207.17,
            discountValueDisplay: '$207.17',
            unitPrice: 207.83,
            unitPriceDisplay: '$207.83',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TW: {
        currency: 'USD',
        price: 277.2,
        display: 'US$277.20',
        quantityDiscount: {
          '1': {
            discountValue: 138.6,
            discountValueDisplay: 'US$138.60',
            unitPrice: 138.6,
            unitPriceDisplay: 'US$138.60',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      DJ: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TZ: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      DK: {
        currency: 'EUR',
        price: 315,
        display: '315,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 157.4,
            discountValueDisplay: '157,40 €',
            unitPrice: 157.6,
            unitPriceDisplay: '157,60 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      DM: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      DO: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      UA: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      UG: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      DZ: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      UM: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      EC: {
        currency: 'USD',
        price: 264,
        display: '$264,00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132,00',
            unitPrice: 132,
            unitPriceDisplay: '$132,00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      US: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      EE: {
        currency: 'EUR',
        price: 308,
        display: '€308.00',
        quantityDiscount: {
          '1': {
            discountValue: 153.62,
            discountValueDisplay: '€153.62',
            unitPrice: 154.38,
            unitPriceDisplay: '€154.38',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      EG: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      EH: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      UY: {
        currency: 'USD',
        price: 264,
        display: 'US$ 264,00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$ 132,00',
            unitPrice: 132,
            unitPriceDisplay: 'US$ 132,00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      UZ: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      VA: {
        currency: 'EUR',
        price: 252,
        display: '€252.00',
        quantityDiscount: {
          '1': {
            discountValue: 125.92,
            discountValueDisplay: '€125.92',
            unitPrice: 126.08,
            unitPriceDisplay: '€126.08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      ER: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      VC: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      ES: {
        currency: 'EUR',
        price: 305,
        display: '305,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 152.36,
            discountValueDisplay: '152,36 €',
            unitPrice: 152.64,
            unitPriceDisplay: '152,64 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      ET: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      VE: {
        currency: 'USD',
        price: 264,
        display: 'USD264,00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'USD132,00',
            unitPrice: 132,
            unitPriceDisplay: 'USD132,00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      VG: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      VI: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      VN: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      VU: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      FI: {
        currency: 'EUR',
        price: 313,
        display: '313,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 156.14,
            discountValueDisplay: '156,14 €',
            unitPrice: 156.86,
            unitPriceDisplay: '156,86 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      FJ: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      FK: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      FM: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      FO: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      FR: {
        currency: 'EUR',
        price: 303,
        display: '303,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 151.1,
            discountValueDisplay: '151,10 €',
            unitPrice: 151.9,
            unitPriceDisplay: '151,90 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      WF: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GA: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GB: {
        currency: 'GBP',
        price: 260,
        display: '£260.00',
        quantityDiscount: {
          '1': {
            discountValue: 129.55,
            discountValueDisplay: '£129.55',
            unitPrice: 130.45,
            unitPriceDisplay: '£130.45',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      WS: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GD: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GE: {
        currency: 'EUR',
        price: 252,
        display: '€252.00',
        quantityDiscount: {
          '1': {
            discountValue: 125.92,
            discountValueDisplay: '€125.92',
            unitPrice: 126.08,
            unitPriceDisplay: '€126.08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GF: {
        currency: 'EUR',
        price: 252,
        display: '€252.00',
        quantityDiscount: {
          '1': {
            discountValue: 125.92,
            discountValueDisplay: '€125.92',
            unitPrice: 126.08,
            unitPriceDisplay: '€126.08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GG: {
        currency: 'GBP',
        price: 216,
        display: '£216.00',
        quantityDiscount: {
          '1': {
            discountValue: 107.96,
            discountValueDisplay: '£107.96',
            unitPrice: 108.04,
            unitPriceDisplay: '£108.04',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GH: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GI: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GL: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GM: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GN: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GP: {
        currency: 'EUR',
        price: 252,
        display: '€252.00',
        quantityDiscount: {
          '1': {
            discountValue: 125.92,
            discountValueDisplay: '€125.92',
            unitPrice: 126.08,
            unitPriceDisplay: '€126.08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GQ: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GR: {
        currency: 'EUR',
        price: 313,
        display: '313,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 156.14,
            discountValueDisplay: '156,14 €',
            unitPrice: 156.86,
            unitPriceDisplay: '156,86 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GS: {
        currency: 'GBP',
        price: 216,
        display: '£216.00',
        quantityDiscount: {
          '1': {
            discountValue: 107.96,
            discountValueDisplay: '£107.96',
            unitPrice: 108.04,
            unitPriceDisplay: '£108.04',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GT: {
        currency: 'USD',
        price: 264,
        display: 'USD264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'USD132.00',
            unitPrice: 132,
            unitPriceDisplay: 'USD132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GU: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GW: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GY: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      HK: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      HM: {
        currency: 'AUD',
        price: 415,
        display: 'A$415.00',
        quantityDiscount: {
          '1': {
            discountValue: 207.17,
            discountValueDisplay: 'A$207.17',
            unitPrice: 207.83,
            unitPriceDisplay: 'A$207.83',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      HN: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      HR: {
        currency: 'EUR',
        price: 315,
        display: '315,00 EUR',
        quantityDiscount: {
          '1': {
            discountValue: 157.4,
            discountValueDisplay: '157,40 EUR',
            unitPrice: 157.6,
            unitPriceDisplay: '157,60 EUR',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      HT: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      YE: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      HU: {
        currency: 'EUR',
        price: 320,
        display: '320,00 EUR',
        quantityDiscount: {
          '1': {
            discountValue: 159.92,
            discountValueDisplay: '159,92 EUR',
            unitPrice: 160.08,
            unitPriceDisplay: '160,08 EUR',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      IC: {
        currency: 'EUR',
        price: 252,
        display: '252,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 125.92,
            discountValueDisplay: '125,92 €',
            unitPrice: 126.08,
            unitPriceDisplay: '126,08 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      ID: {
        currency: 'USD',
        price: 293.04,
        display: '$293.04',
        quantityDiscount: {
          '1': {
            discountValue: 146.52,
            discountValueDisplay: '$146.52',
            unitPrice: 146.52,
            unitPriceDisplay: '$146.52',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      YT: {
        currency: 'EUR',
        price: 252,
        display: '€252.00',
        quantityDiscount: {
          '1': {
            discountValue: 125.92,
            discountValueDisplay: '€125.92',
            unitPrice: 126.08,
            unitPriceDisplay: '€126.08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      IE: {
        currency: 'EUR',
        price: 310,
        display: '€310.00',
        quantityDiscount: {
          '1': {
            discountValue: 154.88,
            discountValueDisplay: '€154.88',
            unitPrice: 155.12,
            unitPriceDisplay: '€155.12',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      IL: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      IM: {
        currency: 'GBP',
        price: 216,
        display: '£216.00',
        quantityDiscount: {
          '1': {
            discountValue: 107.96,
            discountValueDisplay: '£107.96',
            unitPrice: 108.04,
            unitPriceDisplay: '£108.04',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      IN: {
        currency: 'USD',
        price: 311.52,
        display: 'US$ 311.52',
        quantityDiscount: {
          '1': {
            discountValue: 155.76,
            discountValueDisplay: 'US$ 155.76',
            unitPrice: 155.76,
            unitPriceDisplay: 'US$ 155.76',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      IO: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      ZA: {
        currency: 'USD',
        price: 303.6,
        display: 'US$303,60',
        quantityDiscount: {
          '1': {
            discountValue: 151.8,
            discountValueDisplay: 'US$151,80',
            unitPrice: 151.8,
            unitPriceDisplay: 'US$151,80',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      IQ: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      IR: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      IS: {
        currency: 'EUR',
        price: 313,
        display: '€313.00',
        quantityDiscount: {
          '1': {
            discountValue: 156.14,
            discountValueDisplay: '€156.14',
            unitPrice: 156.86,
            unitPriceDisplay: '€156.86',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      IT: {
        currency: 'EUR',
        price: 308,
        display: '308,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 153.62,
            discountValueDisplay: '153,62 €',
            unitPrice: 154.38,
            unitPriceDisplay: '154,38 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      ZM: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      JE: {
        currency: 'GBP',
        price: 216,
        display: '£216.00',
        quantityDiscount: {
          '1': {
            discountValue: 107.96,
            discountValueDisplay: '£107.96',
            unitPrice: 108.04,
            unitPriceDisplay: '£108.04',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      ZW: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      JM: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      JO: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      JP: {
        currency: 'USD',
        price: 290.4,
        display: '$290.40',
        quantityDiscount: {
          '1': {
            discountValue: 145.2,
            discountValueDisplay: '$145.20',
            unitPrice: 145.2,
            unitPriceDisplay: '$145.20',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      KE: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      KG: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      KH: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      KI: {
        currency: 'AUD',
        price: 415,
        display: '$415.00',
        quantityDiscount: {
          '1': {
            discountValue: 207.17,
            discountValueDisplay: '$207.17',
            unitPrice: 207.83,
            unitPriceDisplay: '$207.83',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      KM: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      KN: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      KP: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      KR: {
        currency: 'USD',
        price: 290.4,
        display: 'US$290.40',
        quantityDiscount: {
          '1': {
            discountValue: 145.2,
            discountValueDisplay: 'US$145.20',
            unitPrice: 145.2,
            unitPriceDisplay: 'US$145.20',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      KW: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      KY: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      KZ: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      LA: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      LB: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      LC: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      LI: {
        currency: 'EUR',
        price: 252,
        display: '€252.00',
        quantityDiscount: {
          '1': {
            discountValue: 125.92,
            discountValueDisplay: '€125.92',
            unitPrice: 126.08,
            unitPriceDisplay: '€126.08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      LK: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      LR: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      LS: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      LT: {
        currency: 'EUR',
        price: 305,
        display: '305,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 152.36,
            discountValueDisplay: '152,36 €',
            unitPrice: 152.64,
            unitPriceDisplay: '152,64 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      LU: {
        currency: 'EUR',
        price: 295,
        display: '€295.00',
        quantityDiscount: {
          '1': {
            discountValue: 147.33,
            discountValueDisplay: '€147.33',
            unitPrice: 147.67,
            unitPriceDisplay: '€147.67',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      LV: {
        currency: 'EUR',
        price: 305,
        display: '305,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 152.36,
            discountValueDisplay: '152,36 €',
            unitPrice: 152.64,
            unitPriceDisplay: '152,64 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      LY: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MA: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MC: {
        currency: 'EUR',
        price: 252,
        display: '€252.00',
        quantityDiscount: {
          '1': {
            discountValue: 125.92,
            discountValueDisplay: '€125.92',
            unitPrice: 126.08,
            unitPriceDisplay: '€126.08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MD: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      ME: {
        currency: 'EUR',
        price: 252,
        display: '€252.00',
        quantityDiscount: {
          '1': {
            discountValue: 125.92,
            discountValueDisplay: '€125.92',
            unitPrice: 126.08,
            unitPriceDisplay: '€126.08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MF: {
        currency: 'EUR',
        price: 252,
        display: '€252.00',
        quantityDiscount: {
          '1': {
            discountValue: 125.92,
            discountValueDisplay: '€125.92',
            unitPrice: 126.08,
            unitPriceDisplay: '€126.08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MG: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MH: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MK: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      ML: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MM: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MN: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MO: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MP: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MQ: {
        currency: 'EUR',
        price: 252,
        display: '€252.00',
        quantityDiscount: {
          '1': {
            discountValue: 125.92,
            discountValueDisplay: '€125.92',
            unitPrice: 126.08,
            unitPriceDisplay: '€126.08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MR: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MS: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MT: {
        currency: 'EUR',
        price: 298,
        display: '€298.00',
        quantityDiscount: {
          '1': {
            discountValue: 148.59,
            discountValueDisplay: '€148.59',
            unitPrice: 149.41,
            unitPriceDisplay: '€149.41',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MU: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MV: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MW: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MX: {
        currency: 'USD',
        price: 264,
        display: 'USD264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'USD132.00',
            unitPrice: 132,
            unitPriceDisplay: 'USD132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MY: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MZ: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      NA: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      NC: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      NE: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      NF: {
        currency: 'AUD',
        price: 415,
        display: '$415.00',
        quantityDiscount: {
          '1': {
            discountValue: 207.17,
            discountValueDisplay: '$207.17',
            unitPrice: 207.83,
            unitPriceDisplay: '$207.83',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      NG: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      NI: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      NL: {
        currency: 'EUR',
        price: 305,
        display: '€ 305,00',
        quantityDiscount: {
          '1': {
            discountValue: 152.36,
            discountValueDisplay: '€ 152,36',
            unitPrice: 152.64,
            unitPriceDisplay: '€ 152,64',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      NO: {
        currency: 'EUR',
        price: 315,
        display: '€ 315,00',
        quantityDiscount: {
          '1': {
            discountValue: 157.4,
            discountValueDisplay: '€ 157,40',
            unitPrice: 157.6,
            unitPriceDisplay: '€ 157,60',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      NP: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      NR: {
        currency: 'AUD',
        price: 415,
        display: '$415.00',
        quantityDiscount: {
          '1': {
            discountValue: 207.17,
            discountValueDisplay: '$207.17',
            unitPrice: 207.83,
            unitPriceDisplay: '$207.83',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      NU: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      NZ: {
        currency: 'USD',
        price: 303.6,
        display: 'US$303.60',
        quantityDiscount: {
          '1': {
            discountValue: 151.8,
            discountValueDisplay: 'US$151.80',
            unitPrice: 151.8,
            unitPriceDisplay: 'US$151.80',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      OM: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      PA: {
        currency: 'USD',
        price: 264,
        display: 'USD264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'USD132.00',
            unitPrice: 132,
            unitPriceDisplay: 'USD132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      PE: {
        currency: 'USD',
        price: 264,
        display: 'USD264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'USD132.00',
            unitPrice: 132,
            unitPriceDisplay: 'USD132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      PF: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      PG: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      PH: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      PK: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      PL: {
        currency: 'EUR',
        price: 310,
        display: '310,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 154.88,
            discountValueDisplay: '154,88 €',
            unitPrice: 155.12,
            unitPriceDisplay: '155,12 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      PM: {
        currency: 'EUR',
        price: 252,
        display: '€252.00',
        quantityDiscount: {
          '1': {
            discountValue: 125.92,
            discountValueDisplay: '€125.92',
            unitPrice: 126.08,
            unitPriceDisplay: '€126.08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      PN: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
    },
  },
  'pdf-one-year': {
    action: 'product.price.getall',
    result: 'success',
    product: 'pdf-one-year',
    pricing: {
      PR: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PS: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PT: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 72,
            unitPriceDisplay: '72,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PW: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PY: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      QA: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AD: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AE: {
        currency: 'USD',
        price: 100.8,
        display: '$100.80',
        quantityDiscount: {
          '1': {
            discountValue: 37.8,
            discountValueDisplay: '$37.80',
            unitPrice: 63,
            unitPriceDisplay: '$63.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AF: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AG: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AI: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AL: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AM: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AN: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AO: {
        currency: 'USD',
        price: 96,
        display: '96,00 US$',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 US$',
            unitPrice: 60,
            unitPriceDisplay: '60,00 US$',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AQ: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AR: {
        currency: 'USD',
        price: 96,
        display: 'US$ 96,00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$ 36,00',
            unitPrice: 60,
            unitPriceDisplay: 'US$ 60,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AS: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AT: {
        currency: 'EUR',
        price: 108,
        display: '€ 108,00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€ 36,00',
            unitPrice: 72,
            unitPriceDisplay: '€ 72,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      RE: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AU: {
        currency: 'AUD',
        price: 120,
        display: '$120.00',
        quantityDiscount: {
          '1': {
            discountValue: 48,
            discountValueDisplay: '$48.00',
            unitPrice: 72,
            unitPriceDisplay: '$72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AW: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AX: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AZ: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      RO: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BA: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BB: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      RS: {
        currency: 'EUR',
        price: 108,
        display: '108,00 EUR',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 EUR',
            unitPrice: 72,
            unitPriceDisplay: '72,00 EUR',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BD: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BE: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 72,
            unitPriceDisplay: '72,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      RU: {
        currency: 'USD',
        price: 115.2,
        display: '115,20 $',
        quantityDiscount: {
          '1': {
            discountValue: 43.2,
            discountValueDisplay: '43,20 $',
            unitPrice: 72,
            unitPriceDisplay: '72,00 $',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BF: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BG: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      RW: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BH: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BI: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BJ: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BL: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BM: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BN: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BO: {
        currency: 'USD',
        price: 96,
        display: 'USD96,00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'USD36,00',
            unitPrice: 60,
            unitPriceDisplay: 'USD60,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SA: {
        currency: 'USD',
        price: 110.4,
        display: '$110.40',
        quantityDiscount: {
          '1': {
            discountValue: 41.4,
            discountValueDisplay: '$41.40',
            unitPrice: 69,
            unitPriceDisplay: '$69.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BQ: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SB: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BR: {
        currency: 'USD',
        price: 96,
        display: 'US$ 96,00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$ 36,00',
            unitPrice: 60,
            unitPriceDisplay: 'US$ 60,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SC: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BS: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SD: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BT: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SE: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 72,
            unitPriceDisplay: '72,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BV: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SG: {
        currency: 'USD',
        price: 104.64,
        display: 'US$104.64',
        quantityDiscount: {
          '1': {
            discountValue: 39.24,
            discountValueDisplay: 'US$39.24',
            unitPrice: 65.4,
            unitPriceDisplay: 'US$65.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BW: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SH: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SI: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 72,
            unitPriceDisplay: '72,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BY: {
        currency: 'USD',
        price: 115.2,
        display: '$115.20',
        quantityDiscount: {
          '1': {
            discountValue: 43.2,
            discountValueDisplay: '$43.20',
            unitPrice: 72,
            unitPriceDisplay: '$72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SJ: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BZ: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SK: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 72,
            unitPriceDisplay: '72,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SL: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SM: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SN: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SO: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CA: {
        currency: 'CAD',
        price: 120,
        display: '$120.00',
        quantityDiscount: {
          '1': {
            discountValue: 48,
            discountValueDisplay: '$48.00',
            unitPrice: 72,
            unitPriceDisplay: '$72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SR: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CC: {
        currency: 'AUD',
        price: 120,
        display: '$120.00',
        quantityDiscount: {
          '1': {
            discountValue: 48,
            discountValueDisplay: '$48.00',
            unitPrice: 72,
            unitPriceDisplay: '$72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SS: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CD: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ST: {
        currency: 'USD',
        price: 96,
        display: '96,00 US$',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 US$',
            unitPrice: 60,
            unitPriceDisplay: '60,00 US$',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CF: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SV: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CG: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CH: {
        currency: 'EUR',
        price: 108,
        display: '€ 108,00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€ 36,00',
            unitPrice: 72,
            unitPriceDisplay: '€ 72,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SX: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CI: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SY: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SZ: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CK: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CL: {
        currency: 'USD',
        price: 114.24,
        display: 'US$114,24',
        quantityDiscount: {
          '1': {
            discountValue: 42.84,
            discountValueDisplay: 'US$42,84',
            unitPrice: 71.4,
            unitPriceDisplay: 'US$71,40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CM: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CN: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CO: {
        currency: 'USD',
        price: 114.24,
        display: 'US$ 114,24',
        quantityDiscount: {
          '1': {
            discountValue: 42.84,
            discountValueDisplay: 'US$ 42,84',
            unitPrice: 71.4,
            unitPriceDisplay: 'US$ 71,40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CR: {
        currency: 'USD',
        price: 96,
        display: 'USD96,00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'USD36,00',
            unitPrice: 60,
            unitPriceDisplay: 'USD60,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TC: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TD: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CU: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TF: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CV: {
        currency: 'USD',
        price: 96,
        display: '96,00 US$',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 US$',
            unitPrice: 60,
            unitPriceDisplay: '60,00 US$',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TG: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CW: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TH: {
        currency: 'USD',
        price: 102.72,
        display: '$102.72',
        quantityDiscount: {
          '1': {
            discountValue: 38.52,
            discountValueDisplay: '$38.52',
            unitPrice: 64.2,
            unitPriceDisplay: '$64.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CX: {
        currency: 'AUD',
        price: 120,
        display: '$120.00',
        quantityDiscount: {
          '1': {
            discountValue: 48,
            discountValueDisplay: '$48.00',
            unitPrice: 72,
            unitPriceDisplay: '$72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CY: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TJ: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CZ: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 72,
            unitPriceDisplay: '72,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TK: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TL: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TM: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TN: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TO: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TR: {
        currency: 'USD',
        price: 115.2,
        display: '$115,20',
        quantityDiscount: {
          '1': {
            discountValue: 43.2,
            discountValueDisplay: '$43,20',
            unitPrice: 72,
            unitPriceDisplay: '$72,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TT: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DE: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 72,
            unitPriceDisplay: '72,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TV: {
        currency: 'AUD',
        price: 120,
        display: '$120.00',
        quantityDiscount: {
          '1': {
            discountValue: 48,
            discountValueDisplay: '$48.00',
            unitPrice: 72,
            unitPriceDisplay: '$72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TW: {
        currency: 'USD',
        price: 100.8,
        display: 'US$100.80',
        quantityDiscount: {
          '1': {
            discountValue: 37.8,
            discountValueDisplay: 'US$37.80',
            unitPrice: 63,
            unitPriceDisplay: 'US$63.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DJ: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TZ: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DK: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 72,
            unitPriceDisplay: '72,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DM: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DO: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      UA: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      UG: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DZ: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      UM: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      EC: {
        currency: 'USD',
        price: 96,
        display: '$96,00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36,00',
            unitPrice: 60,
            unitPriceDisplay: '$60,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      US: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      EE: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      EG: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      EH: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      UY: {
        currency: 'USD',
        price: 96,
        display: 'US$ 96,00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$ 36,00',
            unitPrice: 60,
            unitPriceDisplay: 'US$ 60,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      UZ: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VA: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ER: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VC: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ES: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 72,
            unitPriceDisplay: '72,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ET: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VE: {
        currency: 'USD',
        price: 96,
        display: 'USD96,00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'USD36,00',
            unitPrice: 60,
            unitPriceDisplay: 'USD60,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VG: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VI: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VN: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VU: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FI: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 72,
            unitPriceDisplay: '72,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FJ: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FK: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FM: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FO: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FR: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 72,
            unitPriceDisplay: '72,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      WF: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GA: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GB: {
        currency: 'GBP',
        price: 96,
        display: '£96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '£36.00',
            unitPrice: 60,
            unitPriceDisplay: '£60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      WS: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GD: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GE: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GF: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GG: {
        currency: 'GBP',
        price: 96,
        display: '£96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '£36.00',
            unitPrice: 60,
            unitPriceDisplay: '£60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GH: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GI: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GL: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GM: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GN: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GP: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GQ: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GR: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 72,
            unitPriceDisplay: '72,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GS: {
        currency: 'GBP',
        price: 96,
        display: '£96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '£36.00',
            unitPrice: 60,
            unitPriceDisplay: '£60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GT: {
        currency: 'USD',
        price: 96,
        display: 'USD96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'USD36.00',
            unitPrice: 60,
            unitPriceDisplay: 'USD60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GU: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GW: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GY: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HK: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HM: {
        currency: 'AUD',
        price: 120,
        display: 'A$120.00',
        quantityDiscount: {
          '1': {
            discountValue: 48,
            discountValueDisplay: 'A$48.00',
            unitPrice: 72,
            unitPriceDisplay: 'A$72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HN: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HR: {
        currency: 'EUR',
        price: 108,
        display: '108,00 EUR',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 EUR',
            unitPrice: 72,
            unitPriceDisplay: '72,00 EUR',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HT: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      YE: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HU: {
        currency: 'EUR',
        price: 108,
        display: '108,00 EUR',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 EUR',
            unitPrice: 72,
            unitPriceDisplay: '72,00 EUR',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IC: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 72,
            unitPriceDisplay: '72,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ID: {
        currency: 'USD',
        price: 106.56,
        display: '$106.56',
        quantityDiscount: {
          '1': {
            discountValue: 39.96,
            discountValueDisplay: '$39.96',
            unitPrice: 66.6,
            unitPriceDisplay: '$66.60',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      YT: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IE: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IL: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IM: {
        currency: 'GBP',
        price: 96,
        display: '£96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '£36.00',
            unitPrice: 60,
            unitPriceDisplay: '£60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IN: {
        currency: 'USD',
        price: 113.28,
        display: 'US$ 113.28',
        quantityDiscount: {
          '1': {
            discountValue: 42.48,
            discountValueDisplay: 'US$ 42.48',
            unitPrice: 70.8,
            unitPriceDisplay: 'US$ 70.80',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IO: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ZA: {
        currency: 'USD',
        price: 110.4,
        display: 'US$110,40',
        quantityDiscount: {
          '1': {
            discountValue: 41.4,
            discountValueDisplay: 'US$41,40',
            unitPrice: 69,
            unitPriceDisplay: 'US$69,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IQ: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IR: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IS: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IT: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 72,
            unitPriceDisplay: '72,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ZM: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      JE: {
        currency: 'GBP',
        price: 96,
        display: '£96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '£36.00',
            unitPrice: 60,
            unitPriceDisplay: '£60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ZW: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      JM: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      JO: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      JP: {
        currency: 'USD',
        price: 105.6,
        display: '$105.60',
        quantityDiscount: {
          '1': {
            discountValue: 39.6,
            discountValueDisplay: '$39.60',
            unitPrice: 66,
            unitPriceDisplay: '$66.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KE: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KG: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KH: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KI: {
        currency: 'AUD',
        price: 120,
        display: '$120.00',
        quantityDiscount: {
          '1': {
            discountValue: 48,
            discountValueDisplay: '$48.00',
            unitPrice: 72,
            unitPriceDisplay: '$72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KM: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KN: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KP: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KR: {
        currency: 'USD',
        price: 105.6,
        display: 'US$105.60',
        quantityDiscount: {
          '1': {
            discountValue: 39.6,
            discountValueDisplay: 'US$39.60',
            unitPrice: 66,
            unitPriceDisplay: 'US$66.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KW: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KY: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KZ: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LA: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LB: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LC: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LI: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LK: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LR: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LS: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LT: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 72,
            unitPriceDisplay: '72,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LU: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LV: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 72,
            unitPriceDisplay: '72,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LY: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MA: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MC: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MD: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ME: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MF: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MG: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MH: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MK: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ML: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MM: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MN: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MO: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MP: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MQ: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MR: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MS: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MT: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MU: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MV: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MW: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MX: {
        currency: 'USD',
        price: 96,
        display: 'USD96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'USD36.00',
            unitPrice: 60,
            unitPriceDisplay: 'USD60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MY: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MZ: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NA: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NC: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NE: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NF: {
        currency: 'AUD',
        price: 120,
        display: '$120.00',
        quantityDiscount: {
          '1': {
            discountValue: 48,
            discountValueDisplay: '$48.00',
            unitPrice: 72,
            unitPriceDisplay: '$72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NG: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NI: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NL: {
        currency: 'EUR',
        price: 108,
        display: '€ 108,00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€ 36,00',
            unitPrice: 72,
            unitPriceDisplay: '€ 72,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NO: {
        currency: 'EUR',
        price: 108,
        display: '€ 108,00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€ 36,00',
            unitPrice: 72,
            unitPriceDisplay: '€ 72,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NP: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NR: {
        currency: 'AUD',
        price: 120,
        display: '$120.00',
        quantityDiscount: {
          '1': {
            discountValue: 48,
            discountValueDisplay: '$48.00',
            unitPrice: 72,
            unitPriceDisplay: '$72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NU: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NZ: {
        currency: 'USD',
        price: 110.4,
        display: 'US$110.40',
        quantityDiscount: {
          '1': {
            discountValue: 41.4,
            discountValueDisplay: 'US$41.40',
            unitPrice: 69,
            unitPriceDisplay: 'US$69.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      OM: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PA: {
        currency: 'USD',
        price: 96,
        display: 'USD96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'USD36.00',
            unitPrice: 60,
            unitPriceDisplay: 'USD60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PE: {
        currency: 'USD',
        price: 96,
        display: 'USD96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'USD36.00',
            unitPrice: 60,
            unitPriceDisplay: 'USD60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PF: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PG: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PH: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PK: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PL: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 72,
            unitPriceDisplay: '72,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PM: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PN: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
    },
  },
  'ocr-reactivation': {
    action: 'product.price.getall',
    result: 'success',
    product: 'ocr-reactivation',
    pricing: {
      PR: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PS: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PT: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '0,00 €',
            unitPrice: 0,
            unitPriceDisplay: '0,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PY: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      QA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AD: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '€0.00',
            unitPrice: 0,
            unitPriceDisplay: '€0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AE: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AF: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AI: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AL: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '€0.00',
            unitPrice: 0,
            unitPriceDisplay: '€0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AO: {
        currency: 'USD',
        price: 0,
        display: '0,00 US$',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '0,00 US$',
            unitPrice: 0,
            unitPriceDisplay: '0,00 US$',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AQ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AR: {
        currency: 'USD',
        price: 0,
        display: 'US$ 0,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$ 0,00',
            unitPrice: 0,
            unitPriceDisplay: 'US$ 0,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AS: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AT: {
        currency: 'EUR',
        price: 0,
        display: '€ 0,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '€ 0,00',
            unitPrice: 0,
            unitPriceDisplay: '€ 0,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RE: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '€0.00',
            unitPrice: 0,
            unitPriceDisplay: '€0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AU: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AW: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AX: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '€0.00',
            unitPrice: 0,
            unitPriceDisplay: '€0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AZ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RO: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '€0.00',
            unitPrice: 0,
            unitPriceDisplay: '€0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BA: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '€0.00',
            unitPrice: 0,
            unitPriceDisplay: '€0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BB: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RS: {
        currency: 'EUR',
        price: 0,
        display: '0,00 EUR',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '0,00 EUR',
            unitPrice: 0,
            unitPriceDisplay: '0,00 EUR',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BD: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BE: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '0,00 €',
            unitPrice: 0,
            unitPriceDisplay: '0,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RU: {
        currency: 'USD',
        price: 0,
        display: '0,00 $',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '0,00 $',
            unitPrice: 0,
            unitPriceDisplay: '0,00 $',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BF: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BG: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '€0.00',
            unitPrice: 0,
            unitPriceDisplay: '€0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BH: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BI: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BJ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BL: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '€0.00',
            unitPrice: 0,
            unitPriceDisplay: '€0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BO: {
        currency: 'USD',
        price: 0,
        display: 'USD0,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'USD0,00',
            unitPrice: 0,
            unitPriceDisplay: 'USD0,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BQ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SB: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BR: {
        currency: 'USD',
        price: 0,
        display: 'US$ 0,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$ 0,00',
            unitPrice: 0,
            unitPriceDisplay: 'US$ 0,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SC: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BS: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SD: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BT: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SE: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '0,00 €',
            unitPrice: 0,
            unitPriceDisplay: '0,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BV: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SH: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SI: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '0,00 €',
            unitPrice: 0,
            unitPriceDisplay: '0,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BY: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SJ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BZ: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SK: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '0,00 €',
            unitPrice: 0,
            unitPriceDisplay: '0,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SL: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SM: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '€0.00',
            unitPrice: 0,
            unitPriceDisplay: '€0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SO: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CA: {
        currency: 'CAD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SR: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CC: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SS: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CD: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ST: {
        currency: 'USD',
        price: 0,
        display: '0,00 US$',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '0,00 US$',
            unitPrice: 0,
            unitPriceDisplay: '0,00 US$',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CF: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SV: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CG: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CH: {
        currency: 'EUR',
        price: 0,
        display: '€ 0,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '€ 0,00',
            unitPrice: 0,
            unitPriceDisplay: '€ 0,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SX: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CI: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SY: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SZ: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CK: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CL: {
        currency: 'USD',
        price: 0,
        display: 'US$0,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0,00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CN: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CO: {
        currency: 'USD',
        price: 0,
        display: 'US$ 0,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$ 0,00',
            unitPrice: 0,
            unitPriceDisplay: 'US$ 0,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CR: {
        currency: 'USD',
        price: 0,
        display: 'USD0,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'USD0,00',
            unitPrice: 0,
            unitPriceDisplay: 'USD0,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TC: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TD: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CU: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TF: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '€0.00',
            unitPrice: 0,
            unitPriceDisplay: '€0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CV: {
        currency: 'USD',
        price: 0,
        display: '0,00 US$',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '0,00 US$',
            unitPrice: 0,
            unitPriceDisplay: '0,00 US$',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TG: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CW: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TH: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CX: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CY: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '€0.00',
            unitPrice: 0,
            unitPriceDisplay: '€0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TJ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CZ: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '0,00 €',
            unitPrice: 0,
            unitPriceDisplay: '0,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TK: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TL: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TO: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TR: {
        currency: 'USD',
        price: 0,
        display: '$0,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0,00',
            unitPrice: 0,
            unitPriceDisplay: '$0,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TT: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DE: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '0,00 €',
            unitPrice: 0,
            unitPriceDisplay: '0,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TV: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DJ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TZ: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DK: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '0,00 €',
            unitPrice: 0,
            unitPriceDisplay: '0,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DO: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DZ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EC: {
        currency: 'USD',
        price: 0,
        display: '$0,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0,00',
            unitPrice: 0,
            unitPriceDisplay: '$0,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      US: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EE: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '€0.00',
            unitPrice: 0,
            unitPriceDisplay: '€0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EG: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EH: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UY: {
        currency: 'USD',
        price: 0,
        display: 'US$ 0,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$ 0,00',
            unitPrice: 0,
            unitPriceDisplay: 'US$ 0,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UZ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VA: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '€0.00',
            unitPrice: 0,
            unitPriceDisplay: '€0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ER: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VC: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ES: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '0,00 €',
            unitPrice: 0,
            unitPriceDisplay: '0,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ET: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VE: {
        currency: 'USD',
        price: 0,
        display: 'USD0,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'USD0,00',
            unitPrice: 0,
            unitPriceDisplay: 'USD0,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VI: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VU: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FI: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '0,00 €',
            unitPrice: 0,
            unitPriceDisplay: '0,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FJ: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FK: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FO: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FR: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '0,00 €',
            unitPrice: 0,
            unitPriceDisplay: '0,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WF: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GB: {
        currency: 'GBP',
        price: 0,
        display: '£0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '£0.00',
            unitPrice: 0,
            unitPriceDisplay: '£0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WS: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GD: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GE: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '€0.00',
            unitPrice: 0,
            unitPriceDisplay: '€0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GF: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '€0.00',
            unitPrice: 0,
            unitPriceDisplay: '€0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GG: {
        currency: 'GBP',
        price: 0,
        display: '£0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '£0.00',
            unitPrice: 0,
            unitPriceDisplay: '£0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GH: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GI: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GL: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GP: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '€0.00',
            unitPrice: 0,
            unitPriceDisplay: '€0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GQ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GR: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '0,00 €',
            unitPrice: 0,
            unitPriceDisplay: '0,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GS: {
        currency: 'GBP',
        price: 0,
        display: '£0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '£0.00',
            unitPrice: 0,
            unitPriceDisplay: '£0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GT: {
        currency: 'USD',
        price: 0,
        display: 'USD0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'USD0.00',
            unitPrice: 0,
            unitPriceDisplay: 'USD0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GU: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GW: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GY: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HK: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HM: {
        currency: 'AUD',
        price: 0,
        display: 'A$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'A$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'A$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HR: {
        currency: 'EUR',
        price: 0,
        display: '0,00 EUR',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '0,00 EUR',
            unitPrice: 0,
            unitPriceDisplay: '0,00 EUR',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HT: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YE: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HU: {
        currency: 'EUR',
        price: 0,
        display: '0,00 EUR',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '0,00 EUR',
            unitPrice: 0,
            unitPriceDisplay: '0,00 EUR',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IC: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '0,00 €',
            unitPrice: 0,
            unitPriceDisplay: '0,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ID: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YT: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '€0.00',
            unitPrice: 0,
            unitPriceDisplay: '€0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IE: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '€0.00',
            unitPrice: 0,
            unitPriceDisplay: '€0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IL: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IM: {
        currency: 'GBP',
        price: 0,
        display: '£0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '£0.00',
            unitPrice: 0,
            unitPriceDisplay: '£0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IN: {
        currency: 'USD',
        price: 0,
        display: 'US$ 0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$ 0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$ 0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IO: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZA: {
        currency: 'USD',
        price: 0,
        display: 'US$0,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0,00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IQ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IR: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IS: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '€0.00',
            unitPrice: 0,
            unitPriceDisplay: '€0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IT: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '0,00 €',
            unitPrice: 0,
            unitPriceDisplay: '0,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JE: {
        currency: 'GBP',
        price: 0,
        display: '£0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '£0.00',
            unitPrice: 0,
            unitPriceDisplay: '£0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JO: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JP: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KE: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KG: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KH: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KI: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KN: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KP: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KR: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KW: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KY: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KZ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LB: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LC: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LI: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '€0.00',
            unitPrice: 0,
            unitPriceDisplay: '€0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LK: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LR: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LS: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LT: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '0,00 €',
            unitPrice: 0,
            unitPriceDisplay: '0,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LU: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '€0.00',
            unitPrice: 0,
            unitPriceDisplay: '€0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LV: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '0,00 €',
            unitPrice: 0,
            unitPriceDisplay: '0,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LY: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MC: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '€0.00',
            unitPrice: 0,
            unitPriceDisplay: '€0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MD: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ME: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '€0.00',
            unitPrice: 0,
            unitPriceDisplay: '€0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MF: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '€0.00',
            unitPrice: 0,
            unitPriceDisplay: '€0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MH: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MK: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ML: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MO: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MP: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MQ: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '€0.00',
            unitPrice: 0,
            unitPriceDisplay: '€0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MR: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MS: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MT: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '€0.00',
            unitPrice: 0,
            unitPriceDisplay: '€0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MU: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MV: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MX: {
        currency: 'USD',
        price: 0,
        display: 'USD0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'USD0.00',
            unitPrice: 0,
            unitPriceDisplay: 'USD0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MY: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MZ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NA: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NC: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NE: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NF: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NI: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NL: {
        currency: 'EUR',
        price: 0,
        display: '€ 0,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '€ 0,00',
            unitPrice: 0,
            unitPriceDisplay: '€ 0,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NO: {
        currency: 'EUR',
        price: 0,
        display: '€ 0,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '€ 0,00',
            unitPrice: 0,
            unitPriceDisplay: '€ 0,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NP: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NR: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NU: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NZ: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      OM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PA: {
        currency: 'USD',
        price: 0,
        display: 'USD0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'USD0.00',
            unitPrice: 0,
            unitPriceDisplay: 'USD0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PE: {
        currency: 'USD',
        price: 0,
        display: 'USD0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'USD0.00',
            unitPrice: 0,
            unitPriceDisplay: 'USD0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PF: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '$0.00',
            unitPrice: 0,
            unitPriceDisplay: '$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PH: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PK: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PL: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '0,00 €',
            unitPrice: 0,
            unitPriceDisplay: '0,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PM: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: '€0.00',
            unitPrice: 0,
            unitPriceDisplay: '€0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PN: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 0,
            discountValueDisplay: 'US$0.00',
            unitPrice: 0,
            unitPriceDisplay: 'US$0.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
    },
  },
  'pdf-monthly-australia': {
    action: 'product.price.getall',
    result: 'success',
    product: 'pdf-monthly-australia',
    pricing: {
      PR: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PS: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PT: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PY: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      QA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AD: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AE: {
        currency: 'USD',
        price: 5.25,
        display: '$5.25',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AI: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AL: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AO: {
        currency: 'USD',
        price: 5,
        display: '5,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AQ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AR: {
        currency: 'USD',
        price: 5,
        display: 'US$ 5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AS: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AT: {
        currency: 'EUR',
        price: 6,
        display: '€ 6,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RE: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AU: {
        currency: 'AUD',
        price: 8,
        display: '$8.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AW: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AX: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RO: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BA: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BB: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RS: {
        currency: 'EUR',
        price: 6,
        display: '6,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BD: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BE: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RU: {
        currency: 'USD',
        price: 6,
        display: '6,00 $',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BG: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BH: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BI: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BJ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BL: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BO: {
        currency: 'USD',
        price: 5,
        display: 'USD5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SA: {
        currency: 'USD',
        price: 5.75,
        display: '$5.75',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BQ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SB: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BR: {
        currency: 'USD',
        price: 5,
        display: 'US$ 5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SC: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SD: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BT: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SE: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BV: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SG: {
        currency: 'USD',
        price: 5.45,
        display: 'US$5.45',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SH: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SI: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BY: {
        currency: 'USD',
        price: 6,
        display: '$6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SJ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BZ: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SK: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SL: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SM: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SO: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CA: {
        currency: 'CAD',
        price: 7,
        display: '$7.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SR: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CC: {
        currency: 'AUD',
        price: 8,
        display: '$8.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CD: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ST: {
        currency: 'USD',
        price: 5,
        display: '5,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SV: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CG: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CH: {
        currency: 'EUR',
        price: 6,
        display: '€ 6,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SX: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CI: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SY: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SZ: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CL: {
        currency: 'USD',
        price: 5.95,
        display: 'US$5,95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CN: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CO: {
        currency: 'USD',
        price: 5.95,
        display: 'US$ 5,95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CR: {
        currency: 'USD',
        price: 5,
        display: 'USD5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TC: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TD: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CU: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TF: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CV: {
        currency: 'USD',
        price: 5,
        display: '5,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TG: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CW: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TH: {
        currency: 'USD',
        price: 5.35,
        display: '$5.35',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CX: {
        currency: 'AUD',
        price: 8,
        display: '$8.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CY: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TJ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CZ: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TL: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TO: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TR: {
        currency: 'USD',
        price: 6,
        display: '$6,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TT: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DE: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TV: {
        currency: 'AUD',
        price: 8,
        display: '$8.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TW: {
        currency: 'USD',
        price: 5.25,
        display: 'US$5.25',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DJ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TZ: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DK: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DO: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EC: {
        currency: 'USD',
        price: 5,
        display: '$5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      US: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EE: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EG: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EH: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UY: {
        currency: 'USD',
        price: 5,
        display: 'US$ 5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VA: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ER: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VC: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ES: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ET: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VE: {
        currency: 'USD',
        price: 5,
        display: 'USD5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VI: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VU: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FI: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FJ: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FO: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FR: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GB: {
        currency: 'GBP',
        price: 5,
        display: '£5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GD: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GE: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GF: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GG: {
        currency: 'GBP',
        price: 5,
        display: '£5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GH: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GI: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GL: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GP: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GQ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GR: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GS: {
        currency: 'GBP',
        price: 5,
        display: '£5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GT: {
        currency: 'USD',
        price: 5,
        display: 'USD5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GU: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GW: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GY: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HM: {
        currency: 'AUD',
        price: 8,
        display: 'A$8.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HR: {
        currency: 'EUR',
        price: 6,
        display: '6,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HT: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YE: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HU: {
        currency: 'EUR',
        price: 7,
        display: '7,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IC: {
        currency: 'EUR',
        price: 5,
        display: '5,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ID: {
        currency: 'USD',
        price: 5.55,
        display: '$5.55',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YT: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IE: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IL: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IM: {
        currency: 'GBP',
        price: 5,
        display: '£5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IN: {
        currency: 'USD',
        price: 5.9,
        display: 'US$ 5.90',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IO: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZA: {
        currency: 'USD',
        price: 5.75,
        display: 'US$5,75',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IQ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IR: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IS: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IT: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JE: {
        currency: 'GBP',
        price: 5,
        display: '£5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JO: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JP: {
        currency: 'USD',
        price: 5.5,
        display: '$5.50',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KE: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KG: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KH: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KI: {
        currency: 'AUD',
        price: 8,
        display: '$8.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KN: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KP: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KR: {
        currency: 'USD',
        price: 5.5,
        display: 'US$5.50',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KW: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KY: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LB: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LC: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LI: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LK: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LR: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LT: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LU: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LV: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LY: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MC: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MD: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ME: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MF: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MH: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MK: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ML: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MO: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MP: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MQ: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MR: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MT: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MU: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MV: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MX: {
        currency: 'USD',
        price: 5,
        display: 'USD5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MY: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NA: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NC: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NE: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NF: {
        currency: 'AUD',
        price: 8,
        display: '$8.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NI: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NL: {
        currency: 'EUR',
        price: 6,
        display: '€ 6,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NO: {
        currency: 'EUR',
        price: 6,
        display: '€ 6,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NP: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NR: {
        currency: 'AUD',
        price: 8,
        display: '$8.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NU: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NZ: {
        currency: 'USD',
        price: 5.75,
        display: 'US$5.75',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      OM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PA: {
        currency: 'USD',
        price: 5,
        display: 'USD5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PE: {
        currency: 'USD',
        price: 5,
        display: 'USD5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PH: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PL: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PM: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PN: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
    },
  },
  'pdf-one-year-multi': {
    action: 'product.price.getall',
    result: 'success',
    product: 'pdf-one-year-multi',
    pricing: {
      PR: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PS: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PT: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 72,
            unitPriceDisplay: '72,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PW: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PY: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      QA: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AD: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AE: {
        currency: 'USD',
        price: 100.8,
        display: '$100.80',
        quantityDiscount: {
          '1': {
            discountValue: 37.8,
            discountValueDisplay: '$37.80',
            unitPrice: 63,
            unitPriceDisplay: '$63.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AF: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AG: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AI: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AL: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AM: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AN: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AO: {
        currency: 'USD',
        price: 96,
        display: '96,00 US$',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 US$',
            unitPrice: 60,
            unitPriceDisplay: '60,00 US$',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AQ: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AR: {
        currency: 'USD',
        price: 96,
        display: 'US$ 96,00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$ 36,00',
            unitPrice: 60,
            unitPriceDisplay: 'US$ 60,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AS: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AT: {
        currency: 'EUR',
        price: 108,
        display: '€ 108,00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€ 36,00',
            unitPrice: 72,
            unitPriceDisplay: '€ 72,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      RE: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AU: {
        currency: 'AUD',
        price: 120,
        display: '$120.00',
        quantityDiscount: {
          '1': {
            discountValue: 48,
            discountValueDisplay: '$48.00',
            unitPrice: 72,
            unitPriceDisplay: '$72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AW: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AX: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AZ: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      RO: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BA: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BB: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      RS: {
        currency: 'EUR',
        price: 108,
        display: '108,00 EUR',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 EUR',
            unitPrice: 72,
            unitPriceDisplay: '72,00 EUR',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BD: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BE: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 72,
            unitPriceDisplay: '72,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      RU: {
        currency: 'USD',
        price: 115.2,
        display: '115,20 $',
        quantityDiscount: {
          '1': {
            discountValue: 43.2,
            discountValueDisplay: '43,20 $',
            unitPrice: 72,
            unitPriceDisplay: '72,00 $',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BF: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BG: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      RW: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BH: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BI: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BJ: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BL: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BM: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BN: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BO: {
        currency: 'USD',
        price: 96,
        display: 'USD96,00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'USD36,00',
            unitPrice: 60,
            unitPriceDisplay: 'USD60,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SA: {
        currency: 'USD',
        price: 110.4,
        display: '$110.40',
        quantityDiscount: {
          '1': {
            discountValue: 41.4,
            discountValueDisplay: '$41.40',
            unitPrice: 69,
            unitPriceDisplay: '$69.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BQ: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SB: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BR: {
        currency: 'USD',
        price: 96,
        display: 'US$ 96,00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$ 36,00',
            unitPrice: 60,
            unitPriceDisplay: 'US$ 60,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SC: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BS: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SD: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BT: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SE: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 72,
            unitPriceDisplay: '72,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BV: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SG: {
        currency: 'USD',
        price: 104.64,
        display: 'US$104.64',
        quantityDiscount: {
          '1': {
            discountValue: 39.24,
            discountValueDisplay: 'US$39.24',
            unitPrice: 65.4,
            unitPriceDisplay: 'US$65.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BW: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SH: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SI: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 72,
            unitPriceDisplay: '72,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BY: {
        currency: 'USD',
        price: 115.2,
        display: '$115.20',
        quantityDiscount: {
          '1': {
            discountValue: 43.2,
            discountValueDisplay: '$43.20',
            unitPrice: 72,
            unitPriceDisplay: '$72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SJ: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BZ: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SK: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 72,
            unitPriceDisplay: '72,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SL: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SM: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SN: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SO: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CA: {
        currency: 'CAD',
        price: 120,
        display: '$120.00',
        quantityDiscount: {
          '1': {
            discountValue: 48,
            discountValueDisplay: '$48.00',
            unitPrice: 72,
            unitPriceDisplay: '$72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SR: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CC: {
        currency: 'AUD',
        price: 120,
        display: '$120.00',
        quantityDiscount: {
          '1': {
            discountValue: 48,
            discountValueDisplay: '$48.00',
            unitPrice: 72,
            unitPriceDisplay: '$72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SS: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CD: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ST: {
        currency: 'USD',
        price: 96,
        display: '96,00 US$',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 US$',
            unitPrice: 60,
            unitPriceDisplay: '60,00 US$',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CF: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SV: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CG: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CH: {
        currency: 'EUR',
        price: 108,
        display: '€ 108,00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€ 36,00',
            unitPrice: 72,
            unitPriceDisplay: '€ 72,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SX: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CI: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SY: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SZ: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CK: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CL: {
        currency: 'USD',
        price: 114.24,
        display: 'US$114,24',
        quantityDiscount: {
          '1': {
            discountValue: 42.84,
            discountValueDisplay: 'US$42,84',
            unitPrice: 71.4,
            unitPriceDisplay: 'US$71,40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CM: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CN: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CO: {
        currency: 'USD',
        price: 114.24,
        display: 'US$ 114,24',
        quantityDiscount: {
          '1': {
            discountValue: 42.84,
            discountValueDisplay: 'US$ 42,84',
            unitPrice: 71.4,
            unitPriceDisplay: 'US$ 71,40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CR: {
        currency: 'USD',
        price: 96,
        display: 'USD96,00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'USD36,00',
            unitPrice: 60,
            unitPriceDisplay: 'USD60,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TC: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TD: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CU: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TF: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CV: {
        currency: 'USD',
        price: 96,
        display: '96,00 US$',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 US$',
            unitPrice: 60,
            unitPriceDisplay: '60,00 US$',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TG: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CW: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TH: {
        currency: 'USD',
        price: 102.72,
        display: '$102.72',
        quantityDiscount: {
          '1': {
            discountValue: 38.52,
            discountValueDisplay: '$38.52',
            unitPrice: 64.2,
            unitPriceDisplay: '$64.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CX: {
        currency: 'AUD',
        price: 120,
        display: '$120.00',
        quantityDiscount: {
          '1': {
            discountValue: 48,
            discountValueDisplay: '$48.00',
            unitPrice: 72,
            unitPriceDisplay: '$72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CY: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TJ: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CZ: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 72,
            unitPriceDisplay: '72,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TK: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TL: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TM: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TN: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TO: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TR: {
        currency: 'USD',
        price: 115.2,
        display: '$115,20',
        quantityDiscount: {
          '1': {
            discountValue: 43.2,
            discountValueDisplay: '$43,20',
            unitPrice: 72,
            unitPriceDisplay: '$72,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TT: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DE: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 72,
            unitPriceDisplay: '72,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TV: {
        currency: 'AUD',
        price: 120,
        display: '$120.00',
        quantityDiscount: {
          '1': {
            discountValue: 48,
            discountValueDisplay: '$48.00',
            unitPrice: 72,
            unitPriceDisplay: '$72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TW: {
        currency: 'USD',
        price: 100.8,
        display: 'US$100.80',
        quantityDiscount: {
          '1': {
            discountValue: 37.8,
            discountValueDisplay: 'US$37.80',
            unitPrice: 63,
            unitPriceDisplay: 'US$63.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DJ: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TZ: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DK: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 72,
            unitPriceDisplay: '72,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DM: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DO: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      UA: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      UG: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DZ: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      UM: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      EC: {
        currency: 'USD',
        price: 96,
        display: '$96,00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36,00',
            unitPrice: 60,
            unitPriceDisplay: '$60,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      US: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      EE: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      EG: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      EH: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      UY: {
        currency: 'USD',
        price: 96,
        display: 'US$ 96,00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$ 36,00',
            unitPrice: 60,
            unitPriceDisplay: 'US$ 60,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      UZ: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VA: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ER: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VC: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ES: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 72,
            unitPriceDisplay: '72,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ET: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VE: {
        currency: 'USD',
        price: 96,
        display: 'USD96,00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'USD36,00',
            unitPrice: 60,
            unitPriceDisplay: 'USD60,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VG: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VI: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VN: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VU: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FI: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 72,
            unitPriceDisplay: '72,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FJ: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FK: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FM: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FO: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FR: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 72,
            unitPriceDisplay: '72,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      WF: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GA: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GB: {
        currency: 'GBP',
        price: 96,
        display: '£96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '£36.00',
            unitPrice: 60,
            unitPriceDisplay: '£60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      WS: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GD: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GE: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GF: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GG: {
        currency: 'GBP',
        price: 96,
        display: '£96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '£36.00',
            unitPrice: 60,
            unitPriceDisplay: '£60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GH: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GI: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GL: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GM: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GN: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GP: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GQ: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GR: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 72,
            unitPriceDisplay: '72,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GS: {
        currency: 'GBP',
        price: 96,
        display: '£96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '£36.00',
            unitPrice: 60,
            unitPriceDisplay: '£60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GT: {
        currency: 'USD',
        price: 96,
        display: 'USD96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'USD36.00',
            unitPrice: 60,
            unitPriceDisplay: 'USD60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GU: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GW: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GY: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HK: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HM: {
        currency: 'AUD',
        price: 120,
        display: 'A$120.00',
        quantityDiscount: {
          '1': {
            discountValue: 48,
            discountValueDisplay: 'A$48.00',
            unitPrice: 72,
            unitPriceDisplay: 'A$72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HN: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HR: {
        currency: 'EUR',
        price: 108,
        display: '108,00 EUR',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 EUR',
            unitPrice: 72,
            unitPriceDisplay: '72,00 EUR',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HT: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      YE: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HU: {
        currency: 'EUR',
        price: 108,
        display: '108,00 EUR',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 EUR',
            unitPrice: 72,
            unitPriceDisplay: '72,00 EUR',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IC: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 72,
            unitPriceDisplay: '72,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ID: {
        currency: 'USD',
        price: 106.56,
        display: '$106.56',
        quantityDiscount: {
          '1': {
            discountValue: 39.96,
            discountValueDisplay: '$39.96',
            unitPrice: 66.6,
            unitPriceDisplay: '$66.60',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      YT: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IE: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IL: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IM: {
        currency: 'GBP',
        price: 96,
        display: '£96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '£36.00',
            unitPrice: 60,
            unitPriceDisplay: '£60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IN: {
        currency: 'USD',
        price: 113.28,
        display: 'US$ 113.28',
        quantityDiscount: {
          '1': {
            discountValue: 42.48,
            discountValueDisplay: 'US$ 42.48',
            unitPrice: 70.8,
            unitPriceDisplay: 'US$ 70.80',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IO: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ZA: {
        currency: 'USD',
        price: 110.4,
        display: 'US$110,40',
        quantityDiscount: {
          '1': {
            discountValue: 41.4,
            discountValueDisplay: 'US$41,40',
            unitPrice: 69,
            unitPriceDisplay: 'US$69,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IQ: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IR: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IS: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IT: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 72,
            unitPriceDisplay: '72,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ZM: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      JE: {
        currency: 'GBP',
        price: 96,
        display: '£96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '£36.00',
            unitPrice: 60,
            unitPriceDisplay: '£60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ZW: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      JM: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      JO: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      JP: {
        currency: 'USD',
        price: 105.6,
        display: '$105.60',
        quantityDiscount: {
          '1': {
            discountValue: 39.6,
            discountValueDisplay: '$39.60',
            unitPrice: 66,
            unitPriceDisplay: '$66.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KE: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KG: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KH: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KI: {
        currency: 'AUD',
        price: 120,
        display: '$120.00',
        quantityDiscount: {
          '1': {
            discountValue: 48,
            discountValueDisplay: '$48.00',
            unitPrice: 72,
            unitPriceDisplay: '$72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KM: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KN: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KP: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KR: {
        currency: 'USD',
        price: 105.6,
        display: 'US$105.60',
        quantityDiscount: {
          '1': {
            discountValue: 39.6,
            discountValueDisplay: 'US$39.60',
            unitPrice: 66,
            unitPriceDisplay: 'US$66.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KW: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KY: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KZ: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LA: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LB: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LC: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LI: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LK: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LR: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LS: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LT: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 72,
            unitPriceDisplay: '72,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LU: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LV: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 72,
            unitPriceDisplay: '72,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LY: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MA: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MC: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MD: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ME: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MF: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MG: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MH: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MK: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ML: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MM: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MN: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MO: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MP: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MQ: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MR: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MS: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MT: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MU: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MV: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MW: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MX: {
        currency: 'USD',
        price: 96,
        display: 'USD96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'USD36.00',
            unitPrice: 60,
            unitPriceDisplay: 'USD60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MY: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MZ: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NA: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NC: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NE: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NF: {
        currency: 'AUD',
        price: 120,
        display: '$120.00',
        quantityDiscount: {
          '1': {
            discountValue: 48,
            discountValueDisplay: '$48.00',
            unitPrice: 72,
            unitPriceDisplay: '$72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NG: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NI: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NL: {
        currency: 'EUR',
        price: 108,
        display: '€ 108,00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€ 36,00',
            unitPrice: 72,
            unitPriceDisplay: '€ 72,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NO: {
        currency: 'EUR',
        price: 108,
        display: '€ 108,00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€ 36,00',
            unitPrice: 72,
            unitPriceDisplay: '€ 72,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NP: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NR: {
        currency: 'AUD',
        price: 120,
        display: '$120.00',
        quantityDiscount: {
          '1': {
            discountValue: 48,
            discountValueDisplay: '$48.00',
            unitPrice: 72,
            unitPriceDisplay: '$72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NU: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NZ: {
        currency: 'USD',
        price: 110.4,
        display: 'US$110.40',
        quantityDiscount: {
          '1': {
            discountValue: 41.4,
            discountValueDisplay: 'US$41.40',
            unitPrice: 69,
            unitPriceDisplay: 'US$69.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      OM: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PA: {
        currency: 'USD',
        price: 96,
        display: 'USD96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'USD36.00',
            unitPrice: 60,
            unitPriceDisplay: 'USD60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PE: {
        currency: 'USD',
        price: 96,
        display: 'USD96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'USD36.00',
            unitPrice: 60,
            unitPriceDisplay: 'USD60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PF: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 60,
            unitPriceDisplay: '$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PG: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PH: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PK: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PL: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 72,
            unitPriceDisplay: '72,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PM: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 72,
            unitPriceDisplay: '€72.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PN: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'US$36.00',
            unitPrice: 60,
            unitPriceDisplay: 'US$60.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
    },
  },
  'pdf-monthly-ocr-uk': {
    action: 'product.price.getall',
    result: 'success',
    product: 'pdf-monthly-ocr-uk',
    pricing: {
      PR: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PS: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PT: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PY: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      QA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AD: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AE: {
        currency: 'USD',
        price: 5.25,
        display: '$5.25',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AI: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AL: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AO: {
        currency: 'USD',
        price: 5,
        display: '5,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AQ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AR: {
        currency: 'USD',
        price: 5,
        display: 'US$ 5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AS: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AT: {
        currency: 'EUR',
        price: 6,
        display: '€ 6,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RE: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AU: {
        currency: 'AUD',
        price: 10,
        display: '$10.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AW: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AX: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RO: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BA: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BB: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RS: {
        currency: 'EUR',
        price: 6,
        display: '6,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BD: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BE: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RU: {
        currency: 'USD',
        price: 6,
        display: '6,00 $',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BG: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BH: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BI: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BJ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BL: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BO: {
        currency: 'USD',
        price: 5,
        display: 'USD5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SA: {
        currency: 'USD',
        price: 5.75,
        display: '$5.75',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BQ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SB: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BR: {
        currency: 'USD',
        price: 5,
        display: 'US$ 5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SC: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SD: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BT: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SE: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BV: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SG: {
        currency: 'USD',
        price: 5.45,
        display: 'US$5.45',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SH: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SI: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BY: {
        currency: 'USD',
        price: 6,
        display: '$6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SJ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BZ: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SK: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SL: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SM: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SO: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CA: {
        currency: 'CAD',
        price: 7,
        display: '$7.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SR: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CC: {
        currency: 'AUD',
        price: 10,
        display: '$10.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CD: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ST: {
        currency: 'USD',
        price: 5,
        display: '5,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SV: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CG: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CH: {
        currency: 'EUR',
        price: 6,
        display: '€ 6,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SX: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CI: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SY: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SZ: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CL: {
        currency: 'USD',
        price: 5.95,
        display: 'US$5,95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CN: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CO: {
        currency: 'USD',
        price: 5.95,
        display: 'US$ 5,95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CR: {
        currency: 'USD',
        price: 5,
        display: 'USD5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TC: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TD: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CU: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TF: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CV: {
        currency: 'USD',
        price: 5,
        display: '5,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TG: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CW: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TH: {
        currency: 'USD',
        price: 5.35,
        display: '$5.35',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CX: {
        currency: 'AUD',
        price: 10,
        display: '$10.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CY: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TJ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CZ: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TL: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TO: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TR: {
        currency: 'USD',
        price: 6,
        display: '$6,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TT: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DE: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TV: {
        currency: 'AUD',
        price: 10,
        display: '$10.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TW: {
        currency: 'USD',
        price: 5.25,
        display: 'US$5.25',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DJ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TZ: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DK: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DO: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EC: {
        currency: 'USD',
        price: 5,
        display: '$5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      US: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EE: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EG: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EH: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UY: {
        currency: 'USD',
        price: 5,
        display: 'US$ 5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VA: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ER: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VC: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ES: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ET: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VE: {
        currency: 'USD',
        price: 5,
        display: 'USD5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VI: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VU: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FI: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FJ: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FO: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FR: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GB: {
        currency: 'GBP',
        price: 7,
        display: '£7.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GD: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GE: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GF: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GG: {
        currency: 'GBP',
        price: 7,
        display: '£7.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GH: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GI: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GL: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GP: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GQ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GR: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GS: {
        currency: 'GBP',
        price: 7,
        display: '£7.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GT: {
        currency: 'USD',
        price: 5,
        display: 'USD5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GU: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GW: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GY: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HM: {
        currency: 'AUD',
        price: 10,
        display: 'A$10.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HR: {
        currency: 'EUR',
        price: 6,
        display: '6,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HT: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YE: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HU: {
        currency: 'EUR',
        price: 7,
        display: '7,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IC: {
        currency: 'EUR',
        price: 5,
        display: '5,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ID: {
        currency: 'USD',
        price: 5.55,
        display: '$5.55',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YT: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IE: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IL: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IM: {
        currency: 'GBP',
        price: 7,
        display: '£7.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IN: {
        currency: 'USD',
        price: 5.9,
        display: 'US$ 5.90',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IO: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZA: {
        currency: 'USD',
        price: 5.75,
        display: 'US$5,75',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IQ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IR: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IS: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IT: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JE: {
        currency: 'GBP',
        price: 7,
        display: '£7.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JO: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JP: {
        currency: 'USD',
        price: 5.5,
        display: '$5.50',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KE: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KG: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KH: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KI: {
        currency: 'AUD',
        price: 10,
        display: '$10.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KN: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KP: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KR: {
        currency: 'USD',
        price: 5.5,
        display: 'US$5.50',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KW: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KY: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LB: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LC: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LI: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LK: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LR: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LT: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LU: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LV: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LY: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MC: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MD: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ME: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MF: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MH: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MK: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ML: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MO: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MP: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MQ: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MR: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MT: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MU: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MV: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MX: {
        currency: 'USD',
        price: 5,
        display: 'USD5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MY: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NA: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NC: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NE: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NF: {
        currency: 'AUD',
        price: 10,
        display: '$10.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NI: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NL: {
        currency: 'EUR',
        price: 6,
        display: '€ 6,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NO: {
        currency: 'EUR',
        price: 6,
        display: '€ 6,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NP: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NR: {
        currency: 'AUD',
        price: 10,
        display: '$10.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NU: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NZ: {
        currency: 'USD',
        price: 5.75,
        display: 'US$5.75',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      OM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PA: {
        currency: 'USD',
        price: 5,
        display: 'USD5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PE: {
        currency: 'USD',
        price: 5,
        display: 'USD5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PH: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PL: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PM: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PN: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
    },
  },
  example: {
    action: 'product.price.getall',
    result: 'success',
    product: 'example',
    pricing: {
      PR: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PS: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PT: {
        currency: 'EUR',
        price: 58.95,
        display: '58,95 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PW: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PY: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      QA: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AD: {
        currency: 'EUR',
        price: 47.95,
        display: '€47.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AE: {
        currency: 'USD',
        price: 52.45,
        display: '$52.45',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AF: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AG: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AI: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AL: {
        currency: 'EUR',
        price: 47.95,
        display: '€47.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AM: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AN: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AO: {
        currency: 'USD',
        price: 49.95,
        display: '49,95 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AQ: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AR: {
        currency: 'USD',
        price: 49.95,
        display: 'US$ 49,95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AS: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AT: {
        currency: 'EUR',
        price: 57.95,
        display: '€ 57,95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RE: {
        currency: 'EUR',
        price: 47.95,
        display: '€47.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AU: {
        currency: 'AUD',
        price: 86.95,
        display: '$86.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AW: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AX: {
        currency: 'EUR',
        price: 47.95,
        display: '€47.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AZ: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RO: {
        currency: 'EUR',
        price: 56.95,
        display: '€56.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BA: {
        currency: 'EUR',
        price: 47.95,
        display: '€47.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BB: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RS: {
        currency: 'EUR',
        price: 57.95,
        display: '57,95 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BD: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BE: {
        currency: 'EUR',
        price: 57.95,
        display: '57,95 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RU: {
        currency: 'USD',
        price: 59.94,
        display: '59,94 $',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BF: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BG: {
        currency: 'EUR',
        price: 57.95,
        display: '€57.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RW: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BH: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BI: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BJ: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BL: {
        currency: 'EUR',
        price: 47.95,
        display: '€47.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BM: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BN: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BO: {
        currency: 'USD',
        price: 49.95,
        display: 'USD49,95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SA: {
        currency: 'USD',
        price: 57.44,
        display: '$57.44',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BQ: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SB: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BR: {
        currency: 'USD',
        price: 49.95,
        display: 'US$ 49,95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SC: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BS: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SD: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BT: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SE: {
        currency: 'EUR',
        price: 59.95,
        display: '59,95 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BV: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SG: {
        currency: 'USD',
        price: 54.45,
        display: 'US$54.45',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BW: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SH: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SI: {
        currency: 'EUR',
        price: 58.95,
        display: '58,95 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BY: {
        currency: 'USD',
        price: 59.94,
        display: '$59.94',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SJ: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BZ: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SK: {
        currency: 'EUR',
        price: 57.95,
        display: '57,95 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SL: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SM: {
        currency: 'EUR',
        price: 47.95,
        display: '€47.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SN: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SO: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CA: {
        currency: 'CAD',
        price: 69.95,
        display: '$69.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SR: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CC: {
        currency: 'AUD',
        price: 78.95,
        display: '$78.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SS: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CD: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ST: {
        currency: 'USD',
        price: 49.95,
        display: '49,95 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CF: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SV: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CG: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CH: {
        currency: 'EUR',
        price: 51.95,
        display: '€ 51,95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SX: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CI: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SY: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SZ: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CK: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CL: {
        currency: 'USD',
        price: 59.44,
        display: 'US$59,44',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CM: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CN: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CO: {
        currency: 'USD',
        price: 59.44,
        display: 'US$ 59,44',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CR: {
        currency: 'USD',
        price: 49.95,
        display: 'USD49,95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TC: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TD: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CU: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TF: {
        currency: 'EUR',
        price: 47.95,
        display: '€47.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CV: {
        currency: 'USD',
        price: 49.95,
        display: '49,95 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TG: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CW: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TH: {
        currency: 'USD',
        price: 53.45,
        display: '$53.45',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CX: {
        currency: 'AUD',
        price: 78.95,
        display: '$78.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CY: {
        currency: 'EUR',
        price: 56.95,
        display: '€56.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TJ: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CZ: {
        currency: 'EUR',
        price: 57.95,
        display: '57,95 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TK: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TL: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TM: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TN: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TO: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TR: {
        currency: 'USD',
        price: 59.94,
        display: '$59,94',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TT: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DE: {
        currency: 'EUR',
        price: 56.95,
        display: '56,95 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TV: {
        currency: 'AUD',
        price: 78.95,
        display: '$78.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TW: {
        currency: 'USD',
        price: 52.45,
        display: 'US$52.45',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DJ: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TZ: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DK: {
        currency: 'EUR',
        price: 59.95,
        display: '59,95 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DM: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DO: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UA: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UG: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DZ: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UM: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EC: {
        currency: 'USD',
        price: 49.95,
        display: '$49,95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      US: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EE: {
        currency: 'EUR',
        price: 58.95,
        display: '€58.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EG: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EH: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UY: {
        currency: 'USD',
        price: 49.95,
        display: 'US$ 49,95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UZ: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VA: {
        currency: 'EUR',
        price: 47.95,
        display: '€47.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ER: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VC: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ES: {
        currency: 'EUR',
        price: 57.95,
        display: '57,95 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ET: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VE: {
        currency: 'USD',
        price: 49.95,
        display: 'USD49,95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VG: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VI: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VN: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VU: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FI: {
        currency: 'EUR',
        price: 59.95,
        display: '59,95 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FJ: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FK: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FM: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FO: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FR: {
        currency: 'EUR',
        price: 57.95,
        display: '57,95 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WF: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GA: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GB: {
        currency: 'GBP',
        price: 49.95,
        display: '£49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WS: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GD: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GE: {
        currency: 'EUR',
        price: 47.95,
        display: '€47.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GF: {
        currency: 'EUR',
        price: 47.95,
        display: '€47.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GG: {
        currency: 'GBP',
        price: 40.95,
        display: '£40.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GH: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GI: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GL: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GM: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GN: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GP: {
        currency: 'EUR',
        price: 47.95,
        display: '€47.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GQ: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GR: {
        currency: 'EUR',
        price: 59.95,
        display: '59,95 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GS: {
        currency: 'GBP',
        price: 40.95,
        display: '£40.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GT: {
        currency: 'USD',
        price: 49.95,
        display: 'USD49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GU: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GW: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GY: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HK: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HM: {
        currency: 'AUD',
        price: 78.95,
        display: 'A$78.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HN: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HR: {
        currency: 'EUR',
        price: 59.95,
        display: '59,95 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HT: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YE: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HU: {
        currency: 'EUR',
        price: 60.95,
        display: '60,95 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IC: {
        currency: 'EUR',
        price: 47.95,
        display: '47,95 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ID: {
        currency: 'USD',
        price: 55.44,
        display: '$55.44',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YT: {
        currency: 'EUR',
        price: 47.95,
        display: '€47.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IE: {
        currency: 'EUR',
        price: 58.95,
        display: '€58.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IL: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IM: {
        currency: 'GBP',
        price: 40.95,
        display: '£40.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IN: {
        currency: 'USD',
        price: 58.94,
        display: 'US$ 58.94',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IO: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZA: {
        currency: 'USD',
        price: 57.44,
        display: 'US$57,44',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IQ: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IR: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IS: {
        currency: 'EUR',
        price: 59.95,
        display: '€59.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IT: {
        currency: 'EUR',
        price: 58.95,
        display: '58,95 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZM: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JE: {
        currency: 'GBP',
        price: 40.95,
        display: '£40.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZW: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JM: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JO: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JP: {
        currency: 'USD',
        price: 54.95,
        display: '$54.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KE: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KG: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KH: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KI: {
        currency: 'AUD',
        price: 78.95,
        display: '$78.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KM: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KN: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KP: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KR: {
        currency: 'USD',
        price: 54.95,
        display: 'US$54.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KW: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KY: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KZ: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LA: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LB: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LC: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LI: {
        currency: 'EUR',
        price: 47.95,
        display: '€47.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LK: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LR: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LS: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LT: {
        currency: 'EUR',
        price: 57.95,
        display: '57,95 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LU: {
        currency: 'EUR',
        price: 55.95,
        display: '€55.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LV: {
        currency: 'EUR',
        price: 57.95,
        display: '57,95 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LY: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MA: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MC: {
        currency: 'EUR',
        price: 47.95,
        display: '€47.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MD: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ME: {
        currency: 'EUR',
        price: 47.95,
        display: '€47.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MF: {
        currency: 'EUR',
        price: 47.95,
        display: '€47.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MG: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MH: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MK: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ML: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MM: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MN: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MO: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MP: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MQ: {
        currency: 'EUR',
        price: 47.95,
        display: '€47.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MR: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MS: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MT: {
        currency: 'EUR',
        price: 56.95,
        display: '€56.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MU: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MV: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MW: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MX: {
        currency: 'USD',
        price: 49.95,
        display: 'USD49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MY: {
        currency: 'USD',
        price: 53.95,
        display: 'US$53.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MZ: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NA: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NC: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NE: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NF: {
        currency: 'AUD',
        price: 78.95,
        display: '$78.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NG: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NI: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NL: {
        currency: 'EUR',
        price: 57.95,
        display: '€ 57,95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NO: {
        currency: 'EUR',
        price: 59.95,
        display: '€ 59,95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NP: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NR: {
        currency: 'AUD',
        price: 78.95,
        display: '$78.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NU: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NZ: {
        currency: 'USD',
        price: 57.44,
        display: 'US$57.44',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      OM: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PA: {
        currency: 'USD',
        price: 49.95,
        display: 'USD49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PE: {
        currency: 'USD',
        price: 49.95,
        display: 'USD49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PF: {
        currency: 'USD',
        price: 49.95,
        display: '$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PG: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PH: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PK: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PL: {
        currency: 'EUR',
        price: 58.95,
        display: '58,95 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PM: {
        currency: 'EUR',
        price: 47.95,
        display: '€47.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PN: {
        currency: 'USD',
        price: 49.95,
        display: 'US$49.95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
    },
  },
  'pdf-perpetual-multi': {
    action: 'product.price.getall',
    result: 'success',
    product: 'pdf-perpetual-multi',
    pricing: {
      PR: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PS: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PT: {
        currency: 'EUR',
        price: 199,
        display: '199,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PW: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PY: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      QA: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AD: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AE: {
        currency: 'USD',
        price: 201.6,
        display: '$201.60',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AF: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AG: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AI: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AL: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AM: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AN: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AO: {
        currency: 'USD',
        price: 192,
        display: '192,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AQ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AR: {
        currency: 'USD',
        price: 192,
        display: 'US$ 192,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AS: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AT: {
        currency: 'EUR',
        price: 199,
        display: '€ 199,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RE: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AU: {
        currency: 'AUD',
        price: 249,
        display: '$249.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AW: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AX: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AZ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RO: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BA: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BB: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RS: {
        currency: 'EUR',
        price: 199,
        display: '199,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BD: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BE: {
        currency: 'EUR',
        price: 199,
        display: '199,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RU: {
        currency: 'USD',
        price: 230.4,
        display: '230,40 $',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BF: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BG: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RW: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BH: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BI: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BJ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BL: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BM: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BN: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BO: {
        currency: 'USD',
        price: 192,
        display: 'USD192,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SA: {
        currency: 'USD',
        price: 220.8,
        display: '$220.80',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BQ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SB: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BR: {
        currency: 'USD',
        price: 192,
        display: 'US$ 192,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SC: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BS: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SD: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BT: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SE: {
        currency: 'EUR',
        price: 199,
        display: '199,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BV: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SG: {
        currency: 'USD',
        price: 209.28,
        display: 'US$209.28',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BW: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SH: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SI: {
        currency: 'EUR',
        price: 199,
        display: '199,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BY: {
        currency: 'USD',
        price: 230.4,
        display: '$230.40',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SJ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BZ: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SK: {
        currency: 'EUR',
        price: 199,
        display: '199,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SL: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SM: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SN: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SO: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CA: {
        currency: 'CAD',
        price: 249,
        display: '$249.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SR: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CC: {
        currency: 'AUD',
        price: 249,
        display: '$249.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SS: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CD: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ST: {
        currency: 'USD',
        price: 192,
        display: '192,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CF: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SV: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CG: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CH: {
        currency: 'EUR',
        price: 199,
        display: '€ 199,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SX: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CI: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SY: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SZ: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CK: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CL: {
        currency: 'USD',
        price: 228.48,
        display: 'US$228,48',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CM: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CN: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CO: {
        currency: 'USD',
        price: 228.48,
        display: 'US$ 228,48',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CR: {
        currency: 'USD',
        price: 192,
        display: 'USD192,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TC: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TD: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CU: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TF: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CV: {
        currency: 'USD',
        price: 192,
        display: '192,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TG: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CW: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TH: {
        currency: 'USD',
        price: 205.44,
        display: '$205.44',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CX: {
        currency: 'AUD',
        price: 249,
        display: '$249.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CY: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TJ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CZ: {
        currency: 'EUR',
        price: 199,
        display: '199,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TK: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TL: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TM: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TN: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TO: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TR: {
        currency: 'USD',
        price: 230.4,
        display: '$230,40',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TT: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DE: {
        currency: 'EUR',
        price: 199,
        display: '199,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TV: {
        currency: 'AUD',
        price: 249,
        display: '$249.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TW: {
        currency: 'USD',
        price: 201.6,
        display: 'US$201.60',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DJ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TZ: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DK: {
        currency: 'EUR',
        price: 199,
        display: '199,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DM: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DO: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UA: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UG: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DZ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UM: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EC: {
        currency: 'USD',
        price: 192,
        display: '$192,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      US: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EE: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EG: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EH: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UY: {
        currency: 'USD',
        price: 192,
        display: 'US$ 192,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UZ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VA: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ER: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VC: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ES: {
        currency: 'EUR',
        price: 199,
        display: '199,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ET: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VE: {
        currency: 'USD',
        price: 192,
        display: 'USD192,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VG: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VI: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VN: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VU: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FI: {
        currency: 'EUR',
        price: 199,
        display: '199,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FJ: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FK: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FM: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FO: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FR: {
        currency: 'EUR',
        price: 199,
        display: '199,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WF: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GA: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GB: {
        currency: 'GBP',
        price: 189,
        display: '£189.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WS: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GD: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GE: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GF: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GG: {
        currency: 'GBP',
        price: 189,
        display: '£189.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GH: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GI: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GL: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GM: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GN: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GP: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GQ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GR: {
        currency: 'EUR',
        price: 199,
        display: '199,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GS: {
        currency: 'GBP',
        price: 189,
        display: '£189.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GT: {
        currency: 'USD',
        price: 192,
        display: 'USD192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GU: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GW: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GY: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HK: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HM: {
        currency: 'AUD',
        price: 249,
        display: 'A$249.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HN: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HR: {
        currency: 'EUR',
        price: 199,
        display: '199,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HT: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YE: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HU: {
        currency: 'EUR',
        price: 199,
        display: '199,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IC: {
        currency: 'EUR',
        price: 199,
        display: '199,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ID: {
        currency: 'USD',
        price: 213.12,
        display: '$213.12',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YT: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IE: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IL: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IM: {
        currency: 'GBP',
        price: 189,
        display: '£189.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IN: {
        currency: 'USD',
        price: 226.56,
        display: 'US$ 226.56',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IO: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZA: {
        currency: 'USD',
        price: 220.8,
        display: 'US$220,80',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IQ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IR: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IS: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IT: {
        currency: 'EUR',
        price: 199,
        display: '199,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZM: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JE: {
        currency: 'GBP',
        price: 189,
        display: '£189.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZW: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JM: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JO: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JP: {
        currency: 'USD',
        price: 211.2,
        display: '$211.20',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KE: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KG: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KH: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KI: {
        currency: 'AUD',
        price: 249,
        display: '$249.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KM: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KN: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KP: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KR: {
        currency: 'USD',
        price: 211.2,
        display: 'US$211.20',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KW: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KY: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KZ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LA: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LB: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LC: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LI: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LK: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LR: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LS: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LT: {
        currency: 'EUR',
        price: 199,
        display: '199,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LU: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LV: {
        currency: 'EUR',
        price: 199,
        display: '199,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LY: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MA: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MC: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MD: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ME: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MF: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MG: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MH: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MK: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ML: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MM: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MN: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MO: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MP: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MQ: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MR: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MS: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MT: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MU: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MV: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MW: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MX: {
        currency: 'USD',
        price: 192,
        display: 'USD192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MY: {
        currency: 'USD',
        price: 207.36,
        display: 'US$207.36',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MZ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NA: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NC: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NE: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NF: {
        currency: 'AUD',
        price: 249,
        display: '$249.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NG: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NI: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NL: {
        currency: 'EUR',
        price: 199,
        display: '€ 199,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NO: {
        currency: 'EUR',
        price: 199,
        display: '€ 199,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NP: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NR: {
        currency: 'AUD',
        price: 249,
        display: '$249.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NU: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NZ: {
        currency: 'USD',
        price: 220.8,
        display: 'US$220.80',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      OM: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PA: {
        currency: 'USD',
        price: 192,
        display: 'USD192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PE: {
        currency: 'USD',
        price: 192,
        display: 'USD192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PF: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PG: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PH: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PK: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PL: {
        currency: 'EUR',
        price: 199,
        display: '199,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PM: {
        currency: 'EUR',
        price: 199,
        display: '€199.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PN: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
    },
  },
  'pdf-one-year-ocr-reactivation': {
    action: 'product.price.getall',
    result: 'success',
    product: 'pdf-one-year-ocr-reactivation',
    pricing: {
      PR: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PS: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PT: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '117,60 €',
            unitPrice: 29.4,
            unitPriceDisplay: '29,40 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PW: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PY: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      QA: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AD: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '€117.60',
            unitPrice: 29.4,
            unitPriceDisplay: '€29.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AE: {
        currency: 'USD',
        price: 138.6,
        display: '$138.60',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 110.88,
            discountValueDisplay: '$110.88',
            unitPrice: 27.72,
            unitPriceDisplay: '$27.72',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AF: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AG: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AI: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AL: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '€117.60',
            unitPrice: 29.4,
            unitPriceDisplay: '€29.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AM: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AN: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AO: {
        currency: 'USD',
        price: 132,
        display: '132,00 US$',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '105,60 US$',
            unitPrice: 26.4,
            unitPriceDisplay: '26,40 US$',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AQ: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AR: {
        currency: 'USD',
        price: 132,
        display: 'US$ 132,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$ 105,60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$ 26,40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AS: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AT: {
        currency: 'EUR',
        price: 147,
        display: '€ 147,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '€ 117,60',
            unitPrice: 29.4,
            unitPriceDisplay: '€ 29,40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      RE: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '€117.60',
            unitPrice: 29.4,
            unitPriceDisplay: '€29.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AU: {
        currency: 'AUD',
        price: 159,
        display: '$159.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 127.2,
            discountValueDisplay: '$127.20',
            unitPrice: 31.8,
            unitPriceDisplay: '$31.80',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AW: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AX: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '€117.60',
            unitPrice: 29.4,
            unitPriceDisplay: '€29.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AZ: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      RO: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '€117.60',
            unitPrice: 29.4,
            unitPriceDisplay: '€29.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BA: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '€117.60',
            unitPrice: 29.4,
            unitPriceDisplay: '€29.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BB: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      RS: {
        currency: 'EUR',
        price: 147,
        display: '147,00 EUR',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '117,60 EUR',
            unitPrice: 29.4,
            unitPriceDisplay: '29,40 EUR',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BD: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BE: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '117,60 €',
            unitPrice: 29.4,
            unitPriceDisplay: '29,40 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      RU: {
        currency: 'USD',
        price: 158.4,
        display: '158,40 $',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 126.72,
            discountValueDisplay: '126,72 $',
            unitPrice: 31.68,
            unitPriceDisplay: '31,68 $',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BF: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BG: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '€117.60',
            unitPrice: 29.4,
            unitPriceDisplay: '€29.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      RW: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BH: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BI: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BJ: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BL: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '€117.60',
            unitPrice: 29.4,
            unitPriceDisplay: '€29.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BM: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BN: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BO: {
        currency: 'USD',
        price: 132,
        display: 'USD132,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'USD105,60',
            unitPrice: 26.4,
            unitPriceDisplay: 'USD26,40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SA: {
        currency: 'USD',
        price: 151.8,
        display: '$151.80',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 121.45,
            discountValueDisplay: '$121.45',
            unitPrice: 30.35,
            unitPriceDisplay: '$30.35',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BQ: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SB: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BR: {
        currency: 'USD',
        price: 132,
        display: 'US$ 132,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$ 105,60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$ 26,40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SC: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BS: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SD: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BT: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SE: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '117,60 €',
            unitPrice: 29.4,
            unitPriceDisplay: '29,40 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BV: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SG: {
        currency: 'USD',
        price: 143.88,
        display: 'US$143.88',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 115.11,
            discountValueDisplay: 'US$115.11',
            unitPrice: 28.77,
            unitPriceDisplay: 'US$28.77',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BW: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SH: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SI: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '117,60 €',
            unitPrice: 29.4,
            unitPriceDisplay: '29,40 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BY: {
        currency: 'USD',
        price: 158.4,
        display: '$158.40',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 126.72,
            discountValueDisplay: '$126.72',
            unitPrice: 31.68,
            unitPriceDisplay: '$31.68',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SJ: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BZ: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SK: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '117,60 €',
            unitPrice: 29.4,
            unitPriceDisplay: '29,40 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SL: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SM: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '€117.60',
            unitPrice: 29.4,
            unitPriceDisplay: '€29.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SN: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SO: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CA: {
        currency: 'CAD',
        price: 159,
        display: '$159.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 127.2,
            discountValueDisplay: '$127.20',
            unitPrice: 31.8,
            unitPriceDisplay: '$31.80',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SR: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CC: {
        currency: 'AUD',
        price: 159,
        display: '$159.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 127.2,
            discountValueDisplay: '$127.20',
            unitPrice: 31.8,
            unitPriceDisplay: '$31.80',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SS: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CD: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ST: {
        currency: 'USD',
        price: 132,
        display: '132,00 US$',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '105,60 US$',
            unitPrice: 26.4,
            unitPriceDisplay: '26,40 US$',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CF: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SV: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CG: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CH: {
        currency: 'EUR',
        price: 147,
        display: '€ 147,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '€ 117,60',
            unitPrice: 29.4,
            unitPriceDisplay: '€ 29,40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SX: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CI: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SY: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SZ: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CK: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CL: {
        currency: 'USD',
        price: 157.08,
        display: 'US$157,08',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 125.67,
            discountValueDisplay: 'US$125,67',
            unitPrice: 31.41,
            unitPriceDisplay: 'US$31,41',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CM: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CN: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CO: {
        currency: 'USD',
        price: 157.08,
        display: 'US$ 157,08',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 125.67,
            discountValueDisplay: 'US$ 125,67',
            unitPrice: 31.41,
            unitPriceDisplay: 'US$ 31,41',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CR: {
        currency: 'USD',
        price: 132,
        display: 'USD132,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'USD105,60',
            unitPrice: 26.4,
            unitPriceDisplay: 'USD26,40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TC: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TD: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CU: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TF: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '€117.60',
            unitPrice: 29.4,
            unitPriceDisplay: '€29.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CV: {
        currency: 'USD',
        price: 132,
        display: '132,00 US$',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '105,60 US$',
            unitPrice: 26.4,
            unitPriceDisplay: '26,40 US$',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TG: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CW: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TH: {
        currency: 'USD',
        price: 141.24,
        display: '$141.24',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 113,
            discountValueDisplay: '$113.00',
            unitPrice: 28.24,
            unitPriceDisplay: '$28.24',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CX: {
        currency: 'AUD',
        price: 159,
        display: '$159.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 127.2,
            discountValueDisplay: '$127.20',
            unitPrice: 31.8,
            unitPriceDisplay: '$31.80',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CY: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '€117.60',
            unitPrice: 29.4,
            unitPriceDisplay: '€29.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TJ: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CZ: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '117,60 €',
            unitPrice: 29.4,
            unitPriceDisplay: '29,40 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TK: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TL: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TM: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TN: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TO: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TR: {
        currency: 'USD',
        price: 158.4,
        display: '$158,40',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 126.72,
            discountValueDisplay: '$126,72',
            unitPrice: 31.68,
            unitPriceDisplay: '$31,68',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TT: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DE: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '117,60 €',
            unitPrice: 29.4,
            unitPriceDisplay: '29,40 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TV: {
        currency: 'AUD',
        price: 159,
        display: '$159.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 127.2,
            discountValueDisplay: '$127.20',
            unitPrice: 31.8,
            unitPriceDisplay: '$31.80',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TW: {
        currency: 'USD',
        price: 138.6,
        display: 'US$138.60',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 110.88,
            discountValueDisplay: 'US$110.88',
            unitPrice: 27.72,
            unitPriceDisplay: 'US$27.72',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DJ: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TZ: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DK: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '117,60 €',
            unitPrice: 29.4,
            unitPriceDisplay: '29,40 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DM: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DO: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      UA: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      UG: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DZ: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      UM: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      EC: {
        currency: 'USD',
        price: 132,
        display: '$132,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105,60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26,40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      US: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      EE: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '€117.60',
            unitPrice: 29.4,
            unitPriceDisplay: '€29.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      EG: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      EH: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      UY: {
        currency: 'USD',
        price: 132,
        display: 'US$ 132,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$ 105,60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$ 26,40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      UZ: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VA: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '€117.60',
            unitPrice: 29.4,
            unitPriceDisplay: '€29.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ER: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VC: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ES: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '117,60 €',
            unitPrice: 29.4,
            unitPriceDisplay: '29,40 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ET: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VE: {
        currency: 'USD',
        price: 132,
        display: 'USD132,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'USD105,60',
            unitPrice: 26.4,
            unitPriceDisplay: 'USD26,40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VG: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VI: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VN: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VU: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FI: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '117,60 €',
            unitPrice: 29.4,
            unitPriceDisplay: '29,40 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FJ: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FK: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FM: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FO: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FR: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '117,60 €',
            unitPrice: 29.4,
            unitPriceDisplay: '29,40 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      WF: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GA: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GB: {
        currency: 'GBP',
        price: 132,
        display: '£132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '£105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '£26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      WS: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GD: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GE: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '€117.60',
            unitPrice: 29.4,
            unitPriceDisplay: '€29.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GF: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '€117.60',
            unitPrice: 29.4,
            unitPriceDisplay: '€29.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GG: {
        currency: 'GBP',
        price: 132,
        display: '£132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '£105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '£26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GH: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GI: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GL: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GM: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GN: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GP: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '€117.60',
            unitPrice: 29.4,
            unitPriceDisplay: '€29.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GQ: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GR: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '117,60 €',
            unitPrice: 29.4,
            unitPriceDisplay: '29,40 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GS: {
        currency: 'GBP',
        price: 132,
        display: '£132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '£105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '£26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GT: {
        currency: 'USD',
        price: 132,
        display: 'USD132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'USD105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'USD26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GU: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GW: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GY: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HK: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HM: {
        currency: 'AUD',
        price: 159,
        display: 'A$159.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 127.2,
            discountValueDisplay: 'A$127.20',
            unitPrice: 31.8,
            unitPriceDisplay: 'A$31.80',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HN: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HR: {
        currency: 'EUR',
        price: 147,
        display: '147,00 EUR',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '117,60 EUR',
            unitPrice: 29.4,
            unitPriceDisplay: '29,40 EUR',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HT: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      YE: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HU: {
        currency: 'EUR',
        price: 147,
        display: '147,00 EUR',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '117,60 EUR',
            unitPrice: 29.4,
            unitPriceDisplay: '29,40 EUR',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IC: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '117,60 €',
            unitPrice: 29.4,
            unitPriceDisplay: '29,40 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ID: {
        currency: 'USD',
        price: 146.52,
        display: '$146.52',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.22,
            discountValueDisplay: '$117.22',
            unitPrice: 29.3,
            unitPriceDisplay: '$29.30',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      YT: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '€117.60',
            unitPrice: 29.4,
            unitPriceDisplay: '€29.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IE: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '€117.60',
            unitPrice: 29.4,
            unitPriceDisplay: '€29.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IL: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IM: {
        currency: 'GBP',
        price: 132,
        display: '£132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '£105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '£26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IN: {
        currency: 'USD',
        price: 155.76,
        display: 'US$ 155.76',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 124.61,
            discountValueDisplay: 'US$ 124.61',
            unitPrice: 31.15,
            unitPriceDisplay: 'US$ 31.15',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IO: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ZA: {
        currency: 'USD',
        price: 151.8,
        display: 'US$151,80',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 121.45,
            discountValueDisplay: 'US$121,45',
            unitPrice: 30.35,
            unitPriceDisplay: 'US$30,35',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IQ: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IR: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IS: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '€117.60',
            unitPrice: 29.4,
            unitPriceDisplay: '€29.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IT: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '117,60 €',
            unitPrice: 29.4,
            unitPriceDisplay: '29,40 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ZM: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      JE: {
        currency: 'GBP',
        price: 132,
        display: '£132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '£105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '£26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ZW: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      JM: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      JO: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      JP: {
        currency: 'USD',
        price: 145.2,
        display: '$145.20',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 116.16,
            discountValueDisplay: '$116.16',
            unitPrice: 29.04,
            unitPriceDisplay: '$29.04',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KE: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KG: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KH: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KI: {
        currency: 'AUD',
        price: 159,
        display: '$159.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 127.2,
            discountValueDisplay: '$127.20',
            unitPrice: 31.8,
            unitPriceDisplay: '$31.80',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KM: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KN: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KP: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KR: {
        currency: 'USD',
        price: 145.2,
        display: 'US$145.20',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 116.16,
            discountValueDisplay: 'US$116.16',
            unitPrice: 29.04,
            unitPriceDisplay: 'US$29.04',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KW: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KY: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KZ: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LA: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LB: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LC: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LI: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '€117.60',
            unitPrice: 29.4,
            unitPriceDisplay: '€29.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LK: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LR: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LS: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LT: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '117,60 €',
            unitPrice: 29.4,
            unitPriceDisplay: '29,40 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LU: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '€117.60',
            unitPrice: 29.4,
            unitPriceDisplay: '€29.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LV: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '117,60 €',
            unitPrice: 29.4,
            unitPriceDisplay: '29,40 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LY: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MA: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MC: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '€117.60',
            unitPrice: 29.4,
            unitPriceDisplay: '€29.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MD: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ME: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '€117.60',
            unitPrice: 29.4,
            unitPriceDisplay: '€29.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MF: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '€117.60',
            unitPrice: 29.4,
            unitPriceDisplay: '€29.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MG: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MH: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MK: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ML: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MM: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MN: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MO: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MP: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MQ: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '€117.60',
            unitPrice: 29.4,
            unitPriceDisplay: '€29.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MR: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MS: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MT: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '€117.60',
            unitPrice: 29.4,
            unitPriceDisplay: '€29.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MU: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MV: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MW: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MX: {
        currency: 'USD',
        price: 132,
        display: 'USD132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'USD105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'USD26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MY: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MZ: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NA: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NC: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NE: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NF: {
        currency: 'AUD',
        price: 159,
        display: '$159.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 127.2,
            discountValueDisplay: '$127.20',
            unitPrice: 31.8,
            unitPriceDisplay: '$31.80',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NG: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NI: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NL: {
        currency: 'EUR',
        price: 147,
        display: '€ 147,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '€ 117,60',
            unitPrice: 29.4,
            unitPriceDisplay: '€ 29,40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NO: {
        currency: 'EUR',
        price: 147,
        display: '€ 147,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '€ 117,60',
            unitPrice: 29.4,
            unitPriceDisplay: '€ 29,40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NP: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NR: {
        currency: 'AUD',
        price: 159,
        display: '$159.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 127.2,
            discountValueDisplay: '$127.20',
            unitPrice: 31.8,
            unitPriceDisplay: '$31.80',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NU: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NZ: {
        currency: 'USD',
        price: 151.8,
        display: 'US$151.80',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 121.45,
            discountValueDisplay: 'US$121.45',
            unitPrice: 30.35,
            unitPriceDisplay: 'US$30.35',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      OM: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PA: {
        currency: 'USD',
        price: 132,
        display: 'USD132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'USD105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'USD26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PE: {
        currency: 'USD',
        price: 132,
        display: 'USD132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'USD105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'USD26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PF: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: '$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PG: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PH: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PK: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PL: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '117,60 €',
            unitPrice: 29.4,
            unitPriceDisplay: '29,40 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PM: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 117.6,
            discountValueDisplay: '€117.60',
            unitPrice: 29.4,
            unitPriceDisplay: '€29.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PN: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 26.4,
            unitPriceDisplay: 'US$26.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
    },
  },
  'ocr-one': {
    action: 'product.price.getall',
    result: 'success',
    product: 'ocr-one',
    pricing: {
      PR: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      PS: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      PT: {
        currency: 'EUR',
        price: 57,
        display: '57,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 14.08,
            discountValueDisplay: '14,08 €',
            unitPrice: 42.92,
            unitPriceDisplay: '42,92 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      PW: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      PY: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      QA: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AD: {
        currency: 'EUR',
        price: 46,
        display: '€46.00',
        quantityDiscount: {
          '1': {
            discountValue: 11.45,
            discountValueDisplay: '€11.45',
            unitPrice: 34.55,
            unitPriceDisplay: '€34.55',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AE: {
        currency: 'USD',
        price: 50.4,
        display: '$50.40',
        quantityDiscount: {
          '1': {
            discountValue: 12.6,
            discountValueDisplay: '$12.60',
            unitPrice: 37.8,
            unitPriceDisplay: '$37.80',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AF: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AG: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AI: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AL: {
        currency: 'EUR',
        price: 46,
        display: '€46.00',
        quantityDiscount: {
          '1': {
            discountValue: 11.45,
            discountValueDisplay: '€11.45',
            unitPrice: 34.55,
            unitPriceDisplay: '€34.55',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AM: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AN: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AO: {
        currency: 'USD',
        price: 48,
        display: '48,00 US$',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '12,00 US$',
            unitPrice: 36,
            unitPriceDisplay: '36,00 US$',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AQ: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AR: {
        currency: 'USD',
        price: 48,
        display: 'US$ 48,00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$ 12,00',
            unitPrice: 36,
            unitPriceDisplay: 'US$ 36,00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AS: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AT: {
        currency: 'EUR',
        price: 55,
        display: '€ 55,00',
        quantityDiscount: {
          '1': {
            discountValue: 13.74,
            discountValueDisplay: '€ 13,74',
            unitPrice: 41.26,
            unitPriceDisplay: '€ 41,26',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      RE: {
        currency: 'EUR',
        price: 46,
        display: '€46.00',
        quantityDiscount: {
          '1': {
            discountValue: 11.45,
            discountValueDisplay: '€11.45',
            unitPrice: 34.55,
            unitPriceDisplay: '€34.55',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AU: {
        currency: 'AUD',
        price: 83,
        display: '$83.00',
        quantityDiscount: {
          '1': {
            discountValue: 20.71,
            discountValueDisplay: '$20.71',
            unitPrice: 62.29,
            unitPriceDisplay: '$62.29',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AW: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AX: {
        currency: 'EUR',
        price: 46,
        display: '€46.00',
        quantityDiscount: {
          '1': {
            discountValue: 11.45,
            discountValueDisplay: '€11.45',
            unitPrice: 34.55,
            unitPriceDisplay: '€34.55',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AZ: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      RO: {
        currency: 'EUR',
        price: 55,
        display: '€55.00',
        quantityDiscount: {
          '1': {
            discountValue: 13.63,
            discountValueDisplay: '€13.63',
            unitPrice: 41.37,
            unitPriceDisplay: '€41.37',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BA: {
        currency: 'EUR',
        price: 46,
        display: '€46.00',
        quantityDiscount: {
          '1': {
            discountValue: 11.45,
            discountValueDisplay: '€11.45',
            unitPrice: 34.55,
            unitPriceDisplay: '€34.55',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BB: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      RS: {
        currency: 'EUR',
        price: 55,
        display: '55,00 EUR',
        quantityDiscount: {
          '1': {
            discountValue: 13.74,
            discountValueDisplay: '13,74 EUR',
            unitPrice: 41.26,
            unitPriceDisplay: '41,26 EUR',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BD: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BE: {
        currency: 'EUR',
        price: 56,
        display: '56,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 13.85,
            discountValueDisplay: '13,85 €',
            unitPrice: 42.15,
            unitPriceDisplay: '42,15 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      RU: {
        currency: 'USD',
        price: 57.6,
        display: '57,60 $',
        quantityDiscount: {
          '1': {
            discountValue: 14.4,
            discountValueDisplay: '14,40 $',
            unitPrice: 43.2,
            unitPriceDisplay: '43,20 $',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BF: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BG: {
        currency: 'EUR',
        price: 55,
        display: '€55.00',
        quantityDiscount: {
          '1': {
            discountValue: 13.74,
            discountValueDisplay: '€13.74',
            unitPrice: 41.26,
            unitPriceDisplay: '€41.26',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      RW: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BH: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BI: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BJ: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BL: {
        currency: 'EUR',
        price: 46,
        display: '€46.00',
        quantityDiscount: {
          '1': {
            discountValue: 11.45,
            discountValueDisplay: '€11.45',
            unitPrice: 34.55,
            unitPriceDisplay: '€34.55',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BM: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BN: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BO: {
        currency: 'USD',
        price: 48,
        display: 'USD48,00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'USD12,00',
            unitPrice: 36,
            unitPriceDisplay: 'USD36,00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SA: {
        currency: 'USD',
        price: 55.2,
        display: '$55.20',
        quantityDiscount: {
          '1': {
            discountValue: 13.8,
            discountValueDisplay: '$13.80',
            unitPrice: 41.4,
            unitPriceDisplay: '$41.40',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BQ: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SB: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BR: {
        currency: 'USD',
        price: 48,
        display: 'US$ 48,00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$ 12,00',
            unitPrice: 36,
            unitPriceDisplay: 'US$ 36,00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SC: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BS: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SD: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BT: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SE: {
        currency: 'EUR',
        price: 58,
        display: '58,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 14.31,
            discountValueDisplay: '14,31 €',
            unitPrice: 43.69,
            unitPriceDisplay: '43,69 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BV: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SG: {
        currency: 'USD',
        price: 52.32,
        display: 'US$52.32',
        quantityDiscount: {
          '1': {
            discountValue: 13.08,
            discountValueDisplay: 'US$13.08',
            unitPrice: 39.24,
            unitPriceDisplay: 'US$39.24',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BW: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SH: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SI: {
        currency: 'EUR',
        price: 56,
        display: '56,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 13.97,
            discountValueDisplay: '13,97 €',
            unitPrice: 42.03,
            unitPriceDisplay: '42,03 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BY: {
        currency: 'USD',
        price: 57.6,
        display: '$57.60',
        quantityDiscount: {
          '1': {
            discountValue: 14.4,
            discountValueDisplay: '$14.40',
            unitPrice: 43.2,
            unitPriceDisplay: '$43.20',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SJ: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BZ: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SK: {
        currency: 'EUR',
        price: 55,
        display: '55,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 13.74,
            discountValueDisplay: '13,74 €',
            unitPrice: 41.26,
            unitPriceDisplay: '41,26 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SL: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SM: {
        currency: 'EUR',
        price: 46,
        display: '€46.00',
        quantityDiscount: {
          '1': {
            discountValue: 11.45,
            discountValueDisplay: '€11.45',
            unitPrice: 34.55,
            unitPriceDisplay: '€34.55',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SN: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SO: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CA: {
        currency: 'CAD',
        price: 68,
        display: '$68.00',
        quantityDiscount: {
          '1': {
            discountValue: 16.79,
            discountValueDisplay: '$16.79',
            unitPrice: 51.21,
            unitPriceDisplay: '$51.21',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SR: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CC: {
        currency: 'AUD',
        price: 76,
        display: '$76.00',
        quantityDiscount: {
          '1': {
            discountValue: 18.83,
            discountValueDisplay: '$18.83',
            unitPrice: 57.17,
            unitPriceDisplay: '$57.17',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SS: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CD: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      ST: {
        currency: 'USD',
        price: 48,
        display: '48,00 US$',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '12,00 US$',
            unitPrice: 36,
            unitPriceDisplay: '36,00 US$',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CF: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SV: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CG: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CH: {
        currency: 'EUR',
        price: 50,
        display: '€ 50,00',
        quantityDiscount: {
          '1': {
            discountValue: 12.38,
            discountValueDisplay: '€ 12,38',
            unitPrice: 37.62,
            unitPriceDisplay: '€ 37,62',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SX: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CI: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SY: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SZ: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CK: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CL: {
        currency: 'USD',
        price: 57.12,
        display: 'US$57,12',
        quantityDiscount: {
          '1': {
            discountValue: 14.28,
            discountValueDisplay: 'US$14,28',
            unitPrice: 42.84,
            unitPriceDisplay: 'US$42,84',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CM: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CN: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CO: {
        currency: 'USD',
        price: 57.12,
        display: 'US$ 57,12',
        quantityDiscount: {
          '1': {
            discountValue: 14.28,
            discountValueDisplay: 'US$ 14,28',
            unitPrice: 42.84,
            unitPriceDisplay: 'US$ 42,84',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CR: {
        currency: 'USD',
        price: 48,
        display: 'USD48,00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'USD12,00',
            unitPrice: 36,
            unitPriceDisplay: 'USD36,00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TC: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TD: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CU: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TF: {
        currency: 'EUR',
        price: 46,
        display: '€46.00',
        quantityDiscount: {
          '1': {
            discountValue: 11.45,
            discountValueDisplay: '€11.45',
            unitPrice: 34.55,
            unitPriceDisplay: '€34.55',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CV: {
        currency: 'USD',
        price: 48,
        display: '48,00 US$',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '12,00 US$',
            unitPrice: 36,
            unitPriceDisplay: '36,00 US$',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TG: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CW: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TH: {
        currency: 'USD',
        price: 51.36,
        display: '$51.36',
        quantityDiscount: {
          '1': {
            discountValue: 12.84,
            discountValueDisplay: '$12.84',
            unitPrice: 38.52,
            unitPriceDisplay: '$38.52',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CX: {
        currency: 'AUD',
        price: 76,
        display: '$76.00',
        quantityDiscount: {
          '1': {
            discountValue: 18.83,
            discountValueDisplay: '$18.83',
            unitPrice: 57.17,
            unitPriceDisplay: '$57.17',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CY: {
        currency: 'EUR',
        price: 55,
        display: '€55.00',
        quantityDiscount: {
          '1': {
            discountValue: 13.63,
            discountValueDisplay: '€13.63',
            unitPrice: 41.37,
            unitPriceDisplay: '€41.37',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TJ: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CZ: {
        currency: 'EUR',
        price: 56,
        display: '56,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 13.85,
            discountValueDisplay: '13,85 €',
            unitPrice: 42.15,
            unitPriceDisplay: '42,15 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TK: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TL: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TM: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TN: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TO: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TR: {
        currency: 'USD',
        price: 57.6,
        display: '$57,60',
        quantityDiscount: {
          '1': {
            discountValue: 14.4,
            discountValueDisplay: '$14,40',
            unitPrice: 43.2,
            unitPriceDisplay: '$43,20',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TT: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      DE: {
        currency: 'EUR',
        price: 55,
        display: '55,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 13.63,
            discountValueDisplay: '13,63 €',
            unitPrice: 41.37,
            unitPriceDisplay: '41,37 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TV: {
        currency: 'AUD',
        price: 76,
        display: '$76.00',
        quantityDiscount: {
          '1': {
            discountValue: 18.83,
            discountValueDisplay: '$18.83',
            unitPrice: 57.17,
            unitPriceDisplay: '$57.17',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TW: {
        currency: 'USD',
        price: 50.4,
        display: 'US$50.40',
        quantityDiscount: {
          '1': {
            discountValue: 12.6,
            discountValueDisplay: 'US$12.60',
            unitPrice: 37.8,
            unitPriceDisplay: 'US$37.80',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      DJ: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TZ: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      DK: {
        currency: 'EUR',
        price: 58,
        display: '58,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 14.31,
            discountValueDisplay: '14,31 €',
            unitPrice: 43.69,
            unitPriceDisplay: '43,69 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      DM: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      DO: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      UA: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      UG: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      DZ: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      UM: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      EC: {
        currency: 'USD',
        price: 48,
        display: '$48,00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12,00',
            unitPrice: 36,
            unitPriceDisplay: '$36,00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      US: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      EE: {
        currency: 'EUR',
        price: 56,
        display: '€56.00',
        quantityDiscount: {
          '1': {
            discountValue: 13.97,
            discountValueDisplay: '€13.97',
            unitPrice: 42.03,
            unitPriceDisplay: '€42.03',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      EG: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      EH: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      UY: {
        currency: 'USD',
        price: 48,
        display: 'US$ 48,00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$ 12,00',
            unitPrice: 36,
            unitPriceDisplay: 'US$ 36,00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      UZ: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      VA: {
        currency: 'EUR',
        price: 46,
        display: '€46.00',
        quantityDiscount: {
          '1': {
            discountValue: 11.45,
            discountValueDisplay: '€11.45',
            unitPrice: 34.55,
            unitPriceDisplay: '€34.55',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      ER: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      VC: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      ES: {
        currency: 'EUR',
        price: 56,
        display: '56,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 13.85,
            discountValueDisplay: '13,85 €',
            unitPrice: 42.15,
            unitPriceDisplay: '42,15 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      ET: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      VE: {
        currency: 'USD',
        price: 48,
        display: 'USD48,00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'USD12,00',
            unitPrice: 36,
            unitPriceDisplay: 'USD36,00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      VG: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      VI: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      VN: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      VU: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      FI: {
        currency: 'EUR',
        price: 57,
        display: '57,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 14.2,
            discountValueDisplay: '14,20 €',
            unitPrice: 42.8,
            unitPriceDisplay: '42,80 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      FJ: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      FK: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      FM: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      FO: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      FR: {
        currency: 'EUR',
        price: 55,
        display: '55,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 13.74,
            discountValueDisplay: '13,74 €',
            unitPrice: 41.26,
            unitPriceDisplay: '41,26 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      WF: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GA: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GB: {
        currency: 'GBP',
        price: 48,
        display: '£48.00',
        quantityDiscount: {
          '1': {
            discountValue: 11.77,
            discountValueDisplay: '£11.77',
            unitPrice: 36.23,
            unitPriceDisplay: '£36.23',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      WS: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GD: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GE: {
        currency: 'EUR',
        price: 46,
        display: '€46.00',
        quantityDiscount: {
          '1': {
            discountValue: 11.45,
            discountValueDisplay: '€11.45',
            unitPrice: 34.55,
            unitPriceDisplay: '€34.55',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GF: {
        currency: 'EUR',
        price: 46,
        display: '€46.00',
        quantityDiscount: {
          '1': {
            discountValue: 11.45,
            discountValueDisplay: '€11.45',
            unitPrice: 34.55,
            unitPriceDisplay: '€34.55',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GG: {
        currency: 'GBP',
        price: 40,
        display: '£40.00',
        quantityDiscount: {
          '1': {
            discountValue: 9.81,
            discountValueDisplay: '£9.81',
            unitPrice: 30.19,
            unitPriceDisplay: '£30.19',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GH: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GI: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GL: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GM: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GN: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GP: {
        currency: 'EUR',
        price: 46,
        display: '€46.00',
        quantityDiscount: {
          '1': {
            discountValue: 11.45,
            discountValueDisplay: '€11.45',
            unitPrice: 34.55,
            unitPriceDisplay: '€34.55',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GQ: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GR: {
        currency: 'EUR',
        price: 57,
        display: '57,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 14.2,
            discountValueDisplay: '14,20 €',
            unitPrice: 42.8,
            unitPriceDisplay: '42,80 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GS: {
        currency: 'GBP',
        price: 40,
        display: '£40.00',
        quantityDiscount: {
          '1': {
            discountValue: 9.81,
            discountValueDisplay: '£9.81',
            unitPrice: 30.19,
            unitPriceDisplay: '£30.19',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GT: {
        currency: 'USD',
        price: 48,
        display: 'USD48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'USD12.00',
            unitPrice: 36,
            unitPriceDisplay: 'USD36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GU: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GW: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GY: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      HK: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      HM: {
        currency: 'AUD',
        price: 76,
        display: 'A$76.00',
        quantityDiscount: {
          '1': {
            discountValue: 18.83,
            discountValueDisplay: 'A$18.83',
            unitPrice: 57.17,
            unitPriceDisplay: 'A$57.17',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      HN: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      HR: {
        currency: 'EUR',
        price: 58,
        display: '58,00 EUR',
        quantityDiscount: {
          '1': {
            discountValue: 14.31,
            discountValueDisplay: '14,31 EUR',
            unitPrice: 43.69,
            unitPriceDisplay: '43,69 EUR',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      HT: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      YE: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      HU: {
        currency: 'EUR',
        price: 59,
        display: '59,00 EUR',
        quantityDiscount: {
          '1': {
            discountValue: 14.54,
            discountValueDisplay: '14,54 EUR',
            unitPrice: 44.46,
            unitPriceDisplay: '44,46 EUR',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      IC: {
        currency: 'EUR',
        price: 46,
        display: '46,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 11.45,
            discountValueDisplay: '11,45 €',
            unitPrice: 34.55,
            unitPriceDisplay: '34,55 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      ID: {
        currency: 'USD',
        price: 53.28,
        display: '$53.28',
        quantityDiscount: {
          '1': {
            discountValue: 13.32,
            discountValueDisplay: '$13.32',
            unitPrice: 39.96,
            unitPriceDisplay: '$39.96',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      YT: {
        currency: 'EUR',
        price: 46,
        display: '€46.00',
        quantityDiscount: {
          '1': {
            discountValue: 11.45,
            discountValueDisplay: '€11.45',
            unitPrice: 34.55,
            unitPriceDisplay: '€34.55',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      IE: {
        currency: 'EUR',
        price: 57,
        display: '€57.00',
        quantityDiscount: {
          '1': {
            discountValue: 14.08,
            discountValueDisplay: '€14.08',
            unitPrice: 42.92,
            unitPriceDisplay: '€42.92',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      IL: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      IM: {
        currency: 'GBP',
        price: 40,
        display: '£40.00',
        quantityDiscount: {
          '1': {
            discountValue: 9.81,
            discountValueDisplay: '£9.81',
            unitPrice: 30.19,
            unitPriceDisplay: '£30.19',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      IN: {
        currency: 'USD',
        price: 56.64,
        display: 'US$ 56.64',
        quantityDiscount: {
          '1': {
            discountValue: 14.16,
            discountValueDisplay: 'US$ 14.16',
            unitPrice: 42.48,
            unitPriceDisplay: 'US$ 42.48',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      IO: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      ZA: {
        currency: 'USD',
        price: 55.2,
        display: 'US$55,20',
        quantityDiscount: {
          '1': {
            discountValue: 13.8,
            discountValueDisplay: 'US$13,80',
            unitPrice: 41.4,
            unitPriceDisplay: 'US$41,40',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      IQ: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      IR: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      IS: {
        currency: 'EUR',
        price: 57,
        display: '€57.00',
        quantityDiscount: {
          '1': {
            discountValue: 14.2,
            discountValueDisplay: '€14.20',
            unitPrice: 42.8,
            unitPriceDisplay: '€42.80',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      IT: {
        currency: 'EUR',
        price: 56,
        display: '56,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 13.97,
            discountValueDisplay: '13,97 €',
            unitPrice: 42.03,
            unitPriceDisplay: '42,03 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      ZM: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      JE: {
        currency: 'GBP',
        price: 40,
        display: '£40.00',
        quantityDiscount: {
          '1': {
            discountValue: 9.81,
            discountValueDisplay: '£9.81',
            unitPrice: 30.19,
            unitPriceDisplay: '£30.19',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      ZW: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      JM: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      JO: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      JP: {
        currency: 'USD',
        price: 52.8,
        display: '$52.80',
        quantityDiscount: {
          '1': {
            discountValue: 13.2,
            discountValueDisplay: '$13.20',
            unitPrice: 39.6,
            unitPriceDisplay: '$39.60',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      KE: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      KG: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      KH: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      KI: {
        currency: 'AUD',
        price: 76,
        display: '$76.00',
        quantityDiscount: {
          '1': {
            discountValue: 18.83,
            discountValueDisplay: '$18.83',
            unitPrice: 57.17,
            unitPriceDisplay: '$57.17',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      KM: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      KN: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      KP: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      KR: {
        currency: 'USD',
        price: 52.8,
        display: 'US$52.80',
        quantityDiscount: {
          '1': {
            discountValue: 13.2,
            discountValueDisplay: 'US$13.20',
            unitPrice: 39.6,
            unitPriceDisplay: 'US$39.60',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      KW: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      KY: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      KZ: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      LA: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      LB: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      LC: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      LI: {
        currency: 'EUR',
        price: 46,
        display: '€46.00',
        quantityDiscount: {
          '1': {
            discountValue: 11.45,
            discountValueDisplay: '€11.45',
            unitPrice: 34.55,
            unitPriceDisplay: '€34.55',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      LK: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      LR: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      LS: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      LT: {
        currency: 'EUR',
        price: 56,
        display: '56,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 13.85,
            discountValueDisplay: '13,85 €',
            unitPrice: 42.15,
            unitPriceDisplay: '42,15 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      LU: {
        currency: 'EUR',
        price: 54,
        display: '€54.00',
        quantityDiscount: {
          '1': {
            discountValue: 13.4,
            discountValueDisplay: '€13.40',
            unitPrice: 40.6,
            unitPriceDisplay: '€40.60',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      LV: {
        currency: 'EUR',
        price: 56,
        display: '56,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 13.85,
            discountValueDisplay: '13,85 €',
            unitPrice: 42.15,
            unitPriceDisplay: '42,15 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      LY: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MA: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MC: {
        currency: 'EUR',
        price: 46,
        display: '€46.00',
        quantityDiscount: {
          '1': {
            discountValue: 11.45,
            discountValueDisplay: '€11.45',
            unitPrice: 34.55,
            unitPriceDisplay: '€34.55',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MD: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      ME: {
        currency: 'EUR',
        price: 46,
        display: '€46.00',
        quantityDiscount: {
          '1': {
            discountValue: 11.45,
            discountValueDisplay: '€11.45',
            unitPrice: 34.55,
            unitPriceDisplay: '€34.55',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MF: {
        currency: 'EUR',
        price: 46,
        display: '€46.00',
        quantityDiscount: {
          '1': {
            discountValue: 11.45,
            discountValueDisplay: '€11.45',
            unitPrice: 34.55,
            unitPriceDisplay: '€34.55',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MG: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MH: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MK: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      ML: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MM: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MN: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MO: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MP: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MQ: {
        currency: 'EUR',
        price: 46,
        display: '€46.00',
        quantityDiscount: {
          '1': {
            discountValue: 11.45,
            discountValueDisplay: '€11.45',
            unitPrice: 34.55,
            unitPriceDisplay: '€34.55',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MR: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MS: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MT: {
        currency: 'EUR',
        price: 55,
        display: '€55.00',
        quantityDiscount: {
          '1': {
            discountValue: 13.51,
            discountValueDisplay: '€13.51',
            unitPrice: 41.49,
            unitPriceDisplay: '€41.49',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MU: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MV: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MW: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MX: {
        currency: 'USD',
        price: 48,
        display: 'USD48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'USD12.00',
            unitPrice: 36,
            unitPriceDisplay: 'USD36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MY: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MZ: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      NA: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      NC: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      NE: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      NF: {
        currency: 'AUD',
        price: 76,
        display: '$76.00',
        quantityDiscount: {
          '1': {
            discountValue: 18.83,
            discountValueDisplay: '$18.83',
            unitPrice: 57.17,
            unitPriceDisplay: '$57.17',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      NG: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      NI: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      NL: {
        currency: 'EUR',
        price: 56,
        display: '€ 56,00',
        quantityDiscount: {
          '1': {
            discountValue: 13.85,
            discountValueDisplay: '€ 13,85',
            unitPrice: 42.15,
            unitPriceDisplay: '€ 42,15',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      NO: {
        currency: 'EUR',
        price: 58,
        display: '€ 58,00',
        quantityDiscount: {
          '1': {
            discountValue: 14.31,
            discountValueDisplay: '€ 14,31',
            unitPrice: 43.69,
            unitPriceDisplay: '€ 43,69',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      NP: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      NR: {
        currency: 'AUD',
        price: 76,
        display: '$76.00',
        quantityDiscount: {
          '1': {
            discountValue: 18.83,
            discountValueDisplay: '$18.83',
            unitPrice: 57.17,
            unitPriceDisplay: '$57.17',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      NU: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      NZ: {
        currency: 'USD',
        price: 55.2,
        display: 'US$55.20',
        quantityDiscount: {
          '1': {
            discountValue: 13.8,
            discountValueDisplay: 'US$13.80',
            unitPrice: 41.4,
            unitPriceDisplay: 'US$41.40',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      OM: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      PA: {
        currency: 'USD',
        price: 48,
        display: 'USD48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'USD12.00',
            unitPrice: 36,
            unitPriceDisplay: 'USD36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      PE: {
        currency: 'USD',
        price: 48,
        display: 'USD48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'USD12.00',
            unitPrice: 36,
            unitPriceDisplay: 'USD36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      PF: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: '$12.00',
            unitPrice: 36,
            unitPriceDisplay: '$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      PG: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      PH: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      PK: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      PL: {
        currency: 'EUR',
        price: 57,
        display: '57,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 14.08,
            discountValueDisplay: '14,08 €',
            unitPrice: 42.92,
            unitPriceDisplay: '42,92 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      PM: {
        currency: 'EUR',
        price: 46,
        display: '€46.00',
        quantityDiscount: {
          '1': {
            discountValue: 11.45,
            discountValueDisplay: '€11.45',
            unitPrice: 34.55,
            unitPriceDisplay: '€34.55',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      PN: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 12,
            discountValueDisplay: 'US$12.00',
            unitPrice: 36,
            unitPriceDisplay: 'US$36.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
    },
  },
  'pdf-monthly-multi': {
    action: 'product.price.getall',
    result: 'success',
    product: 'pdf-monthly-multi',
    pricing: {
      PR: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PS: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PT: {
        currency: 'EUR',
        price: 15,
        display: '15,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PW: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PY: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      QA: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AD: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AE: {
        currency: 'USD',
        price: 15.75,
        display: '$15.75',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AF: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AG: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AI: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AL: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AM: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AN: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AO: {
        currency: 'USD',
        price: 15,
        display: '15,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AQ: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AR: {
        currency: 'USD',
        price: 15,
        display: 'US$ 15,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AS: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AT: {
        currency: 'EUR',
        price: 15,
        display: '€ 15,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RE: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AU: {
        currency: 'AUD',
        price: 17,
        display: '$17.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AW: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AX: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AZ: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RO: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BA: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BB: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RS: {
        currency: 'EUR',
        price: 15,
        display: '15,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BD: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BE: {
        currency: 'EUR',
        price: 15,
        display: '15,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RU: {
        currency: 'USD',
        price: 18,
        display: '18,00 $',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BF: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BG: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RW: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BH: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BI: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BJ: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BL: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BM: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BN: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BO: {
        currency: 'USD',
        price: 15,
        display: 'USD15,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SA: {
        currency: 'USD',
        price: 17.25,
        display: '$17.25',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BQ: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SB: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BR: {
        currency: 'USD',
        price: 15,
        display: 'US$ 15,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SC: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BS: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SD: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BT: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SE: {
        currency: 'EUR',
        price: 15,
        display: '15,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BV: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SG: {
        currency: 'USD',
        price: 16.35,
        display: 'US$16.35',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BW: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SH: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SI: {
        currency: 'EUR',
        price: 15,
        display: '15,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BY: {
        currency: 'USD',
        price: 18,
        display: '$18.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SJ: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BZ: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SK: {
        currency: 'EUR',
        price: 15,
        display: '15,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SL: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SM: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SN: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SO: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CA: {
        currency: 'CAD',
        price: 17,
        display: '$17.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SR: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CC: {
        currency: 'AUD',
        price: 17,
        display: '$17.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SS: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CD: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ST: {
        currency: 'USD',
        price: 15,
        display: '15,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CF: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SV: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CG: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CH: {
        currency: 'EUR',
        price: 15,
        display: '€ 15,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SX: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CI: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SY: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SZ: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CK: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CL: {
        currency: 'USD',
        price: 17.85,
        display: 'US$17,85',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CM: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CN: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CO: {
        currency: 'USD',
        price: 17.85,
        display: 'US$ 17,85',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CR: {
        currency: 'USD',
        price: 15,
        display: 'USD15,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TC: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TD: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CU: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TF: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CV: {
        currency: 'USD',
        price: 15,
        display: '15,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TG: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CW: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TH: {
        currency: 'USD',
        price: 16.05,
        display: '$16.05',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CX: {
        currency: 'AUD',
        price: 17,
        display: '$17.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CY: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TJ: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CZ: {
        currency: 'EUR',
        price: 15,
        display: '15,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TK: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TL: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TM: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TN: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TO: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TR: {
        currency: 'USD',
        price: 18,
        display: '$18,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TT: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DE: {
        currency: 'EUR',
        price: 15,
        display: '15,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TV: {
        currency: 'AUD',
        price: 17,
        display: '$17.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TW: {
        currency: 'USD',
        price: 15.75,
        display: 'US$15.75',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DJ: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TZ: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DK: {
        currency: 'EUR',
        price: 15,
        display: '15,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DM: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DO: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UA: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UG: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DZ: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UM: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EC: {
        currency: 'USD',
        price: 15,
        display: '$15,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      US: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EE: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EG: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EH: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UY: {
        currency: 'USD',
        price: 15,
        display: 'US$ 15,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UZ: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VA: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ER: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VC: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ES: {
        currency: 'EUR',
        price: 15,
        display: '15,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ET: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VE: {
        currency: 'USD',
        price: 15,
        display: 'USD15,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VG: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VI: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VN: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VU: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FI: {
        currency: 'EUR',
        price: 15,
        display: '15,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FJ: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FK: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FM: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FO: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FR: {
        currency: 'EUR',
        price: 15,
        display: '15,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WF: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GA: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GB: {
        currency: 'GBP',
        price: 15,
        display: '£15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WS: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GD: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GE: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GF: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GG: {
        currency: 'GBP',
        price: 15,
        display: '£15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GH: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GI: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GL: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GM: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GN: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GP: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GQ: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GR: {
        currency: 'EUR',
        price: 15,
        display: '15,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GS: {
        currency: 'GBP',
        price: 15,
        display: '£15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GT: {
        currency: 'USD',
        price: 15,
        display: 'USD15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GU: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GW: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GY: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HK: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HM: {
        currency: 'AUD',
        price: 17,
        display: 'A$17.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HN: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HR: {
        currency: 'EUR',
        price: 15,
        display: '15,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HT: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YE: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HU: {
        currency: 'EUR',
        price: 15,
        display: '15,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IC: {
        currency: 'EUR',
        price: 15,
        display: '15,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ID: {
        currency: 'USD',
        price: 16.65,
        display: '$16.65',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YT: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IE: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IL: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IM: {
        currency: 'GBP',
        price: 15,
        display: '£15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IN: {
        currency: 'USD',
        price: 17.7,
        display: 'US$ 17.70',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IO: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZA: {
        currency: 'USD',
        price: 17.25,
        display: 'US$17,25',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IQ: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IR: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IS: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IT: {
        currency: 'EUR',
        price: 15,
        display: '15,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZM: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JE: {
        currency: 'GBP',
        price: 15,
        display: '£15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZW: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JM: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JO: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JP: {
        currency: 'USD',
        price: 16.5,
        display: '$16.50',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KE: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KG: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KH: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KI: {
        currency: 'AUD',
        price: 17,
        display: '$17.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KM: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KN: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KP: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KR: {
        currency: 'USD',
        price: 16.5,
        display: 'US$16.50',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KW: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KY: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KZ: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LA: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LB: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LC: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LI: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LK: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LR: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LS: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LT: {
        currency: 'EUR',
        price: 15,
        display: '15,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LU: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LV: {
        currency: 'EUR',
        price: 15,
        display: '15,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LY: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MA: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MC: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MD: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ME: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MF: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MG: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MH: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MK: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ML: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MM: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MN: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MO: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MP: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MQ: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MR: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MS: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MT: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MU: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MV: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MW: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MX: {
        currency: 'USD',
        price: 15,
        display: 'USD15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MY: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MZ: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NA: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NC: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NE: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NF: {
        currency: 'AUD',
        price: 17,
        display: '$17.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NG: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NI: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NL: {
        currency: 'EUR',
        price: 15,
        display: '€ 15,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NO: {
        currency: 'EUR',
        price: 15,
        display: '€ 15,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NP: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NR: {
        currency: 'AUD',
        price: 17,
        display: '$17.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NU: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NZ: {
        currency: 'USD',
        price: 17.25,
        display: 'US$17.25',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      OM: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PA: {
        currency: 'USD',
        price: 15,
        display: 'USD15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PE: {
        currency: 'USD',
        price: 15,
        display: 'USD15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PF: {
        currency: 'USD',
        price: 15,
        display: '$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PG: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PH: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PK: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PL: {
        currency: 'EUR',
        price: 15,
        display: '15,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PM: {
        currency: 'EUR',
        price: 15,
        display: '€15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PN: {
        currency: 'USD',
        price: 15,
        display: 'US$15.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
    },
  },
  'edit-text-images': {
    action: 'product.price.getall',
    result: 'success',
    product: 'edit-text-images',
    pricing: {
      PR: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PS: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PT: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PY: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      QA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AD: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AE: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AF: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AI: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AL: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AO: {
        currency: 'USD',
        price: 0,
        display: '0,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AQ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AR: {
        currency: 'USD',
        price: 0,
        display: 'US$ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AS: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AT: {
        currency: 'EUR',
        price: 0,
        display: '€ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RE: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AU: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AW: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AX: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AZ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RO: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BA: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BB: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RS: {
        currency: 'EUR',
        price: 0,
        display: '0,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BD: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BE: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RU: {
        currency: 'USD',
        price: 0,
        display: '0,00 $',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BF: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BG: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BH: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BI: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BJ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BL: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BO: {
        currency: 'USD',
        price: 0,
        display: 'USD0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BQ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SB: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BR: {
        currency: 'USD',
        price: 0,
        display: 'US$ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SC: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BS: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SD: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BT: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SE: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BV: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SH: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SI: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BY: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SJ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BZ: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SK: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SL: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SM: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SO: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CA: {
        currency: 'CAD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SR: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CC: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SS: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CD: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ST: {
        currency: 'USD',
        price: 0,
        display: '0,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CF: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SV: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CG: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CH: {
        currency: 'EUR',
        price: 0,
        display: '€ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SX: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CI: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SY: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SZ: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CK: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CL: {
        currency: 'USD',
        price: 0,
        display: 'US$0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CN: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CO: {
        currency: 'USD',
        price: 0,
        display: 'US$ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CR: {
        currency: 'USD',
        price: 0,
        display: 'USD0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TC: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TD: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CU: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TF: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CV: {
        currency: 'USD',
        price: 0,
        display: '0,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TG: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CW: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TH: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CX: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CY: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TJ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CZ: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TK: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TL: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TO: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TR: {
        currency: 'USD',
        price: 0,
        display: '$0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TT: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DE: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TV: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DJ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TZ: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DK: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DO: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DZ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EC: {
        currency: 'USD',
        price: 0,
        display: '$0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      US: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EE: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EG: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EH: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UY: {
        currency: 'USD',
        price: 0,
        display: 'US$ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UZ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VA: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ER: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VC: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ES: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ET: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VE: {
        currency: 'USD',
        price: 0,
        display: 'USD0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VI: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VU: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FI: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FJ: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FK: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FO: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FR: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WF: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GB: {
        currency: 'GBP',
        price: 0,
        display: '£0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WS: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GD: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GE: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GF: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GG: {
        currency: 'GBP',
        price: 0,
        display: '£0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GH: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GI: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GL: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GP: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GQ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GR: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GS: {
        currency: 'GBP',
        price: 0,
        display: '£0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GT: {
        currency: 'USD',
        price: 0,
        display: 'USD0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GU: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GW: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GY: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HK: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HM: {
        currency: 'AUD',
        price: 0,
        display: 'A$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HR: {
        currency: 'EUR',
        price: 0,
        display: '0,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HT: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YE: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HU: {
        currency: 'EUR',
        price: 0,
        display: '0,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IC: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ID: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YT: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IE: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IL: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IM: {
        currency: 'GBP',
        price: 0,
        display: '£0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IN: {
        currency: 'USD',
        price: 0,
        display: 'US$ 0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IO: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZA: {
        currency: 'USD',
        price: 0,
        display: 'US$0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IQ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IR: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IS: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IT: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JE: {
        currency: 'GBP',
        price: 0,
        display: '£0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JO: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JP: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KE: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KG: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KH: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KI: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KN: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KP: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KR: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KW: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KY: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KZ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LB: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LC: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LI: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LK: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LR: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LS: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LT: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LU: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LV: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LY: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MC: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MD: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ME: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MF: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MH: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MK: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ML: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MO: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MP: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MQ: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MR: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MS: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MT: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MU: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MV: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MX: {
        currency: 'USD',
        price: 0,
        display: 'USD0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MY: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MZ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NA: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NC: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NE: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NF: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NI: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NL: {
        currency: 'EUR',
        price: 0,
        display: '€ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NO: {
        currency: 'EUR',
        price: 0,
        display: '€ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NP: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NR: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NU: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NZ: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      OM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PA: {
        currency: 'USD',
        price: 0,
        display: 'USD0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PE: {
        currency: 'USD',
        price: 0,
        display: 'USD0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PF: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PH: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PK: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PL: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PM: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PN: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
    },
  },
  'pdf-monthly-multi-ocr': {
    action: 'product.price.getall',
    result: 'success',
    product: 'pdf-monthly-multi-ocr',
    pricing: {
      PR: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PS: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PT: {
        currency: 'EUR',
        price: 21,
        display: '21,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PW: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PY: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      QA: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AD: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AE: {
        currency: 'USD',
        price: 21,
        display: '$21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AF: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AG: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AI: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AL: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AM: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AN: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AO: {
        currency: 'USD',
        price: 20,
        display: '20,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AQ: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AR: {
        currency: 'USD',
        price: 20,
        display: 'US$ 20,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AS: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AT: {
        currency: 'EUR',
        price: 21,
        display: '€ 21,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RE: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AU: {
        currency: 'AUD',
        price: 23,
        display: '$23.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AW: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AX: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AZ: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RO: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BA: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BB: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RS: {
        currency: 'EUR',
        price: 21,
        display: '21,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BD: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BE: {
        currency: 'EUR',
        price: 21,
        display: '21,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RU: {
        currency: 'USD',
        price: 24,
        display: '24,00 $',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BF: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BG: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RW: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BH: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BI: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BJ: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BL: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BM: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BN: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BO: {
        currency: 'USD',
        price: 20,
        display: 'USD20,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SA: {
        currency: 'USD',
        price: 23,
        display: '$23.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BQ: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SB: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BR: {
        currency: 'USD',
        price: 20,
        display: 'US$ 20,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SC: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BS: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SD: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BT: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SE: {
        currency: 'EUR',
        price: 21,
        display: '21,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BV: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SG: {
        currency: 'USD',
        price: 21.8,
        display: 'US$21.80',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BW: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SH: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SI: {
        currency: 'EUR',
        price: 21,
        display: '21,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BY: {
        currency: 'USD',
        price: 24,
        display: '$24.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SJ: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BZ: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SK: {
        currency: 'EUR',
        price: 21,
        display: '21,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SL: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SM: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SN: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SO: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CA: {
        currency: 'CAD',
        price: 23,
        display: '$23.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SR: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CC: {
        currency: 'AUD',
        price: 23,
        display: '$23.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SS: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CD: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ST: {
        currency: 'USD',
        price: 20,
        display: '20,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CF: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SV: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CG: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CH: {
        currency: 'EUR',
        price: 21,
        display: '€ 21,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SX: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CI: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SY: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SZ: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CK: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CL: {
        currency: 'USD',
        price: 23.8,
        display: 'US$23,80',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CM: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CN: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CO: {
        currency: 'USD',
        price: 23.8,
        display: 'US$ 23,80',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CR: {
        currency: 'USD',
        price: 20,
        display: 'USD20,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TC: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TD: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CU: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TF: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CV: {
        currency: 'USD',
        price: 20,
        display: '20,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TG: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CW: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TH: {
        currency: 'USD',
        price: 21.4,
        display: '$21.40',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CX: {
        currency: 'AUD',
        price: 23,
        display: '$23.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CY: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TJ: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CZ: {
        currency: 'EUR',
        price: 21,
        display: '21,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TK: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TL: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TM: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TN: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TO: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TR: {
        currency: 'USD',
        price: 24,
        display: '$24,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TT: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DE: {
        currency: 'EUR',
        price: 21,
        display: '21,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TV: {
        currency: 'AUD',
        price: 23,
        display: '$23.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TW: {
        currency: 'USD',
        price: 21,
        display: 'US$21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DJ: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TZ: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DK: {
        currency: 'EUR',
        price: 21,
        display: '21,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DM: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DO: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UA: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UG: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DZ: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UM: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EC: {
        currency: 'USD',
        price: 20,
        display: '$20,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      US: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EE: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EG: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EH: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UY: {
        currency: 'USD',
        price: 20,
        display: 'US$ 20,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UZ: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VA: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ER: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VC: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ES: {
        currency: 'EUR',
        price: 21,
        display: '21,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ET: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VE: {
        currency: 'USD',
        price: 20,
        display: 'USD20,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VG: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VI: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VN: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VU: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FI: {
        currency: 'EUR',
        price: 21,
        display: '21,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FJ: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FK: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FM: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FO: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FR: {
        currency: 'EUR',
        price: 21,
        display: '21,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WF: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GA: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GB: {
        currency: 'GBP',
        price: 20,
        display: '£20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WS: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GD: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GE: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GF: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GG: {
        currency: 'GBP',
        price: 20,
        display: '£20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GH: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GI: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GL: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GM: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GN: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GP: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GQ: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GR: {
        currency: 'EUR',
        price: 21,
        display: '21,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GS: {
        currency: 'GBP',
        price: 20,
        display: '£20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GT: {
        currency: 'USD',
        price: 20,
        display: 'USD20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GU: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GW: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GY: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HK: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HM: {
        currency: 'AUD',
        price: 23,
        display: 'A$23.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HN: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HR: {
        currency: 'EUR',
        price: 21,
        display: '21,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HT: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YE: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HU: {
        currency: 'EUR',
        price: 21,
        display: '21,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IC: {
        currency: 'EUR',
        price: 21,
        display: '21,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ID: {
        currency: 'USD',
        price: 22.2,
        display: '$22.20',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YT: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IE: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IL: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IM: {
        currency: 'GBP',
        price: 20,
        display: '£20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IN: {
        currency: 'USD',
        price: 23.6,
        display: 'US$ 23.60',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IO: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZA: {
        currency: 'USD',
        price: 23,
        display: 'US$23,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IQ: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IR: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IS: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IT: {
        currency: 'EUR',
        price: 21,
        display: '21,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZM: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JE: {
        currency: 'GBP',
        price: 20,
        display: '£20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZW: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JM: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JO: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JP: {
        currency: 'USD',
        price: 22,
        display: '$22.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KE: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KG: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KH: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KI: {
        currency: 'AUD',
        price: 23,
        display: '$23.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KM: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KN: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KP: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KR: {
        currency: 'USD',
        price: 22,
        display: 'US$22.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KW: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KY: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KZ: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LA: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LB: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LC: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LI: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LK: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LR: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LS: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LT: {
        currency: 'EUR',
        price: 21,
        display: '21,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LU: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LV: {
        currency: 'EUR',
        price: 21,
        display: '21,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LY: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MA: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MC: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MD: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ME: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MF: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MG: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MH: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MK: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ML: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MM: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MN: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MO: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MP: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MQ: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MR: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MS: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MT: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MU: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MV: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MW: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MX: {
        currency: 'USD',
        price: 20,
        display: 'USD20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MY: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MZ: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NA: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NC: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NE: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NF: {
        currency: 'AUD',
        price: 23,
        display: '$23.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NG: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NI: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NL: {
        currency: 'EUR',
        price: 21,
        display: '€ 21,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NO: {
        currency: 'EUR',
        price: 21,
        display: '€ 21,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NP: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NR: {
        currency: 'AUD',
        price: 23,
        display: '$23.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NU: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NZ: {
        currency: 'USD',
        price: 23,
        display: 'US$23.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      OM: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PA: {
        currency: 'USD',
        price: 20,
        display: 'USD20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PE: {
        currency: 'USD',
        price: 20,
        display: 'USD20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PF: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PG: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PH: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PK: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PL: {
        currency: 'EUR',
        price: 21,
        display: '21,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PM: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PN: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
    },
  },
  'pdf-one-year-reactivation': {
    action: 'product.price.getall',
    result: 'success',
    product: 'pdf-one-year-reactivation',
    pricing: {
      PR: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PS: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PT: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '86,40 €',
            unitPrice: 21.6,
            unitPriceDisplay: '21,60 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PW: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PY: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      QA: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AD: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '€86.40',
            unitPrice: 21.6,
            unitPriceDisplay: '€21.60',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AE: {
        currency: 'USD',
        price: 100.8,
        display: '$100.80',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 80.64,
            discountValueDisplay: '$80.64',
            unitPrice: 20.16,
            unitPriceDisplay: '$20.16',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AF: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AG: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AI: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AL: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '€86.40',
            unitPrice: 21.6,
            unitPriceDisplay: '€21.60',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AM: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AN: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AO: {
        currency: 'USD',
        price: 96,
        display: '96,00 US$',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '76,80 US$',
            unitPrice: 19.2,
            unitPriceDisplay: '19,20 US$',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AQ: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AR: {
        currency: 'USD',
        price: 96,
        display: 'US$ 96,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$ 76,80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$ 19,20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AS: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AT: {
        currency: 'EUR',
        price: 108,
        display: '€ 108,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '€ 86,40',
            unitPrice: 21.6,
            unitPriceDisplay: '€ 21,60',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      RE: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '€86.40',
            unitPrice: 21.6,
            unitPriceDisplay: '€21.60',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AU: {
        currency: 'AUD',
        price: 120,
        display: '$120.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 24,
            unitPriceDisplay: '$24.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AW: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AX: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '€86.40',
            unitPrice: 21.6,
            unitPriceDisplay: '€21.60',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AZ: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      RO: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '€86.40',
            unitPrice: 21.6,
            unitPriceDisplay: '€21.60',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BA: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '€86.40',
            unitPrice: 21.6,
            unitPriceDisplay: '€21.60',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BB: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      RS: {
        currency: 'EUR',
        price: 108,
        display: '108,00 EUR',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '86,40 EUR',
            unitPrice: 21.6,
            unitPriceDisplay: '21,60 EUR',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BD: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BE: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '86,40 €',
            unitPrice: 21.6,
            unitPriceDisplay: '21,60 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      RU: {
        currency: 'USD',
        price: 115.2,
        display: '115,20 $',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 92.16,
            discountValueDisplay: '92,16 $',
            unitPrice: 23.04,
            unitPriceDisplay: '23,04 $',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BF: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BG: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '€86.40',
            unitPrice: 21.6,
            unitPriceDisplay: '€21.60',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      RW: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BH: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BI: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BJ: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BL: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '€86.40',
            unitPrice: 21.6,
            unitPriceDisplay: '€21.60',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BM: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BN: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BO: {
        currency: 'USD',
        price: 96,
        display: 'USD96,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'USD76,80',
            unitPrice: 19.2,
            unitPriceDisplay: 'USD19,20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SA: {
        currency: 'USD',
        price: 110.4,
        display: '$110.40',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 88.33,
            discountValueDisplay: '$88.33',
            unitPrice: 22.07,
            unitPriceDisplay: '$22.07',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BQ: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SB: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BR: {
        currency: 'USD',
        price: 96,
        display: 'US$ 96,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$ 76,80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$ 19,20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SC: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BS: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SD: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BT: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SE: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '86,40 €',
            unitPrice: 21.6,
            unitPriceDisplay: '21,60 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BV: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SG: {
        currency: 'USD',
        price: 104.64,
        display: 'US$104.64',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 83.72,
            discountValueDisplay: 'US$83.72',
            unitPrice: 20.92,
            unitPriceDisplay: 'US$20.92',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BW: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SH: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SI: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '86,40 €',
            unitPrice: 21.6,
            unitPriceDisplay: '21,60 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BY: {
        currency: 'USD',
        price: 115.2,
        display: '$115.20',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 92.16,
            discountValueDisplay: '$92.16',
            unitPrice: 23.04,
            unitPriceDisplay: '$23.04',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SJ: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BZ: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SK: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '86,40 €',
            unitPrice: 21.6,
            unitPriceDisplay: '21,60 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SL: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SM: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '€86.40',
            unitPrice: 21.6,
            unitPriceDisplay: '€21.60',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SN: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SO: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CA: {
        currency: 'CAD',
        price: 120,
        display: '$120.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 24,
            unitPriceDisplay: '$24.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SR: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CC: {
        currency: 'AUD',
        price: 120,
        display: '$120.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 24,
            unitPriceDisplay: '$24.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SS: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CD: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ST: {
        currency: 'USD',
        price: 96,
        display: '96,00 US$',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '76,80 US$',
            unitPrice: 19.2,
            unitPriceDisplay: '19,20 US$',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CF: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SV: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CG: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CH: {
        currency: 'EUR',
        price: 108,
        display: '€ 108,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '€ 86,40',
            unitPrice: 21.6,
            unitPriceDisplay: '€ 21,60',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SX: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CI: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SY: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SZ: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CK: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CL: {
        currency: 'USD',
        price: 114.24,
        display: 'US$114,24',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 91.4,
            discountValueDisplay: 'US$91,40',
            unitPrice: 22.84,
            unitPriceDisplay: 'US$22,84',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CM: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CN: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CO: {
        currency: 'USD',
        price: 114.24,
        display: 'US$ 114,24',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 91.4,
            discountValueDisplay: 'US$ 91,40',
            unitPrice: 22.84,
            unitPriceDisplay: 'US$ 22,84',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CR: {
        currency: 'USD',
        price: 96,
        display: 'USD96,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'USD76,80',
            unitPrice: 19.2,
            unitPriceDisplay: 'USD19,20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TC: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TD: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CU: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TF: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '€86.40',
            unitPrice: 21.6,
            unitPriceDisplay: '€21.60',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CV: {
        currency: 'USD',
        price: 96,
        display: '96,00 US$',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '76,80 US$',
            unitPrice: 19.2,
            unitPriceDisplay: '19,20 US$',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TG: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CW: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TH: {
        currency: 'USD',
        price: 102.72,
        display: '$102.72',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 82.18,
            discountValueDisplay: '$82.18',
            unitPrice: 20.54,
            unitPriceDisplay: '$20.54',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CX: {
        currency: 'AUD',
        price: 120,
        display: '$120.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 24,
            unitPriceDisplay: '$24.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CY: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '€86.40',
            unitPrice: 21.6,
            unitPriceDisplay: '€21.60',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TJ: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CZ: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '86,40 €',
            unitPrice: 21.6,
            unitPriceDisplay: '21,60 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TK: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TL: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TM: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TN: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TO: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TR: {
        currency: 'USD',
        price: 115.2,
        display: '$115,20',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 92.16,
            discountValueDisplay: '$92,16',
            unitPrice: 23.04,
            unitPriceDisplay: '$23,04',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TT: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DE: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '86,40 €',
            unitPrice: 21.6,
            unitPriceDisplay: '21,60 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TV: {
        currency: 'AUD',
        price: 120,
        display: '$120.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 24,
            unitPriceDisplay: '$24.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TW: {
        currency: 'USD',
        price: 100.8,
        display: 'US$100.80',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 80.64,
            discountValueDisplay: 'US$80.64',
            unitPrice: 20.16,
            unitPriceDisplay: 'US$20.16',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DJ: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TZ: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DK: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '86,40 €',
            unitPrice: 21.6,
            unitPriceDisplay: '21,60 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DM: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DO: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      UA: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      UG: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DZ: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      UM: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      EC: {
        currency: 'USD',
        price: 96,
        display: '$96,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76,80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19,20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      US: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      EE: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '€86.40',
            unitPrice: 21.6,
            unitPriceDisplay: '€21.60',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      EG: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      EH: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      UY: {
        currency: 'USD',
        price: 96,
        display: 'US$ 96,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$ 76,80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$ 19,20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      UZ: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VA: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '€86.40',
            unitPrice: 21.6,
            unitPriceDisplay: '€21.60',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ER: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VC: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ES: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '86,40 €',
            unitPrice: 21.6,
            unitPriceDisplay: '21,60 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ET: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VE: {
        currency: 'USD',
        price: 96,
        display: 'USD96,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'USD76,80',
            unitPrice: 19.2,
            unitPriceDisplay: 'USD19,20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VG: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VI: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VN: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VU: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FI: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '86,40 €',
            unitPrice: 21.6,
            unitPriceDisplay: '21,60 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FJ: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FK: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FM: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FO: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FR: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '86,40 €',
            unitPrice: 21.6,
            unitPriceDisplay: '21,60 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      WF: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GA: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GB: {
        currency: 'GBP',
        price: 96,
        display: '£96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '£76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '£19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      WS: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GD: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GE: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '€86.40',
            unitPrice: 21.6,
            unitPriceDisplay: '€21.60',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GF: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '€86.40',
            unitPrice: 21.6,
            unitPriceDisplay: '€21.60',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GG: {
        currency: 'GBP',
        price: 96,
        display: '£96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '£76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '£19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GH: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GI: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GL: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GM: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GN: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GP: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '€86.40',
            unitPrice: 21.6,
            unitPriceDisplay: '€21.60',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GQ: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GR: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '86,40 €',
            unitPrice: 21.6,
            unitPriceDisplay: '21,60 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GS: {
        currency: 'GBP',
        price: 96,
        display: '£96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '£76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '£19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GT: {
        currency: 'USD',
        price: 96,
        display: 'USD96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'USD76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'USD19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GU: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GW: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GY: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HK: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HM: {
        currency: 'AUD',
        price: 120,
        display: 'A$120.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 96,
            discountValueDisplay: 'A$96.00',
            unitPrice: 24,
            unitPriceDisplay: 'A$24.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HN: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HR: {
        currency: 'EUR',
        price: 108,
        display: '108,00 EUR',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '86,40 EUR',
            unitPrice: 21.6,
            unitPriceDisplay: '21,60 EUR',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HT: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      YE: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HU: {
        currency: 'EUR',
        price: 108,
        display: '108,00 EUR',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '86,40 EUR',
            unitPrice: 21.6,
            unitPriceDisplay: '21,60 EUR',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IC: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '86,40 €',
            unitPrice: 21.6,
            unitPriceDisplay: '21,60 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ID: {
        currency: 'USD',
        price: 106.56,
        display: '$106.56',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 85.25,
            discountValueDisplay: '$85.25',
            unitPrice: 21.31,
            unitPriceDisplay: '$21.31',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      YT: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '€86.40',
            unitPrice: 21.6,
            unitPriceDisplay: '€21.60',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IE: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '€86.40',
            unitPrice: 21.6,
            unitPriceDisplay: '€21.60',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IL: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IM: {
        currency: 'GBP',
        price: 96,
        display: '£96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '£76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '£19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IN: {
        currency: 'USD',
        price: 113.28,
        display: 'US$ 113.28',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 90.63,
            discountValueDisplay: 'US$ 90.63',
            unitPrice: 22.65,
            unitPriceDisplay: 'US$ 22.65',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IO: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ZA: {
        currency: 'USD',
        price: 110.4,
        display: 'US$110,40',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 88.33,
            discountValueDisplay: 'US$88,33',
            unitPrice: 22.07,
            unitPriceDisplay: 'US$22,07',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IQ: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IR: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IS: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '€86.40',
            unitPrice: 21.6,
            unitPriceDisplay: '€21.60',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IT: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '86,40 €',
            unitPrice: 21.6,
            unitPriceDisplay: '21,60 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ZM: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      JE: {
        currency: 'GBP',
        price: 96,
        display: '£96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '£76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '£19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ZW: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      JM: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      JO: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      JP: {
        currency: 'USD',
        price: 105.6,
        display: '$105.60',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 84.48,
            discountValueDisplay: '$84.48',
            unitPrice: 21.12,
            unitPriceDisplay: '$21.12',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KE: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KG: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KH: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KI: {
        currency: 'AUD',
        price: 120,
        display: '$120.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 24,
            unitPriceDisplay: '$24.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KM: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KN: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KP: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KR: {
        currency: 'USD',
        price: 105.6,
        display: 'US$105.60',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 84.48,
            discountValueDisplay: 'US$84.48',
            unitPrice: 21.12,
            unitPriceDisplay: 'US$21.12',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KW: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KY: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KZ: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LA: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LB: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LC: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LI: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '€86.40',
            unitPrice: 21.6,
            unitPriceDisplay: '€21.60',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LK: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LR: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LS: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LT: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '86,40 €',
            unitPrice: 21.6,
            unitPriceDisplay: '21,60 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LU: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '€86.40',
            unitPrice: 21.6,
            unitPriceDisplay: '€21.60',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LV: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '86,40 €',
            unitPrice: 21.6,
            unitPriceDisplay: '21,60 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LY: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MA: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MC: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '€86.40',
            unitPrice: 21.6,
            unitPriceDisplay: '€21.60',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MD: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ME: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '€86.40',
            unitPrice: 21.6,
            unitPriceDisplay: '€21.60',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MF: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '€86.40',
            unitPrice: 21.6,
            unitPriceDisplay: '€21.60',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MG: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MH: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MK: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ML: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MM: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MN: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MO: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MP: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MQ: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '€86.40',
            unitPrice: 21.6,
            unitPriceDisplay: '€21.60',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MR: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MS: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MT: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '€86.40',
            unitPrice: 21.6,
            unitPriceDisplay: '€21.60',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MU: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MV: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MW: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MX: {
        currency: 'USD',
        price: 96,
        display: 'USD96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'USD76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'USD19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MY: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MZ: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NA: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NC: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NE: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NF: {
        currency: 'AUD',
        price: 120,
        display: '$120.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 24,
            unitPriceDisplay: '$24.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NG: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NI: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NL: {
        currency: 'EUR',
        price: 108,
        display: '€ 108,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '€ 86,40',
            unitPrice: 21.6,
            unitPriceDisplay: '€ 21,60',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NO: {
        currency: 'EUR',
        price: 108,
        display: '€ 108,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '€ 86,40',
            unitPrice: 21.6,
            unitPriceDisplay: '€ 21,60',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NP: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NR: {
        currency: 'AUD',
        price: 120,
        display: '$120.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 24,
            unitPriceDisplay: '$24.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NU: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NZ: {
        currency: 'USD',
        price: 110.4,
        display: 'US$110.40',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 88.33,
            discountValueDisplay: 'US$88.33',
            unitPrice: 22.07,
            unitPriceDisplay: 'US$22.07',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      OM: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PA: {
        currency: 'USD',
        price: 96,
        display: 'USD96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'USD76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'USD19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PE: {
        currency: 'USD',
        price: 96,
        display: 'USD96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'USD76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'USD19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PF: {
        currency: 'USD',
        price: 96,
        display: '$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: '$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: '$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PG: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PH: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PK: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PL: {
        currency: 'EUR',
        price: 108,
        display: '108,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '86,40 €',
            unitPrice: 21.6,
            unitPriceDisplay: '21,60 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PM: {
        currency: 'EUR',
        price: 108,
        display: '€108.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 86.4,
            discountValueDisplay: '€86.40',
            unitPrice: 21.6,
            unitPriceDisplay: '€21.60',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PN: {
        currency: 'USD',
        price: 96,
        display: 'US$96.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 76.8,
            discountValueDisplay: 'US$76.80',
            unitPrice: 19.2,
            unitPriceDisplay: 'US$19.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
    },
  },
  'ocr-two': {
    action: 'product.price.getall',
    result: 'success',
    product: 'ocr-two',
    pricing: {
      PR: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      PS: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      PT: {
        currency: 'EUR',
        price: 57,
        display: '57,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 18.77,
            discountValueDisplay: '18,77 €',
            unitPrice: 38.23,
            unitPriceDisplay: '38,23 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      PW: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      PY: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      QA: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AD: {
        currency: 'EUR',
        price: 46,
        display: '€46.00',
        quantityDiscount: {
          '1': {
            discountValue: 15.26,
            discountValueDisplay: '€15.26',
            unitPrice: 30.74,
            unitPriceDisplay: '€30.74',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AE: {
        currency: 'USD',
        price: 50.4,
        display: '$50.40',
        quantityDiscount: {
          '1': {
            discountValue: 16.8,
            discountValueDisplay: '$16.80',
            unitPrice: 33.6,
            unitPriceDisplay: '$33.60',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AF: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AG: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AI: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AL: {
        currency: 'EUR',
        price: 46,
        display: '€46.00',
        quantityDiscount: {
          '1': {
            discountValue: 15.26,
            discountValueDisplay: '€15.26',
            unitPrice: 30.74,
            unitPriceDisplay: '€30.74',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AM: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AN: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AO: {
        currency: 'USD',
        price: 48,
        display: '48,00 US$',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '16,00 US$',
            unitPrice: 32,
            unitPriceDisplay: '32,00 US$',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AQ: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AR: {
        currency: 'USD',
        price: 48,
        display: 'US$ 48,00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$ 16,00',
            unitPrice: 32,
            unitPriceDisplay: 'US$ 32,00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AS: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AT: {
        currency: 'EUR',
        price: 55,
        display: '€ 55,00',
        quantityDiscount: {
          '1': {
            discountValue: 18.31,
            discountValueDisplay: '€ 18,31',
            unitPrice: 36.69,
            unitPriceDisplay: '€ 36,69',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      RE: {
        currency: 'EUR',
        price: 46,
        display: '€46.00',
        quantityDiscount: {
          '1': {
            discountValue: 15.26,
            discountValueDisplay: '€15.26',
            unitPrice: 30.74,
            unitPriceDisplay: '€30.74',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AU: {
        currency: 'AUD',
        price: 83,
        display: '$83.00',
        quantityDiscount: {
          '1': {
            discountValue: 27.62,
            discountValueDisplay: '$27.62',
            unitPrice: 55.38,
            unitPriceDisplay: '$55.38',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AW: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AX: {
        currency: 'EUR',
        price: 46,
        display: '€46.00',
        quantityDiscount: {
          '1': {
            discountValue: 15.26,
            discountValueDisplay: '€15.26',
            unitPrice: 30.74,
            unitPriceDisplay: '€30.74',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      AZ: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      RO: {
        currency: 'EUR',
        price: 55,
        display: '€55.00',
        quantityDiscount: {
          '1': {
            discountValue: 18.16,
            discountValueDisplay: '€18.16',
            unitPrice: 36.84,
            unitPriceDisplay: '€36.84',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BA: {
        currency: 'EUR',
        price: 46,
        display: '€46.00',
        quantityDiscount: {
          '1': {
            discountValue: 15.26,
            discountValueDisplay: '€15.26',
            unitPrice: 30.74,
            unitPriceDisplay: '€30.74',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BB: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      RS: {
        currency: 'EUR',
        price: 55,
        display: '55,00 EUR',
        quantityDiscount: {
          '1': {
            discountValue: 18.31,
            discountValueDisplay: '18,31 EUR',
            unitPrice: 36.69,
            unitPriceDisplay: '36,69 EUR',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BD: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BE: {
        currency: 'EUR',
        price: 56,
        display: '56,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 18.46,
            discountValueDisplay: '18,46 €',
            unitPrice: 37.54,
            unitPriceDisplay: '37,54 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      RU: {
        currency: 'USD',
        price: 57.6,
        display: '57,60 $',
        quantityDiscount: {
          '1': {
            discountValue: 19.2,
            discountValueDisplay: '19,20 $',
            unitPrice: 38.4,
            unitPriceDisplay: '38,40 $',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BF: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BG: {
        currency: 'EUR',
        price: 55,
        display: '€55.00',
        quantityDiscount: {
          '1': {
            discountValue: 18.31,
            discountValueDisplay: '€18.31',
            unitPrice: 36.69,
            unitPriceDisplay: '€36.69',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      RW: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BH: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BI: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BJ: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BL: {
        currency: 'EUR',
        price: 46,
        display: '€46.00',
        quantityDiscount: {
          '1': {
            discountValue: 15.26,
            discountValueDisplay: '€15.26',
            unitPrice: 30.74,
            unitPriceDisplay: '€30.74',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BM: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BN: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BO: {
        currency: 'USD',
        price: 48,
        display: 'USD48,00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'USD16,00',
            unitPrice: 32,
            unitPriceDisplay: 'USD32,00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SA: {
        currency: 'USD',
        price: 55.2,
        display: '$55.20',
        quantityDiscount: {
          '1': {
            discountValue: 18.4,
            discountValueDisplay: '$18.40',
            unitPrice: 36.8,
            unitPriceDisplay: '$36.80',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BQ: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SB: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BR: {
        currency: 'USD',
        price: 48,
        display: 'US$ 48,00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$ 16,00',
            unitPrice: 32,
            unitPriceDisplay: 'US$ 32,00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SC: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BS: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SD: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BT: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SE: {
        currency: 'EUR',
        price: 58,
        display: '58,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 19.08,
            discountValueDisplay: '19,08 €',
            unitPrice: 38.92,
            unitPriceDisplay: '38,92 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BV: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SG: {
        currency: 'USD',
        price: 52.32,
        display: 'US$52.32',
        quantityDiscount: {
          '1': {
            discountValue: 17.44,
            discountValueDisplay: 'US$17.44',
            unitPrice: 34.88,
            unitPriceDisplay: 'US$34.88',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BW: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SH: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SI: {
        currency: 'EUR',
        price: 56,
        display: '56,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 18.62,
            discountValueDisplay: '18,62 €',
            unitPrice: 37.38,
            unitPriceDisplay: '37,38 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BY: {
        currency: 'USD',
        price: 57.6,
        display: '$57.60',
        quantityDiscount: {
          '1': {
            discountValue: 19.2,
            discountValueDisplay: '$19.20',
            unitPrice: 38.4,
            unitPriceDisplay: '$38.40',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SJ: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      BZ: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SK: {
        currency: 'EUR',
        price: 55,
        display: '55,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 18.31,
            discountValueDisplay: '18,31 €',
            unitPrice: 36.69,
            unitPriceDisplay: '36,69 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SL: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SM: {
        currency: 'EUR',
        price: 46,
        display: '€46.00',
        quantityDiscount: {
          '1': {
            discountValue: 15.26,
            discountValueDisplay: '€15.26',
            unitPrice: 30.74,
            unitPriceDisplay: '€30.74',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SN: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SO: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CA: {
        currency: 'CAD',
        price: 68,
        display: '$68.00',
        quantityDiscount: {
          '1': {
            discountValue: 22.38,
            discountValueDisplay: '$22.38',
            unitPrice: 45.62,
            unitPriceDisplay: '$45.62',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SR: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CC: {
        currency: 'AUD',
        price: 76,
        display: '$76.00',
        quantityDiscount: {
          '1': {
            discountValue: 25.11,
            discountValueDisplay: '$25.11',
            unitPrice: 50.89,
            unitPriceDisplay: '$50.89',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SS: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CD: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      ST: {
        currency: 'USD',
        price: 48,
        display: '48,00 US$',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '16,00 US$',
            unitPrice: 32,
            unitPriceDisplay: '32,00 US$',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CF: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SV: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CG: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CH: {
        currency: 'EUR',
        price: 50,
        display: '€ 50,00',
        quantityDiscount: {
          '1': {
            discountValue: 16.5,
            discountValueDisplay: '€ 16,50',
            unitPrice: 33.5,
            unitPriceDisplay: '€ 33,50',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SX: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CI: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SY: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      SZ: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CK: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CL: {
        currency: 'USD',
        price: 57.12,
        display: 'US$57,12',
        quantityDiscount: {
          '1': {
            discountValue: 19.04,
            discountValueDisplay: 'US$19,04',
            unitPrice: 38.08,
            unitPriceDisplay: 'US$38,08',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CM: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CN: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CO: {
        currency: 'USD',
        price: 57.12,
        display: 'US$ 57,12',
        quantityDiscount: {
          '1': {
            discountValue: 19.04,
            discountValueDisplay: 'US$ 19,04',
            unitPrice: 38.08,
            unitPriceDisplay: 'US$ 38,08',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CR: {
        currency: 'USD',
        price: 48,
        display: 'USD48,00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'USD16,00',
            unitPrice: 32,
            unitPriceDisplay: 'USD32,00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TC: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TD: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CU: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TF: {
        currency: 'EUR',
        price: 46,
        display: '€46.00',
        quantityDiscount: {
          '1': {
            discountValue: 15.26,
            discountValueDisplay: '€15.26',
            unitPrice: 30.74,
            unitPriceDisplay: '€30.74',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CV: {
        currency: 'USD',
        price: 48,
        display: '48,00 US$',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '16,00 US$',
            unitPrice: 32,
            unitPriceDisplay: '32,00 US$',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TG: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CW: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TH: {
        currency: 'USD',
        price: 51.36,
        display: '$51.36',
        quantityDiscount: {
          '1': {
            discountValue: 17.12,
            discountValueDisplay: '$17.12',
            unitPrice: 34.24,
            unitPriceDisplay: '$34.24',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CX: {
        currency: 'AUD',
        price: 76,
        display: '$76.00',
        quantityDiscount: {
          '1': {
            discountValue: 25.11,
            discountValueDisplay: '$25.11',
            unitPrice: 50.89,
            unitPriceDisplay: '$50.89',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CY: {
        currency: 'EUR',
        price: 55,
        display: '€55.00',
        quantityDiscount: {
          '1': {
            discountValue: 18.16,
            discountValueDisplay: '€18.16',
            unitPrice: 36.84,
            unitPriceDisplay: '€36.84',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TJ: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      CZ: {
        currency: 'EUR',
        price: 56,
        display: '56,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 18.46,
            discountValueDisplay: '18,46 €',
            unitPrice: 37.54,
            unitPriceDisplay: '37,54 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TK: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TL: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TM: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TN: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TO: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TR: {
        currency: 'USD',
        price: 57.6,
        display: '$57,60',
        quantityDiscount: {
          '1': {
            discountValue: 19.2,
            discountValueDisplay: '$19,20',
            unitPrice: 38.4,
            unitPriceDisplay: '$38,40',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TT: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      DE: {
        currency: 'EUR',
        price: 55,
        display: '55,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 18.16,
            discountValueDisplay: '18,16 €',
            unitPrice: 36.84,
            unitPriceDisplay: '36,84 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TV: {
        currency: 'AUD',
        price: 76,
        display: '$76.00',
        quantityDiscount: {
          '1': {
            discountValue: 25.11,
            discountValueDisplay: '$25.11',
            unitPrice: 50.89,
            unitPriceDisplay: '$50.89',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TW: {
        currency: 'USD',
        price: 50.4,
        display: 'US$50.40',
        quantityDiscount: {
          '1': {
            discountValue: 16.8,
            discountValueDisplay: 'US$16.80',
            unitPrice: 33.6,
            unitPriceDisplay: 'US$33.60',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      DJ: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      TZ: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      DK: {
        currency: 'EUR',
        price: 58,
        display: '58,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 19.08,
            discountValueDisplay: '19,08 €',
            unitPrice: 38.92,
            unitPriceDisplay: '38,92 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      DM: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      DO: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      UA: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      UG: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      DZ: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      UM: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      EC: {
        currency: 'USD',
        price: 48,
        display: '$48,00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16,00',
            unitPrice: 32,
            unitPriceDisplay: '$32,00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      US: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      EE: {
        currency: 'EUR',
        price: 56,
        display: '€56.00',
        quantityDiscount: {
          '1': {
            discountValue: 18.62,
            discountValueDisplay: '€18.62',
            unitPrice: 37.38,
            unitPriceDisplay: '€37.38',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      EG: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      EH: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      UY: {
        currency: 'USD',
        price: 48,
        display: 'US$ 48,00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$ 16,00',
            unitPrice: 32,
            unitPriceDisplay: 'US$ 32,00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      UZ: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      VA: {
        currency: 'EUR',
        price: 46,
        display: '€46.00',
        quantityDiscount: {
          '1': {
            discountValue: 15.26,
            discountValueDisplay: '€15.26',
            unitPrice: 30.74,
            unitPriceDisplay: '€30.74',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      ER: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      VC: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      ES: {
        currency: 'EUR',
        price: 56,
        display: '56,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 18.46,
            discountValueDisplay: '18,46 €',
            unitPrice: 37.54,
            unitPriceDisplay: '37,54 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      ET: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      VE: {
        currency: 'USD',
        price: 48,
        display: 'USD48,00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'USD16,00',
            unitPrice: 32,
            unitPriceDisplay: 'USD32,00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      VG: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      VI: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      VN: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      VU: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      FI: {
        currency: 'EUR',
        price: 57,
        display: '57,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 18.92,
            discountValueDisplay: '18,92 €',
            unitPrice: 38.08,
            unitPriceDisplay: '38,08 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      FJ: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      FK: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      FM: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      FO: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      FR: {
        currency: 'EUR',
        price: 55,
        display: '55,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 18.31,
            discountValueDisplay: '18,31 €',
            unitPrice: 36.69,
            unitPriceDisplay: '36,69 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      WF: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GA: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GB: {
        currency: 'GBP',
        price: 48,
        display: '£48.00',
        quantityDiscount: {
          '1': {
            discountValue: 15.71,
            discountValueDisplay: '£15.71',
            unitPrice: 32.29,
            unitPriceDisplay: '£32.29',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      WS: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GD: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GE: {
        currency: 'EUR',
        price: 46,
        display: '€46.00',
        quantityDiscount: {
          '1': {
            discountValue: 15.26,
            discountValueDisplay: '€15.26',
            unitPrice: 30.74,
            unitPriceDisplay: '€30.74',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GF: {
        currency: 'EUR',
        price: 46,
        display: '€46.00',
        quantityDiscount: {
          '1': {
            discountValue: 15.26,
            discountValueDisplay: '€15.26',
            unitPrice: 30.74,
            unitPriceDisplay: '€30.74',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GG: {
        currency: 'GBP',
        price: 40,
        display: '£40.00',
        quantityDiscount: {
          '1': {
            discountValue: 13.09,
            discountValueDisplay: '£13.09',
            unitPrice: 26.91,
            unitPriceDisplay: '£26.91',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GH: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GI: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GL: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GM: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GN: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GP: {
        currency: 'EUR',
        price: 46,
        display: '€46.00',
        quantityDiscount: {
          '1': {
            discountValue: 15.26,
            discountValueDisplay: '€15.26',
            unitPrice: 30.74,
            unitPriceDisplay: '€30.74',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GQ: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GR: {
        currency: 'EUR',
        price: 57,
        display: '57,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 18.92,
            discountValueDisplay: '18,92 €',
            unitPrice: 38.08,
            unitPriceDisplay: '38,08 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GS: {
        currency: 'GBP',
        price: 40,
        display: '£40.00',
        quantityDiscount: {
          '1': {
            discountValue: 13.09,
            discountValueDisplay: '£13.09',
            unitPrice: 26.91,
            unitPriceDisplay: '£26.91',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GT: {
        currency: 'USD',
        price: 48,
        display: 'USD48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'USD16.00',
            unitPrice: 32,
            unitPriceDisplay: 'USD32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GU: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GW: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      GY: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      HK: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      HM: {
        currency: 'AUD',
        price: 76,
        display: 'A$76.00',
        quantityDiscount: {
          '1': {
            discountValue: 25.11,
            discountValueDisplay: 'A$25.11',
            unitPrice: 50.89,
            unitPriceDisplay: 'A$50.89',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      HN: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      HR: {
        currency: 'EUR',
        price: 58,
        display: '58,00 EUR',
        quantityDiscount: {
          '1': {
            discountValue: 19.08,
            discountValueDisplay: '19,08 EUR',
            unitPrice: 38.92,
            unitPriceDisplay: '38,92 EUR',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      HT: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      YE: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      HU: {
        currency: 'EUR',
        price: 59,
        display: '59,00 EUR',
        quantityDiscount: {
          '1': {
            discountValue: 19.38,
            discountValueDisplay: '19,38 EUR',
            unitPrice: 39.62,
            unitPriceDisplay: '39,62 EUR',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      IC: {
        currency: 'EUR',
        price: 46,
        display: '46,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 15.26,
            discountValueDisplay: '15,26 €',
            unitPrice: 30.74,
            unitPriceDisplay: '30,74 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      ID: {
        currency: 'USD',
        price: 53.28,
        display: '$53.28',
        quantityDiscount: {
          '1': {
            discountValue: 17.76,
            discountValueDisplay: '$17.76',
            unitPrice: 35.52,
            unitPriceDisplay: '$35.52',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      YT: {
        currency: 'EUR',
        price: 46,
        display: '€46.00',
        quantityDiscount: {
          '1': {
            discountValue: 15.26,
            discountValueDisplay: '€15.26',
            unitPrice: 30.74,
            unitPriceDisplay: '€30.74',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      IE: {
        currency: 'EUR',
        price: 57,
        display: '€57.00',
        quantityDiscount: {
          '1': {
            discountValue: 18.77,
            discountValueDisplay: '€18.77',
            unitPrice: 38.23,
            unitPriceDisplay: '€38.23',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      IL: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      IM: {
        currency: 'GBP',
        price: 40,
        display: '£40.00',
        quantityDiscount: {
          '1': {
            discountValue: 13.09,
            discountValueDisplay: '£13.09',
            unitPrice: 26.91,
            unitPriceDisplay: '£26.91',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      IN: {
        currency: 'USD',
        price: 56.64,
        display: 'US$ 56.64',
        quantityDiscount: {
          '1': {
            discountValue: 18.88,
            discountValueDisplay: 'US$ 18.88',
            unitPrice: 37.76,
            unitPriceDisplay: 'US$ 37.76',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      IO: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      ZA: {
        currency: 'USD',
        price: 55.2,
        display: 'US$55,20',
        quantityDiscount: {
          '1': {
            discountValue: 18.4,
            discountValueDisplay: 'US$18,40',
            unitPrice: 36.8,
            unitPriceDisplay: 'US$36,80',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      IQ: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      IR: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      IS: {
        currency: 'EUR',
        price: 57,
        display: '€57.00',
        quantityDiscount: {
          '1': {
            discountValue: 18.92,
            discountValueDisplay: '€18.92',
            unitPrice: 38.08,
            unitPriceDisplay: '€38.08',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      IT: {
        currency: 'EUR',
        price: 56,
        display: '56,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 18.62,
            discountValueDisplay: '18,62 €',
            unitPrice: 37.38,
            unitPriceDisplay: '37,38 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      ZM: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      JE: {
        currency: 'GBP',
        price: 40,
        display: '£40.00',
        quantityDiscount: {
          '1': {
            discountValue: 13.09,
            discountValueDisplay: '£13.09',
            unitPrice: 26.91,
            unitPriceDisplay: '£26.91',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      ZW: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      JM: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      JO: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      JP: {
        currency: 'USD',
        price: 52.8,
        display: '$52.80',
        quantityDiscount: {
          '1': {
            discountValue: 17.6,
            discountValueDisplay: '$17.60',
            unitPrice: 35.2,
            unitPriceDisplay: '$35.20',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      KE: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      KG: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      KH: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      KI: {
        currency: 'AUD',
        price: 76,
        display: '$76.00',
        quantityDiscount: {
          '1': {
            discountValue: 25.11,
            discountValueDisplay: '$25.11',
            unitPrice: 50.89,
            unitPriceDisplay: '$50.89',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      KM: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      KN: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      KP: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      KR: {
        currency: 'USD',
        price: 52.8,
        display: 'US$52.80',
        quantityDiscount: {
          '1': {
            discountValue: 17.6,
            discountValueDisplay: 'US$17.60',
            unitPrice: 35.2,
            unitPriceDisplay: 'US$35.20',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      KW: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      KY: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      KZ: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      LA: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      LB: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      LC: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      LI: {
        currency: 'EUR',
        price: 46,
        display: '€46.00',
        quantityDiscount: {
          '1': {
            discountValue: 15.26,
            discountValueDisplay: '€15.26',
            unitPrice: 30.74,
            unitPriceDisplay: '€30.74',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      LK: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      LR: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      LS: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      LT: {
        currency: 'EUR',
        price: 56,
        display: '56,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 18.46,
            discountValueDisplay: '18,46 €',
            unitPrice: 37.54,
            unitPriceDisplay: '37,54 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      LU: {
        currency: 'EUR',
        price: 54,
        display: '€54.00',
        quantityDiscount: {
          '1': {
            discountValue: 17.85,
            discountValueDisplay: '€17.85',
            unitPrice: 36.15,
            unitPriceDisplay: '€36.15',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      LV: {
        currency: 'EUR',
        price: 56,
        display: '56,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 18.46,
            discountValueDisplay: '18,46 €',
            unitPrice: 37.54,
            unitPriceDisplay: '37,54 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      LY: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MA: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MC: {
        currency: 'EUR',
        price: 46,
        display: '€46.00',
        quantityDiscount: {
          '1': {
            discountValue: 15.26,
            discountValueDisplay: '€15.26',
            unitPrice: 30.74,
            unitPriceDisplay: '€30.74',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MD: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      ME: {
        currency: 'EUR',
        price: 46,
        display: '€46.00',
        quantityDiscount: {
          '1': {
            discountValue: 15.26,
            discountValueDisplay: '€15.26',
            unitPrice: 30.74,
            unitPriceDisplay: '€30.74',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MF: {
        currency: 'EUR',
        price: 46,
        display: '€46.00',
        quantityDiscount: {
          '1': {
            discountValue: 15.26,
            discountValueDisplay: '€15.26',
            unitPrice: 30.74,
            unitPriceDisplay: '€30.74',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MG: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MH: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MK: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      ML: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MM: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MN: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MO: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MP: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MQ: {
        currency: 'EUR',
        price: 46,
        display: '€46.00',
        quantityDiscount: {
          '1': {
            discountValue: 15.26,
            discountValueDisplay: '€15.26',
            unitPrice: 30.74,
            unitPriceDisplay: '€30.74',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MR: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MS: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MT: {
        currency: 'EUR',
        price: 55,
        display: '€55.00',
        quantityDiscount: {
          '1': {
            discountValue: 18.01,
            discountValueDisplay: '€18.01',
            unitPrice: 36.99,
            unitPriceDisplay: '€36.99',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MU: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MV: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MW: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MX: {
        currency: 'USD',
        price: 48,
        display: 'USD48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'USD16.00',
            unitPrice: 32,
            unitPriceDisplay: 'USD32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MY: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      MZ: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      NA: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      NC: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      NE: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      NF: {
        currency: 'AUD',
        price: 76,
        display: '$76.00',
        quantityDiscount: {
          '1': {
            discountValue: 25.11,
            discountValueDisplay: '$25.11',
            unitPrice: 50.89,
            unitPriceDisplay: '$50.89',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      NG: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      NI: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      NL: {
        currency: 'EUR',
        price: 56,
        display: '€ 56,00',
        quantityDiscount: {
          '1': {
            discountValue: 18.46,
            discountValueDisplay: '€ 18,46',
            unitPrice: 37.54,
            unitPriceDisplay: '€ 37,54',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      NO: {
        currency: 'EUR',
        price: 58,
        display: '€ 58,00',
        quantityDiscount: {
          '1': {
            discountValue: 19.08,
            discountValueDisplay: '€ 19,08',
            unitPrice: 38.92,
            unitPriceDisplay: '€ 38,92',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      NP: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      NR: {
        currency: 'AUD',
        price: 76,
        display: '$76.00',
        quantityDiscount: {
          '1': {
            discountValue: 25.11,
            discountValueDisplay: '$25.11',
            unitPrice: 50.89,
            unitPriceDisplay: '$50.89',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      NU: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      NZ: {
        currency: 'USD',
        price: 55.2,
        display: 'US$55.20',
        quantityDiscount: {
          '1': {
            discountValue: 18.4,
            discountValueDisplay: 'US$18.40',
            unitPrice: 36.8,
            unitPriceDisplay: 'US$36.80',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      OM: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      PA: {
        currency: 'USD',
        price: 48,
        display: 'USD48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'USD16.00',
            unitPrice: 32,
            unitPriceDisplay: 'USD32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      PE: {
        currency: 'USD',
        price: 48,
        display: 'USD48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'USD16.00',
            unitPrice: 32,
            unitPriceDisplay: 'USD32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      PF: {
        currency: 'USD',
        price: 48,
        display: '$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: '$16.00',
            unitPrice: 32,
            unitPriceDisplay: '$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      PG: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      PH: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      PK: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      PL: {
        currency: 'EUR',
        price: 57,
        display: '57,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 18.77,
            discountValueDisplay: '18,77 €',
            unitPrice: 38.23,
            unitPriceDisplay: '38,23 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      PM: {
        currency: 'EUR',
        price: 46,
        display: '€46.00',
        quantityDiscount: {
          '1': {
            discountValue: 15.26,
            discountValueDisplay: '€15.26',
            unitPrice: 30.74,
            unitPriceDisplay: '€30.74',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
      PN: {
        currency: 'USD',
        price: 48,
        display: 'US$48.00',
        quantityDiscount: {
          '1': {
            discountValue: 16,
            discountValueDisplay: 'US$16.00',
            unitPrice: 32,
            unitPriceDisplay: 'US$32.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 'null',
        available: {},
      },
    },
  },
  'pdf-monthly-multi-uk': {
    action: 'product.price.getall',
    result: 'success',
    product: 'pdf-monthly-multi-uk',
    pricing: {
      PR: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PS: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PT: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PY: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      QA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AD: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AE: {
        currency: 'USD',
        price: 5.25,
        display: '$5.25',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AI: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AL: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AO: {
        currency: 'USD',
        price: 5,
        display: '5,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AQ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AR: {
        currency: 'USD',
        price: 5,
        display: 'US$ 5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AS: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AT: {
        currency: 'EUR',
        price: 6,
        display: '€ 6,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RE: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AU: {
        currency: 'AUD',
        price: 8,
        display: '$8.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AW: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AX: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RO: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BA: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BB: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RS: {
        currency: 'EUR',
        price: 6,
        display: '6,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BD: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BE: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RU: {
        currency: 'USD',
        price: 6,
        display: '6,00 $',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BG: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BH: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BI: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BJ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BL: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BO: {
        currency: 'USD',
        price: 5,
        display: 'USD5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SA: {
        currency: 'USD',
        price: 5.75,
        display: '$5.75',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BQ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SB: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BR: {
        currency: 'USD',
        price: 5,
        display: 'US$ 5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SC: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SD: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BT: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SE: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BV: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SG: {
        currency: 'USD',
        price: 5.45,
        display: 'US$5.45',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SH: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SI: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BY: {
        currency: 'USD',
        price: 6,
        display: '$6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SJ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BZ: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SK: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SL: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SM: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SO: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CA: {
        currency: 'CAD',
        price: 7,
        display: '$7.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SR: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CC: {
        currency: 'AUD',
        price: 8,
        display: '$8.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CD: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ST: {
        currency: 'USD',
        price: 5,
        display: '5,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SV: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CG: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CH: {
        currency: 'EUR',
        price: 6,
        display: '€ 6,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SX: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CI: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SY: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SZ: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CL: {
        currency: 'USD',
        price: 5.95,
        display: 'US$5,95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CN: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CO: {
        currency: 'USD',
        price: 5.95,
        display: 'US$ 5,95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CR: {
        currency: 'USD',
        price: 5,
        display: 'USD5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TC: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TD: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CU: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TF: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CV: {
        currency: 'USD',
        price: 5,
        display: '5,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TG: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CW: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TH: {
        currency: 'USD',
        price: 5.35,
        display: '$5.35',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CX: {
        currency: 'AUD',
        price: 8,
        display: '$8.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CY: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TJ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CZ: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TL: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TO: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TR: {
        currency: 'USD',
        price: 6,
        display: '$6,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TT: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DE: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TV: {
        currency: 'AUD',
        price: 8,
        display: '$8.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TW: {
        currency: 'USD',
        price: 5.25,
        display: 'US$5.25',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DJ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TZ: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DK: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DO: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EC: {
        currency: 'USD',
        price: 5,
        display: '$5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      US: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EE: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EG: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EH: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UY: {
        currency: 'USD',
        price: 5,
        display: 'US$ 5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VA: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ER: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VC: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ES: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ET: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VE: {
        currency: 'USD',
        price: 5,
        display: 'USD5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VI: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VU: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FI: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FJ: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FO: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FR: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GB: {
        currency: 'GBP',
        price: 5,
        display: '£5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GD: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GE: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GF: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GG: {
        currency: 'GBP',
        price: 5,
        display: '£5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GH: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GI: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GL: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GP: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GQ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GR: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GS: {
        currency: 'GBP',
        price: 5,
        display: '£5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GT: {
        currency: 'USD',
        price: 5,
        display: 'USD5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GU: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GW: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GY: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HM: {
        currency: 'AUD',
        price: 8,
        display: 'A$8.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HR: {
        currency: 'EUR',
        price: 6,
        display: '6,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HT: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YE: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HU: {
        currency: 'EUR',
        price: 7,
        display: '7,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IC: {
        currency: 'EUR',
        price: 5,
        display: '5,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ID: {
        currency: 'USD',
        price: 5.55,
        display: '$5.55',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YT: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IE: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IL: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IM: {
        currency: 'GBP',
        price: 5,
        display: '£5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IN: {
        currency: 'USD',
        price: 5.9,
        display: 'US$ 5.90',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IO: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZA: {
        currency: 'USD',
        price: 5.75,
        display: 'US$5,75',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IQ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IR: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IS: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IT: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JE: {
        currency: 'GBP',
        price: 5,
        display: '£5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JO: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JP: {
        currency: 'USD',
        price: 5.5,
        display: '$5.50',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KE: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KG: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KH: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KI: {
        currency: 'AUD',
        price: 8,
        display: '$8.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KN: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KP: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KR: {
        currency: 'USD',
        price: 5.5,
        display: 'US$5.50',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KW: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KY: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LB: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LC: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LI: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LK: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LR: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LT: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LU: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LV: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LY: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MC: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MD: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ME: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MF: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MH: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MK: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ML: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MO: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MP: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MQ: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MR: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MT: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MU: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MV: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MX: {
        currency: 'USD',
        price: 5,
        display: 'USD5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MY: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NA: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NC: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NE: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NF: {
        currency: 'AUD',
        price: 8,
        display: '$8.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NI: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NL: {
        currency: 'EUR',
        price: 6,
        display: '€ 6,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NO: {
        currency: 'EUR',
        price: 6,
        display: '€ 6,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NP: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NR: {
        currency: 'AUD',
        price: 8,
        display: '$8.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NU: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NZ: {
        currency: 'USD',
        price: 5.75,
        display: 'US$5.75',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      OM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PA: {
        currency: 'USD',
        price: 5,
        display: 'USD5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PE: {
        currency: 'USD',
        price: 5,
        display: 'USD5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PH: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PL: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PM: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PN: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
    },
  },
  'pdf-two-year-multi': {
    action: 'product.price.getall',
    result: 'success',
    product: 'pdf-two-year-multi',
    pricing: {
      PR: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      PS: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      PT: {
        currency: 'EUR',
        price: 226,
        display: '226,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 112.64,
            discountValueDisplay: '112,64 €',
            unitPrice: 113.36,
            unitPriceDisplay: '113,36 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      PW: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      PY: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      QA: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AD: {
        currency: 'EUR',
        price: 184,
        display: '€184.00',
        quantityDiscount: {
          '1': {
            discountValue: 91.58,
            discountValueDisplay: '€91.58',
            unitPrice: 92.42,
            unitPriceDisplay: '€92.42',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AE: {
        currency: 'USD',
        price: 201.6,
        display: '$201.60',
        quantityDiscount: {
          '1': {
            discountValue: 100.8,
            discountValueDisplay: '$100.80',
            unitPrice: 100.8,
            unitPriceDisplay: '$100.80',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AF: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AG: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AI: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AL: {
        currency: 'EUR',
        price: 184,
        display: '€184.00',
        quantityDiscount: {
          '1': {
            discountValue: 91.58,
            discountValueDisplay: '€91.58',
            unitPrice: 92.42,
            unitPriceDisplay: '€92.42',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AM: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AN: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AO: {
        currency: 'USD',
        price: 192,
        display: '192,00 US$',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '96,00 US$',
            unitPrice: 96,
            unitPriceDisplay: '96,00 US$',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AQ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AR: {
        currency: 'USD',
        price: 192,
        display: 'US$ 192,00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$ 96,00',
            unitPrice: 96,
            unitPriceDisplay: 'US$ 96,00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AS: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AT: {
        currency: 'EUR',
        price: 220,
        display: '€ 220,00',
        quantityDiscount: {
          '1': {
            discountValue: 109.9,
            discountValueDisplay: '€ 109,90',
            unitPrice: 110.1,
            unitPriceDisplay: '€ 110,10',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      RE: {
        currency: 'EUR',
        price: 184,
        display: '€184.00',
        quantityDiscount: {
          '1': {
            discountValue: 91.58,
            discountValueDisplay: '€91.58',
            unitPrice: 92.42,
            unitPriceDisplay: '€92.42',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AU: {
        currency: 'AUD',
        price: 332,
        display: '$332.00',
        quantityDiscount: {
          '1': {
            discountValue: 165.74,
            discountValueDisplay: '$165.74',
            unitPrice: 166.26,
            unitPriceDisplay: '$166.26',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AW: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AX: {
        currency: 'EUR',
        price: 184,
        display: '€184.00',
        quantityDiscount: {
          '1': {
            discountValue: 91.58,
            discountValueDisplay: '€91.58',
            unitPrice: 92.42,
            unitPriceDisplay: '€92.42',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AZ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      RO: {
        currency: 'EUR',
        price: 218,
        display: '€218.00',
        quantityDiscount: {
          '1': {
            discountValue: 108.98,
            discountValueDisplay: '€108.98',
            unitPrice: 109.02,
            unitPriceDisplay: '€109.02',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BA: {
        currency: 'EUR',
        price: 184,
        display: '€184.00',
        quantityDiscount: {
          '1': {
            discountValue: 91.58,
            discountValueDisplay: '€91.58',
            unitPrice: 92.42,
            unitPriceDisplay: '€92.42',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BB: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      RS: {
        currency: 'EUR',
        price: 220,
        display: '220,00 EUR',
        quantityDiscount: {
          '1': {
            discountValue: 109.9,
            discountValueDisplay: '109,90 EUR',
            unitPrice: 110.1,
            unitPriceDisplay: '110,10 EUR',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BD: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BE: {
        currency: 'EUR',
        price: 222,
        display: '222,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 110.81,
            discountValueDisplay: '110,81 €',
            unitPrice: 111.19,
            unitPriceDisplay: '111,19 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      RU: {
        currency: 'USD',
        price: 230.4,
        display: '230,40 $',
        quantityDiscount: {
          '1': {
            discountValue: 115.2,
            discountValueDisplay: '115,20 $',
            unitPrice: 115.2,
            unitPriceDisplay: '115,20 $',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BF: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BG: {
        currency: 'EUR',
        price: 220,
        display: '€220.00',
        quantityDiscount: {
          '1': {
            discountValue: 109.9,
            discountValueDisplay: '€109.90',
            unitPrice: 110.1,
            unitPriceDisplay: '€110.10',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      RW: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BH: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BI: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BJ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BL: {
        currency: 'EUR',
        price: 184,
        display: '€184.00',
        quantityDiscount: {
          '1': {
            discountValue: 91.58,
            discountValueDisplay: '€91.58',
            unitPrice: 92.42,
            unitPriceDisplay: '€92.42',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BM: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BN: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BO: {
        currency: 'USD',
        price: 192,
        display: 'USD192,00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'USD96,00',
            unitPrice: 96,
            unitPriceDisplay: 'USD96,00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SA: {
        currency: 'USD',
        price: 220.8,
        display: '$220.80',
        quantityDiscount: {
          '1': {
            discountValue: 110.4,
            discountValueDisplay: '$110.40',
            unitPrice: 110.4,
            unitPriceDisplay: '$110.40',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BQ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SB: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BR: {
        currency: 'USD',
        price: 192,
        display: 'US$ 192,00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$ 96,00',
            unitPrice: 96,
            unitPriceDisplay: 'US$ 96,00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SC: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BS: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SD: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BT: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SE: {
        currency: 'EUR',
        price: 229,
        display: '229,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 114.48,
            discountValueDisplay: '114,48 €',
            unitPrice: 114.52,
            unitPriceDisplay: '114,52 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BV: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SG: {
        currency: 'USD',
        price: 209.28,
        display: 'US$209.28',
        quantityDiscount: {
          '1': {
            discountValue: 104.64,
            discountValueDisplay: 'US$104.64',
            unitPrice: 104.64,
            unitPriceDisplay: 'US$104.64',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BW: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SH: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SI: {
        currency: 'EUR',
        price: 224,
        display: '224,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 111.73,
            discountValueDisplay: '111,73 €',
            unitPrice: 112.27,
            unitPriceDisplay: '112,27 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BY: {
        currency: 'USD',
        price: 230.4,
        display: '$230.40',
        quantityDiscount: {
          '1': {
            discountValue: 115.2,
            discountValueDisplay: '$115.20',
            unitPrice: 115.2,
            unitPriceDisplay: '$115.20',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SJ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BZ: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SK: {
        currency: 'EUR',
        price: 220,
        display: '220,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 109.9,
            discountValueDisplay: '109,90 €',
            unitPrice: 110.1,
            unitPriceDisplay: '110,10 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SL: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SM: {
        currency: 'EUR',
        price: 184,
        display: '€184.00',
        quantityDiscount: {
          '1': {
            discountValue: 91.58,
            discountValueDisplay: '€91.58',
            unitPrice: 92.42,
            unitPriceDisplay: '€92.42',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SN: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SO: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CA: {
        currency: 'CAD',
        price: 269,
        display: '$269.00',
        quantityDiscount: {
          '1': {
            discountValue: 134.29,
            discountValueDisplay: '$134.29',
            unitPrice: 134.71,
            unitPriceDisplay: '$134.71',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SR: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CC: {
        currency: 'AUD',
        price: 302,
        display: '$302.00',
        quantityDiscount: {
          '1': {
            discountValue: 150.67,
            discountValueDisplay: '$150.67',
            unitPrice: 151.33,
            unitPriceDisplay: '$151.33',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SS: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CD: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      ST: {
        currency: 'USD',
        price: 192,
        display: '192,00 US$',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '96,00 US$',
            unitPrice: 96,
            unitPriceDisplay: '96,00 US$',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CF: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SV: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CG: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CH: {
        currency: 'EUR',
        price: 198,
        display: '€ 198,00',
        quantityDiscount: {
          '1': {
            discountValue: 99,
            discountValueDisplay: '€ 99,00',
            unitPrice: 99,
            unitPriceDisplay: '€ 99,00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SX: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CI: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SY: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SZ: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CK: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CL: {
        currency: 'USD',
        price: 228.48,
        display: 'US$228,48',
        quantityDiscount: {
          '1': {
            discountValue: 114.24,
            discountValueDisplay: 'US$114,24',
            unitPrice: 114.24,
            unitPriceDisplay: 'US$114,24',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CM: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CN: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CO: {
        currency: 'USD',
        price: 228.48,
        display: 'US$ 228,48',
        quantityDiscount: {
          '1': {
            discountValue: 114.24,
            discountValueDisplay: 'US$ 114,24',
            unitPrice: 114.24,
            unitPriceDisplay: 'US$ 114,24',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CR: {
        currency: 'USD',
        price: 192,
        display: 'USD192,00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'USD96,00',
            unitPrice: 96,
            unitPriceDisplay: 'USD96,00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TC: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TD: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CU: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TF: {
        currency: 'EUR',
        price: 184,
        display: '€184.00',
        quantityDiscount: {
          '1': {
            discountValue: 91.58,
            discountValueDisplay: '€91.58',
            unitPrice: 92.42,
            unitPriceDisplay: '€92.42',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CV: {
        currency: 'USD',
        price: 192,
        display: '192,00 US$',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '96,00 US$',
            unitPrice: 96,
            unitPriceDisplay: '96,00 US$',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TG: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CW: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TH: {
        currency: 'USD',
        price: 205.44,
        display: '$205.44',
        quantityDiscount: {
          '1': {
            discountValue: 102.72,
            discountValueDisplay: '$102.72',
            unitPrice: 102.72,
            unitPriceDisplay: '$102.72',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CX: {
        currency: 'AUD',
        price: 302,
        display: '$302.00',
        quantityDiscount: {
          '1': {
            discountValue: 150.67,
            discountValueDisplay: '$150.67',
            unitPrice: 151.33,
            unitPriceDisplay: '$151.33',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CY: {
        currency: 'EUR',
        price: 218,
        display: '€218.00',
        quantityDiscount: {
          '1': {
            discountValue: 108.98,
            discountValueDisplay: '€108.98',
            unitPrice: 109.02,
            unitPriceDisplay: '€109.02',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TJ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CZ: {
        currency: 'EUR',
        price: 222,
        display: '222,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 110.81,
            discountValueDisplay: '110,81 €',
            unitPrice: 111.19,
            unitPriceDisplay: '111,19 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TK: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TL: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TM: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TN: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TO: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TR: {
        currency: 'USD',
        price: 230.4,
        display: '$230,40',
        quantityDiscount: {
          '1': {
            discountValue: 115.2,
            discountValueDisplay: '$115,20',
            unitPrice: 115.2,
            unitPriceDisplay: '$115,20',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TT: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      DE: {
        currency: 'EUR',
        price: 218,
        display: '218,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 108.98,
            discountValueDisplay: '108,98 €',
            unitPrice: 109.02,
            unitPriceDisplay: '109,02 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TV: {
        currency: 'AUD',
        price: 302,
        display: '$302.00',
        quantityDiscount: {
          '1': {
            discountValue: 150.67,
            discountValueDisplay: '$150.67',
            unitPrice: 151.33,
            unitPriceDisplay: '$151.33',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TW: {
        currency: 'USD',
        price: 201.6,
        display: 'US$201.60',
        quantityDiscount: {
          '1': {
            discountValue: 100.8,
            discountValueDisplay: 'US$100.80',
            unitPrice: 100.8,
            unitPriceDisplay: 'US$100.80',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      DJ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TZ: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      DK: {
        currency: 'EUR',
        price: 229,
        display: '229,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 114.48,
            discountValueDisplay: '114,48 €',
            unitPrice: 114.52,
            unitPriceDisplay: '114,52 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      DM: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      DO: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      UA: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      UG: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      DZ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      UM: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      EC: {
        currency: 'USD',
        price: 192,
        display: '$192,00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96,00',
            unitPrice: 96,
            unitPriceDisplay: '$96,00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      US: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      EE: {
        currency: 'EUR',
        price: 224,
        display: '€224.00',
        quantityDiscount: {
          '1': {
            discountValue: 111.73,
            discountValueDisplay: '€111.73',
            unitPrice: 112.27,
            unitPriceDisplay: '€112.27',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      EG: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      EH: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      UY: {
        currency: 'USD',
        price: 192,
        display: 'US$ 192,00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$ 96,00',
            unitPrice: 96,
            unitPriceDisplay: 'US$ 96,00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      UZ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      VA: {
        currency: 'EUR',
        price: 184,
        display: '€184.00',
        quantityDiscount: {
          '1': {
            discountValue: 91.58,
            discountValueDisplay: '€91.58',
            unitPrice: 92.42,
            unitPriceDisplay: '€92.42',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      ER: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      VC: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      ES: {
        currency: 'EUR',
        price: 222,
        display: '222,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 110.81,
            discountValueDisplay: '110,81 €',
            unitPrice: 111.19,
            unitPriceDisplay: '111,19 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      ET: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      VE: {
        currency: 'USD',
        price: 192,
        display: 'USD192,00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'USD96,00',
            unitPrice: 96,
            unitPriceDisplay: 'USD96,00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      VG: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      VI: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      VN: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      VU: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      FI: {
        currency: 'EUR',
        price: 228,
        display: '228,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 113.56,
            discountValueDisplay: '113,56 €',
            unitPrice: 114.44,
            unitPriceDisplay: '114,44 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      FJ: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      FK: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      FM: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      FO: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      FR: {
        currency: 'EUR',
        price: 220,
        display: '220,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 109.9,
            discountValueDisplay: '109,90 €',
            unitPrice: 110.1,
            unitPriceDisplay: '110,10 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      WF: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GA: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GB: {
        currency: 'GBP',
        price: 189,
        display: '£189.00',
        quantityDiscount: {
          '1': {
            discountValue: 94.21,
            discountValueDisplay: '£94.21',
            unitPrice: 94.79,
            unitPriceDisplay: '£94.79',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      WS: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GD: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GE: {
        currency: 'EUR',
        price: 184,
        display: '€184.00',
        quantityDiscount: {
          '1': {
            discountValue: 91.58,
            discountValueDisplay: '€91.58',
            unitPrice: 92.42,
            unitPriceDisplay: '€92.42',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GF: {
        currency: 'EUR',
        price: 184,
        display: '€184.00',
        quantityDiscount: {
          '1': {
            discountValue: 91.58,
            discountValueDisplay: '€91.58',
            unitPrice: 92.42,
            unitPriceDisplay: '€92.42',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GG: {
        currency: 'GBP',
        price: 158,
        display: '£158.00',
        quantityDiscount: {
          '1': {
            discountValue: 78.51,
            discountValueDisplay: '£78.51',
            unitPrice: 79.49,
            unitPriceDisplay: '£79.49',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GH: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GI: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GL: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GM: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GN: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GP: {
        currency: 'EUR',
        price: 184,
        display: '€184.00',
        quantityDiscount: {
          '1': {
            discountValue: 91.58,
            discountValueDisplay: '€91.58',
            unitPrice: 92.42,
            unitPriceDisplay: '€92.42',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GQ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GR: {
        currency: 'EUR',
        price: 228,
        display: '228,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 113.56,
            discountValueDisplay: '113,56 €',
            unitPrice: 114.44,
            unitPriceDisplay: '114,44 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GS: {
        currency: 'GBP',
        price: 158,
        display: '£158.00',
        quantityDiscount: {
          '1': {
            discountValue: 78.51,
            discountValueDisplay: '£78.51',
            unitPrice: 79.49,
            unitPriceDisplay: '£79.49',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GT: {
        currency: 'USD',
        price: 192,
        display: 'USD192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'USD96.00',
            unitPrice: 96,
            unitPriceDisplay: 'USD96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GU: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GW: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GY: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      HK: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      HM: {
        currency: 'AUD',
        price: 302,
        display: 'A$302.00',
        quantityDiscount: {
          '1': {
            discountValue: 150.67,
            discountValueDisplay: 'A$150.67',
            unitPrice: 151.33,
            unitPriceDisplay: 'A$151.33',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      HN: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      HR: {
        currency: 'EUR',
        price: 229,
        display: '229,00 EUR',
        quantityDiscount: {
          '1': {
            discountValue: 114.48,
            discountValueDisplay: '114,48 EUR',
            unitPrice: 114.52,
            unitPriceDisplay: '114,52 EUR',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      HT: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      YE: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      HU: {
        currency: 'EUR',
        price: 233,
        display: '233,00 EUR',
        quantityDiscount: {
          '1': {
            discountValue: 116.31,
            discountValueDisplay: '116,31 EUR',
            unitPrice: 116.69,
            unitPriceDisplay: '116,69 EUR',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      IC: {
        currency: 'EUR',
        price: 184,
        display: '184,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 91.58,
            discountValueDisplay: '91,58 €',
            unitPrice: 92.42,
            unitPriceDisplay: '92,42 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      ID: {
        currency: 'USD',
        price: 213.12,
        display: '$213.12',
        quantityDiscount: {
          '1': {
            discountValue: 106.56,
            discountValueDisplay: '$106.56',
            unitPrice: 106.56,
            unitPriceDisplay: '$106.56',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      YT: {
        currency: 'EUR',
        price: 184,
        display: '€184.00',
        quantityDiscount: {
          '1': {
            discountValue: 91.58,
            discountValueDisplay: '€91.58',
            unitPrice: 92.42,
            unitPriceDisplay: '€92.42',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      IE: {
        currency: 'EUR',
        price: 226,
        display: '€226.00',
        quantityDiscount: {
          '1': {
            discountValue: 112.64,
            discountValueDisplay: '€112.64',
            unitPrice: 113.36,
            unitPriceDisplay: '€113.36',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      IL: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      IM: {
        currency: 'GBP',
        price: 158,
        display: '£158.00',
        quantityDiscount: {
          '1': {
            discountValue: 78.51,
            discountValueDisplay: '£78.51',
            unitPrice: 79.49,
            unitPriceDisplay: '£79.49',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      IN: {
        currency: 'USD',
        price: 226.56,
        display: 'US$ 226.56',
        quantityDiscount: {
          '1': {
            discountValue: 113.28,
            discountValueDisplay: 'US$ 113.28',
            unitPrice: 113.28,
            unitPriceDisplay: 'US$ 113.28',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      IO: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      ZA: {
        currency: 'USD',
        price: 220.8,
        display: 'US$220,80',
        quantityDiscount: {
          '1': {
            discountValue: 110.4,
            discountValueDisplay: 'US$110,40',
            unitPrice: 110.4,
            unitPriceDisplay: 'US$110,40',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      IQ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      IR: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      IS: {
        currency: 'EUR',
        price: 228,
        display: '€228.00',
        quantityDiscount: {
          '1': {
            discountValue: 113.56,
            discountValueDisplay: '€113.56',
            unitPrice: 114.44,
            unitPriceDisplay: '€114.44',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      IT: {
        currency: 'EUR',
        price: 224,
        display: '224,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 111.73,
            discountValueDisplay: '111,73 €',
            unitPrice: 112.27,
            unitPriceDisplay: '112,27 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      ZM: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      JE: {
        currency: 'GBP',
        price: 158,
        display: '£158.00',
        quantityDiscount: {
          '1': {
            discountValue: 78.51,
            discountValueDisplay: '£78.51',
            unitPrice: 79.49,
            unitPriceDisplay: '£79.49',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      ZW: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      JM: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      JO: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      JP: {
        currency: 'USD',
        price: 211.2,
        display: '$211.20',
        quantityDiscount: {
          '1': {
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 105.6,
            unitPriceDisplay: '$105.60',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      KE: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      KG: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      KH: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      KI: {
        currency: 'AUD',
        price: 302,
        display: '$302.00',
        quantityDiscount: {
          '1': {
            discountValue: 150.67,
            discountValueDisplay: '$150.67',
            unitPrice: 151.33,
            unitPriceDisplay: '$151.33',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      KM: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      KN: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      KP: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      KR: {
        currency: 'USD',
        price: 211.2,
        display: 'US$211.20',
        quantityDiscount: {
          '1': {
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 105.6,
            unitPriceDisplay: 'US$105.60',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      KW: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      KY: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      KZ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      LA: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      LB: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      LC: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      LI: {
        currency: 'EUR',
        price: 184,
        display: '€184.00',
        quantityDiscount: {
          '1': {
            discountValue: 91.58,
            discountValueDisplay: '€91.58',
            unitPrice: 92.42,
            unitPriceDisplay: '€92.42',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      LK: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      LR: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      LS: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      LT: {
        currency: 'EUR',
        price: 222,
        display: '222,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 110.81,
            discountValueDisplay: '110,81 €',
            unitPrice: 111.19,
            unitPriceDisplay: '111,19 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      LU: {
        currency: 'EUR',
        price: 215,
        display: '€215.00',
        quantityDiscount: {
          '1': {
            discountValue: 107.15,
            discountValueDisplay: '€107.15',
            unitPrice: 107.85,
            unitPriceDisplay: '€107.85',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      LV: {
        currency: 'EUR',
        price: 222,
        display: '222,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 110.81,
            discountValueDisplay: '110,81 €',
            unitPrice: 111.19,
            unitPriceDisplay: '111,19 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      LY: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MA: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MC: {
        currency: 'EUR',
        price: 184,
        display: '€184.00',
        quantityDiscount: {
          '1': {
            discountValue: 91.58,
            discountValueDisplay: '€91.58',
            unitPrice: 92.42,
            unitPriceDisplay: '€92.42',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MD: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      ME: {
        currency: 'EUR',
        price: 184,
        display: '€184.00',
        quantityDiscount: {
          '1': {
            discountValue: 91.58,
            discountValueDisplay: '€91.58',
            unitPrice: 92.42,
            unitPriceDisplay: '€92.42',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MF: {
        currency: 'EUR',
        price: 184,
        display: '€184.00',
        quantityDiscount: {
          '1': {
            discountValue: 91.58,
            discountValueDisplay: '€91.58',
            unitPrice: 92.42,
            unitPriceDisplay: '€92.42',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MG: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MH: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MK: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      ML: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MM: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MN: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MO: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MP: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MQ: {
        currency: 'EUR',
        price: 184,
        display: '€184.00',
        quantityDiscount: {
          '1': {
            discountValue: 91.58,
            discountValueDisplay: '€91.58',
            unitPrice: 92.42,
            unitPriceDisplay: '€92.42',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MR: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MS: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MT: {
        currency: 'EUR',
        price: 217,
        display: '€217.00',
        quantityDiscount: {
          '1': {
            discountValue: 108.06,
            discountValueDisplay: '€108.06',
            unitPrice: 108.94,
            unitPriceDisplay: '€108.94',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MU: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MV: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MW: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MX: {
        currency: 'USD',
        price: 192,
        display: 'USD192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'USD96.00',
            unitPrice: 96,
            unitPriceDisplay: 'USD96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MY: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MZ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      NA: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      NC: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      NE: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      NF: {
        currency: 'AUD',
        price: 302,
        display: '$302.00',
        quantityDiscount: {
          '1': {
            discountValue: 150.67,
            discountValueDisplay: '$150.67',
            unitPrice: 151.33,
            unitPriceDisplay: '$151.33',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      NG: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      NI: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      NL: {
        currency: 'EUR',
        price: 222,
        display: '€ 222,00',
        quantityDiscount: {
          '1': {
            discountValue: 110.81,
            discountValueDisplay: '€ 110,81',
            unitPrice: 111.19,
            unitPriceDisplay: '€ 111,19',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      NO: {
        currency: 'EUR',
        price: 229,
        display: '€ 229,00',
        quantityDiscount: {
          '1': {
            discountValue: 114.48,
            discountValueDisplay: '€ 114,48',
            unitPrice: 114.52,
            unitPriceDisplay: '€ 114,52',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      NP: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      NR: {
        currency: 'AUD',
        price: 302,
        display: '$302.00',
        quantityDiscount: {
          '1': {
            discountValue: 150.67,
            discountValueDisplay: '$150.67',
            unitPrice: 151.33,
            unitPriceDisplay: '$151.33',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      NU: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      NZ: {
        currency: 'USD',
        price: 220.8,
        display: 'US$220.80',
        quantityDiscount: {
          '1': {
            discountValue: 110.4,
            discountValueDisplay: 'US$110.40',
            unitPrice: 110.4,
            unitPriceDisplay: 'US$110.40',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      OM: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      PA: {
        currency: 'USD',
        price: 192,
        display: 'USD192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'USD96.00',
            unitPrice: 96,
            unitPriceDisplay: 'USD96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      PE: {
        currency: 'USD',
        price: 192,
        display: 'USD192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'USD96.00',
            unitPrice: 96,
            unitPriceDisplay: 'USD96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      PF: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      PG: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      PH: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      PK: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      PL: {
        currency: 'EUR',
        price: 226,
        display: '226,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 112.64,
            discountValueDisplay: '112,64 €',
            unitPrice: 113.36,
            unitPriceDisplay: '113,36 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      PM: {
        currency: 'EUR',
        price: 184,
        display: '€184.00',
        quantityDiscount: {
          '1': {
            discountValue: 91.58,
            discountValueDisplay: '€91.58',
            unitPrice: 92.42,
            unitPriceDisplay: '€92.42',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      PN: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
    },
  },
  'pdf-perpetual-multi-ocr': {
    action: 'product.price.getall',
    result: 'success',
    product: 'pdf-perpetual-multi-ocr',
    pricing: {
      PR: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PS: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PT: {
        currency: 'EUR',
        price: 271,
        display: '271,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 235,
            unitPriceDisplay: '235,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PW: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PY: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      QA: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AD: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AE: {
        currency: 'USD',
        price: 264.6,
        display: '$264.60',
        quantityDiscount: {
          '1': {
            discountValue: 31.5,
            discountValueDisplay: '$31.50',
            unitPrice: 233.1,
            unitPriceDisplay: '$233.10',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AF: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AG: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AI: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AL: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AM: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AN: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AO: {
        currency: 'USD',
        price: 252,
        display: '252,00 US$',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '30,00 US$',
            unitPrice: 222,
            unitPriceDisplay: '222,00 US$',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AQ: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AR: {
        currency: 'USD',
        price: 252,
        display: 'US$ 252,00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$ 30,00',
            unitPrice: 222,
            unitPriceDisplay: 'US$ 222,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AS: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AT: {
        currency: 'EUR',
        price: 271,
        display: '€ 271,00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€ 36,00',
            unitPrice: 235,
            unitPriceDisplay: '€ 235,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RE: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AU: {
        currency: 'AUD',
        price: 321,
        display: '$321.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 285,
            unitPriceDisplay: '$285.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AW: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AX: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AZ: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RO: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BA: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BB: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RS: {
        currency: 'EUR',
        price: 271,
        display: '271,00 EUR',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 EUR',
            unitPrice: 235,
            unitPriceDisplay: '235,00 EUR',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BD: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BE: {
        currency: 'EUR',
        price: 271,
        display: '271,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 235,
            unitPriceDisplay: '235,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RU: {
        currency: 'USD',
        price: 302.4,
        display: '302,40 $',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 $',
            unitPrice: 266.4,
            unitPriceDisplay: '266,40 $',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BF: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BG: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RW: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BH: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BI: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BJ: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BL: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BM: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BN: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BO: {
        currency: 'USD',
        price: 252,
        display: 'USD252,00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'USD30,00',
            unitPrice: 222,
            unitPriceDisplay: 'USD222,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SA: {
        currency: 'USD',
        price: 289.8,
        display: '$289.80',
        quantityDiscount: {
          '1': {
            discountValue: 34.5,
            discountValueDisplay: '$34.50',
            unitPrice: 255.3,
            unitPriceDisplay: '$255.30',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BQ: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SB: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BR: {
        currency: 'USD',
        price: 252,
        display: 'US$ 252,00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$ 30,00',
            unitPrice: 222,
            unitPriceDisplay: 'US$ 222,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SC: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BS: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SD: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BT: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SE: {
        currency: 'EUR',
        price: 271,
        display: '271,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 235,
            unitPriceDisplay: '235,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BV: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SG: {
        currency: 'USD',
        price: 274.68,
        display: 'US$274.68',
        quantityDiscount: {
          '1': {
            discountValue: 32.7,
            discountValueDisplay: 'US$32.70',
            unitPrice: 241.98,
            unitPriceDisplay: 'US$241.98',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BW: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SH: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SI: {
        currency: 'EUR',
        price: 271,
        display: '271,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 235,
            unitPriceDisplay: '235,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BY: {
        currency: 'USD',
        price: 302.4,
        display: '$302.40',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 266.4,
            unitPriceDisplay: '$266.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SJ: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BZ: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SK: {
        currency: 'EUR',
        price: 271,
        display: '271,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 235,
            unitPriceDisplay: '235,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SL: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SM: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SN: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SO: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CA: {
        currency: 'CAD',
        price: 321,
        display: '$321.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 285,
            unitPriceDisplay: '$285.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SR: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CC: {
        currency: 'AUD',
        price: 321,
        display: '$321.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 285,
            unitPriceDisplay: '$285.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SS: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CD: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ST: {
        currency: 'USD',
        price: 252,
        display: '252,00 US$',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '30,00 US$',
            unitPrice: 222,
            unitPriceDisplay: '222,00 US$',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CF: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SV: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CG: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CH: {
        currency: 'EUR',
        price: 271,
        display: '€ 271,00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€ 36,00',
            unitPrice: 235,
            unitPriceDisplay: '€ 235,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SX: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CI: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SY: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SZ: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CK: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CL: {
        currency: 'USD',
        price: 299.88,
        display: 'US$299,88',
        quantityDiscount: {
          '1': {
            discountValue: 35.7,
            discountValueDisplay: 'US$35,70',
            unitPrice: 264.18,
            unitPriceDisplay: 'US$264,18',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CM: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CN: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CO: {
        currency: 'USD',
        price: 299.88,
        display: 'US$ 299,88',
        quantityDiscount: {
          '1': {
            discountValue: 35.7,
            discountValueDisplay: 'US$ 35,70',
            unitPrice: 264.18,
            unitPriceDisplay: 'US$ 264,18',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CR: {
        currency: 'USD',
        price: 252,
        display: 'USD252,00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'USD30,00',
            unitPrice: 222,
            unitPriceDisplay: 'USD222,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TC: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TD: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CU: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TF: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CV: {
        currency: 'USD',
        price: 252,
        display: '252,00 US$',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '30,00 US$',
            unitPrice: 222,
            unitPriceDisplay: '222,00 US$',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TG: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CW: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TH: {
        currency: 'USD',
        price: 269.64,
        display: '$269.64',
        quantityDiscount: {
          '1': {
            discountValue: 32.1,
            discountValueDisplay: '$32.10',
            unitPrice: 237.54,
            unitPriceDisplay: '$237.54',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CX: {
        currency: 'AUD',
        price: 321,
        display: '$321.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 285,
            unitPriceDisplay: '$285.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CY: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TJ: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CZ: {
        currency: 'EUR',
        price: 271,
        display: '271,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 235,
            unitPriceDisplay: '235,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TK: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TL: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TM: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TN: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TO: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TR: {
        currency: 'USD',
        price: 302.4,
        display: '$302,40',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36,00',
            unitPrice: 266.4,
            unitPriceDisplay: '$266,40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TT: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DE: {
        currency: 'EUR',
        price: 271,
        display: '271,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 235,
            unitPriceDisplay: '235,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TV: {
        currency: 'AUD',
        price: 321,
        display: '$321.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 285,
            unitPriceDisplay: '$285.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TW: {
        currency: 'USD',
        price: 264.6,
        display: 'US$264.60',
        quantityDiscount: {
          '1': {
            discountValue: 31.5,
            discountValueDisplay: 'US$31.50',
            unitPrice: 233.1,
            unitPriceDisplay: 'US$233.10',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DJ: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TZ: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DK: {
        currency: 'EUR',
        price: 271,
        display: '271,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 235,
            unitPriceDisplay: '235,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DM: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DO: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UA: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UG: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DZ: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UM: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EC: {
        currency: 'USD',
        price: 252,
        display: '$252,00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30,00',
            unitPrice: 222,
            unitPriceDisplay: '$222,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      US: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EE: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EG: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EH: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UY: {
        currency: 'USD',
        price: 252,
        display: 'US$ 252,00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$ 30,00',
            unitPrice: 222,
            unitPriceDisplay: 'US$ 222,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UZ: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VA: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ER: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VC: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ES: {
        currency: 'EUR',
        price: 271,
        display: '271,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 235,
            unitPriceDisplay: '235,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ET: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VE: {
        currency: 'USD',
        price: 252,
        display: 'USD252,00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'USD30,00',
            unitPrice: 222,
            unitPriceDisplay: 'USD222,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VG: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VI: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VN: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VU: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FI: {
        currency: 'EUR',
        price: 271,
        display: '271,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 235,
            unitPriceDisplay: '235,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FJ: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FK: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FM: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FO: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FR: {
        currency: 'EUR',
        price: 271,
        display: '271,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 235,
            unitPriceDisplay: '235,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WF: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GA: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GB: {
        currency: 'GBP',
        price: 249,
        display: '£249.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '£30.00',
            unitPrice: 219,
            unitPriceDisplay: '£219.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WS: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GD: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GE: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GF: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GG: {
        currency: 'GBP',
        price: 249,
        display: '£249.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '£30.00',
            unitPrice: 219,
            unitPriceDisplay: '£219.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GH: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GI: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GL: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GM: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GN: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GP: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GQ: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GR: {
        currency: 'EUR',
        price: 271,
        display: '271,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 235,
            unitPriceDisplay: '235,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GS: {
        currency: 'GBP',
        price: 249,
        display: '£249.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '£30.00',
            unitPrice: 219,
            unitPriceDisplay: '£219.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GT: {
        currency: 'USD',
        price: 252,
        display: 'USD252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'USD30.00',
            unitPrice: 222,
            unitPriceDisplay: 'USD222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GU: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GW: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GY: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HK: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HM: {
        currency: 'AUD',
        price: 321,
        display: 'A$321.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'A$36.00',
            unitPrice: 285,
            unitPriceDisplay: 'A$285.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HN: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HR: {
        currency: 'EUR',
        price: 271,
        display: '271,00 EUR',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 EUR',
            unitPrice: 235,
            unitPriceDisplay: '235,00 EUR',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HT: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YE: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HU: {
        currency: 'EUR',
        price: 271,
        display: '271,00 EUR',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 EUR',
            unitPrice: 235,
            unitPriceDisplay: '235,00 EUR',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IC: {
        currency: 'EUR',
        price: 271,
        display: '271,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 235,
            unitPriceDisplay: '235,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ID: {
        currency: 'USD',
        price: 279.72,
        display: '$279.72',
        quantityDiscount: {
          '1': {
            discountValue: 33.3,
            discountValueDisplay: '$33.30',
            unitPrice: 246.42,
            unitPriceDisplay: '$246.42',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YT: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IE: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IL: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IM: {
        currency: 'GBP',
        price: 249,
        display: '£249.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '£30.00',
            unitPrice: 219,
            unitPriceDisplay: '£219.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IN: {
        currency: 'USD',
        price: 297.36,
        display: 'US$ 297.36',
        quantityDiscount: {
          '1': {
            discountValue: 35.4,
            discountValueDisplay: 'US$ 35.40',
            unitPrice: 261.96,
            unitPriceDisplay: 'US$ 261.96',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IO: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZA: {
        currency: 'USD',
        price: 289.8,
        display: 'US$289,80',
        quantityDiscount: {
          '1': {
            discountValue: 34.5,
            discountValueDisplay: 'US$34,50',
            unitPrice: 255.3,
            unitPriceDisplay: 'US$255,30',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IQ: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IR: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IS: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IT: {
        currency: 'EUR',
        price: 271,
        display: '271,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 235,
            unitPriceDisplay: '235,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZM: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JE: {
        currency: 'GBP',
        price: 249,
        display: '£249.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '£30.00',
            unitPrice: 219,
            unitPriceDisplay: '£219.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZW: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JM: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JO: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JP: {
        currency: 'USD',
        price: 277.2,
        display: '$277.20',
        quantityDiscount: {
          '1': {
            discountValue: 33,
            discountValueDisplay: '$33.00',
            unitPrice: 244.2,
            unitPriceDisplay: '$244.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KE: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KG: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KH: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KI: {
        currency: 'AUD',
        price: 321,
        display: '$321.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 285,
            unitPriceDisplay: '$285.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KM: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KN: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KP: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KR: {
        currency: 'USD',
        price: 277.2,
        display: 'US$277.20',
        quantityDiscount: {
          '1': {
            discountValue: 33,
            discountValueDisplay: 'US$33.00',
            unitPrice: 244.2,
            unitPriceDisplay: 'US$244.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KW: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KY: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KZ: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LA: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LB: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LC: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LI: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LK: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LR: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LS: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LT: {
        currency: 'EUR',
        price: 271,
        display: '271,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 235,
            unitPriceDisplay: '235,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LU: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LV: {
        currency: 'EUR',
        price: 271,
        display: '271,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 235,
            unitPriceDisplay: '235,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LY: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MA: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MC: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MD: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ME: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MF: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MG: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MH: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MK: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ML: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MM: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MN: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MO: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MP: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MQ: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MR: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MS: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MT: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MU: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MV: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MW: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MX: {
        currency: 'USD',
        price: 252,
        display: 'USD252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'USD30.00',
            unitPrice: 222,
            unitPriceDisplay: 'USD222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MY: {
        currency: 'USD',
        price: 272.16,
        display: 'US$272.16',
        quantityDiscount: {
          '1': {
            discountValue: 32.4,
            discountValueDisplay: 'US$32.40',
            unitPrice: 239.76,
            unitPriceDisplay: 'US$239.76',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MZ: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NA: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NC: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NE: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NF: {
        currency: 'AUD',
        price: 321,
        display: '$321.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 285,
            unitPriceDisplay: '$285.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NG: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NI: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NL: {
        currency: 'EUR',
        price: 271,
        display: '€ 271,00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€ 36,00',
            unitPrice: 235,
            unitPriceDisplay: '€ 235,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NO: {
        currency: 'EUR',
        price: 271,
        display: '€ 271,00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€ 36,00',
            unitPrice: 235,
            unitPriceDisplay: '€ 235,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NP: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NR: {
        currency: 'AUD',
        price: 321,
        display: '$321.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 285,
            unitPriceDisplay: '$285.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NU: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NZ: {
        currency: 'USD',
        price: 289.8,
        display: 'US$289.80',
        quantityDiscount: {
          '1': {
            discountValue: 34.5,
            discountValueDisplay: 'US$34.50',
            unitPrice: 255.3,
            unitPriceDisplay: 'US$255.30',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      OM: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PA: {
        currency: 'USD',
        price: 252,
        display: 'USD252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'USD30.00',
            unitPrice: 222,
            unitPriceDisplay: 'USD222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PE: {
        currency: 'USD',
        price: 252,
        display: 'USD252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'USD30.00',
            unitPrice: 222,
            unitPriceDisplay: 'USD222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PF: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PG: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PH: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PK: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PL: {
        currency: 'EUR',
        price: 271,
        display: '271,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 235,
            unitPriceDisplay: '235,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PM: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PN: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
    },
  },
  'perpetual-pdf': {
    action: 'product.price.getall',
    result: 'success',
    product: 'perpetual-pdf',
    pricing: {
      PR: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PS: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PT: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PY: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      QA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AD: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AE: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AF: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AI: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AL: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AO: {
        currency: 'USD',
        price: 0,
        display: '0,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AQ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AR: {
        currency: 'USD',
        price: 0,
        display: 'US$ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AS: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AT: {
        currency: 'EUR',
        price: 0,
        display: '€ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RE: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AU: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AW: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AX: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AZ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RO: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BA: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BB: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RS: {
        currency: 'EUR',
        price: 0,
        display: '0,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BD: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BE: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RU: {
        currency: 'USD',
        price: 0,
        display: '0,00 $',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BF: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BG: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BH: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BI: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BJ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BL: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BO: {
        currency: 'USD',
        price: 0,
        display: 'USD0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BQ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SB: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BR: {
        currency: 'USD',
        price: 0,
        display: 'US$ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SC: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BS: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SD: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BT: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SE: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BV: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SH: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SI: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BY: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SJ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BZ: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SK: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SL: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SM: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SO: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CA: {
        currency: 'CAD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SR: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CC: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SS: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CD: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ST: {
        currency: 'USD',
        price: 0,
        display: '0,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CF: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SV: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CG: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CH: {
        currency: 'EUR',
        price: 0,
        display: '€ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SX: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CI: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SY: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SZ: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CK: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CL: {
        currency: 'USD',
        price: 0,
        display: 'US$0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CN: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CO: {
        currency: 'USD',
        price: 0,
        display: 'US$ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CR: {
        currency: 'USD',
        price: 0,
        display: 'USD0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TC: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TD: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CU: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TF: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CV: {
        currency: 'USD',
        price: 0,
        display: '0,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TG: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CW: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TH: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CX: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CY: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TJ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CZ: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TK: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TL: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TO: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TR: {
        currency: 'USD',
        price: 0,
        display: '$0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TT: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DE: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TV: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DJ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TZ: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DK: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DO: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DZ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EC: {
        currency: 'USD',
        price: 0,
        display: '$0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      US: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EE: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EG: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EH: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UY: {
        currency: 'USD',
        price: 0,
        display: 'US$ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UZ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VA: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ER: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VC: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ES: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ET: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VE: {
        currency: 'USD',
        price: 0,
        display: 'USD0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VI: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VU: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FI: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FJ: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FK: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FO: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FR: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WF: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GB: {
        currency: 'GBP',
        price: 0,
        display: '£0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WS: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GD: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GE: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GF: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GG: {
        currency: 'GBP',
        price: 0,
        display: '£0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GH: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GI: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GL: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GP: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GQ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GR: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GS: {
        currency: 'GBP',
        price: 0,
        display: '£0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GT: {
        currency: 'USD',
        price: 0,
        display: 'USD0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GU: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GW: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GY: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HK: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HM: {
        currency: 'AUD',
        price: 0,
        display: 'A$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HR: {
        currency: 'EUR',
        price: 0,
        display: '0,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HT: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YE: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HU: {
        currency: 'EUR',
        price: 0,
        display: '0,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IC: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ID: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YT: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IE: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IL: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IM: {
        currency: 'GBP',
        price: 0,
        display: '£0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IN: {
        currency: 'USD',
        price: 0,
        display: 'US$ 0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IO: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZA: {
        currency: 'USD',
        price: 0,
        display: 'US$0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IQ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IR: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IS: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IT: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JE: {
        currency: 'GBP',
        price: 0,
        display: '£0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JO: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JP: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KE: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KG: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KH: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KI: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KN: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KP: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KR: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KW: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KY: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KZ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LB: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LC: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LI: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LK: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LR: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LS: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LT: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LU: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LV: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LY: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MC: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MD: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ME: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MF: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MH: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MK: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ML: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MO: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MP: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MQ: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MR: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MS: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MT: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MU: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MV: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MX: {
        currency: 'USD',
        price: 0,
        display: 'USD0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MY: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MZ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NA: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NC: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NE: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NF: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NI: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NL: {
        currency: 'EUR',
        price: 0,
        display: '€ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NO: {
        currency: 'EUR',
        price: 0,
        display: '€ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NP: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NR: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NU: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NZ: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      OM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PA: {
        currency: 'USD',
        price: 0,
        display: 'USD0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PE: {
        currency: 'USD',
        price: 0,
        display: 'USD0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PF: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PH: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PK: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PL: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PM: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PN: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
    },
  },
  'pdf-two-year-multi-ocr': {
    action: 'product.price.getall',
    result: 'success',
    product: 'pdf-two-year-multi-ocr',
    pricing: {
      PR: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      PS: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      PT: {
        currency: 'EUR',
        price: 310,
        display: '310,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 154.88,
            discountValueDisplay: '154,88 €',
            unitPrice: 155.12,
            unitPriceDisplay: '155,12 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      PW: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      PY: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      QA: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AD: {
        currency: 'EUR',
        price: 252,
        display: '€252.00',
        quantityDiscount: {
          '1': {
            discountValue: 125.92,
            discountValueDisplay: '€125.92',
            unitPrice: 126.08,
            unitPriceDisplay: '€126.08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AE: {
        currency: 'USD',
        price: 277.2,
        display: '$277.20',
        quantityDiscount: {
          '1': {
            discountValue: 138.6,
            discountValueDisplay: '$138.60',
            unitPrice: 138.6,
            unitPriceDisplay: '$138.60',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AF: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AG: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AI: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AL: {
        currency: 'EUR',
        price: 252,
        display: '€252.00',
        quantityDiscount: {
          '1': {
            discountValue: 125.92,
            discountValueDisplay: '€125.92',
            unitPrice: 126.08,
            unitPriceDisplay: '€126.08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AM: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AN: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AO: {
        currency: 'USD',
        price: 264,
        display: '264,00 US$',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '132,00 US$',
            unitPrice: 132,
            unitPriceDisplay: '132,00 US$',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AQ: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AR: {
        currency: 'USD',
        price: 264,
        display: 'US$ 264,00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$ 132,00',
            unitPrice: 132,
            unitPriceDisplay: 'US$ 132,00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AS: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AT: {
        currency: 'EUR',
        price: 303,
        display: '€ 303,00',
        quantityDiscount: {
          '1': {
            discountValue: 151.1,
            discountValueDisplay: '€ 151,10',
            unitPrice: 151.9,
            unitPriceDisplay: '€ 151,90',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      RE: {
        currency: 'EUR',
        price: 252,
        display: '€252.00',
        quantityDiscount: {
          '1': {
            discountValue: 125.92,
            discountValueDisplay: '€125.92',
            unitPrice: 126.08,
            unitPriceDisplay: '€126.08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AU: {
        currency: 'AUD',
        price: 456,
        display: '$456.00',
        quantityDiscount: {
          '1': {
            discountValue: 227.89,
            discountValueDisplay: '$227.89',
            unitPrice: 228.11,
            unitPriceDisplay: '$228.11',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AW: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AX: {
        currency: 'EUR',
        price: 252,
        display: '€252.00',
        quantityDiscount: {
          '1': {
            discountValue: 125.92,
            discountValueDisplay: '€125.92',
            unitPrice: 126.08,
            unitPriceDisplay: '€126.08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      AZ: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      RO: {
        currency: 'EUR',
        price: 300,
        display: '€300.00',
        quantityDiscount: {
          '1': {
            discountValue: 149.84,
            discountValueDisplay: '€149.84',
            unitPrice: 150.16,
            unitPriceDisplay: '€150.16',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BA: {
        currency: 'EUR',
        price: 252,
        display: '€252.00',
        quantityDiscount: {
          '1': {
            discountValue: 125.92,
            discountValueDisplay: '€125.92',
            unitPrice: 126.08,
            unitPriceDisplay: '€126.08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BB: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      RS: {
        currency: 'EUR',
        price: 303,
        display: '303,00 EUR',
        quantityDiscount: {
          '1': {
            discountValue: 151.1,
            discountValueDisplay: '151,10 EUR',
            unitPrice: 151.9,
            unitPriceDisplay: '151,90 EUR',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BD: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BE: {
        currency: 'EUR',
        price: 305,
        display: '305,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 152.36,
            discountValueDisplay: '152,36 €',
            unitPrice: 152.64,
            unitPriceDisplay: '152,64 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      RU: {
        currency: 'USD',
        price: 316.8,
        display: '316,80 $',
        quantityDiscount: {
          '1': {
            discountValue: 158.4,
            discountValueDisplay: '158,40 $',
            unitPrice: 158.4,
            unitPriceDisplay: '158,40 $',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BF: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BG: {
        currency: 'EUR',
        price: 303,
        display: '€303.00',
        quantityDiscount: {
          '1': {
            discountValue: 151.1,
            discountValueDisplay: '€151.10',
            unitPrice: 151.9,
            unitPriceDisplay: '€151.90',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      RW: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BH: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BI: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BJ: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BL: {
        currency: 'EUR',
        price: 252,
        display: '€252.00',
        quantityDiscount: {
          '1': {
            discountValue: 125.92,
            discountValueDisplay: '€125.92',
            unitPrice: 126.08,
            unitPriceDisplay: '€126.08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BM: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BN: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BO: {
        currency: 'USD',
        price: 264,
        display: 'USD264,00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'USD132,00',
            unitPrice: 132,
            unitPriceDisplay: 'USD132,00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SA: {
        currency: 'USD',
        price: 303.6,
        display: '$303.60',
        quantityDiscount: {
          '1': {
            discountValue: 151.8,
            discountValueDisplay: '$151.80',
            unitPrice: 151.8,
            unitPriceDisplay: '$151.80',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BQ: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SB: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BR: {
        currency: 'USD',
        price: 264,
        display: 'US$ 264,00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$ 132,00',
            unitPrice: 132,
            unitPriceDisplay: 'US$ 132,00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SC: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BS: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SD: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BT: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SE: {
        currency: 'EUR',
        price: 315,
        display: '315,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 157.4,
            discountValueDisplay: '157,40 €',
            unitPrice: 157.6,
            unitPriceDisplay: '157,60 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BV: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SG: {
        currency: 'USD',
        price: 287.76,
        display: 'US$287.76',
        quantityDiscount: {
          '1': {
            discountValue: 143.88,
            discountValueDisplay: 'US$143.88',
            unitPrice: 143.88,
            unitPriceDisplay: 'US$143.88',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BW: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SH: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SI: {
        currency: 'EUR',
        price: 308,
        display: '308,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 153.62,
            discountValueDisplay: '153,62 €',
            unitPrice: 154.38,
            unitPriceDisplay: '154,38 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BY: {
        currency: 'USD',
        price: 316.8,
        display: '$316.80',
        quantityDiscount: {
          '1': {
            discountValue: 158.4,
            discountValueDisplay: '$158.40',
            unitPrice: 158.4,
            unitPriceDisplay: '$158.40',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SJ: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      BZ: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SK: {
        currency: 'EUR',
        price: 303,
        display: '303,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 151.1,
            discountValueDisplay: '151,10 €',
            unitPrice: 151.9,
            unitPriceDisplay: '151,90 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SL: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SM: {
        currency: 'EUR',
        price: 252,
        display: '€252.00',
        quantityDiscount: {
          '1': {
            discountValue: 125.92,
            discountValueDisplay: '€125.92',
            unitPrice: 126.08,
            unitPriceDisplay: '€126.08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SN: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SO: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CA: {
        currency: 'CAD',
        price: 370,
        display: '$370.00',
        quantityDiscount: {
          '1': {
            discountValue: 184.65,
            discountValueDisplay: '$184.65',
            unitPrice: 185.35,
            unitPriceDisplay: '$185.35',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SR: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CC: {
        currency: 'AUD',
        price: 415,
        display: '$415.00',
        quantityDiscount: {
          '1': {
            discountValue: 207.17,
            discountValueDisplay: '$207.17',
            unitPrice: 207.83,
            unitPriceDisplay: '$207.83',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SS: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CD: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      ST: {
        currency: 'USD',
        price: 264,
        display: '264,00 US$',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '132,00 US$',
            unitPrice: 132,
            unitPriceDisplay: '132,00 US$',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CF: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SV: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CG: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CH: {
        currency: 'EUR',
        price: 273,
        display: '€ 273,00',
        quantityDiscount: {
          '1': {
            discountValue: 136.12,
            discountValueDisplay: '€ 136,12',
            unitPrice: 136.88,
            unitPriceDisplay: '€ 136,88',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SX: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CI: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SY: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      SZ: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CK: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CL: {
        currency: 'USD',
        price: 314.16,
        display: 'US$314,16',
        quantityDiscount: {
          '1': {
            discountValue: 157.08,
            discountValueDisplay: 'US$157,08',
            unitPrice: 157.08,
            unitPriceDisplay: 'US$157,08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CM: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CN: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CO: {
        currency: 'USD',
        price: 314.16,
        display: 'US$ 314,16',
        quantityDiscount: {
          '1': {
            discountValue: 157.08,
            discountValueDisplay: 'US$ 157,08',
            unitPrice: 157.08,
            unitPriceDisplay: 'US$ 157,08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CR: {
        currency: 'USD',
        price: 264,
        display: 'USD264,00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'USD132,00',
            unitPrice: 132,
            unitPriceDisplay: 'USD132,00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TC: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TD: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CU: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TF: {
        currency: 'EUR',
        price: 252,
        display: '€252.00',
        quantityDiscount: {
          '1': {
            discountValue: 125.92,
            discountValueDisplay: '€125.92',
            unitPrice: 126.08,
            unitPriceDisplay: '€126.08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CV: {
        currency: 'USD',
        price: 264,
        display: '264,00 US$',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '132,00 US$',
            unitPrice: 132,
            unitPriceDisplay: '132,00 US$',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TG: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CW: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TH: {
        currency: 'USD',
        price: 282.48,
        display: '$282.48',
        quantityDiscount: {
          '1': {
            discountValue: 141.24,
            discountValueDisplay: '$141.24',
            unitPrice: 141.24,
            unitPriceDisplay: '$141.24',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CX: {
        currency: 'AUD',
        price: 415,
        display: '$415.00',
        quantityDiscount: {
          '1': {
            discountValue: 207.17,
            discountValueDisplay: '$207.17',
            unitPrice: 207.83,
            unitPriceDisplay: '$207.83',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CY: {
        currency: 'EUR',
        price: 300,
        display: '€300.00',
        quantityDiscount: {
          '1': {
            discountValue: 149.84,
            discountValueDisplay: '€149.84',
            unitPrice: 150.16,
            unitPriceDisplay: '€150.16',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TJ: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      CZ: {
        currency: 'EUR',
        price: 305,
        display: '305,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 152.36,
            discountValueDisplay: '152,36 €',
            unitPrice: 152.64,
            unitPriceDisplay: '152,64 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TK: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TL: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TM: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TN: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TO: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TR: {
        currency: 'USD',
        price: 316.8,
        display: '$316,80',
        quantityDiscount: {
          '1': {
            discountValue: 158.4,
            discountValueDisplay: '$158,40',
            unitPrice: 158.4,
            unitPriceDisplay: '$158,40',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TT: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      DE: {
        currency: 'EUR',
        price: 300,
        display: '300,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 149.84,
            discountValueDisplay: '149,84 €',
            unitPrice: 150.16,
            unitPriceDisplay: '150,16 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TV: {
        currency: 'AUD',
        price: 415,
        display: '$415.00',
        quantityDiscount: {
          '1': {
            discountValue: 207.17,
            discountValueDisplay: '$207.17',
            unitPrice: 207.83,
            unitPriceDisplay: '$207.83',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TW: {
        currency: 'USD',
        price: 277.2,
        display: 'US$277.20',
        quantityDiscount: {
          '1': {
            discountValue: 138.6,
            discountValueDisplay: 'US$138.60',
            unitPrice: 138.6,
            unitPriceDisplay: 'US$138.60',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      DJ: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      TZ: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      DK: {
        currency: 'EUR',
        price: 315,
        display: '315,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 157.4,
            discountValueDisplay: '157,40 €',
            unitPrice: 157.6,
            unitPriceDisplay: '157,60 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      DM: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      DO: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      UA: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      UG: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      DZ: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      UM: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      EC: {
        currency: 'USD',
        price: 264,
        display: '$264,00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132,00',
            unitPrice: 132,
            unitPriceDisplay: '$132,00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      US: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      EE: {
        currency: 'EUR',
        price: 308,
        display: '€308.00',
        quantityDiscount: {
          '1': {
            discountValue: 153.62,
            discountValueDisplay: '€153.62',
            unitPrice: 154.38,
            unitPriceDisplay: '€154.38',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      EG: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      EH: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      UY: {
        currency: 'USD',
        price: 264,
        display: 'US$ 264,00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$ 132,00',
            unitPrice: 132,
            unitPriceDisplay: 'US$ 132,00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      UZ: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      VA: {
        currency: 'EUR',
        price: 252,
        display: '€252.00',
        quantityDiscount: {
          '1': {
            discountValue: 125.92,
            discountValueDisplay: '€125.92',
            unitPrice: 126.08,
            unitPriceDisplay: '€126.08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      ER: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      VC: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      ES: {
        currency: 'EUR',
        price: 305,
        display: '305,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 152.36,
            discountValueDisplay: '152,36 €',
            unitPrice: 152.64,
            unitPriceDisplay: '152,64 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      ET: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      VE: {
        currency: 'USD',
        price: 264,
        display: 'USD264,00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'USD132,00',
            unitPrice: 132,
            unitPriceDisplay: 'USD132,00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      VG: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      VI: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      VN: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      VU: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      FI: {
        currency: 'EUR',
        price: 313,
        display: '313,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 156.14,
            discountValueDisplay: '156,14 €',
            unitPrice: 156.86,
            unitPriceDisplay: '156,86 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      FJ: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      FK: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      FM: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      FO: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      FR: {
        currency: 'EUR',
        price: 303,
        display: '303,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 151.1,
            discountValueDisplay: '151,10 €',
            unitPrice: 151.9,
            unitPriceDisplay: '151,90 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      WF: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GA: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GB: {
        currency: 'GBP',
        price: 260,
        display: '£260.00',
        quantityDiscount: {
          '1': {
            discountValue: 129.55,
            discountValueDisplay: '£129.55',
            unitPrice: 130.45,
            unitPriceDisplay: '£130.45',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      WS: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GD: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GE: {
        currency: 'EUR',
        price: 252,
        display: '€252.00',
        quantityDiscount: {
          '1': {
            discountValue: 125.92,
            discountValueDisplay: '€125.92',
            unitPrice: 126.08,
            unitPriceDisplay: '€126.08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GF: {
        currency: 'EUR',
        price: 252,
        display: '€252.00',
        quantityDiscount: {
          '1': {
            discountValue: 125.92,
            discountValueDisplay: '€125.92',
            unitPrice: 126.08,
            unitPriceDisplay: '€126.08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GG: {
        currency: 'GBP',
        price: 216,
        display: '£216.00',
        quantityDiscount: {
          '1': {
            discountValue: 107.96,
            discountValueDisplay: '£107.96',
            unitPrice: 108.04,
            unitPriceDisplay: '£108.04',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GH: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GI: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GL: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GM: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GN: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GP: {
        currency: 'EUR',
        price: 252,
        display: '€252.00',
        quantityDiscount: {
          '1': {
            discountValue: 125.92,
            discountValueDisplay: '€125.92',
            unitPrice: 126.08,
            unitPriceDisplay: '€126.08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GQ: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GR: {
        currency: 'EUR',
        price: 313,
        display: '313,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 156.14,
            discountValueDisplay: '156,14 €',
            unitPrice: 156.86,
            unitPriceDisplay: '156,86 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GS: {
        currency: 'GBP',
        price: 216,
        display: '£216.00',
        quantityDiscount: {
          '1': {
            discountValue: 107.96,
            discountValueDisplay: '£107.96',
            unitPrice: 108.04,
            unitPriceDisplay: '£108.04',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GT: {
        currency: 'USD',
        price: 264,
        display: 'USD264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'USD132.00',
            unitPrice: 132,
            unitPriceDisplay: 'USD132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GU: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GW: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      GY: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      HK: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      HM: {
        currency: 'AUD',
        price: 415,
        display: 'A$415.00',
        quantityDiscount: {
          '1': {
            discountValue: 207.17,
            discountValueDisplay: 'A$207.17',
            unitPrice: 207.83,
            unitPriceDisplay: 'A$207.83',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      HN: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      HR: {
        currency: 'EUR',
        price: 315,
        display: '315,00 EUR',
        quantityDiscount: {
          '1': {
            discountValue: 157.4,
            discountValueDisplay: '157,40 EUR',
            unitPrice: 157.6,
            unitPriceDisplay: '157,60 EUR',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      HT: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      YE: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      HU: {
        currency: 'EUR',
        price: 320,
        display: '320,00 EUR',
        quantityDiscount: {
          '1': {
            discountValue: 159.92,
            discountValueDisplay: '159,92 EUR',
            unitPrice: 160.08,
            unitPriceDisplay: '160,08 EUR',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      IC: {
        currency: 'EUR',
        price: 252,
        display: '252,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 125.92,
            discountValueDisplay: '125,92 €',
            unitPrice: 126.08,
            unitPriceDisplay: '126,08 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      ID: {
        currency: 'USD',
        price: 293.04,
        display: '$293.04',
        quantityDiscount: {
          '1': {
            discountValue: 146.52,
            discountValueDisplay: '$146.52',
            unitPrice: 146.52,
            unitPriceDisplay: '$146.52',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      YT: {
        currency: 'EUR',
        price: 252,
        display: '€252.00',
        quantityDiscount: {
          '1': {
            discountValue: 125.92,
            discountValueDisplay: '€125.92',
            unitPrice: 126.08,
            unitPriceDisplay: '€126.08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      IE: {
        currency: 'EUR',
        price: 310,
        display: '€310.00',
        quantityDiscount: {
          '1': {
            discountValue: 154.88,
            discountValueDisplay: '€154.88',
            unitPrice: 155.12,
            unitPriceDisplay: '€155.12',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      IL: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      IM: {
        currency: 'GBP',
        price: 216,
        display: '£216.00',
        quantityDiscount: {
          '1': {
            discountValue: 107.96,
            discountValueDisplay: '£107.96',
            unitPrice: 108.04,
            unitPriceDisplay: '£108.04',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      IN: {
        currency: 'USD',
        price: 311.52,
        display: 'US$ 311.52',
        quantityDiscount: {
          '1': {
            discountValue: 155.76,
            discountValueDisplay: 'US$ 155.76',
            unitPrice: 155.76,
            unitPriceDisplay: 'US$ 155.76',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      IO: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      ZA: {
        currency: 'USD',
        price: 303.6,
        display: 'US$303,60',
        quantityDiscount: {
          '1': {
            discountValue: 151.8,
            discountValueDisplay: 'US$151,80',
            unitPrice: 151.8,
            unitPriceDisplay: 'US$151,80',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      IQ: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      IR: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      IS: {
        currency: 'EUR',
        price: 313,
        display: '€313.00',
        quantityDiscount: {
          '1': {
            discountValue: 156.14,
            discountValueDisplay: '€156.14',
            unitPrice: 156.86,
            unitPriceDisplay: '€156.86',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      IT: {
        currency: 'EUR',
        price: 308,
        display: '308,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 153.62,
            discountValueDisplay: '153,62 €',
            unitPrice: 154.38,
            unitPriceDisplay: '154,38 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      ZM: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      JE: {
        currency: 'GBP',
        price: 216,
        display: '£216.00',
        quantityDiscount: {
          '1': {
            discountValue: 107.96,
            discountValueDisplay: '£107.96',
            unitPrice: 108.04,
            unitPriceDisplay: '£108.04',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      ZW: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      JM: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      JO: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      JP: {
        currency: 'USD',
        price: 290.4,
        display: '$290.40',
        quantityDiscount: {
          '1': {
            discountValue: 145.2,
            discountValueDisplay: '$145.20',
            unitPrice: 145.2,
            unitPriceDisplay: '$145.20',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      KE: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      KG: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      KH: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      KI: {
        currency: 'AUD',
        price: 415,
        display: '$415.00',
        quantityDiscount: {
          '1': {
            discountValue: 207.17,
            discountValueDisplay: '$207.17',
            unitPrice: 207.83,
            unitPriceDisplay: '$207.83',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      KM: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      KN: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      KP: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      KR: {
        currency: 'USD',
        price: 290.4,
        display: 'US$290.40',
        quantityDiscount: {
          '1': {
            discountValue: 145.2,
            discountValueDisplay: 'US$145.20',
            unitPrice: 145.2,
            unitPriceDisplay: 'US$145.20',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      KW: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      KY: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      KZ: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      LA: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      LB: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      LC: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      LI: {
        currency: 'EUR',
        price: 252,
        display: '€252.00',
        quantityDiscount: {
          '1': {
            discountValue: 125.92,
            discountValueDisplay: '€125.92',
            unitPrice: 126.08,
            unitPriceDisplay: '€126.08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      LK: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      LR: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      LS: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      LT: {
        currency: 'EUR',
        price: 305,
        display: '305,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 152.36,
            discountValueDisplay: '152,36 €',
            unitPrice: 152.64,
            unitPriceDisplay: '152,64 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      LU: {
        currency: 'EUR',
        price: 295,
        display: '€295.00',
        quantityDiscount: {
          '1': {
            discountValue: 147.33,
            discountValueDisplay: '€147.33',
            unitPrice: 147.67,
            unitPriceDisplay: '€147.67',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      LV: {
        currency: 'EUR',
        price: 305,
        display: '305,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 152.36,
            discountValueDisplay: '152,36 €',
            unitPrice: 152.64,
            unitPriceDisplay: '152,64 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      LY: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MA: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MC: {
        currency: 'EUR',
        price: 252,
        display: '€252.00',
        quantityDiscount: {
          '1': {
            discountValue: 125.92,
            discountValueDisplay: '€125.92',
            unitPrice: 126.08,
            unitPriceDisplay: '€126.08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MD: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      ME: {
        currency: 'EUR',
        price: 252,
        display: '€252.00',
        quantityDiscount: {
          '1': {
            discountValue: 125.92,
            discountValueDisplay: '€125.92',
            unitPrice: 126.08,
            unitPriceDisplay: '€126.08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MF: {
        currency: 'EUR',
        price: 252,
        display: '€252.00',
        quantityDiscount: {
          '1': {
            discountValue: 125.92,
            discountValueDisplay: '€125.92',
            unitPrice: 126.08,
            unitPriceDisplay: '€126.08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MG: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MH: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MK: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      ML: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MM: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MN: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MO: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MP: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MQ: {
        currency: 'EUR',
        price: 252,
        display: '€252.00',
        quantityDiscount: {
          '1': {
            discountValue: 125.92,
            discountValueDisplay: '€125.92',
            unitPrice: 126.08,
            unitPriceDisplay: '€126.08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MR: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MS: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MT: {
        currency: 'EUR',
        price: 298,
        display: '€298.00',
        quantityDiscount: {
          '1': {
            discountValue: 148.59,
            discountValueDisplay: '€148.59',
            unitPrice: 149.41,
            unitPriceDisplay: '€149.41',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MU: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MV: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MW: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MX: {
        currency: 'USD',
        price: 264,
        display: 'USD264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'USD132.00',
            unitPrice: 132,
            unitPriceDisplay: 'USD132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MY: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      MZ: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      NA: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      NC: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      NE: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      NF: {
        currency: 'AUD',
        price: 415,
        display: '$415.00',
        quantityDiscount: {
          '1': {
            discountValue: 207.17,
            discountValueDisplay: '$207.17',
            unitPrice: 207.83,
            unitPriceDisplay: '$207.83',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      NG: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      NI: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      NL: {
        currency: 'EUR',
        price: 305,
        display: '€ 305,00',
        quantityDiscount: {
          '1': {
            discountValue: 152.36,
            discountValueDisplay: '€ 152,36',
            unitPrice: 152.64,
            unitPriceDisplay: '€ 152,64',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      NO: {
        currency: 'EUR',
        price: 315,
        display: '€ 315,00',
        quantityDiscount: {
          '1': {
            discountValue: 157.4,
            discountValueDisplay: '€ 157,40',
            unitPrice: 157.6,
            unitPriceDisplay: '€ 157,60',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      NP: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      NR: {
        currency: 'AUD',
        price: 415,
        display: '$415.00',
        quantityDiscount: {
          '1': {
            discountValue: 207.17,
            discountValueDisplay: '$207.17',
            unitPrice: 207.83,
            unitPriceDisplay: '$207.83',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      NU: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      NZ: {
        currency: 'USD',
        price: 303.6,
        display: 'US$303.60',
        quantityDiscount: {
          '1': {
            discountValue: 151.8,
            discountValueDisplay: 'US$151.80',
            unitPrice: 151.8,
            unitPriceDisplay: 'US$151.80',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      OM: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      PA: {
        currency: 'USD',
        price: 264,
        display: 'USD264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'USD132.00',
            unitPrice: 132,
            unitPriceDisplay: 'USD132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      PE: {
        currency: 'USD',
        price: 264,
        display: 'USD264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'USD132.00',
            unitPrice: 132,
            unitPriceDisplay: 'USD132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      PF: {
        currency: 'USD',
        price: 264,
        display: '$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: '$132.00',
            unitPrice: 132,
            unitPriceDisplay: '$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      PG: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      PH: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      PK: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      PL: {
        currency: 'EUR',
        price: 310,
        display: '310,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 154.88,
            discountValueDisplay: '154,88 €',
            unitPrice: 155.12,
            unitPriceDisplay: '155,12 €',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      PM: {
        currency: 'EUR',
        price: 252,
        display: '€252.00',
        quantityDiscount: {
          '1': {
            discountValue: 125.92,
            discountValueDisplay: '€125.92',
            unitPrice: 126.08,
            unitPriceDisplay: '€126.08',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
      PN: {
        currency: 'USD',
        price: 264,
        display: 'US$264.00',
        quantityDiscount: {
          '1': {
            discountValue: 132,
            discountValueDisplay: 'US$132.00',
            unitPrice: 132,
            unitPriceDisplay: 'US$132.00',
          },
        },
        discountReason: {},
        discountPeriodCount: 1,
        available: {},
      },
    },
  },
  ocr: {
    action: 'product.price.getall',
    result: 'success',
    product: 'ocr',
    pricing: {
      PR: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PS: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PT: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PY: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      QA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AD: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AE: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AF: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AI: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AL: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AO: {
        currency: 'USD',
        price: 0,
        display: '0,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AQ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AR: {
        currency: 'USD',
        price: 0,
        display: 'US$ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AS: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AT: {
        currency: 'EUR',
        price: 0,
        display: '€ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RE: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AU: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AW: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AX: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AZ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RO: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BA: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BB: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RS: {
        currency: 'EUR',
        price: 0,
        display: '0,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BD: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BE: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RU: {
        currency: 'USD',
        price: 0,
        display: '0,00 $',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BF: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BG: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BH: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BI: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BJ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BL: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BO: {
        currency: 'USD',
        price: 0,
        display: 'USD0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BQ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SB: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BR: {
        currency: 'USD',
        price: 0,
        display: 'US$ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SC: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BS: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SD: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BT: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SE: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BV: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SH: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SI: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BY: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SJ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BZ: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SK: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SL: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SM: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SO: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CA: {
        currency: 'CAD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SR: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CC: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SS: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CD: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ST: {
        currency: 'USD',
        price: 0,
        display: '0,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CF: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SV: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CG: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CH: {
        currency: 'EUR',
        price: 0,
        display: '€ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SX: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CI: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SY: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SZ: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CK: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CL: {
        currency: 'USD',
        price: 0,
        display: 'US$0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CN: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CO: {
        currency: 'USD',
        price: 0,
        display: 'US$ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CR: {
        currency: 'USD',
        price: 0,
        display: 'USD0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TC: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TD: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CU: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TF: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CV: {
        currency: 'USD',
        price: 0,
        display: '0,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TG: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CW: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TH: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CX: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CY: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TJ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CZ: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TK: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TL: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TO: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TR: {
        currency: 'USD',
        price: 0,
        display: '$0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TT: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DE: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TV: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DJ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TZ: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DK: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DO: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DZ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EC: {
        currency: 'USD',
        price: 0,
        display: '$0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      US: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EE: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EG: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EH: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UY: {
        currency: 'USD',
        price: 0,
        display: 'US$ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UZ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VA: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ER: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VC: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ES: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ET: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VE: {
        currency: 'USD',
        price: 0,
        display: 'USD0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VI: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VU: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FI: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FJ: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FK: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FO: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FR: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WF: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GB: {
        currency: 'GBP',
        price: 0,
        display: '£0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WS: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GD: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GE: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GF: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GG: {
        currency: 'GBP',
        price: 0,
        display: '£0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GH: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GI: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GL: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GP: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GQ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GR: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GS: {
        currency: 'GBP',
        price: 0,
        display: '£0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GT: {
        currency: 'USD',
        price: 0,
        display: 'USD0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GU: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GW: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GY: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HK: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HM: {
        currency: 'AUD',
        price: 0,
        display: 'A$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HR: {
        currency: 'EUR',
        price: 0,
        display: '0,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HT: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YE: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HU: {
        currency: 'EUR',
        price: 0,
        display: '0,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IC: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ID: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YT: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IE: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IL: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IM: {
        currency: 'GBP',
        price: 0,
        display: '£0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IN: {
        currency: 'USD',
        price: 0,
        display: 'US$ 0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IO: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZA: {
        currency: 'USD',
        price: 0,
        display: 'US$0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IQ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IR: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IS: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IT: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JE: {
        currency: 'GBP',
        price: 0,
        display: '£0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JO: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JP: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KE: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KG: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KH: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KI: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KN: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KP: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KR: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KW: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KY: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KZ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LB: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LC: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LI: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LK: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LR: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LS: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LT: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LU: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LV: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LY: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MC: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MD: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ME: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MF: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MH: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MK: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ML: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MO: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MP: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MQ: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MR: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MS: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MT: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MU: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MV: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MX: {
        currency: 'USD',
        price: 0,
        display: 'USD0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MY: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MZ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NA: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NC: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NE: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NF: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NI: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NL: {
        currency: 'EUR',
        price: 0,
        display: '€ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NO: {
        currency: 'EUR',
        price: 0,
        display: '€ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NP: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NR: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NU: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NZ: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      OM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PA: {
        currency: 'USD',
        price: 0,
        display: 'USD0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PE: {
        currency: 'USD',
        price: 0,
        display: 'USD0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PF: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PH: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PK: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PL: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PM: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PN: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
    },
  },
  'lite-feature': {
    action: 'product.price.getall',
    result: 'success',
    product: 'lite-feature',
    pricing: {
      PR: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PS: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PT: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PY: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      QA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AD: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AE: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AF: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AI: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AL: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AO: {
        currency: 'USD',
        price: 0,
        display: '0,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AQ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AR: {
        currency: 'USD',
        price: 0,
        display: 'US$ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AS: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AT: {
        currency: 'EUR',
        price: 0,
        display: '€ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RE: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AU: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AW: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AX: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AZ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RO: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BA: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BB: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RS: {
        currency: 'EUR',
        price: 0,
        display: '0,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BD: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BE: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RU: {
        currency: 'USD',
        price: 0,
        display: '0,00 $',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BF: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BG: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BH: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BI: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BJ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BL: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BO: {
        currency: 'USD',
        price: 0,
        display: 'USD0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BQ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SB: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BR: {
        currency: 'USD',
        price: 0,
        display: 'US$ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SC: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BS: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SD: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BT: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SE: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BV: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SH: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SI: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BY: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SJ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BZ: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SK: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SL: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SM: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SO: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CA: {
        currency: 'CAD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SR: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CC: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SS: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CD: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ST: {
        currency: 'USD',
        price: 0,
        display: '0,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CF: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SV: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CG: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CH: {
        currency: 'EUR',
        price: 0,
        display: '€ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SX: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CI: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SY: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SZ: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CK: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CL: {
        currency: 'USD',
        price: 0,
        display: 'US$0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CN: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CO: {
        currency: 'USD',
        price: 0,
        display: 'US$ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CR: {
        currency: 'USD',
        price: 0,
        display: 'USD0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TC: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TD: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CU: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TF: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CV: {
        currency: 'USD',
        price: 0,
        display: '0,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TG: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CW: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TH: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CX: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CY: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TJ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CZ: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TK: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TL: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TO: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TR: {
        currency: 'USD',
        price: 0,
        display: '$0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TT: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DE: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TV: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DJ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TZ: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DK: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DO: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DZ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EC: {
        currency: 'USD',
        price: 0,
        display: '$0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      US: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EE: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EG: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EH: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UY: {
        currency: 'USD',
        price: 0,
        display: 'US$ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UZ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VA: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ER: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VC: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ES: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ET: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VE: {
        currency: 'USD',
        price: 0,
        display: 'USD0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VI: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VU: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FI: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FJ: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FK: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FO: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FR: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WF: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GB: {
        currency: 'GBP',
        price: 0,
        display: '£0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WS: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GD: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GE: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GF: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GG: {
        currency: 'GBP',
        price: 0,
        display: '£0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GH: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GI: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GL: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GP: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GQ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GR: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GS: {
        currency: 'GBP',
        price: 0,
        display: '£0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GT: {
        currency: 'USD',
        price: 0,
        display: 'USD0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GU: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GW: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GY: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HK: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HM: {
        currency: 'AUD',
        price: 0,
        display: 'A$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HR: {
        currency: 'EUR',
        price: 0,
        display: '0,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HT: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YE: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HU: {
        currency: 'EUR',
        price: 0,
        display: '0,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IC: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ID: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YT: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IE: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IL: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IM: {
        currency: 'GBP',
        price: 0,
        display: '£0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IN: {
        currency: 'USD',
        price: 0,
        display: 'US$ 0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IO: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZA: {
        currency: 'USD',
        price: 0,
        display: 'US$0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IQ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IR: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IS: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IT: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JE: {
        currency: 'GBP',
        price: 0,
        display: '£0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JO: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JP: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KE: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KG: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KH: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KI: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KN: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KP: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KR: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KW: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KY: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KZ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LB: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LC: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LI: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LK: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LR: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LS: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LT: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LU: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LV: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LY: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MC: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MD: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ME: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MF: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MH: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MK: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ML: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MO: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MP: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MQ: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MR: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MS: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MT: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MU: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MV: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MX: {
        currency: 'USD',
        price: 0,
        display: 'USD0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MY: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MZ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NA: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NC: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NE: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NF: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NI: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NL: {
        currency: 'EUR',
        price: 0,
        display: '€ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NO: {
        currency: 'EUR',
        price: 0,
        display: '€ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NP: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NR: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NU: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NZ: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      OM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PA: {
        currency: 'USD',
        price: 0,
        display: 'USD0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PE: {
        currency: 'USD',
        price: 0,
        display: 'USD0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PF: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PH: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PK: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PL: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PM: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PN: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
    },
  },
  'pdf-pro-lite-m': {
    action: 'product.price.getall',
    result: 'success',
    product: 'pdf-pro-lite-m',
    pricing: {
      PR: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PS: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PT: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PY: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      QA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AD: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AE: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AF: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AI: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AL: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AO: {
        currency: 'USD',
        price: 0,
        display: '0,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AQ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AR: {
        currency: 'USD',
        price: 0,
        display: 'US$ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AS: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AT: {
        currency: 'EUR',
        price: 0,
        display: '€ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RE: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AU: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AW: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AX: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AZ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RO: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BA: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BB: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RS: {
        currency: 'EUR',
        price: 0,
        display: '0,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BD: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BE: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RU: {
        currency: 'USD',
        price: 0,
        display: '0,00 $',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BF: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BG: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BH: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BI: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BJ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BL: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BO: {
        currency: 'USD',
        price: 0,
        display: 'USD0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BQ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SB: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BR: {
        currency: 'USD',
        price: 0,
        display: 'US$ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SC: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BS: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SD: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BT: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SE: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BV: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SH: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SI: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BY: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SJ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BZ: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SK: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SL: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SM: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SO: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CA: {
        currency: 'CAD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SR: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CC: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SS: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CD: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ST: {
        currency: 'USD',
        price: 0,
        display: '0,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CF: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SV: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CG: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CH: {
        currency: 'EUR',
        price: 0,
        display: '€ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SX: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CI: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SY: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SZ: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CK: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CL: {
        currency: 'USD',
        price: 0,
        display: 'US$0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CN: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CO: {
        currency: 'USD',
        price: 0,
        display: 'US$ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CR: {
        currency: 'USD',
        price: 0,
        display: 'USD0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TC: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TD: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CU: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TF: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CV: {
        currency: 'USD',
        price: 0,
        display: '0,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TG: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CW: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TH: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CX: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CY: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TJ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CZ: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TK: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TL: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TO: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TR: {
        currency: 'USD',
        price: 0,
        display: '$0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TT: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DE: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TV: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DJ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TZ: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DK: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DO: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DZ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EC: {
        currency: 'USD',
        price: 0,
        display: '$0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      US: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EE: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EG: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EH: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UY: {
        currency: 'USD',
        price: 0,
        display: 'US$ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UZ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VA: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ER: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VC: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ES: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ET: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VE: {
        currency: 'USD',
        price: 0,
        display: 'USD0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VI: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VU: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FI: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FJ: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FK: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FO: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FR: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WF: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GB: {
        currency: 'GBP',
        price: 0,
        display: '£0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WS: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GD: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GE: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GF: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GG: {
        currency: 'GBP',
        price: 0,
        display: '£0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GH: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GI: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GL: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GP: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GQ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GR: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GS: {
        currency: 'GBP',
        price: 0,
        display: '£0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GT: {
        currency: 'USD',
        price: 0,
        display: 'USD0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GU: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GW: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GY: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HK: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HM: {
        currency: 'AUD',
        price: 0,
        display: 'A$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HR: {
        currency: 'EUR',
        price: 0,
        display: '0,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HT: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YE: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HU: {
        currency: 'EUR',
        price: 0,
        display: '0,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IC: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ID: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YT: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IE: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IL: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IM: {
        currency: 'GBP',
        price: 0,
        display: '£0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IN: {
        currency: 'USD',
        price: 0,
        display: 'US$ 0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IO: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZA: {
        currency: 'USD',
        price: 0,
        display: 'US$0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IQ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IR: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IS: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IT: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JE: {
        currency: 'GBP',
        price: 0,
        display: '£0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JO: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JP: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KE: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KG: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KH: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KI: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KN: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KP: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KR: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KW: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KY: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KZ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LB: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LC: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LI: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LK: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LR: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LS: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LT: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LU: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LV: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LY: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MC: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MD: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ME: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MF: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MH: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MK: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ML: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MO: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MP: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MQ: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MR: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MS: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MT: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MU: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MV: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MX: {
        currency: 'USD',
        price: 0,
        display: 'USD0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MY: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MZ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NA: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NC: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NE: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NF: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NI: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NL: {
        currency: 'EUR',
        price: 0,
        display: '€ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NO: {
        currency: 'EUR',
        price: 0,
        display: '€ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NP: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NR: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NU: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NZ: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      OM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PA: {
        currency: 'USD',
        price: 0,
        display: 'USD0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PE: {
        currency: 'USD',
        price: 0,
        display: 'USD0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PF: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PH: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PK: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PL: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PM: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PN: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
    },
  },
  var2: {
    action: 'product.price.getall',
    result: 'success',
    product: 'var2',
    pricing: {
      PR: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PS: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PT: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PY: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      QA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AD: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AE: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AF: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AI: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AL: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AO: {
        currency: 'USD',
        price: 0,
        display: '0,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AQ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AR: {
        currency: 'USD',
        price: 0,
        display: 'US$ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AS: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AT: {
        currency: 'EUR',
        price: 0,
        display: '€ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RE: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AU: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AW: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AX: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AZ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RO: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BA: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BB: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RS: {
        currency: 'EUR',
        price: 0,
        display: '0,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BD: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BE: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RU: {
        currency: 'USD',
        price: 0,
        display: '0,00 $',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BF: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BG: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BH: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BI: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BJ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BL: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BO: {
        currency: 'USD',
        price: 0,
        display: 'USD0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BQ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SB: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BR: {
        currency: 'USD',
        price: 0,
        display: 'US$ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SC: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BS: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SD: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BT: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SE: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BV: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SH: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SI: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BY: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SJ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BZ: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SK: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SL: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SM: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SO: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CA: {
        currency: 'CAD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SR: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CC: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SS: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CD: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ST: {
        currency: 'USD',
        price: 0,
        display: '0,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CF: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SV: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CG: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CH: {
        currency: 'EUR',
        price: 0,
        display: '€ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SX: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CI: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SY: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SZ: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CK: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CL: {
        currency: 'USD',
        price: 0,
        display: 'US$0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CN: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CO: {
        currency: 'USD',
        price: 0,
        display: 'US$ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CR: {
        currency: 'USD',
        price: 0,
        display: 'USD0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TC: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TD: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CU: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TF: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CV: {
        currency: 'USD',
        price: 0,
        display: '0,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TG: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CW: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TH: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CX: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CY: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TJ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CZ: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TK: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TL: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TO: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TR: {
        currency: 'USD',
        price: 0,
        display: '$0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TT: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DE: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TV: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DJ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TZ: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DK: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DO: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DZ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EC: {
        currency: 'USD',
        price: 0,
        display: '$0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      US: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EE: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EG: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EH: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UY: {
        currency: 'USD',
        price: 0,
        display: 'US$ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UZ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VA: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ER: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VC: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ES: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ET: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VE: {
        currency: 'USD',
        price: 0,
        display: 'USD0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VI: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VU: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FI: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FJ: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FK: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FO: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FR: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WF: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GB: {
        currency: 'GBP',
        price: 0,
        display: '£0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WS: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GD: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GE: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GF: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GG: {
        currency: 'GBP',
        price: 0,
        display: '£0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GH: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GI: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GL: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GP: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GQ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GR: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GS: {
        currency: 'GBP',
        price: 0,
        display: '£0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GT: {
        currency: 'USD',
        price: 0,
        display: 'USD0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GU: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GW: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GY: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HK: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HM: {
        currency: 'AUD',
        price: 0,
        display: 'A$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HR: {
        currency: 'EUR',
        price: 0,
        display: '0,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HT: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YE: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HU: {
        currency: 'EUR',
        price: 0,
        display: '0,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IC: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ID: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YT: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IE: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IL: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IM: {
        currency: 'GBP',
        price: 0,
        display: '£0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IN: {
        currency: 'USD',
        price: 0,
        display: 'US$ 0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IO: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZA: {
        currency: 'USD',
        price: 0,
        display: 'US$0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IQ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IR: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IS: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IT: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JE: {
        currency: 'GBP',
        price: 0,
        display: '£0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JO: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JP: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KE: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KG: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KH: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KI: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KN: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KP: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KR: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KW: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KY: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KZ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LB: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LC: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LI: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LK: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LR: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LS: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LT: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LU: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LV: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LY: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MC: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MD: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ME: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MF: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MH: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MK: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ML: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MO: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MP: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MQ: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MR: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MS: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MT: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MU: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MV: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MX: {
        currency: 'USD',
        price: 0,
        display: 'USD0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MY: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MZ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NA: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NC: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NE: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NF: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NI: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NL: {
        currency: 'EUR',
        price: 0,
        display: '€ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NO: {
        currency: 'EUR',
        price: 0,
        display: '€ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NP: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NR: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NU: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NZ: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      OM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PA: {
        currency: 'USD',
        price: 0,
        display: 'USD0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PE: {
        currency: 'USD',
        price: 0,
        display: 'USD0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PF: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PH: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PK: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PL: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PM: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PN: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
    },
  },
  'pdf-two-year': {
    action: 'product.price.getall',
    result: 'success',
    product: 'pdf-two-year',
    pricing: {
      PR: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PS: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PT: {
        currency: 'EUR',
        price: 226,
        display: '226,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 112.64,
            discountValueDisplay: '112,64 €',
            unitPrice: 113.36,
            unitPriceDisplay: '113,36 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PW: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PY: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      QA: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AD: {
        currency: 'EUR',
        price: 184,
        display: '€184.00',
        quantityDiscount: {
          '1': {
            discountValue: 91.58,
            discountValueDisplay: '€91.58',
            unitPrice: 92.42,
            unitPriceDisplay: '€92.42',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AE: {
        currency: 'USD',
        price: 201.6,
        display: '$201.60',
        quantityDiscount: {
          '1': {
            discountValue: 100.8,
            discountValueDisplay: '$100.80',
            unitPrice: 100.8,
            unitPriceDisplay: '$100.80',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AF: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AG: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AI: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AL: {
        currency: 'EUR',
        price: 184,
        display: '€184.00',
        quantityDiscount: {
          '1': {
            discountValue: 91.58,
            discountValueDisplay: '€91.58',
            unitPrice: 92.42,
            unitPriceDisplay: '€92.42',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AM: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AN: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AO: {
        currency: 'USD',
        price: 192,
        display: '192,00 US$',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '96,00 US$',
            unitPrice: 96,
            unitPriceDisplay: '96,00 US$',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AQ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AR: {
        currency: 'USD',
        price: 192,
        display: 'US$ 192,00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$ 96,00',
            unitPrice: 96,
            unitPriceDisplay: 'US$ 96,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AS: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AT: {
        currency: 'EUR',
        price: 220,
        display: '€ 220,00',
        quantityDiscount: {
          '1': {
            discountValue: 109.9,
            discountValueDisplay: '€ 109,90',
            unitPrice: 110.1,
            unitPriceDisplay: '€ 110,10',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      RE: {
        currency: 'EUR',
        price: 184,
        display: '€184.00',
        quantityDiscount: {
          '1': {
            discountValue: 91.58,
            discountValueDisplay: '€91.58',
            unitPrice: 92.42,
            unitPriceDisplay: '€92.42',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AU: {
        currency: 'AUD',
        price: 332,
        display: '$332.00',
        quantityDiscount: {
          '1': {
            discountValue: 165.74,
            discountValueDisplay: '$165.74',
            unitPrice: 166.26,
            unitPriceDisplay: '$166.26',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AW: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AX: {
        currency: 'EUR',
        price: 184,
        display: '€184.00',
        quantityDiscount: {
          '1': {
            discountValue: 91.58,
            discountValueDisplay: '€91.58',
            unitPrice: 92.42,
            unitPriceDisplay: '€92.42',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AZ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      RO: {
        currency: 'EUR',
        price: 218,
        display: '€218.00',
        quantityDiscount: {
          '1': {
            discountValue: 108.98,
            discountValueDisplay: '€108.98',
            unitPrice: 109.02,
            unitPriceDisplay: '€109.02',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BA: {
        currency: 'EUR',
        price: 184,
        display: '€184.00',
        quantityDiscount: {
          '1': {
            discountValue: 91.58,
            discountValueDisplay: '€91.58',
            unitPrice: 92.42,
            unitPriceDisplay: '€92.42',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BB: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      RS: {
        currency: 'EUR',
        price: 220,
        display: '220,00 EUR',
        quantityDiscount: {
          '1': {
            discountValue: 109.9,
            discountValueDisplay: '109,90 EUR',
            unitPrice: 110.1,
            unitPriceDisplay: '110,10 EUR',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BD: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BE: {
        currency: 'EUR',
        price: 222,
        display: '222,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 110.81,
            discountValueDisplay: '110,81 €',
            unitPrice: 111.19,
            unitPriceDisplay: '111,19 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      RU: {
        currency: 'USD',
        price: 230.4,
        display: '230,40 $',
        quantityDiscount: {
          '1': {
            discountValue: 115.2,
            discountValueDisplay: '115,20 $',
            unitPrice: 115.2,
            unitPriceDisplay: '115,20 $',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BF: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BG: {
        currency: 'EUR',
        price: 220,
        display: '€220.00',
        quantityDiscount: {
          '1': {
            discountValue: 109.9,
            discountValueDisplay: '€109.90',
            unitPrice: 110.1,
            unitPriceDisplay: '€110.10',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      RW: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BH: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BI: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BJ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BL: {
        currency: 'EUR',
        price: 184,
        display: '€184.00',
        quantityDiscount: {
          '1': {
            discountValue: 91.58,
            discountValueDisplay: '€91.58',
            unitPrice: 92.42,
            unitPriceDisplay: '€92.42',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BM: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BN: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BO: {
        currency: 'USD',
        price: 192,
        display: 'USD192,00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'USD96,00',
            unitPrice: 96,
            unitPriceDisplay: 'USD96,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SA: {
        currency: 'USD',
        price: 220.8,
        display: '$220.80',
        quantityDiscount: {
          '1': {
            discountValue: 110.4,
            discountValueDisplay: '$110.40',
            unitPrice: 110.4,
            unitPriceDisplay: '$110.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BQ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SB: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BR: {
        currency: 'USD',
        price: 192,
        display: 'US$ 192,00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$ 96,00',
            unitPrice: 96,
            unitPriceDisplay: 'US$ 96,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SC: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BS: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SD: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BT: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SE: {
        currency: 'EUR',
        price: 229,
        display: '229,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 114.48,
            discountValueDisplay: '114,48 €',
            unitPrice: 114.52,
            unitPriceDisplay: '114,52 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BV: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SG: {
        currency: 'USD',
        price: 209.28,
        display: 'US$209.28',
        quantityDiscount: {
          '1': {
            discountValue: 104.64,
            discountValueDisplay: 'US$104.64',
            unitPrice: 104.64,
            unitPriceDisplay: 'US$104.64',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BW: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SH: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SI: {
        currency: 'EUR',
        price: 224,
        display: '224,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 111.73,
            discountValueDisplay: '111,73 €',
            unitPrice: 112.27,
            unitPriceDisplay: '112,27 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BY: {
        currency: 'USD',
        price: 230.4,
        display: '$230.40',
        quantityDiscount: {
          '1': {
            discountValue: 115.2,
            discountValueDisplay: '$115.20',
            unitPrice: 115.2,
            unitPriceDisplay: '$115.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SJ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BZ: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SK: {
        currency: 'EUR',
        price: 220,
        display: '220,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 109.9,
            discountValueDisplay: '109,90 €',
            unitPrice: 110.1,
            unitPriceDisplay: '110,10 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SL: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SM: {
        currency: 'EUR',
        price: 184,
        display: '€184.00',
        quantityDiscount: {
          '1': {
            discountValue: 91.58,
            discountValueDisplay: '€91.58',
            unitPrice: 92.42,
            unitPriceDisplay: '€92.42',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SN: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SO: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CA: {
        currency: 'CAD',
        price: 269,
        display: '$269.00',
        quantityDiscount: {
          '1': {
            discountValue: 134.29,
            discountValueDisplay: '$134.29',
            unitPrice: 134.71,
            unitPriceDisplay: '$134.71',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SR: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CC: {
        currency: 'AUD',
        price: 302,
        display: '$302.00',
        quantityDiscount: {
          '1': {
            discountValue: 150.67,
            discountValueDisplay: '$150.67',
            unitPrice: 151.33,
            unitPriceDisplay: '$151.33',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SS: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CD: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ST: {
        currency: 'USD',
        price: 192,
        display: '192,00 US$',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '96,00 US$',
            unitPrice: 96,
            unitPriceDisplay: '96,00 US$',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CF: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SV: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CG: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CH: {
        currency: 'EUR',
        price: 198,
        display: '€ 198,00',
        quantityDiscount: {
          '1': {
            discountValue: 99,
            discountValueDisplay: '€ 99,00',
            unitPrice: 99,
            unitPriceDisplay: '€ 99,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SX: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CI: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SY: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SZ: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CK: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CL: {
        currency: 'USD',
        price: 228.48,
        display: 'US$228,48',
        quantityDiscount: {
          '1': {
            discountValue: 114.24,
            discountValueDisplay: 'US$114,24',
            unitPrice: 114.24,
            unitPriceDisplay: 'US$114,24',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CM: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CN: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CO: {
        currency: 'USD',
        price: 228.48,
        display: 'US$ 228,48',
        quantityDiscount: {
          '1': {
            discountValue: 114.24,
            discountValueDisplay: 'US$ 114,24',
            unitPrice: 114.24,
            unitPriceDisplay: 'US$ 114,24',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CR: {
        currency: 'USD',
        price: 192,
        display: 'USD192,00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'USD96,00',
            unitPrice: 96,
            unitPriceDisplay: 'USD96,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TC: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TD: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CU: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TF: {
        currency: 'EUR',
        price: 184,
        display: '€184.00',
        quantityDiscount: {
          '1': {
            discountValue: 91.58,
            discountValueDisplay: '€91.58',
            unitPrice: 92.42,
            unitPriceDisplay: '€92.42',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CV: {
        currency: 'USD',
        price: 192,
        display: '192,00 US$',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '96,00 US$',
            unitPrice: 96,
            unitPriceDisplay: '96,00 US$',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TG: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CW: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TH: {
        currency: 'USD',
        price: 205.44,
        display: '$205.44',
        quantityDiscount: {
          '1': {
            discountValue: 102.72,
            discountValueDisplay: '$102.72',
            unitPrice: 102.72,
            unitPriceDisplay: '$102.72',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CX: {
        currency: 'AUD',
        price: 302,
        display: '$302.00',
        quantityDiscount: {
          '1': {
            discountValue: 150.67,
            discountValueDisplay: '$150.67',
            unitPrice: 151.33,
            unitPriceDisplay: '$151.33',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CY: {
        currency: 'EUR',
        price: 218,
        display: '€218.00',
        quantityDiscount: {
          '1': {
            discountValue: 108.98,
            discountValueDisplay: '€108.98',
            unitPrice: 109.02,
            unitPriceDisplay: '€109.02',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TJ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CZ: {
        currency: 'EUR',
        price: 222,
        display: '222,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 110.81,
            discountValueDisplay: '110,81 €',
            unitPrice: 111.19,
            unitPriceDisplay: '111,19 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TK: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TL: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TM: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TN: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TO: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TR: {
        currency: 'USD',
        price: 230.4,
        display: '$230,40',
        quantityDiscount: {
          '1': {
            discountValue: 115.2,
            discountValueDisplay: '$115,20',
            unitPrice: 115.2,
            unitPriceDisplay: '$115,20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TT: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DE: {
        currency: 'EUR',
        price: 218,
        display: '218,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 108.98,
            discountValueDisplay: '108,98 €',
            unitPrice: 109.02,
            unitPriceDisplay: '109,02 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TV: {
        currency: 'AUD',
        price: 302,
        display: '$302.00',
        quantityDiscount: {
          '1': {
            discountValue: 150.67,
            discountValueDisplay: '$150.67',
            unitPrice: 151.33,
            unitPriceDisplay: '$151.33',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TW: {
        currency: 'USD',
        price: 201.6,
        display: 'US$201.60',
        quantityDiscount: {
          '1': {
            discountValue: 100.8,
            discountValueDisplay: 'US$100.80',
            unitPrice: 100.8,
            unitPriceDisplay: 'US$100.80',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DJ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TZ: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DK: {
        currency: 'EUR',
        price: 229,
        display: '229,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 114.48,
            discountValueDisplay: '114,48 €',
            unitPrice: 114.52,
            unitPriceDisplay: '114,52 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DM: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DO: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      UA: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      UG: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DZ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      UM: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      EC: {
        currency: 'USD',
        price: 192,
        display: '$192,00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96,00',
            unitPrice: 96,
            unitPriceDisplay: '$96,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      US: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      EE: {
        currency: 'EUR',
        price: 224,
        display: '€224.00',
        quantityDiscount: {
          '1': {
            discountValue: 111.73,
            discountValueDisplay: '€111.73',
            unitPrice: 112.27,
            unitPriceDisplay: '€112.27',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      EG: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      EH: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      UY: {
        currency: 'USD',
        price: 192,
        display: 'US$ 192,00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$ 96,00',
            unitPrice: 96,
            unitPriceDisplay: 'US$ 96,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      UZ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VA: {
        currency: 'EUR',
        price: 184,
        display: '€184.00',
        quantityDiscount: {
          '1': {
            discountValue: 91.58,
            discountValueDisplay: '€91.58',
            unitPrice: 92.42,
            unitPriceDisplay: '€92.42',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ER: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VC: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ES: {
        currency: 'EUR',
        price: 222,
        display: '222,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 110.81,
            discountValueDisplay: '110,81 €',
            unitPrice: 111.19,
            unitPriceDisplay: '111,19 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ET: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VE: {
        currency: 'USD',
        price: 192,
        display: 'USD192,00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'USD96,00',
            unitPrice: 96,
            unitPriceDisplay: 'USD96,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VG: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VI: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VN: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VU: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FI: {
        currency: 'EUR',
        price: 228,
        display: '228,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 113.56,
            discountValueDisplay: '113,56 €',
            unitPrice: 114.44,
            unitPriceDisplay: '114,44 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FJ: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FK: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FM: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FO: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FR: {
        currency: 'EUR',
        price: 220,
        display: '220,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 109.9,
            discountValueDisplay: '109,90 €',
            unitPrice: 110.1,
            unitPriceDisplay: '110,10 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      WF: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GA: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GB: {
        currency: 'GBP',
        price: 189,
        display: '£189.00',
        quantityDiscount: {
          '1': {
            discountValue: 94.21,
            discountValueDisplay: '£94.21',
            unitPrice: 94.79,
            unitPriceDisplay: '£94.79',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      WS: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GD: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GE: {
        currency: 'EUR',
        price: 184,
        display: '€184.00',
        quantityDiscount: {
          '1': {
            discountValue: 91.58,
            discountValueDisplay: '€91.58',
            unitPrice: 92.42,
            unitPriceDisplay: '€92.42',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GF: {
        currency: 'EUR',
        price: 184,
        display: '€184.00',
        quantityDiscount: {
          '1': {
            discountValue: 91.58,
            discountValueDisplay: '€91.58',
            unitPrice: 92.42,
            unitPriceDisplay: '€92.42',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GG: {
        currency: 'GBP',
        price: 158,
        display: '£158.00',
        quantityDiscount: {
          '1': {
            discountValue: 78.51,
            discountValueDisplay: '£78.51',
            unitPrice: 79.49,
            unitPriceDisplay: '£79.49',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GH: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GI: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GL: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GM: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GN: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GP: {
        currency: 'EUR',
        price: 184,
        display: '€184.00',
        quantityDiscount: {
          '1': {
            discountValue: 91.58,
            discountValueDisplay: '€91.58',
            unitPrice: 92.42,
            unitPriceDisplay: '€92.42',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GQ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GR: {
        currency: 'EUR',
        price: 228,
        display: '228,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 113.56,
            discountValueDisplay: '113,56 €',
            unitPrice: 114.44,
            unitPriceDisplay: '114,44 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GS: {
        currency: 'GBP',
        price: 158,
        display: '£158.00',
        quantityDiscount: {
          '1': {
            discountValue: 78.51,
            discountValueDisplay: '£78.51',
            unitPrice: 79.49,
            unitPriceDisplay: '£79.49',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GT: {
        currency: 'USD',
        price: 192,
        display: 'USD192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'USD96.00',
            unitPrice: 96,
            unitPriceDisplay: 'USD96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GU: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GW: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GY: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HK: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HM: {
        currency: 'AUD',
        price: 302,
        display: 'A$302.00',
        quantityDiscount: {
          '1': {
            discountValue: 150.67,
            discountValueDisplay: 'A$150.67',
            unitPrice: 151.33,
            unitPriceDisplay: 'A$151.33',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HN: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HR: {
        currency: 'EUR',
        price: 229,
        display: '229,00 EUR',
        quantityDiscount: {
          '1': {
            discountValue: 114.48,
            discountValueDisplay: '114,48 EUR',
            unitPrice: 114.52,
            unitPriceDisplay: '114,52 EUR',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HT: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      YE: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HU: {
        currency: 'EUR',
        price: 233,
        display: '233,00 EUR',
        quantityDiscount: {
          '1': {
            discountValue: 116.31,
            discountValueDisplay: '116,31 EUR',
            unitPrice: 116.69,
            unitPriceDisplay: '116,69 EUR',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IC: {
        currency: 'EUR',
        price: 184,
        display: '184,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 91.58,
            discountValueDisplay: '91,58 €',
            unitPrice: 92.42,
            unitPriceDisplay: '92,42 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ID: {
        currency: 'USD',
        price: 213.12,
        display: '$213.12',
        quantityDiscount: {
          '1': {
            discountValue: 106.56,
            discountValueDisplay: '$106.56',
            unitPrice: 106.56,
            unitPriceDisplay: '$106.56',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      YT: {
        currency: 'EUR',
        price: 184,
        display: '€184.00',
        quantityDiscount: {
          '1': {
            discountValue: 91.58,
            discountValueDisplay: '€91.58',
            unitPrice: 92.42,
            unitPriceDisplay: '€92.42',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IE: {
        currency: 'EUR',
        price: 226,
        display: '€226.00',
        quantityDiscount: {
          '1': {
            discountValue: 112.64,
            discountValueDisplay: '€112.64',
            unitPrice: 113.36,
            unitPriceDisplay: '€113.36',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IL: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IM: {
        currency: 'GBP',
        price: 158,
        display: '£158.00',
        quantityDiscount: {
          '1': {
            discountValue: 78.51,
            discountValueDisplay: '£78.51',
            unitPrice: 79.49,
            unitPriceDisplay: '£79.49',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IN: {
        currency: 'USD',
        price: 226.56,
        display: 'US$ 226.56',
        quantityDiscount: {
          '1': {
            discountValue: 113.28,
            discountValueDisplay: 'US$ 113.28',
            unitPrice: 113.28,
            unitPriceDisplay: 'US$ 113.28',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IO: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ZA: {
        currency: 'USD',
        price: 220.8,
        display: 'US$220,80',
        quantityDiscount: {
          '1': {
            discountValue: 110.4,
            discountValueDisplay: 'US$110,40',
            unitPrice: 110.4,
            unitPriceDisplay: 'US$110,40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IQ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IR: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IS: {
        currency: 'EUR',
        price: 228,
        display: '€228.00',
        quantityDiscount: {
          '1': {
            discountValue: 113.56,
            discountValueDisplay: '€113.56',
            unitPrice: 114.44,
            unitPriceDisplay: '€114.44',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IT: {
        currency: 'EUR',
        price: 224,
        display: '224,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 111.73,
            discountValueDisplay: '111,73 €',
            unitPrice: 112.27,
            unitPriceDisplay: '112,27 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ZM: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      JE: {
        currency: 'GBP',
        price: 158,
        display: '£158.00',
        quantityDiscount: {
          '1': {
            discountValue: 78.51,
            discountValueDisplay: '£78.51',
            unitPrice: 79.49,
            unitPriceDisplay: '£79.49',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ZW: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      JM: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      JO: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      JP: {
        currency: 'USD',
        price: 211.2,
        display: '$211.20',
        quantityDiscount: {
          '1': {
            discountValue: 105.6,
            discountValueDisplay: '$105.60',
            unitPrice: 105.6,
            unitPriceDisplay: '$105.60',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KE: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KG: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KH: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KI: {
        currency: 'AUD',
        price: 302,
        display: '$302.00',
        quantityDiscount: {
          '1': {
            discountValue: 150.67,
            discountValueDisplay: '$150.67',
            unitPrice: 151.33,
            unitPriceDisplay: '$151.33',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KM: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KN: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KP: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KR: {
        currency: 'USD',
        price: 211.2,
        display: 'US$211.20',
        quantityDiscount: {
          '1': {
            discountValue: 105.6,
            discountValueDisplay: 'US$105.60',
            unitPrice: 105.6,
            unitPriceDisplay: 'US$105.60',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KW: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KY: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KZ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LA: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LB: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LC: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LI: {
        currency: 'EUR',
        price: 184,
        display: '€184.00',
        quantityDiscount: {
          '1': {
            discountValue: 91.58,
            discountValueDisplay: '€91.58',
            unitPrice: 92.42,
            unitPriceDisplay: '€92.42',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LK: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LR: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LS: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LT: {
        currency: 'EUR',
        price: 222,
        display: '222,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 110.81,
            discountValueDisplay: '110,81 €',
            unitPrice: 111.19,
            unitPriceDisplay: '111,19 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LU: {
        currency: 'EUR',
        price: 215,
        display: '€215.00',
        quantityDiscount: {
          '1': {
            discountValue: 107.15,
            discountValueDisplay: '€107.15',
            unitPrice: 107.85,
            unitPriceDisplay: '€107.85',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LV: {
        currency: 'EUR',
        price: 222,
        display: '222,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 110.81,
            discountValueDisplay: '110,81 €',
            unitPrice: 111.19,
            unitPriceDisplay: '111,19 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LY: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MA: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MC: {
        currency: 'EUR',
        price: 184,
        display: '€184.00',
        quantityDiscount: {
          '1': {
            discountValue: 91.58,
            discountValueDisplay: '€91.58',
            unitPrice: 92.42,
            unitPriceDisplay: '€92.42',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MD: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ME: {
        currency: 'EUR',
        price: 184,
        display: '€184.00',
        quantityDiscount: {
          '1': {
            discountValue: 91.58,
            discountValueDisplay: '€91.58',
            unitPrice: 92.42,
            unitPriceDisplay: '€92.42',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MF: {
        currency: 'EUR',
        price: 184,
        display: '€184.00',
        quantityDiscount: {
          '1': {
            discountValue: 91.58,
            discountValueDisplay: '€91.58',
            unitPrice: 92.42,
            unitPriceDisplay: '€92.42',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MG: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MH: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MK: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ML: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MM: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MN: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MO: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MP: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MQ: {
        currency: 'EUR',
        price: 184,
        display: '€184.00',
        quantityDiscount: {
          '1': {
            discountValue: 91.58,
            discountValueDisplay: '€91.58',
            unitPrice: 92.42,
            unitPriceDisplay: '€92.42',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MR: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MS: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MT: {
        currency: 'EUR',
        price: 217,
        display: '€217.00',
        quantityDiscount: {
          '1': {
            discountValue: 108.06,
            discountValueDisplay: '€108.06',
            unitPrice: 108.94,
            unitPriceDisplay: '€108.94',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MU: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MV: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MW: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MX: {
        currency: 'USD',
        price: 192,
        display: 'USD192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'USD96.00',
            unitPrice: 96,
            unitPriceDisplay: 'USD96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MY: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MZ: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NA: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NC: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NE: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NF: {
        currency: 'AUD',
        price: 302,
        display: '$302.00',
        quantityDiscount: {
          '1': {
            discountValue: 150.67,
            discountValueDisplay: '$150.67',
            unitPrice: 151.33,
            unitPriceDisplay: '$151.33',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NG: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NI: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NL: {
        currency: 'EUR',
        price: 222,
        display: '€ 222,00',
        quantityDiscount: {
          '1': {
            discountValue: 110.81,
            discountValueDisplay: '€ 110,81',
            unitPrice: 111.19,
            unitPriceDisplay: '€ 111,19',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NO: {
        currency: 'EUR',
        price: 229,
        display: '€ 229,00',
        quantityDiscount: {
          '1': {
            discountValue: 114.48,
            discountValueDisplay: '€ 114,48',
            unitPrice: 114.52,
            unitPriceDisplay: '€ 114,52',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NP: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NR: {
        currency: 'AUD',
        price: 302,
        display: '$302.00',
        quantityDiscount: {
          '1': {
            discountValue: 150.67,
            discountValueDisplay: '$150.67',
            unitPrice: 151.33,
            unitPriceDisplay: '$151.33',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NU: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NZ: {
        currency: 'USD',
        price: 220.8,
        display: 'US$220.80',
        quantityDiscount: {
          '1': {
            discountValue: 110.4,
            discountValueDisplay: 'US$110.40',
            unitPrice: 110.4,
            unitPriceDisplay: 'US$110.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      OM: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PA: {
        currency: 'USD',
        price: 192,
        display: 'USD192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'USD96.00',
            unitPrice: 96,
            unitPriceDisplay: 'USD96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PE: {
        currency: 'USD',
        price: 192,
        display: 'USD192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'USD96.00',
            unitPrice: 96,
            unitPriceDisplay: 'USD96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PF: {
        currency: 'USD',
        price: 192,
        display: '$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: '$96.00',
            unitPrice: 96,
            unitPriceDisplay: '$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PG: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PH: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PK: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PL: {
        currency: 'EUR',
        price: 226,
        display: '226,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 112.64,
            discountValueDisplay: '112,64 €',
            unitPrice: 113.36,
            unitPriceDisplay: '113,36 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PM: {
        currency: 'EUR',
        price: 184,
        display: '€184.00',
        quantityDiscount: {
          '1': {
            discountValue: 91.58,
            discountValueDisplay: '€91.58',
            unitPrice: 92.42,
            unitPriceDisplay: '€92.42',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PN: {
        currency: 'USD',
        price: 192,
        display: 'US$192.00',
        quantityDiscount: {
          '1': {
            discountValue: 96,
            discountValueDisplay: 'US$96.00',
            unitPrice: 96,
            unitPriceDisplay: 'US$96.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
    },
  },
  var1: {
    action: 'product.price.getall',
    result: 'success',
    product: 'var1',
    pricing: {
      PR: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PS: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PT: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PY: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      QA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AD: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AE: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AF: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AI: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AL: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AO: {
        currency: 'USD',
        price: 0,
        display: '0,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AQ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AR: {
        currency: 'USD',
        price: 0,
        display: 'US$ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AS: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AT: {
        currency: 'EUR',
        price: 0,
        display: '€ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RE: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AU: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AW: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AX: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AZ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RO: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BA: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BB: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RS: {
        currency: 'EUR',
        price: 0,
        display: '0,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BD: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BE: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RU: {
        currency: 'USD',
        price: 0,
        display: '0,00 $',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BF: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BG: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BH: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BI: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BJ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BL: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BO: {
        currency: 'USD',
        price: 0,
        display: 'USD0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BQ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SB: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BR: {
        currency: 'USD',
        price: 0,
        display: 'US$ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SC: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BS: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SD: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BT: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SE: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BV: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SH: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SI: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BY: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SJ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BZ: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SK: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SL: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SM: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SO: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CA: {
        currency: 'CAD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SR: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CC: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SS: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CD: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ST: {
        currency: 'USD',
        price: 0,
        display: '0,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CF: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SV: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CG: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CH: {
        currency: 'EUR',
        price: 0,
        display: '€ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SX: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CI: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SY: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SZ: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CK: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CL: {
        currency: 'USD',
        price: 0,
        display: 'US$0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CN: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CO: {
        currency: 'USD',
        price: 0,
        display: 'US$ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CR: {
        currency: 'USD',
        price: 0,
        display: 'USD0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TC: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TD: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CU: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TF: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CV: {
        currency: 'USD',
        price: 0,
        display: '0,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TG: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CW: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TH: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CX: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CY: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TJ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CZ: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TK: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TL: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TO: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TR: {
        currency: 'USD',
        price: 0,
        display: '$0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TT: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DE: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TV: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DJ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TZ: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DK: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DO: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DZ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EC: {
        currency: 'USD',
        price: 0,
        display: '$0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      US: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EE: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EG: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EH: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UY: {
        currency: 'USD',
        price: 0,
        display: 'US$ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UZ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VA: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ER: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VC: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ES: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ET: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VE: {
        currency: 'USD',
        price: 0,
        display: 'USD0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VI: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VU: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FI: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FJ: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FK: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FO: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FR: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WF: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GB: {
        currency: 'GBP',
        price: 0,
        display: '£0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WS: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GD: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GE: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GF: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GG: {
        currency: 'GBP',
        price: 0,
        display: '£0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GH: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GI: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GL: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GP: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GQ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GR: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GS: {
        currency: 'GBP',
        price: 0,
        display: '£0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GT: {
        currency: 'USD',
        price: 0,
        display: 'USD0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GU: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GW: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GY: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HK: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HM: {
        currency: 'AUD',
        price: 0,
        display: 'A$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HR: {
        currency: 'EUR',
        price: 0,
        display: '0,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HT: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YE: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HU: {
        currency: 'EUR',
        price: 0,
        display: '0,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IC: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ID: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YT: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IE: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IL: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IM: {
        currency: 'GBP',
        price: 0,
        display: '£0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IN: {
        currency: 'USD',
        price: 0,
        display: 'US$ 0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IO: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZA: {
        currency: 'USD',
        price: 0,
        display: 'US$0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IQ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IR: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IS: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IT: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JE: {
        currency: 'GBP',
        price: 0,
        display: '£0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JM: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JO: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JP: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KE: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KG: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KH: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KI: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KN: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KP: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KR: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KW: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KY: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KZ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LB: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LC: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LI: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LK: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LR: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LS: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LT: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LU: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LV: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LY: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MA: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MC: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MD: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ME: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MF: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MH: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MK: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ML: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MN: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MO: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MP: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MQ: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MR: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MS: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MT: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MU: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MV: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MW: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MX: {
        currency: 'USD',
        price: 0,
        display: 'USD0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MY: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MZ: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NA: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NC: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NE: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NF: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NI: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NL: {
        currency: 'EUR',
        price: 0,
        display: '€ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NO: {
        currency: 'EUR',
        price: 0,
        display: '€ 0,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NP: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NR: {
        currency: 'AUD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NU: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NZ: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      OM: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PA: {
        currency: 'USD',
        price: 0,
        display: 'USD0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PE: {
        currency: 'USD',
        price: 0,
        display: 'USD0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PF: {
        currency: 'USD',
        price: 0,
        display: '$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PG: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PH: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PK: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PL: {
        currency: 'EUR',
        price: 0,
        display: '0,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PM: {
        currency: 'EUR',
        price: 0,
        display: '€0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PN: {
        currency: 'USD',
        price: 0,
        display: 'US$0.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
    },
  },
  'pdf-monthly-ocr': {
    action: 'product.price.getall',
    result: 'success',
    product: 'pdf-monthly-ocr',
    pricing: {
      PR: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PS: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PT: {
        currency: 'EUR',
        price: 21,
        display: '21,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PW: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PY: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      QA: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AD: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AE: {
        currency: 'USD',
        price: 21,
        display: '$21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AF: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AG: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AI: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AL: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AM: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AN: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AO: {
        currency: 'USD',
        price: 20,
        display: '20,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AQ: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AR: {
        currency: 'USD',
        price: 20,
        display: 'US$ 20,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AS: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AT: {
        currency: 'EUR',
        price: 21,
        display: '€ 21,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RE: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AU: {
        currency: 'AUD',
        price: 23,
        display: '$23.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AW: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AX: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AZ: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RO: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BA: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BB: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RS: {
        currency: 'EUR',
        price: 21,
        display: '21,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BD: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BE: {
        currency: 'EUR',
        price: 21,
        display: '21,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RU: {
        currency: 'USD',
        price: 24,
        display: '24,00 $',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BF: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BG: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RW: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BH: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BI: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BJ: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BL: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BM: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BN: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BO: {
        currency: 'USD',
        price: 20,
        display: 'USD20,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SA: {
        currency: 'USD',
        price: 23,
        display: '$23.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BQ: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SB: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BR: {
        currency: 'USD',
        price: 20,
        display: 'US$ 20,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SC: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BS: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SD: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BT: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SE: {
        currency: 'EUR',
        price: 21,
        display: '21,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BV: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SG: {
        currency: 'USD',
        price: 21.8,
        display: 'US$21.80',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BW: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SH: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SI: {
        currency: 'EUR',
        price: 21,
        display: '21,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BY: {
        currency: 'USD',
        price: 24,
        display: '$24.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SJ: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BZ: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SK: {
        currency: 'EUR',
        price: 21,
        display: '21,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SL: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SM: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SN: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SO: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CA: {
        currency: 'CAD',
        price: 23,
        display: '$23.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SR: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CC: {
        currency: 'AUD',
        price: 23,
        display: '$23.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SS: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CD: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ST: {
        currency: 'USD',
        price: 20,
        display: '20,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CF: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SV: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CG: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CH: {
        currency: 'EUR',
        price: 21,
        display: '€ 21,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SX: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CI: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SY: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SZ: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CK: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CL: {
        currency: 'USD',
        price: 23.8,
        display: 'US$23,80',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CM: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CN: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CO: {
        currency: 'USD',
        price: 23.8,
        display: 'US$ 23,80',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CR: {
        currency: 'USD',
        price: 20,
        display: 'USD20,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TC: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TD: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CU: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TF: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CV: {
        currency: 'USD',
        price: 20,
        display: '20,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TG: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CW: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TH: {
        currency: 'USD',
        price: 21.4,
        display: '$21.40',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CX: {
        currency: 'AUD',
        price: 23,
        display: '$23.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CY: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TJ: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CZ: {
        currency: 'EUR',
        price: 21,
        display: '21,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TK: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TL: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TM: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TN: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TO: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TR: {
        currency: 'USD',
        price: 24,
        display: '$24,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TT: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DE: {
        currency: 'EUR',
        price: 21,
        display: '21,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TV: {
        currency: 'AUD',
        price: 23,
        display: '$23.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TW: {
        currency: 'USD',
        price: 21,
        display: 'US$21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DJ: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TZ: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DK: {
        currency: 'EUR',
        price: 21,
        display: '21,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DM: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DO: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UA: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UG: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DZ: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UM: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EC: {
        currency: 'USD',
        price: 20,
        display: '$20,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      US: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EE: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EG: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EH: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UY: {
        currency: 'USD',
        price: 20,
        display: 'US$ 20,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UZ: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VA: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ER: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VC: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ES: {
        currency: 'EUR',
        price: 21,
        display: '21,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ET: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VE: {
        currency: 'USD',
        price: 20,
        display: 'USD20,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VG: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VI: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VN: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VU: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FI: {
        currency: 'EUR',
        price: 21,
        display: '21,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FJ: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FK: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FM: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FO: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FR: {
        currency: 'EUR',
        price: 21,
        display: '21,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WF: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GA: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GB: {
        currency: 'GBP',
        price: 20,
        display: '£20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WS: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GD: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GE: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GF: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GG: {
        currency: 'GBP',
        price: 20,
        display: '£20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GH: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GI: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GL: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GM: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GN: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GP: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GQ: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GR: {
        currency: 'EUR',
        price: 21,
        display: '21,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GS: {
        currency: 'GBP',
        price: 20,
        display: '£20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GT: {
        currency: 'USD',
        price: 20,
        display: 'USD20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GU: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GW: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GY: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HK: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HM: {
        currency: 'AUD',
        price: 23,
        display: 'A$23.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HN: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HR: {
        currency: 'EUR',
        price: 21,
        display: '21,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HT: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YE: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HU: {
        currency: 'EUR',
        price: 21,
        display: '21,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IC: {
        currency: 'EUR',
        price: 21,
        display: '21,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ID: {
        currency: 'USD',
        price: 22.2,
        display: '$22.20',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YT: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IE: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IL: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IM: {
        currency: 'GBP',
        price: 20,
        display: '£20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IN: {
        currency: 'USD',
        price: 23.6,
        display: 'US$ 23.60',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IO: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZA: {
        currency: 'USD',
        price: 23,
        display: 'US$23,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IQ: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IR: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IS: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IT: {
        currency: 'EUR',
        price: 21,
        display: '21,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZM: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JE: {
        currency: 'GBP',
        price: 20,
        display: '£20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZW: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JM: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JO: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JP: {
        currency: 'USD',
        price: 22,
        display: '$22.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KE: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KG: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KH: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KI: {
        currency: 'AUD',
        price: 23,
        display: '$23.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KM: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KN: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KP: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KR: {
        currency: 'USD',
        price: 22,
        display: 'US$22.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KW: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KY: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KZ: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LA: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LB: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LC: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LI: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LK: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LR: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LS: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LT: {
        currency: 'EUR',
        price: 21,
        display: '21,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LU: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LV: {
        currency: 'EUR',
        price: 21,
        display: '21,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LY: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MA: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MC: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MD: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ME: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MF: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MG: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MH: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MK: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ML: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MM: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MN: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MO: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MP: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MQ: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MR: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MS: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MT: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MU: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MV: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MW: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MX: {
        currency: 'USD',
        price: 20,
        display: 'USD20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MY: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MZ: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NA: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NC: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NE: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NF: {
        currency: 'AUD',
        price: 23,
        display: '$23.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NG: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NI: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NL: {
        currency: 'EUR',
        price: 21,
        display: '€ 21,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NO: {
        currency: 'EUR',
        price: 21,
        display: '€ 21,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NP: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NR: {
        currency: 'AUD',
        price: 23,
        display: '$23.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NU: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NZ: {
        currency: 'USD',
        price: 23,
        display: 'US$23.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      OM: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PA: {
        currency: 'USD',
        price: 20,
        display: 'USD20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PE: {
        currency: 'USD',
        price: 20,
        display: 'USD20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PF: {
        currency: 'USD',
        price: 20,
        display: '$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PG: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PH: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PK: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PL: {
        currency: 'EUR',
        price: 21,
        display: '21,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PM: {
        currency: 'EUR',
        price: 21,
        display: '€21.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PN: {
        currency: 'USD',
        price: 20,
        display: 'US$20.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
    },
  },
  'pdf-one-year-ocr': {
    action: 'product.price.getall',
    result: 'success',
    product: 'pdf-one-year-ocr',
    pricing: {
      PR: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PS: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PT: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '57,00 €',
            unitPrice: 90,
            unitPriceDisplay: '90,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PW: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PY: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      QA: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AD: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AE: {
        currency: 'USD',
        price: 138.6,
        display: '$138.60',
        quantityDiscount: {
          '1': {
            discountValue: 56.7,
            discountValueDisplay: '$56.70',
            unitPrice: 81.9,
            unitPriceDisplay: '$81.90',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AF: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AG: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AI: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AL: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AM: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AN: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AO: {
        currency: 'USD',
        price: 132,
        display: '132,00 US$',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '54,00 US$',
            unitPrice: 78,
            unitPriceDisplay: '78,00 US$',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AQ: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AR: {
        currency: 'USD',
        price: 132,
        display: 'US$ 132,00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$ 54,00',
            unitPrice: 78,
            unitPriceDisplay: 'US$ 78,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AS: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AT: {
        currency: 'EUR',
        price: 147,
        display: '€ 147,00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€ 57,00',
            unitPrice: 90,
            unitPriceDisplay: '€ 90,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      RE: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AU: {
        currency: 'AUD',
        price: 159,
        display: '$159.00',
        quantityDiscount: {
          '1': {
            discountValue: 69,
            discountValueDisplay: '$69.00',
            unitPrice: 90,
            unitPriceDisplay: '$90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AW: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AX: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      AZ: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      RO: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BA: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BB: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      RS: {
        currency: 'EUR',
        price: 147,
        display: '147,00 EUR',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '57,00 EUR',
            unitPrice: 90,
            unitPriceDisplay: '90,00 EUR',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BD: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BE: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '57,00 €',
            unitPrice: 90,
            unitPriceDisplay: '90,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      RU: {
        currency: 'USD',
        price: 158.4,
        display: '158,40 $',
        quantityDiscount: {
          '1': {
            discountValue: 64.8,
            discountValueDisplay: '64,80 $',
            unitPrice: 93.6,
            unitPriceDisplay: '93,60 $',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BF: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BG: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      RW: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BH: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BI: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BJ: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BL: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BM: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BN: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BO: {
        currency: 'USD',
        price: 132,
        display: 'USD132,00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'USD54,00',
            unitPrice: 78,
            unitPriceDisplay: 'USD78,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SA: {
        currency: 'USD',
        price: 151.8,
        display: '$151.80',
        quantityDiscount: {
          '1': {
            discountValue: 62.1,
            discountValueDisplay: '$62.10',
            unitPrice: 89.7,
            unitPriceDisplay: '$89.70',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BQ: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SB: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BR: {
        currency: 'USD',
        price: 132,
        display: 'US$ 132,00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$ 54,00',
            unitPrice: 78,
            unitPriceDisplay: 'US$ 78,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SC: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BS: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SD: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BT: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SE: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '57,00 €',
            unitPrice: 90,
            unitPriceDisplay: '90,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BV: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SG: {
        currency: 'USD',
        price: 143.88,
        display: 'US$143.88',
        quantityDiscount: {
          '1': {
            discountValue: 58.86,
            discountValueDisplay: 'US$58.86',
            unitPrice: 85.02,
            unitPriceDisplay: 'US$85.02',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BW: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SH: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SI: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '57,00 €',
            unitPrice: 90,
            unitPriceDisplay: '90,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BY: {
        currency: 'USD',
        price: 158.4,
        display: '$158.40',
        quantityDiscount: {
          '1': {
            discountValue: 64.8,
            discountValueDisplay: '$64.80',
            unitPrice: 93.6,
            unitPriceDisplay: '$93.60',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SJ: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      BZ: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SK: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '57,00 €',
            unitPrice: 90,
            unitPriceDisplay: '90,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SL: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SM: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SN: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SO: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CA: {
        currency: 'CAD',
        price: 159,
        display: '$159.00',
        quantityDiscount: {
          '1': {
            discountValue: 69,
            discountValueDisplay: '$69.00',
            unitPrice: 90,
            unitPriceDisplay: '$90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SR: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CC: {
        currency: 'AUD',
        price: 159,
        display: '$159.00',
        quantityDiscount: {
          '1': {
            discountValue: 69,
            discountValueDisplay: '$69.00',
            unitPrice: 90,
            unitPriceDisplay: '$90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SS: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CD: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ST: {
        currency: 'USD',
        price: 132,
        display: '132,00 US$',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '54,00 US$',
            unitPrice: 78,
            unitPriceDisplay: '78,00 US$',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CF: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SV: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CG: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CH: {
        currency: 'EUR',
        price: 147,
        display: '€ 147,00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€ 57,00',
            unitPrice: 90,
            unitPriceDisplay: '€ 90,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SX: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CI: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SY: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      SZ: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CK: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CL: {
        currency: 'USD',
        price: 157.08,
        display: 'US$157,08',
        quantityDiscount: {
          '1': {
            discountValue: 64.26,
            discountValueDisplay: 'US$64,26',
            unitPrice: 92.82,
            unitPriceDisplay: 'US$92,82',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CM: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CN: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CO: {
        currency: 'USD',
        price: 157.08,
        display: 'US$ 157,08',
        quantityDiscount: {
          '1': {
            discountValue: 64.26,
            discountValueDisplay: 'US$ 64,26',
            unitPrice: 92.82,
            unitPriceDisplay: 'US$ 92,82',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CR: {
        currency: 'USD',
        price: 132,
        display: 'USD132,00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'USD54,00',
            unitPrice: 78,
            unitPriceDisplay: 'USD78,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TC: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TD: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CU: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TF: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CV: {
        currency: 'USD',
        price: 132,
        display: '132,00 US$',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '54,00 US$',
            unitPrice: 78,
            unitPriceDisplay: '78,00 US$',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TG: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CW: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TH: {
        currency: 'USD',
        price: 141.24,
        display: '$141.24',
        quantityDiscount: {
          '1': {
            discountValue: 57.78,
            discountValueDisplay: '$57.78',
            unitPrice: 83.46,
            unitPriceDisplay: '$83.46',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CX: {
        currency: 'AUD',
        price: 159,
        display: '$159.00',
        quantityDiscount: {
          '1': {
            discountValue: 69,
            discountValueDisplay: '$69.00',
            unitPrice: 90,
            unitPriceDisplay: '$90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CY: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TJ: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      CZ: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '57,00 €',
            unitPrice: 90,
            unitPriceDisplay: '90,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TK: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TL: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TM: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TN: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TO: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TR: {
        currency: 'USD',
        price: 158.4,
        display: '$158,40',
        quantityDiscount: {
          '1': {
            discountValue: 64.8,
            discountValueDisplay: '$64,80',
            unitPrice: 93.6,
            unitPriceDisplay: '$93,60',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TT: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DE: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '57,00 €',
            unitPrice: 90,
            unitPriceDisplay: '90,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TV: {
        currency: 'AUD',
        price: 159,
        display: '$159.00',
        quantityDiscount: {
          '1': {
            discountValue: 69,
            discountValueDisplay: '$69.00',
            unitPrice: 90,
            unitPriceDisplay: '$90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TW: {
        currency: 'USD',
        price: 138.6,
        display: 'US$138.60',
        quantityDiscount: {
          '1': {
            discountValue: 56.7,
            discountValueDisplay: 'US$56.70',
            unitPrice: 81.9,
            unitPriceDisplay: 'US$81.90',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DJ: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      TZ: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DK: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '57,00 €',
            unitPrice: 90,
            unitPriceDisplay: '90,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DM: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DO: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      UA: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      UG: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      DZ: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      UM: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      EC: {
        currency: 'USD',
        price: 132,
        display: '$132,00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54,00',
            unitPrice: 78,
            unitPriceDisplay: '$78,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      US: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      EE: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      EG: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      EH: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      UY: {
        currency: 'USD',
        price: 132,
        display: 'US$ 132,00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$ 54,00',
            unitPrice: 78,
            unitPriceDisplay: 'US$ 78,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      UZ: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VA: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ER: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VC: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ES: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '57,00 €',
            unitPrice: 90,
            unitPriceDisplay: '90,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ET: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VE: {
        currency: 'USD',
        price: 132,
        display: 'USD132,00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'USD54,00',
            unitPrice: 78,
            unitPriceDisplay: 'USD78,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VG: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VI: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VN: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      VU: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FI: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '57,00 €',
            unitPrice: 90,
            unitPriceDisplay: '90,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FJ: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FK: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FM: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FO: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      FR: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '57,00 €',
            unitPrice: 90,
            unitPriceDisplay: '90,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      WF: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GA: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GB: {
        currency: 'GBP',
        price: 132,
        display: '£132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '£54.00',
            unitPrice: 78,
            unitPriceDisplay: '£78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      WS: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GD: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GE: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GF: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GG: {
        currency: 'GBP',
        price: 132,
        display: '£132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '£54.00',
            unitPrice: 78,
            unitPriceDisplay: '£78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GH: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GI: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GL: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GM: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GN: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GP: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GQ: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GR: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '57,00 €',
            unitPrice: 90,
            unitPriceDisplay: '90,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GS: {
        currency: 'GBP',
        price: 132,
        display: '£132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '£54.00',
            unitPrice: 78,
            unitPriceDisplay: '£78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GT: {
        currency: 'USD',
        price: 132,
        display: 'USD132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'USD54.00',
            unitPrice: 78,
            unitPriceDisplay: 'USD78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GU: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GW: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      GY: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HK: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HM: {
        currency: 'AUD',
        price: 159,
        display: 'A$159.00',
        quantityDiscount: {
          '1': {
            discountValue: 69,
            discountValueDisplay: 'A$69.00',
            unitPrice: 90,
            unitPriceDisplay: 'A$90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HN: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HR: {
        currency: 'EUR',
        price: 147,
        display: '147,00 EUR',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '57,00 EUR',
            unitPrice: 90,
            unitPriceDisplay: '90,00 EUR',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HT: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      YE: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      HU: {
        currency: 'EUR',
        price: 147,
        display: '147,00 EUR',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '57,00 EUR',
            unitPrice: 90,
            unitPriceDisplay: '90,00 EUR',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IC: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '57,00 €',
            unitPrice: 90,
            unitPriceDisplay: '90,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ID: {
        currency: 'USD',
        price: 146.52,
        display: '$146.52',
        quantityDiscount: {
          '1': {
            discountValue: 59.94,
            discountValueDisplay: '$59.94',
            unitPrice: 86.58,
            unitPriceDisplay: '$86.58',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      YT: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IE: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IL: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IM: {
        currency: 'GBP',
        price: 132,
        display: '£132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '£54.00',
            unitPrice: 78,
            unitPriceDisplay: '£78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IN: {
        currency: 'USD',
        price: 155.76,
        display: 'US$ 155.76',
        quantityDiscount: {
          '1': {
            discountValue: 63.72,
            discountValueDisplay: 'US$ 63.72',
            unitPrice: 92.04,
            unitPriceDisplay: 'US$ 92.04',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IO: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ZA: {
        currency: 'USD',
        price: 151.8,
        display: 'US$151,80',
        quantityDiscount: {
          '1': {
            discountValue: 62.1,
            discountValueDisplay: 'US$62,10',
            unitPrice: 89.7,
            unitPriceDisplay: 'US$89,70',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IQ: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IR: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IS: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      IT: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '57,00 €',
            unitPrice: 90,
            unitPriceDisplay: '90,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ZM: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      JE: {
        currency: 'GBP',
        price: 132,
        display: '£132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '£54.00',
            unitPrice: 78,
            unitPriceDisplay: '£78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ZW: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      JM: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      JO: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      JP: {
        currency: 'USD',
        price: 145.2,
        display: '$145.20',
        quantityDiscount: {
          '1': {
            discountValue: 59.4,
            discountValueDisplay: '$59.40',
            unitPrice: 85.8,
            unitPriceDisplay: '$85.80',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KE: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KG: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KH: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KI: {
        currency: 'AUD',
        price: 159,
        display: '$159.00',
        quantityDiscount: {
          '1': {
            discountValue: 69,
            discountValueDisplay: '$69.00',
            unitPrice: 90,
            unitPriceDisplay: '$90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KM: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KN: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KP: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KR: {
        currency: 'USD',
        price: 145.2,
        display: 'US$145.20',
        quantityDiscount: {
          '1': {
            discountValue: 59.4,
            discountValueDisplay: 'US$59.40',
            unitPrice: 85.8,
            unitPriceDisplay: 'US$85.80',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KW: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KY: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      KZ: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LA: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LB: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LC: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LI: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LK: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LR: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LS: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LT: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '57,00 €',
            unitPrice: 90,
            unitPriceDisplay: '90,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LU: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LV: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '57,00 €',
            unitPrice: 90,
            unitPriceDisplay: '90,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      LY: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MA: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MC: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MD: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ME: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MF: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MG: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MH: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MK: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      ML: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MM: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MN: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MO: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MP: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MQ: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MR: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MS: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MT: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MU: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MV: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MW: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MX: {
        currency: 'USD',
        price: 132,
        display: 'USD132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'USD54.00',
            unitPrice: 78,
            unitPriceDisplay: 'USD78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MY: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      MZ: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NA: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NC: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NE: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NF: {
        currency: 'AUD',
        price: 159,
        display: '$159.00',
        quantityDiscount: {
          '1': {
            discountValue: 69,
            discountValueDisplay: '$69.00',
            unitPrice: 90,
            unitPriceDisplay: '$90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NG: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NI: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NL: {
        currency: 'EUR',
        price: 147,
        display: '€ 147,00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€ 57,00',
            unitPrice: 90,
            unitPriceDisplay: '€ 90,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NO: {
        currency: 'EUR',
        price: 147,
        display: '€ 147,00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€ 57,00',
            unitPrice: 90,
            unitPriceDisplay: '€ 90,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NP: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NR: {
        currency: 'AUD',
        price: 159,
        display: '$159.00',
        quantityDiscount: {
          '1': {
            discountValue: 69,
            discountValueDisplay: '$69.00',
            unitPrice: 90,
            unitPriceDisplay: '$90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NU: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      NZ: {
        currency: 'USD',
        price: 151.8,
        display: 'US$151.80',
        quantityDiscount: {
          '1': {
            discountValue: 62.1,
            discountValueDisplay: 'US$62.10',
            unitPrice: 89.7,
            unitPriceDisplay: 'US$89.70',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      OM: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PA: {
        currency: 'USD',
        price: 132,
        display: 'USD132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'USD54.00',
            unitPrice: 78,
            unitPriceDisplay: 'USD78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PE: {
        currency: 'USD',
        price: 132,
        display: 'USD132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'USD54.00',
            unitPrice: 78,
            unitPriceDisplay: 'USD78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PF: {
        currency: 'USD',
        price: 132,
        display: '$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: '$54.00',
            unitPrice: 78,
            unitPriceDisplay: '$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PG: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PH: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PK: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PL: {
        currency: 'EUR',
        price: 147,
        display: '147,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '57,00 €',
            unitPrice: 90,
            unitPriceDisplay: '90,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PM: {
        currency: 'EUR',
        price: 147,
        display: '€147.00',
        quantityDiscount: {
          '1': {
            discountValue: 57,
            discountValueDisplay: '€57.00',
            unitPrice: 90,
            unitPriceDisplay: '€90.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
      PN: {
        currency: 'USD',
        price: 132,
        display: 'US$132.00',
        quantityDiscount: {
          '1': {
            discountValue: 54,
            discountValueDisplay: 'US$54.00',
            unitPrice: 78,
            unitPriceDisplay: 'US$78.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 1,
        available: {},
      },
    },
  },
  'pdf-perpetual-ocr': {
    action: 'product.price.getall',
    result: 'success',
    product: 'pdf-perpetual-ocr',
    pricing: {
      PR: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PS: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PT: {
        currency: 'EUR',
        price: 271,
        display: '271,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 235,
            unitPriceDisplay: '235,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PW: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PY: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      QA: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AD: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AE: {
        currency: 'USD',
        price: 264.6,
        display: '$264.60',
        quantityDiscount: {
          '1': {
            discountValue: 31.5,
            discountValueDisplay: '$31.50',
            unitPrice: 233.1,
            unitPriceDisplay: '$233.10',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AF: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AG: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AI: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AL: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AM: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AN: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AO: {
        currency: 'USD',
        price: 252,
        display: '252,00 US$',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '30,00 US$',
            unitPrice: 222,
            unitPriceDisplay: '222,00 US$',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AQ: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AR: {
        currency: 'USD',
        price: 252,
        display: 'US$ 252,00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$ 30,00',
            unitPrice: 222,
            unitPriceDisplay: 'US$ 222,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AS: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AT: {
        currency: 'EUR',
        price: 271,
        display: '€ 271,00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€ 36,00',
            unitPrice: 235,
            unitPriceDisplay: '€ 235,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RE: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AU: {
        currency: 'AUD',
        price: 321,
        display: '$321.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 285,
            unitPriceDisplay: '$285.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AW: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AX: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AZ: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RO: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BA: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BB: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RS: {
        currency: 'EUR',
        price: 271,
        display: '271,00 EUR',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 EUR',
            unitPrice: 235,
            unitPriceDisplay: '235,00 EUR',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BD: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BE: {
        currency: 'EUR',
        price: 271,
        display: '271,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 235,
            unitPriceDisplay: '235,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RU: {
        currency: 'USD',
        price: 302.4,
        display: '302,40 $',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 $',
            unitPrice: 266.4,
            unitPriceDisplay: '266,40 $',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BF: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BG: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RW: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BH: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BI: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BJ: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BL: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BM: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BN: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BO: {
        currency: 'USD',
        price: 252,
        display: 'USD252,00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'USD30,00',
            unitPrice: 222,
            unitPriceDisplay: 'USD222,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SA: {
        currency: 'USD',
        price: 289.8,
        display: '$289.80',
        quantityDiscount: {
          '1': {
            discountValue: 34.5,
            discountValueDisplay: '$34.50',
            unitPrice: 255.3,
            unitPriceDisplay: '$255.30',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BQ: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SB: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BR: {
        currency: 'USD',
        price: 252,
        display: 'US$ 252,00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$ 30,00',
            unitPrice: 222,
            unitPriceDisplay: 'US$ 222,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SC: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BS: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SD: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BT: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SE: {
        currency: 'EUR',
        price: 271,
        display: '271,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 235,
            unitPriceDisplay: '235,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BV: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SG: {
        currency: 'USD',
        price: 274.68,
        display: 'US$274.68',
        quantityDiscount: {
          '1': {
            discountValue: 32.7,
            discountValueDisplay: 'US$32.70',
            unitPrice: 241.98,
            unitPriceDisplay: 'US$241.98',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BW: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SH: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SI: {
        currency: 'EUR',
        price: 271,
        display: '271,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 235,
            unitPriceDisplay: '235,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BY: {
        currency: 'USD',
        price: 302.4,
        display: '$302.40',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 266.4,
            unitPriceDisplay: '$266.40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SJ: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BZ: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SK: {
        currency: 'EUR',
        price: 271,
        display: '271,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 235,
            unitPriceDisplay: '235,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SL: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SM: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SN: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SO: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CA: {
        currency: 'CAD',
        price: 321,
        display: '$321.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 285,
            unitPriceDisplay: '$285.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SR: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CC: {
        currency: 'AUD',
        price: 321,
        display: '$321.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 285,
            unitPriceDisplay: '$285.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SS: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CD: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ST: {
        currency: 'USD',
        price: 252,
        display: '252,00 US$',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '30,00 US$',
            unitPrice: 222,
            unitPriceDisplay: '222,00 US$',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CF: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SV: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CG: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CH: {
        currency: 'EUR',
        price: 271,
        display: '€ 271,00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€ 36,00',
            unitPrice: 235,
            unitPriceDisplay: '€ 235,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SX: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CI: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SY: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SZ: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CK: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CL: {
        currency: 'USD',
        price: 299.88,
        display: 'US$299,88',
        quantityDiscount: {
          '1': {
            discountValue: 35.7,
            discountValueDisplay: 'US$35,70',
            unitPrice: 264.18,
            unitPriceDisplay: 'US$264,18',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CM: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CN: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CO: {
        currency: 'USD',
        price: 299.88,
        display: 'US$ 299,88',
        quantityDiscount: {
          '1': {
            discountValue: 35.7,
            discountValueDisplay: 'US$ 35,70',
            unitPrice: 264.18,
            unitPriceDisplay: 'US$ 264,18',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CR: {
        currency: 'USD',
        price: 252,
        display: 'USD252,00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'USD30,00',
            unitPrice: 222,
            unitPriceDisplay: 'USD222,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TC: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TD: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CU: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TF: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CV: {
        currency: 'USD',
        price: 252,
        display: '252,00 US$',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '30,00 US$',
            unitPrice: 222,
            unitPriceDisplay: '222,00 US$',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TG: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CW: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TH: {
        currency: 'USD',
        price: 269.64,
        display: '$269.64',
        quantityDiscount: {
          '1': {
            discountValue: 32.1,
            discountValueDisplay: '$32.10',
            unitPrice: 237.54,
            unitPriceDisplay: '$237.54',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CX: {
        currency: 'AUD',
        price: 321,
        display: '$321.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 285,
            unitPriceDisplay: '$285.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CY: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TJ: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CZ: {
        currency: 'EUR',
        price: 271,
        display: '271,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 235,
            unitPriceDisplay: '235,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TK: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TL: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TM: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TN: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TO: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TR: {
        currency: 'USD',
        price: 302.4,
        display: '$302,40',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36,00',
            unitPrice: 266.4,
            unitPriceDisplay: '$266,40',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TT: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DE: {
        currency: 'EUR',
        price: 271,
        display: '271,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 235,
            unitPriceDisplay: '235,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TV: {
        currency: 'AUD',
        price: 321,
        display: '$321.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 285,
            unitPriceDisplay: '$285.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TW: {
        currency: 'USD',
        price: 264.6,
        display: 'US$264.60',
        quantityDiscount: {
          '1': {
            discountValue: 31.5,
            discountValueDisplay: 'US$31.50',
            unitPrice: 233.1,
            unitPriceDisplay: 'US$233.10',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DJ: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TZ: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DK: {
        currency: 'EUR',
        price: 271,
        display: '271,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 235,
            unitPriceDisplay: '235,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DM: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DO: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UA: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UG: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DZ: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UM: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EC: {
        currency: 'USD',
        price: 252,
        display: '$252,00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30,00',
            unitPrice: 222,
            unitPriceDisplay: '$222,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      US: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EE: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EG: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EH: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UY: {
        currency: 'USD',
        price: 252,
        display: 'US$ 252,00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$ 30,00',
            unitPrice: 222,
            unitPriceDisplay: 'US$ 222,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UZ: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VA: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ER: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VC: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ES: {
        currency: 'EUR',
        price: 271,
        display: '271,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 235,
            unitPriceDisplay: '235,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ET: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VE: {
        currency: 'USD',
        price: 252,
        display: 'USD252,00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'USD30,00',
            unitPrice: 222,
            unitPriceDisplay: 'USD222,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VG: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VI: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VN: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VU: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FI: {
        currency: 'EUR',
        price: 271,
        display: '271,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 235,
            unitPriceDisplay: '235,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FJ: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FK: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FM: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FO: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FR: {
        currency: 'EUR',
        price: 271,
        display: '271,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 235,
            unitPriceDisplay: '235,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WF: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GA: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GB: {
        currency: 'GBP',
        price: 249,
        display: '£249.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '£30.00',
            unitPrice: 219,
            unitPriceDisplay: '£219.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WS: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GD: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GE: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GF: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GG: {
        currency: 'GBP',
        price: 249,
        display: '£249.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '£30.00',
            unitPrice: 219,
            unitPriceDisplay: '£219.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GH: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GI: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GL: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GM: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GN: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GP: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GQ: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GR: {
        currency: 'EUR',
        price: 271,
        display: '271,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 235,
            unitPriceDisplay: '235,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GS: {
        currency: 'GBP',
        price: 249,
        display: '£249.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '£30.00',
            unitPrice: 219,
            unitPriceDisplay: '£219.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GT: {
        currency: 'USD',
        price: 252,
        display: 'USD252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'USD30.00',
            unitPrice: 222,
            unitPriceDisplay: 'USD222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GU: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GW: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GY: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HK: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HM: {
        currency: 'AUD',
        price: 321,
        display: 'A$321.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: 'A$36.00',
            unitPrice: 285,
            unitPriceDisplay: 'A$285.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HN: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HR: {
        currency: 'EUR',
        price: 271,
        display: '271,00 EUR',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 EUR',
            unitPrice: 235,
            unitPriceDisplay: '235,00 EUR',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HT: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YE: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HU: {
        currency: 'EUR',
        price: 271,
        display: '271,00 EUR',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 EUR',
            unitPrice: 235,
            unitPriceDisplay: '235,00 EUR',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IC: {
        currency: 'EUR',
        price: 271,
        display: '271,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 235,
            unitPriceDisplay: '235,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ID: {
        currency: 'USD',
        price: 279.72,
        display: '$279.72',
        quantityDiscount: {
          '1': {
            discountValue: 33.3,
            discountValueDisplay: '$33.30',
            unitPrice: 246.42,
            unitPriceDisplay: '$246.42',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YT: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IE: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IL: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IM: {
        currency: 'GBP',
        price: 249,
        display: '£249.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '£30.00',
            unitPrice: 219,
            unitPriceDisplay: '£219.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IN: {
        currency: 'USD',
        price: 297.36,
        display: 'US$ 297.36',
        quantityDiscount: {
          '1': {
            discountValue: 35.4,
            discountValueDisplay: 'US$ 35.40',
            unitPrice: 261.96,
            unitPriceDisplay: 'US$ 261.96',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IO: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZA: {
        currency: 'USD',
        price: 289.8,
        display: 'US$289,80',
        quantityDiscount: {
          '1': {
            discountValue: 34.5,
            discountValueDisplay: 'US$34,50',
            unitPrice: 255.3,
            unitPriceDisplay: 'US$255,30',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IQ: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IR: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IS: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IT: {
        currency: 'EUR',
        price: 271,
        display: '271,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 235,
            unitPriceDisplay: '235,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZM: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JE: {
        currency: 'GBP',
        price: 249,
        display: '£249.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '£30.00',
            unitPrice: 219,
            unitPriceDisplay: '£219.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZW: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JM: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JO: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JP: {
        currency: 'USD',
        price: 277.2,
        display: '$277.20',
        quantityDiscount: {
          '1': {
            discountValue: 33,
            discountValueDisplay: '$33.00',
            unitPrice: 244.2,
            unitPriceDisplay: '$244.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KE: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KG: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KH: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KI: {
        currency: 'AUD',
        price: 321,
        display: '$321.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 285,
            unitPriceDisplay: '$285.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KM: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KN: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KP: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KR: {
        currency: 'USD',
        price: 277.2,
        display: 'US$277.20',
        quantityDiscount: {
          '1': {
            discountValue: 33,
            discountValueDisplay: 'US$33.00',
            unitPrice: 244.2,
            unitPriceDisplay: 'US$244.20',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KW: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KY: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KZ: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LA: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LB: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LC: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LI: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LK: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LR: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LS: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LT: {
        currency: 'EUR',
        price: 271,
        display: '271,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 235,
            unitPriceDisplay: '235,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LU: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LV: {
        currency: 'EUR',
        price: 271,
        display: '271,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 235,
            unitPriceDisplay: '235,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LY: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MA: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MC: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MD: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ME: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MF: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MG: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MH: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MK: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ML: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MM: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MN: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MO: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MP: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MQ: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MR: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MS: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MT: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MU: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MV: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MW: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MX: {
        currency: 'USD',
        price: 252,
        display: 'USD252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'USD30.00',
            unitPrice: 222,
            unitPriceDisplay: 'USD222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MY: {
        currency: 'USD',
        price: 272.16,
        display: 'US$272.16',
        quantityDiscount: {
          '1': {
            discountValue: 32.4,
            discountValueDisplay: 'US$32.40',
            unitPrice: 239.76,
            unitPriceDisplay: 'US$239.76',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MZ: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NA: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NC: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NE: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NF: {
        currency: 'AUD',
        price: 321,
        display: '$321.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 285,
            unitPriceDisplay: '$285.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NG: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NI: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NL: {
        currency: 'EUR',
        price: 271,
        display: '€ 271,00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€ 36,00',
            unitPrice: 235,
            unitPriceDisplay: '€ 235,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NO: {
        currency: 'EUR',
        price: 271,
        display: '€ 271,00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€ 36,00',
            unitPrice: 235,
            unitPriceDisplay: '€ 235,00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NP: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NR: {
        currency: 'AUD',
        price: 321,
        display: '$321.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '$36.00',
            unitPrice: 285,
            unitPriceDisplay: '$285.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NU: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NZ: {
        currency: 'USD',
        price: 289.8,
        display: 'US$289.80',
        quantityDiscount: {
          '1': {
            discountValue: 34.5,
            discountValueDisplay: 'US$34.50',
            unitPrice: 255.3,
            unitPriceDisplay: 'US$255.30',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      OM: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PA: {
        currency: 'USD',
        price: 252,
        display: 'USD252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'USD30.00',
            unitPrice: 222,
            unitPriceDisplay: 'USD222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PE: {
        currency: 'USD',
        price: 252,
        display: 'USD252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'USD30.00',
            unitPrice: 222,
            unitPriceDisplay: 'USD222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PF: {
        currency: 'USD',
        price: 252,
        display: '$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: '$30.00',
            unitPrice: 222,
            unitPriceDisplay: '$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PG: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PH: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PK: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PL: {
        currency: 'EUR',
        price: 271,
        display: '271,00 €',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '36,00 €',
            unitPrice: 235,
            unitPriceDisplay: '235,00 €',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PM: {
        currency: 'EUR',
        price: 271,
        display: '€271.00',
        quantityDiscount: {
          '1': {
            discountValue: 36,
            discountValueDisplay: '€36.00',
            unitPrice: 235,
            unitPriceDisplay: '€235.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PN: {
        currency: 'USD',
        price: 252,
        display: 'US$252.00',
        quantityDiscount: {
          '1': {
            discountValue: 30,
            discountValueDisplay: 'US$30.00',
            unitPrice: 222,
            unitPriceDisplay: 'US$222.00',
          },
        },
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
    },
  },
  'pdf-monthly-uk': {
    action: 'product.price.getall',
    result: 'success',
    product: 'pdf-monthly-uk',
    pricing: {
      PR: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PS: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PT: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PY: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      QA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AD: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AE: {
        currency: 'USD',
        price: 5.25,
        display: '$5.25',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AI: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AL: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AO: {
        currency: 'USD',
        price: 5,
        display: '5,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AQ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AR: {
        currency: 'USD',
        price: 5,
        display: 'US$ 5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AS: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AT: {
        currency: 'EUR',
        price: 6,
        display: '€ 6,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RE: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AU: {
        currency: 'AUD',
        price: 8,
        display: '$8.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AW: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AX: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      AZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RO: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BA: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BB: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RS: {
        currency: 'EUR',
        price: 6,
        display: '6,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BD: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BE: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RU: {
        currency: 'USD',
        price: 6,
        display: '6,00 $',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BG: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      RW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BH: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BI: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BJ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BL: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BO: {
        currency: 'USD',
        price: 5,
        display: 'USD5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SA: {
        currency: 'USD',
        price: 5.75,
        display: '$5.75',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BQ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SB: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BR: {
        currency: 'USD',
        price: 5,
        display: 'US$ 5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SC: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SD: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BT: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SE: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BV: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SG: {
        currency: 'USD',
        price: 5.45,
        display: 'US$5.45',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SH: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SI: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BY: {
        currency: 'USD',
        price: 6,
        display: '$6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SJ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      BZ: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SK: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SL: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SM: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SO: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CA: {
        currency: 'CAD',
        price: 7,
        display: '$7.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SR: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CC: {
        currency: 'AUD',
        price: 8,
        display: '$8.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CD: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ST: {
        currency: 'USD',
        price: 5,
        display: '5,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SV: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CG: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CH: {
        currency: 'EUR',
        price: 6,
        display: '€ 6,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SX: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CI: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SY: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      SZ: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CL: {
        currency: 'USD',
        price: 5.95,
        display: 'US$5,95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CN: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CO: {
        currency: 'USD',
        price: 5.95,
        display: 'US$ 5,95',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CR: {
        currency: 'USD',
        price: 5,
        display: 'USD5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TC: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TD: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CU: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TF: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CV: {
        currency: 'USD',
        price: 5,
        display: '5,00 US$',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TG: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CW: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TH: {
        currency: 'USD',
        price: 5.35,
        display: '$5.35',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CX: {
        currency: 'AUD',
        price: 8,
        display: '$8.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CY: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TJ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      CZ: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TL: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TO: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TR: {
        currency: 'USD',
        price: 6,
        display: '$6,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TT: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DE: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TV: {
        currency: 'AUD',
        price: 8,
        display: '$8.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TW: {
        currency: 'USD',
        price: 5.25,
        display: 'US$5.25',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DJ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      TZ: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DK: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DO: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      DZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EC: {
        currency: 'USD',
        price: 5,
        display: '$5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      US: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EE: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EG: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      EH: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UY: {
        currency: 'USD',
        price: 5,
        display: 'US$ 5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      UZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VA: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ER: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VC: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ES: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ET: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VE: {
        currency: 'USD',
        price: 5,
        display: 'USD5,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VI: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      VU: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FI: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FJ: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FO: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      FR: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GB: {
        currency: 'GBP',
        price: 5,
        display: '£5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      WS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GD: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GE: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GF: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GG: {
        currency: 'GBP',
        price: 5,
        display: '£5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GH: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GI: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GL: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GP: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GQ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GR: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GS: {
        currency: 'GBP',
        price: 5,
        display: '£5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GT: {
        currency: 'USD',
        price: 5,
        display: 'USD5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GU: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GW: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      GY: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HM: {
        currency: 'AUD',
        price: 8,
        display: 'A$8.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HR: {
        currency: 'EUR',
        price: 6,
        display: '6,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HT: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YE: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      HU: {
        currency: 'EUR',
        price: 7,
        display: '7,00 EUR',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IC: {
        currency: 'EUR',
        price: 5,
        display: '5,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ID: {
        currency: 'USD',
        price: 5.55,
        display: '$5.55',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      YT: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IE: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IL: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IM: {
        currency: 'GBP',
        price: 5,
        display: '£5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IN: {
        currency: 'USD',
        price: 5.9,
        display: 'US$ 5.90',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IO: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZA: {
        currency: 'USD',
        price: 5.75,
        display: 'US$5,75',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IQ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IR: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IS: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      IT: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JE: {
        currency: 'GBP',
        price: 5,
        display: '£5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ZW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JO: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      JP: {
        currency: 'USD',
        price: 5.5,
        display: '$5.50',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KE: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KG: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KH: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KI: {
        currency: 'AUD',
        price: 8,
        display: '$8.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KN: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KP: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KR: {
        currency: 'USD',
        price: 5.5,
        display: 'US$5.50',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KW: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KY: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      KZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LB: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LC: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LI: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LK: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LR: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LT: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LU: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LV: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      LY: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MC: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MD: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ME: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MF: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MH: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MK: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      ML: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MO: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MP: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MQ: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MR: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MT: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MU: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MV: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MX: {
        currency: 'USD',
        price: 5,
        display: 'USD5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MY: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      MZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NA: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NC: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NE: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NF: {
        currency: 'AUD',
        price: 8,
        display: '$8.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NI: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NL: {
        currency: 'EUR',
        price: 6,
        display: '€ 6,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NO: {
        currency: 'EUR',
        price: 6,
        display: '€ 6,00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NP: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NR: {
        currency: 'AUD',
        price: 8,
        display: '$8.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NU: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      NZ: {
        currency: 'USD',
        price: 5.75,
        display: 'US$5.75',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      OM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PA: {
        currency: 'USD',
        price: 5,
        display: 'USD5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PE: {
        currency: 'USD',
        price: 5,
        display: 'USD5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PH: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PL: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PM: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
      PN: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {},
        discountReason: 'null',
        discountPeriodCount: 'null',
        available: {},
      },
    },
  },
  pdf: {
    action: 'product.price.getall',
    result: 'success',
    product: 'pdf',
    pricing: {
      PR: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      PS: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      PT: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.8,
            discountValueDisplay: '4,80 €',
            unitPrice: 1.2,
            unitPriceDisplay: '1,20 €',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      PW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      PY: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      QA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      AD: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '€4.00',
            unitPrice: 1,
            unitPriceDisplay: '€1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      AE: {
        currency: 'USD',
        price: 5.25,
        display: '$5.25',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.2,
            discountValueDisplay: '$4.20',
            unitPrice: 1.05,
            unitPriceDisplay: '$1.05',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      AF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      AG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      AI: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      AL: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '€4.00',
            unitPrice: 1,
            unitPriceDisplay: '€1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      AM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      AN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      AO: {
        currency: 'USD',
        price: 5,
        display: '5,00 US$',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '4,00 US$',
            unitPrice: 1,
            unitPriceDisplay: '1,00 US$',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      AQ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      AR: {
        currency: 'USD',
        price: 5,
        display: 'US$ 5,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$ 4,00',
            unitPrice: 1,
            unitPriceDisplay: 'US$ 1,00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      AS: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      AT: {
        currency: 'EUR',
        price: 6,
        display: '€ 6,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.8,
            discountValueDisplay: '€ 4,80',
            unitPrice: 1.2,
            unitPriceDisplay: '€ 1,20',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      RE: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '€4.00',
            unitPrice: 1,
            unitPriceDisplay: '€1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      AU: {
        currency: 'AUD',
        price: 10,
        display: '$10.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 8,
            discountValueDisplay: '$8.00',
            unitPrice: 2,
            unitPriceDisplay: '$2.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      AW: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      AX: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '€4.00',
            unitPrice: 1,
            unitPriceDisplay: '€1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      AZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      RO: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.8,
            discountValueDisplay: '€4.80',
            unitPrice: 1.2,
            unitPriceDisplay: '€1.20',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      BA: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '€4.00',
            unitPrice: 1,
            unitPriceDisplay: '€1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      BB: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      RS: {
        currency: 'EUR',
        price: 6,
        display: '6,00 EUR',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.8,
            discountValueDisplay: '4,80 EUR',
            unitPrice: 1.2,
            unitPriceDisplay: '1,20 EUR',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      BD: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      BE: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.8,
            discountValueDisplay: '4,80 €',
            unitPrice: 1.2,
            unitPriceDisplay: '1,20 €',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      RU: {
        currency: 'USD',
        price: 6,
        display: '6,00 $',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.8,
            discountValueDisplay: '4,80 $',
            unitPrice: 1.2,
            unitPriceDisplay: '1,20 $',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      BF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      BG: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.8,
            discountValueDisplay: '€4.80',
            unitPrice: 1.2,
            unitPriceDisplay: '€1.20',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      RW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      BH: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      BI: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      BJ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      BL: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '€4.00',
            unitPrice: 1,
            unitPriceDisplay: '€1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      BM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      BN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      BO: {
        currency: 'USD',
        price: 5,
        display: 'USD5,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'USD4,00',
            unitPrice: 1,
            unitPriceDisplay: 'USD1,00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      SA: {
        currency: 'USD',
        price: 5.75,
        display: '$5.75',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.6,
            discountValueDisplay: '$4.60',
            unitPrice: 1.15,
            unitPriceDisplay: '$1.15',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      BQ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      SB: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      BR: {
        currency: 'USD',
        price: 5,
        display: 'US$ 5,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$ 4,00',
            unitPrice: 1,
            unitPriceDisplay: 'US$ 1,00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      SC: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      BS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      SD: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      BT: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      SE: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.8,
            discountValueDisplay: '4,80 €',
            unitPrice: 1.2,
            unitPriceDisplay: '1,20 €',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      BV: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      SG: {
        currency: 'USD',
        price: 5.45,
        display: 'US$5.45',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.36,
            discountValueDisplay: 'US$4.36',
            unitPrice: 1.09,
            unitPriceDisplay: 'US$1.09',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      BW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      SH: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      SI: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.8,
            discountValueDisplay: '4,80 €',
            unitPrice: 1.2,
            unitPriceDisplay: '1,20 €',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      BY: {
        currency: 'USD',
        price: 6,
        display: '$6.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.8,
            discountValueDisplay: '$4.80',
            unitPrice: 1.2,
            unitPriceDisplay: '$1.20',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      SJ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      BZ: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      SK: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.8,
            discountValueDisplay: '4,80 €',
            unitPrice: 1.2,
            unitPriceDisplay: '1,20 €',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      SL: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      SM: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '€4.00',
            unitPrice: 1,
            unitPriceDisplay: '€1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      SN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      SO: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      CA: {
        currency: 'CAD',
        price: 7,
        display: '$7.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 5.6,
            discountValueDisplay: '$5.60',
            unitPrice: 1.4,
            unitPriceDisplay: '$1.40',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      SR: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      CC: {
        currency: 'AUD',
        price: 10,
        display: '$10.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 8,
            discountValueDisplay: '$8.00',
            unitPrice: 2,
            unitPriceDisplay: '$2.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      SS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      CD: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      ST: {
        currency: 'USD',
        price: 5,
        display: '5,00 US$',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '4,00 US$',
            unitPrice: 1,
            unitPriceDisplay: '1,00 US$',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      CF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      SV: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      CG: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      CH: {
        currency: 'EUR',
        price: 6,
        display: '€ 6,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.8,
            discountValueDisplay: '€ 4,80',
            unitPrice: 1.2,
            unitPriceDisplay: '€ 1,20',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      SX: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      CI: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      SY: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      SZ: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      CK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      CL: {
        currency: 'USD',
        price: 5.95,
        display: 'US$5,95',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.76,
            discountValueDisplay: 'US$4,76',
            unitPrice: 1.19,
            unitPriceDisplay: 'US$1,19',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      CM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      CN: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      CO: {
        currency: 'USD',
        price: 5.95,
        display: 'US$ 5,95',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.76,
            discountValueDisplay: 'US$ 4,76',
            unitPrice: 1.19,
            unitPriceDisplay: 'US$ 1,19',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      CR: {
        currency: 'USD',
        price: 5,
        display: 'USD5,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'USD4,00',
            unitPrice: 1,
            unitPriceDisplay: 'USD1,00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      TC: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      TD: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      CU: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      TF: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '€4.00',
            unitPrice: 1,
            unitPriceDisplay: '€1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      CV: {
        currency: 'USD',
        price: 5,
        display: '5,00 US$',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '4,00 US$',
            unitPrice: 1,
            unitPriceDisplay: '1,00 US$',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      TG: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      CW: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      TH: {
        currency: 'USD',
        price: 5.35,
        display: '$5.35',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.28,
            discountValueDisplay: '$4.28',
            unitPrice: 1.07,
            unitPriceDisplay: '$1.07',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      CX: {
        currency: 'AUD',
        price: 10,
        display: '$10.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 8,
            discountValueDisplay: '$8.00',
            unitPrice: 2,
            unitPriceDisplay: '$2.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      CY: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.8,
            discountValueDisplay: '€4.80',
            unitPrice: 1.2,
            unitPriceDisplay: '€1.20',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      TJ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      CZ: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.8,
            discountValueDisplay: '4,80 €',
            unitPrice: 1.2,
            unitPriceDisplay: '1,20 €',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      TK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      TL: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      TM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      TN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      TO: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      TR: {
        currency: 'USD',
        price: 6,
        display: '$6,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.8,
            discountValueDisplay: '$4,80',
            unitPrice: 1.2,
            unitPriceDisplay: '$1,20',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      TT: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      DE: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.8,
            discountValueDisplay: '4,80 €',
            unitPrice: 1.2,
            unitPriceDisplay: '1,20 €',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      TV: {
        currency: 'AUD',
        price: 10,
        display: '$10.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 8,
            discountValueDisplay: '$8.00',
            unitPrice: 2,
            unitPriceDisplay: '$2.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      TW: {
        currency: 'USD',
        price: 5.25,
        display: 'US$5.25',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.2,
            discountValueDisplay: 'US$4.20',
            unitPrice: 1.05,
            unitPriceDisplay: 'US$1.05',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      DJ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      TZ: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      DK: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.8,
            discountValueDisplay: '4,80 €',
            unitPrice: 1.2,
            unitPriceDisplay: '1,20 €',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      DM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      DO: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      UA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      UG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      DZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      UM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      EC: {
        currency: 'USD',
        price: 5,
        display: '$5,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4,00',
            unitPrice: 1,
            unitPriceDisplay: '$1,00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      US: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      EE: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.8,
            discountValueDisplay: '€4.80',
            unitPrice: 1.2,
            unitPriceDisplay: '€1.20',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      EG: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      EH: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      UY: {
        currency: 'USD',
        price: 5,
        display: 'US$ 5,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$ 4,00',
            unitPrice: 1,
            unitPriceDisplay: 'US$ 1,00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      UZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      VA: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '€4.00',
            unitPrice: 1,
            unitPriceDisplay: '€1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      ER: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      VC: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      ES: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.8,
            discountValueDisplay: '4,80 €',
            unitPrice: 1.2,
            unitPriceDisplay: '1,20 €',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      ET: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      VE: {
        currency: 'USD',
        price: 5,
        display: 'USD5,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'USD4,00',
            unitPrice: 1,
            unitPriceDisplay: 'USD1,00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      VG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      VI: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      VN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      VU: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      FI: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.8,
            discountValueDisplay: '4,80 €',
            unitPrice: 1.2,
            unitPriceDisplay: '1,20 €',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      FJ: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      FK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      FM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      FO: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      FR: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.8,
            discountValueDisplay: '4,80 €',
            unitPrice: 1.2,
            unitPriceDisplay: '1,20 €',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      WF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      GA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      GB: {
        currency: 'GBP',
        price: 7,
        display: '£7.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 5.6,
            discountValueDisplay: '£5.60',
            unitPrice: 1.4,
            unitPriceDisplay: '£1.40',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      WS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      GD: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      GE: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '€4.00',
            unitPrice: 1,
            unitPriceDisplay: '€1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      GF: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '€4.00',
            unitPrice: 1,
            unitPriceDisplay: '€1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      GG: {
        currency: 'GBP',
        price: 7,
        display: '£7.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 5.6,
            discountValueDisplay: '£5.60',
            unitPrice: 1.4,
            unitPriceDisplay: '£1.40',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      GH: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      GI: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      GL: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      GM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      GN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      GP: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '€4.00',
            unitPrice: 1,
            unitPriceDisplay: '€1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      GQ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      GR: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.8,
            discountValueDisplay: '4,80 €',
            unitPrice: 1.2,
            unitPriceDisplay: '1,20 €',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      GS: {
        currency: 'GBP',
        price: 7,
        display: '£7.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 5.6,
            discountValueDisplay: '£5.60',
            unitPrice: 1.4,
            unitPriceDisplay: '£1.40',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      GT: {
        currency: 'USD',
        price: 5,
        display: 'USD5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'USD4.00',
            unitPrice: 1,
            unitPriceDisplay: 'USD1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      GU: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      GW: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      GY: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      HK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      HM: {
        currency: 'AUD',
        price: 10,
        display: 'A$10.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 8,
            discountValueDisplay: 'A$8.00',
            unitPrice: 2,
            unitPriceDisplay: 'A$2.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      HN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      HR: {
        currency: 'EUR',
        price: 6,
        display: '6,00 EUR',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.8,
            discountValueDisplay: '4,80 EUR',
            unitPrice: 1.2,
            unitPriceDisplay: '1,20 EUR',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      HT: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      YE: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      HU: {
        currency: 'EUR',
        price: 7,
        display: '7,00 EUR',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 5.6,
            discountValueDisplay: '5,60 EUR',
            unitPrice: 1.4,
            unitPriceDisplay: '1,40 EUR',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      IC: {
        currency: 'EUR',
        price: 5,
        display: '5,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '4,00 €',
            unitPrice: 1,
            unitPriceDisplay: '1,00 €',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      ID: {
        currency: 'USD',
        price: 5.55,
        display: '$5.55',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.44,
            discountValueDisplay: '$4.44',
            unitPrice: 1.11,
            unitPriceDisplay: '$1.11',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      YT: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '€4.00',
            unitPrice: 1,
            unitPriceDisplay: '€1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      IE: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.8,
            discountValueDisplay: '€4.80',
            unitPrice: 1.2,
            unitPriceDisplay: '€1.20',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      IL: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      IM: {
        currency: 'GBP',
        price: 7,
        display: '£7.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 5.6,
            discountValueDisplay: '£5.60',
            unitPrice: 1.4,
            unitPriceDisplay: '£1.40',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      IN: {
        currency: 'USD',
        price: 5.9,
        display: 'US$ 5.90',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.73,
            discountValueDisplay: 'US$ 4.73',
            unitPrice: 1.17,
            unitPriceDisplay: 'US$ 1.17',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      IO: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      ZA: {
        currency: 'USD',
        price: 5.75,
        display: 'US$5,75',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.6,
            discountValueDisplay: 'US$4,60',
            unitPrice: 1.15,
            unitPriceDisplay: 'US$1,15',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      IQ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      IR: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      IS: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.8,
            discountValueDisplay: '€4.80',
            unitPrice: 1.2,
            unitPriceDisplay: '€1.20',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      IT: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.8,
            discountValueDisplay: '4,80 €',
            unitPrice: 1.2,
            unitPriceDisplay: '1,20 €',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      ZM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      JE: {
        currency: 'GBP',
        price: 7,
        display: '£7.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 5.6,
            discountValueDisplay: '£5.60',
            unitPrice: 1.4,
            unitPriceDisplay: '£1.40',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      ZW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      JM: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      JO: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      JP: {
        currency: 'USD',
        price: 5.5,
        display: '$5.50',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.4,
            discountValueDisplay: '$4.40',
            unitPrice: 1.1,
            unitPriceDisplay: '$1.10',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      KE: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      KG: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      KH: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      KI: {
        currency: 'AUD',
        price: 10,
        display: '$10.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 8,
            discountValueDisplay: '$8.00',
            unitPrice: 2,
            unitPriceDisplay: '$2.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      KM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      KN: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      KP: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      KR: {
        currency: 'USD',
        price: 5.5,
        display: 'US$5.50',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.4,
            discountValueDisplay: 'US$4.40',
            unitPrice: 1.1,
            unitPriceDisplay: 'US$1.10',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      KW: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      KY: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      KZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      LA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      LB: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      LC: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      LI: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '€4.00',
            unitPrice: 1,
            unitPriceDisplay: '€1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      LK: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      LR: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      LS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      LT: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.8,
            discountValueDisplay: '4,80 €',
            unitPrice: 1.2,
            unitPriceDisplay: '1,20 €',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      LU: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.8,
            discountValueDisplay: '€4.80',
            unitPrice: 1.2,
            unitPriceDisplay: '€1.20',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      LV: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.8,
            discountValueDisplay: '4,80 €',
            unitPrice: 1.2,
            unitPriceDisplay: '1,20 €',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      LY: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      MA: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      MC: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '€4.00',
            unitPrice: 1,
            unitPriceDisplay: '€1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      MD: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      ME: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '€4.00',
            unitPrice: 1,
            unitPriceDisplay: '€1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      MF: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '€4.00',
            unitPrice: 1,
            unitPriceDisplay: '€1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      MG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      MH: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      MK: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      ML: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      MM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      MN: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      MO: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      MP: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      MQ: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '€4.00',
            unitPrice: 1,
            unitPriceDisplay: '€1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      MR: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      MS: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      MT: {
        currency: 'EUR',
        price: 6,
        display: '€6.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.8,
            discountValueDisplay: '€4.80',
            unitPrice: 1.2,
            unitPriceDisplay: '€1.20',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      MU: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      MV: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      MW: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      MX: {
        currency: 'USD',
        price: 5,
        display: 'USD5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'USD4.00',
            unitPrice: 1,
            unitPriceDisplay: 'USD1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      MY: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      MZ: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      NA: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      NC: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      NE: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      NF: {
        currency: 'AUD',
        price: 10,
        display: '$10.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 8,
            discountValueDisplay: '$8.00',
            unitPrice: 2,
            unitPriceDisplay: '$2.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      NG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      NI: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      NL: {
        currency: 'EUR',
        price: 6,
        display: '€ 6,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.8,
            discountValueDisplay: '€ 4,80',
            unitPrice: 1.2,
            unitPriceDisplay: '€ 1,20',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      NO: {
        currency: 'EUR',
        price: 6,
        display: '€ 6,00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.8,
            discountValueDisplay: '€ 4,80',
            unitPrice: 1.2,
            unitPriceDisplay: '€ 1,20',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      NP: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      NR: {
        currency: 'AUD',
        price: 10,
        display: '$10.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 8,
            discountValueDisplay: '$8.00',
            unitPrice: 2,
            unitPriceDisplay: '$2.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      NU: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      NZ: {
        currency: 'USD',
        price: 5.75,
        display: 'US$5.75',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.6,
            discountValueDisplay: 'US$4.60',
            unitPrice: 1.15,
            unitPriceDisplay: 'US$1.15',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      OM: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      PA: {
        currency: 'USD',
        price: 5,
        display: 'USD5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'USD4.00',
            unitPrice: 1,
            unitPriceDisplay: 'USD1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      PE: {
        currency: 'USD',
        price: 5,
        display: 'USD5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'USD4.00',
            unitPrice: 1,
            unitPriceDisplay: 'USD1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      PF: {
        currency: 'USD',
        price: 5,
        display: '$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '$4.00',
            unitPrice: 1,
            unitPriceDisplay: '$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      PG: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      PH: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      PK: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      PL: {
        currency: 'EUR',
        price: 6,
        display: '6,00 €',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4.8,
            discountValueDisplay: '4,80 €',
            unitPrice: 1.2,
            unitPriceDisplay: '1,20 €',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      PM: {
        currency: 'EUR',
        price: 5,
        display: '€5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: '€4.00',
            unitPrice: 1,
            unitPriceDisplay: '€1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
      PN: {
        currency: 'USD',
        price: 5,
        display: 'US$5.00',
        quantityDiscount: {
          '1': {
            discountPercent: 80,
            discountValue: 4,
            discountValueDisplay: 'US$4.00',
            unitPrice: 1,
            unitPriceDisplay: 'US$1.00',
          },
        },
        discountReason: { en: 'Renewal once off' },
        discountPeriodCount: 1,
        available: {},
      },
    },
  },
};
